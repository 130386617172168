.wrapper {
  padding: 0 10%;
  margin: 20px auto;
}

.rowContent {
  justify-content: space-between;
}

.colContent {
  margin-bottom: 30px;
}

.infoName {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #c3002a;
}

.lineContent {
  display: flex;
}

.lineContent p {
  color: #c3002a;
  margin-right: 5px;
  font-weight: bold;
}

.lineContent a:hover {
  color: #c3002a;
}

.buttonSubmit {
  width: 200px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  padding: 0 10px;
  height: 50px;
  color: #fff;
  background-color: #c3002a !important;
  border: none !important;
  border-radius: 5px !important;
  margin-top: 20px;
}

.ant-btn-primary:focus {
  outline: none !important;
}

@media screen and (max-width: 500px) {
  .buttonSubmit {
    width: 200px;
  }
}

/* ModalConfirm */
.ant-result {
  padding: 25px 32px !important;
}

.groupButtonModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.btnConfirm {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 20px;
  padding: 0 10px;
  height: 50px;
  color: #fff;
  background-color: #c3002a !important;
  border: none !important;
  border-radius: 5px !important;
}

@media screen and (max-width: 1000px) {
  .wrapper {
    padding: 0 10px;
    margin: 20px auto;
  }
}
