.header-admin {
  background-color: #ed1a36 !important;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 5px 10px rgb(129 126 126 / 31%);
}

.sider-admin {
  background-color: #fff !important;
  height: 92vh;
  box-shadow: 1px 1px rgb(129 126 126 / 31%);
  overflow-y: scroll;
}

.ant-layout-sider-trigger {
  background-color: #ed1a36 !important;
}

.sider-admin::-webkit-scrollbar {
  display: none;
}

.content-admin {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80vh;
}
