.wrapperCustoms {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 5rem;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  height: 70vh;
}

.rowContent {
  margin: 10px 0;
}

.wrapperContent {
  display: flex;
}

.label {
  min-width: 120px;
  text-align: right;
  padding: 0 10px;
}

.required {
  color: red;
}

.width60Percent {
  width: 75%;
}

.widthSelect {
  width: 300px;
}
