body {
  background: #ffffff;
}

.header {
  background: white;
}

.row-top .content {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.row-top .content .blogo {
  padding: 0px 0px 0px;
}

.row-top .link-top {
  float: left;
}

.search input {
  height: 30px !important;
}

.search button {
  left: 10px !important;
  top: 5px !important;
}

.link-top li a:hover {
  color: #ee0033 !important;
}

.bacgroud-header {
  box-shadow: 0 5px 10px rgba(129, 126, 126, 0.31);
}

.bacgroud-header.row-menu .boxmenu ul li a {
  color: #fff !important;
  text-transform: uppercase;
}

.has-sub .current:hover * {
  color: #ee0033 !important;
}

.login-register ul li .sub-regis .inner-sub-register .row-item {
  color: #000000 !important;
}

.login-register ul li .sub-regis .inner-sub-register .row-item > a {
  color: #000000 !important;
}

span.tkc {
  border: 1px solid #000000 !important;
}

.text-color-display {
  color: #ee0033 !important;
}

/* .tab-title li a:hover,
.tab-title li.active a {
    color: #EE0033 !important;
    border-bottom: 2px solid #EE0033 !important;
} */

.button-login-bacgroud {
  background: #ee0033 !important;
  color: #ffffff !important;
}

.button-register-bacgroud {
  line-height: 42px !important;
  color: #000000 !important;
  font-weight: bold !important;
}

.button-register-bacgroud:hover {
  background: unset !important;
  color: #ffffff !important;
}

.login-register ul > li > div.hello span.txt {
  color: #000000 !important;
}

.login-register ul > li > div.hello span.txt:after {
  border-top: 5px solid #000000 !important;
}

/* .button-text-red {
    color: #EE0033 !important;
} */

.button-text-red:hover {
  border-color: #ee0033 !important;
}

.button-text-red-more {
  border: 2px solid #ee0033 !important;
  color: #ee0033 !important;
}

.button-text-red-more:hover {
  background: #ee0033 !important;
  color: #ffffff !important;
}

/* .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .slick-arrow {
    color: #EE0033 !important;
} */

.banner-slideshow.owl-carousel .owl-nav button.owl-next,
.banner-slideshow.owl-carousel .owl-nav button.owl-prev {
  color: #fff;
  background: #ccc;
  border: 1px solid #e6e6e6;
  width: 56px;
  height: 56px;
}

.slider button.owl-dot.active span {
  background: #ee0033 !important;
}

.slider button.owl-dot:hover span {
  background: #ee0033 !important;
}

.lists button.owl-dot.active span {
  background: #ee0033 !important;
}

.lists button.owl-dot:hover span {
  background: #ee0033 !important;
}

/* .slider button.owl-dot span {
    background: #FFFFFF !important;
} */

.footer {
  background: #f2f2f2 !important;
  color: #000000 !important;
}

.footer .footer-content .footer-top {
  background: #f2f2f2 !important;
}

.footer .footer-content .footer-top .column.first .infotop {
  color: #000000 !important;
}

.footer .footer-content .footer-top .column .menu p {
  color: #000000 !important;
}

.footer .footer-content .footer-top .column .menu .sub-menu li {
  margin: 10px 0;
}

.footer .footer-content .footer-top .column .menu .sub-menu li a {
  color: #000000 !important;
}

.footer .footer-content .footer-bottom {
  background: #c2002a !important;
}

.box-data-cacgoi .inner-item h2.h2-minmax,
.box-data-cacgoi .inner-item h3.h2-minmax {
  background: -webkit-linear-gradient(289.46deg, #ee0033 0%, #b70d02 98.92%);
  background: linear-gradient(160.54deg, #ee0033 0%, #b70d02 98.92%);
}

.inner-item h2.h2-minmax1,
.inner-item h3.h2-minmax1 {
  background: -webkit-linear-gradient(106.26deg, #ee0033 1.81%, #b70d02 97.76%);
  background: linear-gradient(343.74deg, #ee0033 1.81%, #7c3631 97.76%);
}

.inner-item h2.h2-minmax2,
.inner-item h3.h2-minmax2 {
  background: linear-gradient(164.22deg, #ee0033 0%, #7c3631 98.78%);
}

.inner-item h2.h2-minmax,
.inner-item h3.h2-minmax {
  background: -webkit-linear-gradient(285.78deg, #ee0033 0%, #7c3631 98.92%);
  background: linear-gradient(164.22deg, #ee0033 0%, #7c3631 98.92%);
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax2 {
  background: -webkit-linear-gradient(110.05deg, #ee0033 1.81%, #7c3631 97.76%);
  background: linear-gradient(339.95deg, #ee0033 1.81%, #7c3631 97.76%);
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax2:after {
  top: inherit;
  right: 7%;
  bottom: 31%;
  width: 350px;
  height: 350px;
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax3 {
  background: -webkit-linear-gradient(289.48deg, #ee0033 0%, #7c3631 98.92%);
  background: linear-gradient(160.52deg, #ee0033 0%, #7c3631 98.92%);
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax3,
.page-qltkdd .box-data-cacgoi .inner-item h3.h2-minmax3 {
  background: -webkit-linear-gradient(289.48deg, #ee0033 0%, #7c3631 98.92%);
  background: linear-gradient(160.52deg, #ee0033 0%, #7c3631 98.92%);
}

.mobile-service #tabs-data .item:after {
  background: -webkit-linear-gradient(bottom, #ee0033 0.34%, #7c36313d 62.52%, rgba(0, 194, 149, 0.0001) 99.81%);
  background: linear-gradient(0deg, #ee0033 0.34%, #7c36313d 62.52%, rgba(0, 194, 149, 0.0001) 99.81%);
}

.mobile-service #tabs-data .item:hover:before {
  background: #ee003354;
}

.i-success {
  color: #ee0033 !important;
}

.navpage ul li.current strong,
.navpage ul li:not(.arrow):hover a {
  background: #ee0033;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn {
  border: 1px solid #ee0033 !important;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn a {
  color: #ee0033 !important;
}

/* select:focus,
input:focus,
textarea:focus {
    border-color: #EE0033 !important;
} */

/* @media only screen and (min-width: 1170px) {
  .bacgroud-header.row-menu .boxmenu ul li:hover > a {
    background: #ffffff;
  }
} */

@media (max-width: 767px) {
  .warp #row-top {
    background-color: #ffffff !important;
  }
  .off-canvas-toggle {
    color: #000000 !important;
  }
  .warp #row-top .search-top i.icon-search {
    color: #000000 !important;
  }
  .row-top .content .blogo {
    margin: 0 auto;
  }
  .content .btabs .menu_select_sp {
    color: #ee0033 !important;
  }
  .footer .footer-sp {
    background: #f2f2f2 !important;
    color: #000000 !important;
  }
}
