.row-menu .boxmenu ul li .submenu {
    width: 244px;
}

@media (min-width: 1024px) {
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--2col {
        width: 500px;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--2col .sub-item:first-child {
        width: 50%;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--3col {
        width: 693px;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--3col .sub-item:first-child {
        width: 27%;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--3col .sub-item {
        width: 38%;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu.submenu--3col .sub-item:last-child {
        width: 35%;
    }
}

.wrapperInputSearch {
    position: relative;
}

.iconSearch {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
}

.inputSearch {
    height: 30px;
    background-color: rgba(244, 248, 248, 0.788188);
    padding-right: 35px;
}

.status-user {
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
    display: none;
}

@media (max-width: 1038px) {
    .status-user {
        display: inline;
    }
}

.status-user.status-user-logged {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
}

.status-user img {
    width: 100%;
    height: 100%;
}
