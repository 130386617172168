.wrap-detail {
  /* margin: 0; */
  /* margin: auto; */
  margin-top: 150px !important;
  /* max-width: 1200px; */
}
.wrap-detail .title {
  font-size: 15px;
  line-height: 25px;
  background: #c3002a;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  width: 100% !important;
}
.wrap-detail .title::after {
  content: "";
  background: url("https://www.viettelhochiminh.vn/skins/default/images/i-menumobile.png");
  width: 24px;
  height: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.wrap-detail .content {
  background: #ffffff;
  box-shadow: 0px 5px 14px #f7f7f7;
  margin-bottom: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 !important;
}
.wrap-detail .content a {
  display: block;
  padding: 12px 15px;
  border-bottom: solid 1px #dddddd;
  position: relative;
}
.wrap-detail .box-title {
  position: relative;
}
.wrap-detail .box-title::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  border-bottom: 4px solid #c3002a;
  width: 50px;
}
.wrap-detail .heading {
  padding: 0;
  color: #333333;
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
}
.wrap-detail .box-content {
  margin-top: 42px;
  margin-bottom: 68px;
}

.titleLabel {
  text-transform: uppercase;
}

@media only screen and (max-width: 1040px) {
  .wrap-detail {
    margin-top: 68px !important;
  }
}
