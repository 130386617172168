.logo {
  padding: 10px 20px;
  background: #fff;
  border-radius: 24px;
}

.logo div {
  display: flex;
  justify-content: center;
}

.headerRight {
  display: flex;
  align-items: center;
}

.form-searching {
  padding: 6px 10px;
  border-radius: 9px;
  margin-right: 20px;
}

.form-searching div {
  margin: 0;
}

.input-search {
  border-radius: 10px;
}

.input-search input {
  border: none;
}

.input-search input:focus {
  outline: none;
}

.input-search input:focus {
  outline: none;
}

.notify {
  margin-right: 20px;
}

.notification-icon {
  color: #fff;
  font-size: 25px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.ant-dropdown-menu {
  border-radius: 30px;
  padding: 15px;
}

.ant-dropdown-menu-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
  &:last-child {
    border: none;
  }
}

.menuEmpty {
  background-color: #fff;
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
