.table-titles {
  padding: 20px 0px;
}

.groupButton {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  padding-bottom: 20px;
  margin-top: 30px;
}

.wrapperPost {
  padding: 20px;
  margin: 20px 15px;
  background-color: #fff;
  border-radius: 20px;
}

.wrapperButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectRow {
  width: 50%;
}
