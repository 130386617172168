.titleManagement {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 5rem;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  height: 70vh;
}

.btnAdd {
  margin-bottom: 20px;
}

.btnAction {
  min-width: 100px;
}

.tagStatus {
  text-align: center;
  min-width: 60px;
}

/* add.js */
.wrapper-form {
  margin: 30px 55px;
  background-color: #fff;
  border-radius: 20px;
  height: 70vh;
  padding: 20px;
}
