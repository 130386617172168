.row-top .language {
  margin-top: 5px;
}

.modal-setup-schedule__branch-name.schedule__branch-name-custom {
  font-size: 20px;
}

.vacine-covid__btn .disabled {
  pointer-events: none;
  background: #bdbdbd;
}

.vacine-covid__list {
  margin: 20px -8px 10px;
}

.modal-setup-schedule__branch-name.schedule__branch-name-custom {
  font-size: 20px;
}

.process-switch__container .form-input {
  margin-bottom: 5px;
}

.network input:read-only {
  color: unset;
  cursor: unset;
}

.shopItem {
  cursor: pointer;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.net-switch__policy {
  background-color: #eeeeee;
  padding-top: 30px;
  position: relative;
  margin-bottom: 224px;
  width: 100%;
  float: left;
}

.list-cua-hang table tr td .number-booker {
  margin-top: 8px;
}

.description4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error,
.text-error {
  color: red !important;
}

.banner-slideshow {
  margin: 0 0 0px;
}

.alt-right.error {
  position: unset;
  left: 0;
  width: 100%;
  top: 0;
  padding-top: 5px;
  text-align: right;
  font-size: 12px;
}

.alt-right span:first-child {
  margin: 5px 0px 5px 0px;
}

.alt-right span {
  height: 5px;
  width: 20px;
}

.alc-right.error {
  position: unset;
  left: 0;
  width: 100%;
  top: 0;
  padding-top: 5px;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  text-align: right;
}

div.error {
  font-weight: 400;
}

.login .pathway {
  margin: 0;
}

.displayv5 .pathway {
  margin: 0;
}

a.viewall {
  text-decoration-line: unset;
}

.tel-device {
  margin: 0px;
}

/* .login-register ul li:hover>a,
.login-register ul li.active>a {
border-radius: 10px 10px 10px 0px;
} */

#popup-detail-total-price .flight-detail__top .flight-detail__name {
  text-align: center;
}

.choose-place__name {
  display: flex;
}

.choose-place__des {
  display: flex;
}

#popup-detail-total-price h3 {
  margin-bottom: 5px;
}

.radio-custom1 input.active ~ .checkmark:after {
  display: block;
  background: #ee0033;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.box-tickets-list {
  margin-bottom: 50px;
}

#popup-detail-total-price .flight-detail__item:not(:last-child) {
  margin: 0 0 5px;
}

#popup-detail-total-price .flight-detail__name {
  font-size: 14px;
}

#popup-detail-total-price .flight-detail__value {
  font-size: 14px;
}

#popup-detail-total-price .flight-detail__list_custom {
  padding-top: 15px;
  border-top: 1px solid #cacaca;
}

#popup-detail-total-price .flight-detail__list_custom .flight-detail__value {
  color: #ffba23;
  font-weight: bold;
}

#popup-detail-total-price .flight-detail__list_custom .flight-detail__name p {
  color: #888;
  font-size: 12px;
}

.contact-info.custom-contact-info {
  background: #fff;
  border: 0.5px solid #bdbdbd;
  border-radius: 10px;
}

.contact-info.custom-contact-info .contact-info__top {
  background: #ffffff;
  padding: 10px 5px;
  position: relative;
  margin: 0;
  border-radius: 10px;
  padding: 15px 15px;
  display: grid;
}

.contact-info.custom-contact-info .contact-info__top .passenger-info__action {
  position: absolute;
  right: 15px;
}

.contact-info.custom-contact-info .contact-info__top .passenger-info__title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.contact-info.custom-contact-info .contact-info__top .text-error {
  font-size: 14px;
  margin-top: 5px;
}

.contact-info.custom-contact-info .form-vertical {
  float: left;
}

.contact-info.custom-contact-info .contact-info__top .contact-info__title {
  color: #ffffff;
}

.contact-info.custom-contact-info .contact-info__form {
  margin: 0;
  border-top: 0.5px solid rgb(189, 189, 189);
  padding-top: 20px;
}

.content-trung-vang {
  padding: 15px;
}

.no-pack-item-internet {
  text-align: center;
  font-size: 20px;
  color: #ee0033;
  font-weight: bold;
  padding: 0 15px;
}

.choose-date__item:hover {
  background-color: #ee0033;
  color: #fff;
}

.content-trung-vang h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #ee0033;
}

.content-trung-vang p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.content-trung-vang strong {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.cus-modal-tet__service-item {
  color: #5596f8;
}

.custom-landing-tet {
  font-family: "roboto";
}

.postage__item .btn.btn-large {
  padding: 0px !important;
}

.modal-tet__chance-des.custom-click-detail-trung-vang {
  width: 100%;
  font-weight: 500;
  font-size: 21px;
  text-align: right;
  text-align: right;
  color: #444343;
  float: right;
}

/* .list-dt .min-maxd h2 span, .min-data {
max-height: unset;
overflow: unset;
text-overflow: unset;
} */

.tooltip-custom:hover .tooltip-custom__text {
  visibility: visible;
}

.tooltip-custom__text:hover {
  visibility: visible;
}

.tooltip-custom__text {
  top: 0;
  max-height: 170px;
  z-index: 1;
  overflow: auto;
}

.select-data .list-dt .goi-cuoc .tien {
  font-size: 24px;
}

.contact-now__close {
  display: block;
  right: -15px;
  top: -15px;
}

.dervice-boxcolor-name {
  width: 100%;
  float: left;
  font-weight: bold;
  font-size: 16px;
}

.contact-now {
  bottom: 15px;
}

.contact-now__close {
  display: block;
}

/* .list-dt .min-maxd h2 span, .min-data {
max-height: unset;
overflow: unset;
text-overflow: unset;
} */

.product-btn .btn-red {
  background-color: #fff;
  border: 1px solid #f00;
  border-radius: 10px;
  color: #f00;
  font-size: 20px;
  padding: 14px 16px;
  text-align: center;
  width: 100%;
}

.product-btn.custom-user-device {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.product-promotion {
  margin: 15px 0 50px;
}

.tooltip-custom:hover + .tooltip-custom__text {
  visibility: visible;
}

.tooltip-custom__text:hover {
  visibility: visible;
}

.tooltip-custom__text {
  top: 0;
  max-height: 145px;
  z-index: 1;
  overflow: auto;
}

.select-data .list-dt .goi-cuoc .tien {
  font-size: 24px;
}

.row-top .content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intro-time--product .intro-time__menu {
  width: 130px;
}

.minmax-custom .min-maxd {
  padding: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row-menu .menu-pc {
  position: relative;
}

.gtgt-news {
  padding-right: 0 !important;
}

.menu-pc {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
}

.contact-now {
  min-width: 175px;
}

.pack-list__detail.spread-data {
  margin-bottom: 25px;
}

.spread-data .pack-list__item {
  width: 31%;
}

.customer_heared-popup {
  display: block !important;
  /* position: absolute; */
  width: 100%;
  height: 100px;
  padding: 5px 5px;
  background: #ee0033;
}

.customer_heared-popup h5 {
  width: 97%;
  float: left;
  font-weight: bold;
  font-size: 37px;
  line-height: 95px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.customer_heared-popup .modal__close {
  float: right;
  line-height: 52px;
}

#popup_quatet .modal-tet__footer {
  margin: 80px 0px 0;
}

.banner-shop__left {
  width: 65%;
  float: left;
}

.banner-shop__right {
  width: 35%;
  float: left;
}

.banner-shop__right .banner-shop__item {
  width: 100%;
  float: left;
}

.device-shop__info {
  overflow: hidden;
  max-height: 295px;
}

.device-shop__des {
  /* overflow: hidden;
max-height: 235px; */
  padding: 0 10px;
}

.post-shop__item {
  width: calc(100% / 2);
  padding: 0;
  margin: 0 5px;
  background-image: unset;
}

.modal__code-images img {
  width: 200px;
}

.lp-advantages__left {
  z-index: 2;
  position: unset;
}

.main-htkh .support-online {
  position: unset;
}

.lists.slider {
  z-index: 0;
}

.device-shop__des {
  overflow: hidden;
  max-height: 235px;
  padding: 0 10px;
}

.internet-shop__item .list-check {
  overflow: hidden;
  max-height: 155px;
}

.loadding-event-history {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-width: unset;
  max-height: unset;
  z-index: 10000;
  background: rgb(255 255 255 / 24%);
  display: none;
  align-items: center;
  justify-content: center;
}

.loadding-event-history img {
  width: 150px;
  height: 150px;
}

.modal__code-images img {
  width: 200px;
}

.lp-advantages__left {
  z-index: 2;
}

.form-group--otp.one-otp input {
  width: 100%;
  text-align: center;
  max-width: 100%;
}

.search-orders__note {
  font-size: 16px;
}

.box-orders__name {
  width: 60%;
}

.main-htkh .support-online {
  position: unset;
}

.form-group--otp.one-otp input {
  width: 100%;
  text-align: center;
  max-width: 100%;
}

.lists.slider {
  z-index: 0;
}

/* .postage__item .btn {
font-size: 12px;
line-height: 30px;
padding: 5px 6px;
} */

.radio-customize {
  width: 50%;
  float: left;
}

.main-pay .radio-customize {
  width: unset;
  float: unset;
}

.tabs-item:hover .tabs-link {
  border-bottom: 2px solid #ee0033;
  color: #ee0033;
  font-weight: bold;
}

.error-select-date {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: center;
  color: #f00;
  margin-top: 5px;
}

.intro-time__menu {
  width: 320px;
}

.intro-time__col {
  width: 45%;
}

.intro-time__input input {
  padding: 2px 0 2px 2px;
}

.intro-time__tilde {
  width: 5px;
  -webkit-flex: 0 0 5px;
  -ms-flex: 0 0 5px;
  flex: 0 0 5px;
  top: 18px;
}

.intro-time__input:before {
  display: none;
}

.postage__price-old {
  text-decoration: line-through;
}

/* .radio-custom1 input:checked~.txt-radio {
color: #EE0033;
} */

.pay-viettel__item .radio-customize .radio-custom1 {
  margin: 0;
}

.pay-viettel__item .radio-custom1 .checkmark {
  top: 15px;
}

.postage__item .btn.btn-large {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  border-radius: 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  min-width: 90px;
  padding: 5px 10px;
  border: 2px solid;
}

.detail-cuoc .minmax .btn.btn-white {
  border: 2px solid;
}

a.btn.btn-blue.rg,
b.btn.btn-blue.rg {
  padding-top: 0;
  background: #ffffff;
  padding-bottom: 0;
  color: #ee0033;
}

a.icon-continue.disable,
div.icon-continue.disable:hover {
  background: #e7e7e7;
  cursor: none;
  color: #ee0033;
}

.code-otp a.btn-find.disable,
.code-otp a.btn-find.disable:hover {
  color: #ee0033;
}

.sim-sp__item a.buynow {
  min-width: unset;
}

.sim-sp__item a .icon-i-wishlist {
  color: #ee0033;
  font-size: 22px;
}

.code-otp .btn-find {
  top: 45%;
}

.box-music .goi-cuoc .imgs {
  height: 100%;
}

.box-music .goi-cuoc .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-utilities .goi-cuoc .imgs {
  height: 100%;
}

.box-utilities .goi-cuoc .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-entertainm .gtgt .imgs {
  height: 200px;
}

.box-entertainm .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-entertainm .gtgt h2 {
  position: relative;
}

.box-entertainm .gtgt h3 {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  font-size: 24px;
}

.list-dt .custom-min-maxd {
  padding: 0 33% 0 0 !important;
}

.minmax-custom .min-maxd h3 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.intro-more-custom {
  padding: 20px 20px 30px !important;
  width: 100% !important;
}

.box-entertainm .gtgt h2 {
  width: 100%;
}

.box-entertainm .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.box-entertainm .gtgt h3 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.box-music .gtgt .imgs {
  height: 100%;
}

.box-music .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-music .gtgt h2 {
  position: relative;
}

.box-music .gtgt h2 a {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.box-music .gtgt h2 {
  width: 100%;
}

.box-music .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.box-music .min-data {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.box-news .gtgt .imgs {
  height: 150px;
}

.box-news .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-news .gtgt h2 {
  position: relative;
}

.box-news .gtgt h2 {
  width: 100%;
}

.box-news .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.box-news .new-desc {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: unset;
}

.box-utilities .gtgt .imgs {
  height: 100%;
}

.box-utilities .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-utilities .gtgt h2 {
  position: relative;
}

.box-utilities .gtgt h2 a {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.box-utilities .gtgt h2 {
  width: 100%;
}

.box-utilities .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.box-same .gtgt {
  width: 100%;
  flex: unset;
  -webkit-flex: unset;
}

.box-same .gtgt .new-desc {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: unset;
}

.lists.slider .list-same .item {
  margin: 0 15px;
}

.box-same .gtgt .imgs {
  height: 135px;
}

.box-same .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-same .gtgt h2 {
  position: relative;
}

.box-same .gtgt h2 {
  width: 100%;
}

.contact-now__close {
  display: block;
  right: -15px;
  top: -15px;
}

.box-same .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.minmax .pr span {
  color: #ee0033;
}

.dn h2 {
  font-size: 16px;
}

.box-price .speci-price {
  font-size: 16px;
}

.main-packages .data-content p.descript {
  display: -webkit-box;
}

.row-top .link-top li {
  float: left;
  margin: 0px 14px 0px 0px;
}

.header {
  height: unset;
  z-index: 13;
}

.intro-code__content {
  border: none;
  border-radius: 0px;
  padding: 0px;
}

.intro-code__content input {
  color: #ee0033;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 5px;
  text-align: center;
}

.intro-time__btn.custom-search {
  width: 60%;
  margin: 0 auto;
  background-color: #ee0033;
  color: #ffffff;
}

.int .int-desc {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #576c8a;
  margin: 10px 30px 18px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #576c8a;
  margin: 10px 30px 18px;
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
}

.int {
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
}

.box-data h2,
.box-data h3 {
  width: 100%;
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info h2 a {
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .rating-summary {
  width: auto;
  float: left;
  margin-bottom: 0;
}

.rating-summary .rating-result {
  height: 25px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .new-pr {
  width: 100%;
  float: left;
  font-size: 16px;
  margin: 10px 0;
}

.box-spillover__top {
  margin: 0;
  width: auto;
  float: left;
}

.box-spillover__top.history-spread {
  margin: 5px 0 20px;
  width: 100%;
  float: left;
}

/* common v5 */

.pathway.pathway-m0 {
  margin: 0;
}

@media (max-width: 767px) {
  .pathway.pathway-m0,
  .pathway.pathway-m0 .content ul {
    display: block;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  top: calc(50% - ((16px + 18px) / 2));
  /* 16: height của dots; 18 là margin-top của dots */
}

.pt0 {
  padding-top: 0;
}

.fw700 {
  font-weight: 700;
}

/* end common v5 */

/* menu v5 */

.has-sub .submenu li a {
  display: flex !important;
}

.menu-slide__sub li {
  margin-bottom: 5px;
}

.list-language {
  top: 120%;
}

.list-language li a {
  display: block;
}

.login-register ul li > a > a {
  display: none !important;
}

.login-register ul li:hover {
  cursor: default;
}

.login-register ul li.active:hover > a.button-login-bacgroud {
  color: #fff !important;
}

.login-register ul li.active:hover > a.button-login-bacgroud:hover {
  color: #ee0033 !important;
}

.row-menu .boxmenu ul li a {
  font-weight: unset;
}

.bacgroud-header {
  box-shadow: unset !important;
}

a.off-canvas-toggle::before {
  display: none;
}

/* end menu v5 */

/* home v5 */

@media (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-next {
    left: unset;
  }
}

@media (max-width: 991px) {
  .apply-number .viewall {
    bottom: -40px;
  }
}

@media (max-width: 767px) {
  .content.tabs .btabs .menu_select_sp {
    color: #576c8a !important;
  }
}

@media (min-width: 443px) and (max-width: 767px) {
  .content .tit h2.left {
    width: 50%;
  }
}

.news-event__info {
  align-items: baseline;
}

.news-event__left .news-event__images a.imgs {
  width: 100%;
}

.sim-number th {
  text-align: left;
}

.radio-custom1 {
  margin: 0;
}

.fee-pack__price.custom-fontsize {
  font-size: 26px;
}

.fee-pack__price span {
  text-transform: lowercase;
}

.title-home.custom-lineheight {
  line-height: 40px;
}

.vat-service__des {
  height: 46px;
}

.vat-service__price span {
  text-transform: lowercase;
}

button.owl-dot.active span {
  width: 10px;
}

.news-event h5 {
  font-size: 12px;
}

.title-block {
  font-size: 26px;
}

.mobile-service.mobile-service-home {
  padding: 30px 0 20px;
}

.viettel-plush {
  margin: 55px 0 0;
}

.vat-service__price {
  align-items: flex-end;
}

.list-check li {
  padding: 0 0 0 28px;
  margin-bottom: 14px;
  height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.list-check li i {
  top: 1px;
  left: 1px;
  transform: unset;
}

.fee-pack__name:hover {
  color: #44494d;
}

.vat-service__btn .button {
  width: unset;
}

.viettel-plus__info {
  display: flex;
  align-items: center;
}

.row input {
  color: #576c8a;
}

@media only screen and (max-width: 1023px) {
  .mobile-service .content .tit .btabs {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .title-home {
    font-size: 18px !important;
  }
}

@media (max-width: 568px) {
  .news-event__detail--flex568.news-event__detail,
  .news-event__detail--flex568 .news-event__right {
    display: flex !important;
  }
  .news-event__detail--flex568 .news-event__item {
    margin: 0 10px 5px 0 !important;
  }
}

/* end home v5 */

/* register v5 */

.form-input.form-input__2icon .icon-clear-field {
  right: 42px;
}

.form-input.form-input__2icon .form-control {
  padding-right: 70px;
}

.item-acc .edit {
  cursor: pointer;
}

.auth {
  justify-content: space-between;
}

.auth__images {
  margin: 0;
}

.form-customize .form-control:focus {
  border-color: #58b84f !important;
}

/* end register v5 */

/* login */

.auth__des-link--right {
  justify-content: flex-end;
}

/* end login */

/* đa tài khoản */

.pathwayor {
  margin: 0;
}

@media (min-width: 992px) {
  .account-add__btn .button {
    width: 328px;
  }
}

.account-addional {
  margin-bottom: 30px;
}

/* end đa tài khoản */

/* fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

.space1.space-bottom,
.service-gtgt h2.title {
  margin-bottom: 60px;
}

/* end fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

/* thong tin tai khoan v5 */

.page-qltkdd .tabs h3.promotion__title,
.page-qltkdd .tabs h3.slider-search__description {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #44494d;
  text-transform: initial;
}

.user-mobile-pre-v5 .btabs.left.opened {
  z-index: 3;
}

.debt-charges__util-title {
  text-transform: uppercase;
}

.data-package__header-name.data-package__header-name--fs36 {
  font-size: 36px;
}

.service-list__total-price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-qltkdd .tabs h3.promotion__title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service-list__description.service-list__description--2lines {
  height: 48px;
}

.promotion__title.promotion__title--2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page-qltkdd .tabs-lookups {
  padding: 30px 0 0.1px;
  background: #e4e4e4;
}

.tabs-lookups .content.tabs {
  float: none;
}

@media (max-width: 991px) {
  .page-qltkdd .tabs h3.slider-search__description,
  .page-qltkdd .tabs h3.promotion__title {
    width: 100%;
    white-space: unset;
  }
  .debt-charges__util-img {
    margin: 0 auto;
    width: fit-content;
  }
}

.tab-title li a {
  margin: 0 0 -1px 0;
}

.service-list__title-top {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 110px);
  font-size: 18px;
}

.service-list__intro--bottom {
  display: flex;
  align-items: flex-end;
}

.service-list__description {
  overflow: hidden;
  width: 95%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.service-list__intro--bottom .service-list__intro--bottom-left {
  width: calc(100% - 105px);
}

.service-list__intro--bottom .service-list__intro--bottom-right {
  width: 105px;
}

.tabs-dvdsd__notfound__text {
  margin-bottom: 15px;
}

.debt-charges__avatar-user {
  border-radius: 50%;
}

.data-package__btn-box {
  margin-left: 0;
}

@media (min-width: 1154px) and (max-width: 1170px) {
  .debt-charges__header {
    padding: 20px;
  }
}

@media (min-width: 370px) and (max-width: 478px) {
  .user-mobile-service-promotions-v5 .content .tit h2.left {
    overflow: unset;
  }
}

@media (min-width: 334px) and (max-width: 410px) {
  .user-mobile-service-pack-used-v5 .content .tit h2.left {
    overflow: unset;
  }
}

.debt-charges__end-term {
  margin-right: 73px;
}

.ud-viettel .content .tit h2.left {
  overflow: unset;
}

/* @media (max-width: 549px) {
    .user-mobile-pos-v5 .data-pack__btn,
    .user-mobile-pre-v5 .data-pack__btn,
    .user-ftth-v5 .data-pack__btn {
        margin: 0;
    }
} */

.data-package__title.data-package__title--custom {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .ud-viettel .owl-nav {
    display: none;
  }
}

@media (max-width: 1150px) {
  .manager-ftth__util {
    position: unset;
    margin: 0 20px 30px;
    border: 0;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

/* end thong tin tai khoan v5 */

/* mobile services v5 */

.clear-padding.owl-carousel .owl-stage-outer {
  padding-bottom: 0 !important;
}

.mobile-services-page-v5 {
  overflow-y: hidden;
}

@media (max-width: 991px) {
  .mobile-services-page-v5 .content.tabs {
    margin-bottom: 0;
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
}

@media (max-width: 991px) {
  .inter-service__des.inter-service__des--3lines {
    height: 63px;
  }
}

.owl-carousel.col4 .fee-pack__price {
  font-size: 26px;
}

.vat-service__images,
.vat-service__images + img {
  border-radius: 12px 12px 12px 0;
}

.hot-key__value {
  cursor: pointer;
}

@media (max-width: 1140px) {
  .net-switch__video {
    height: unset;
  }
}

@media (min-width: 390px) and (max-width: 767px) {
  .mobile-services-page-v5 .content .tit h2.left {
    text-overflow: unset;
    overflow: unset;
  }
}

.service-data__list.service-data__list--start-items {
  justify-content: flex-start;
}

.service-data__list.service-data__list--start-items.service-data .data-pack__des {
  height: 41.6px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    flex-direction: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -15px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(100% / 3 - 30px);
    flex: 0 0 calc(100% / 3 - 30px);
    margin: 0 15px 25px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -10px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

@media (max-width: 576px) {
  .vat-service__list.vat-service__list--rps .vat-service__item {
    flex: 0 0 calc(100% - 16px);
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
}

.pack-detail__box p {
  line-height: 24px;
}

#main-packs {
  margin: 0 -15px;
}

@media (min-width: 992px) {
  #main-packs:hover .slick-arrow {
    opacity: 1;
  }
}

#main-packs .pack-master__item {
  margin: 0 0 30px;
  padding: 0 15px;
}

#main-packs .pack-master__item .fee-pack__price {
  font-size: 25px;
}

#main-packs .slick-arrow {
  background: #ccc;
  border: 1px solid #e6e6e6;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  z-index: unset;
}

#main-packs .slick-arrow:hover {
  background-color: #fff;
  border-color: #ee0033;
  color: #ee0033;
}

#main-packs .owl-dots {
  margin-top: -10px;
}

#main-packs .owl-dots li button,
#main-packs .owl-dots li.slick-active button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

#main-packs .owl-dots li.slick-active button {
  background: #ee0033;
}

#main-packs .owl-dots li:hover button {
  background: #ee0033;
}

.box-data h2,
.box-data h3 {
  width: 100%;
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info h2 a {
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .rating-summary {
  width: auto;
  float: left;
  margin-bottom: 0;
}

.rating-summary .rating-result {
  height: 25px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .new-pr {
  width: 100%;
  float: left;
  font-size: 16px;
  opacity: 0.8;
}

@media (max-width: 991px) {
  .btabs.left.width-100-xs {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.btabs.left.m0 {
  margin: 0;
}

.box-spillover__top.history-spread {
  margin: 5px 0 20px;
  width: 100%;
  float: left;
}

/* common v5 */

.pathway.pathway-m0 {
  margin: 0;
}

@media (max-width: 767px) {
  .pathway.pathway-m0,
  .pathway.pathway-m0 .content ul {
    display: block;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  top: calc(50% - ((16px + 18px) / 2));
  /* 16: height của dots; 18 là margin-top của dots */
}

.icon-i-phone {
  transform: rotate(-90deg);
}

.msg-enter-otp,
.msg-noti-sent-number-phone {
  color: #44494d;
  font-size: 14px;
}

.select-default-viettel-v5 {
  background-position-x: calc(100% - 16px);
}

/* end common v5 */

/* menu v5 */

.has-sub .submenu li a {
  display: flex !important;
}

.menu-slide__sub li {
  margin-bottom: 5px;
}

.list-language {
  top: 120%;
}

.list-language li a {
  display: block;
}

.login-register ul li > a > a {
  display: none !important;
}

.login-register ul li:hover {
  cursor: default;
}

.login-register ul li.active:hover > a.button-login-bacgroud {
  color: #fff !important;
}

.login-register ul li.active:hover > a.button-login-bacgroud:hover {
  color: #ee0033 !important;
}

.row-menu .boxmenu ul li a {
  font-weight: unset;
}

.bacgroud-header {
  box-shadow: unset !important;
}

a.off-canvas-toggle::before {
  display: none;
}

.login-register.login-register-v5 ul li > a {
  height: 40px;
  line-height: 40px;
  color: #4b4b4b;
  border-radius: 8px 8px 8px 0;
  width: 119px;
  font-weight: 600;
}

.login-register.login-register-v5 ul li > a:hover {
  color: #fff;
}

.login-register.login-register-v5 ul li > a.button--register:hover::before {
  bottom: -1px;
  height: 40px;
  width: calc(100% + 1px);
}

.login-register.login-register-v5 ul li.active > a {
  background-color: #ee0033;
  color: #fff;
}

.login-register.login-register-v5 ul li.active:hover > a {
  background: #ee0033;
}

/* end menu v5 */

/* home v5 */

@media (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-next {
    left: unset;
  }
}

@media (max-width: 991px) {
  .apply-number .viewall {
    bottom: -40px;
  }
}

@media (max-width: 767px) {
  .content.tabs .btabs .menu_select_sp {
    color: #576c8a !important;
  }
}

@media (min-width: 443px) and (max-width: 767px) {
  .content .tit h2.left {
    width: 50%;
  }
}

.news-event__info {
  align-items: baseline;
}

.news-event__left .news-event__images a.imgs {
  width: 100%;
}

.sim-number th {
  text-align: left;
}

.radio-custom1 {
  margin: 0;
}

.fee-pack__price.custom-fontsize {
  font-size: 26px;
}

.fee-pack__price span {
  text-transform: lowercase;
}

.title-home.custom-lineheight {
  line-height: 40px;
}

.vat-service__des {
  height: 46px;
}

.vat-service__price span {
  text-transform: lowercase;
}

button.owl-dot.active span {
  width: 10px;
}

.news-event h5 {
  font-size: 12px;
}

.title-block {
  font-size: 26px;
}

.mobile-service.mobile-service-home {
  padding: 30px 0 20px;
}

.viettel-plush {
  margin: 55px 0 0;
}

.vat-service__price {
  align-items: flex-end;
}

.list-check li {
  padding: 0 0 0 28px;
  margin-bottom: 14px;
  height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.list-check li i {
  top: 1px;
  left: 1px;
  transform: unset;
}

.fee-pack__name:hover {
  color: #44494d;
}

.vat-service__btn .button {
  width: unset;
}

.viettel-plus__info {
  display: flex;
  align-items: center;
}

.row input {
  color: #576c8a;
}

@media only screen and (max-width: 1023px) {
  .mobile-service .content .tit .btabs {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .title-home {
    font-size: 18px !important;
  }
}

/* end home v5 */

/* register v5 */

.form-input.form-input__2icon .icon-clear-field {
  right: 42px;
}

.form-input.form-input__2icon .form-control {
  padding-right: 70px;
}

.item-acc .edit {
  cursor: pointer;
}

.auth {
  justify-content: space-between;
}

.auth__images {
  margin: 0;
}

.form-customize .form-control:focus {
  border-color: #58b84f !important;
}

/* end register v5 */

/* login */

.auth__des-link--right {
  justify-content: flex-end;
}

/* end login */

/* đa tài khoản */

.pathwayor {
  margin: 0;
}

@media (min-width: 992px) {
  .account-add__btn .button {
    width: 328px;
  }
}

.account-addional {
  margin-bottom: 30px;
}

/* end đa tài khoản */

/* fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

.space1.space-bottom,
.service-gtgt h2.title {
  margin-bottom: 60px;
}

/* end fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

/* thong tin tai khoan v5 */

.page-qltkdd .tabs h3.promotion__title,
.page-qltkdd .tabs h3.slider-search__description {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #44494d;
  text-transform: initial;
}

.user-mobile-pre-v5 .btabs.left.opened {
  z-index: 3;
}

.debt-charges__util-title {
  text-transform: uppercase;
}

.data-package__header-name.data-package__header-name--fs36 {
  font-size: 36px;
}

.service-list__total-price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-qltkdd .tabs h3.promotion__title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service-list__description.service-list__description--2lines {
  height: 48px;
}

.promotion__title.promotion__title--2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page-qltkdd .tabs-lookups {
  padding: 30px 0 0.1px;
  background: #e4e4e4;
}

.tabs-lookups .content.tabs {
  float: none;
}

@media (max-width: 991px) {
  .page-qltkdd .tabs h3.slider-search__description,
  .page-qltkdd .tabs h3.promotion__title {
    width: 100%;
    white-space: unset;
  }
  .debt-charges__util-img {
    margin: 0 auto;
    width: fit-content;
  }
}

.tab-title li a {
  margin: 0 0 -1px 0;
}

.service-list__title-top {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 110px);
  font-size: 18px;
}

.service-list__intro--bottom {
  display: flex;
  align-items: flex-end;
}

.service-list__description {
  overflow: hidden;
  width: 95%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.service-list__intro--bottom .service-list__intro--bottom-left {
  width: calc(100% - 105px);
}

.service-list__intro--bottom .service-list__intro--bottom-right {
  width: 105px;
}

.tabs-dvdsd__notfound__text {
  margin-bottom: 15px;
}

.debt-charges__avatar-user {
  border-radius: 50%;
}

.data-package__btn-box {
  margin-left: 0;
}

@media (min-width: 1154px) and (max-width: 1170px) {
  .debt-charges__header {
    padding: 20px;
  }
}

@media (min-width: 370px) and (max-width: 478px) {
  .user-mobile-service-promotions-v5 .content .tit h2.left {
    overflow: unset;
  }
}

@media (min-width: 334px) and (max-width: 410px) {
  .user-mobile-service-pack-used-v5 .content .tit h2.left {
    overflow: unset;
  }
}

.debt-charges__end-term {
  margin-right: 73px;
}

.ud-viettel .content .tit h2.left {
  overflow: unset;
}

.data-package__title.data-package__title--custom {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .ud-viettel .owl-nav {
    display: none;
  }
}

@media (max-width: 1150px) {
  .manager-ftth__util {
    position: unset;
    margin: 0 20px 30px;
    border: 0;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

.tab-title li.activeAlive a {
  color: #ee0033;
  border-bottom: 1px solid #ee0033;
  font-weight: bold;
}

.center-items .owl-stage-outer {
  display: flex;
  justify-content: center;
}

.debt-charges__info--custom-follow-fm .debt-charges__name-user i {
  position: relative;
  top: 3px;
}

.debt-charges__info--custom-follow-fm .debt-charges__divide {
  width: 1px;
  height: 14px;
  margin: 0 12px 0 8px;
}

.debt-charges__info--custom-follow-fm .debt-charges__divide,
.debt-charges__info--custom-follow-fm .debt-charges__phone-number {
  margin-top: 5px;
}

.data-package__header-box--mb0 {
  margin-bottom: 0;
}

.service-list__header-name--mt-20px {
  margin-top: -20px;
}

.mt-10 {
  margin-top: -10px;
}

.debt-charges__user,
.business-customers__account-info,
.debt-charges__phone-number,
.business-customers__username-group {
  cursor: default;
}

/* end thong tin tai khoan v5 */

/* mobile services v5 */

.clear-padding.owl-carousel .owl-stage-outer {
  padding-bottom: 0 !important;
}

.mobile-services-page-v5 {
  overflow-y: hidden;
}

@media (max-width: 991px) {
  .mobile-services-page-v5 .content.tabs {
    margin-bottom: 0;
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fee-pack__des.fee-pack__des--2lines {
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--3lines {
  height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--4lines {
  height: 96px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 991px) {
  .inter-service__des.inter-service__des--3lines {
    height: 63px;
  }
}

.owl-carousel.col4 .fee-pack__price {
  font-size: 26px;
}

.vat-service__images,
.vat-service__images + img {
  border-radius: 12px 12px 12px 0;
}

.hot-key__value {
  cursor: pointer;
}

@media (max-width: 1140px) {
  .net-switch__video {
    height: unset;
  }
}

@media (min-width: 390px) and (max-width: 767px) {
  .mobile-services-page-v5 .content .tit h2.left {
    text-overflow: unset;
    overflow: unset;
  }
}

.service-data__list.service-data__list--start-items {
  justify-content: flex-start;
}

.service-data__list.service-data__list--start-items.service-data .data-pack__des {
  height: 41.6px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    flex-direction: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -15px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(100% / 3 - 30px);
    flex: 0 0 calc(100% / 3 - 30px);
    margin: 0 15px 25px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -10px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

@media (max-width: 576px) {
  .vat-service__list.vat-service__list--rps .vat-service__item {
    flex: 0 0 calc(100% - 16px);
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
}

.pack-detail__box p {
  line-height: 24px;
}

#main-packs {
  margin: 0 -15px;
}

@media (min-width: 992px) {
  #main-packs:hover .slick-arrow {
    opacity: 1;
  }
}

#main-packs .pack-master__item {
  margin: 0 0 30px;
  padding: 0 15px;
}

#main-packs .slick-slide > div:first-child {
  margin-bottom: 42px;
  /* 42px: tổng 72px, các item đã có margin-bottom 30px */
}

#main-packs .pack-master__item .fee-pack__price {
  font-size: 25px;
}

#main-packs .slick-arrow {
  background: #ccc;
  border: 1px solid #e6e6e6;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  z-index: unset;
}

.box-same .gtgt .new-desc {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: unset;
}

.lists.slider .list-same .item {
  margin: 0 15px;
}

.box-same .gtgt .imgs {
  height: 135px;
}

.box-same .gtgt .imgs a img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-same .gtgt h2 {
  position: relative;
}

.box-same .gtgt h2 {
  width: 100%;
}

@media (max-width: 991px) {
  .btabs.left.width-100-xs {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.btabs.left.m0 {
  margin: 0;
}

.box-spillover__top.history-spread {
  margin: 5px 0 20px;
  width: 100%;
  float: left;
}

/* common v5 */

.pathway.pathway-m0 {
  margin: 0;
}

@media (max-width: 550px) {
  .pathway.pathway-m0 {
    padding: 10px 0;
  }
  .pathway.pathway-m0 .content ul li {
    margin-bottom: 5px;
  }
  .service-voice--detail,
  .pack-master {
    padding-top: 10px;
  }
  .package-detail__images {
    width: 100%;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  top: calc(50% - ((16px + 18px) / 2));
  /* 16: height của dots; 18 là margin-top của dots */
}

/* end common v5 */

/* menu v5 */

.has-sub .submenu li a {
  display: flex !important;
}

.menu-slide__sub li {
  margin-bottom: 5px;
}

.list-language {
  top: 120%;
}

.list-language li a {
  display: block;
}

.login-register ul li > a > a {
  display: none !important;
}

.login-register ul li:hover {
  cursor: default;
}

.login-register ul li.active:hover > a.button-login-bacgroud {
  color: #fff !important;
}

.login-register ul li.active:hover > a.button-login-bacgroud:hover {
  color: #ee0033 !important;
}

.row-menu .boxmenu ul li a {
  font-weight: unset;
}

.bacgroud-header {
  box-shadow: unset !important;
}

a.off-canvas-toggle::before {
  display: none;
}

/* end menu v5 */

/* home v5 */

@media (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-next {
    left: unset;
  }
}

@media (max-width: 991px) {
  .apply-number .viewall {
    bottom: -40px;
  }
}

@media (max-width: 767px) {
  .content.tabs .btabs .menu_select_sp {
    color: #576c8a !important;
  }
}

@media (min-width: 443px) and (max-width: 767px) {
  .content .tit h2.left {
    width: 50%;
  }
}

.news-event__info {
  align-items: baseline;
}

.news-event__left .news-event__images a.imgs {
  width: 100%;
}

.sim-number th {
  text-align: left;
}

.radio-custom1 {
  margin: 0;
}

.fee-pack__price.custom-fontsize {
  font-size: 26px;
}

.fee-pack__price span {
  text-transform: lowercase;
}

.title-home.custom-lineheight {
  line-height: 40px;
}

.vat-service__des {
  height: 46px;
}

.vat-service__price span {
  text-transform: lowercase;
}

button.owl-dot.active span {
  width: 10px;
}

.news-event h5 {
  font-size: 12px;
}

.title-block {
  font-size: 26px;
}

.mobile-service.mobile-service-home {
  padding: 30px 0 20px;
}

.viettel-plush {
  margin: 55px 0 0;
}

.vat-service__price {
  align-items: flex-end;
}

.list-check li {
  padding: 0 0 0 28px;
  margin-bottom: 14px;
  height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.list-check li i {
  top: 1px;
  left: 1px;
  transform: unset;
}

.fee-pack__name:hover {
  color: #44494d;
}

.vat-service__btn .button {
  width: unset;
}

.viettel-plus__info {
  display: flex;
  align-items: center;
}

.row input {
  color: #576c8a;
}

@media only screen and (max-width: 1023px) {
  .mobile-service .content .tit .btabs {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .title-home {
    font-size: 18px !important;
  }
}

.vat-service__price--mt13 {
  margin-top: 13px;
}

/* end home v5 */

/* register v5 */

.form-input.form-input__2icon .icon-clear-field {
  right: 42px;
}

.form-input.form-input__2icon .form-control {
  padding-right: 70px;
}

.item-acc .edit {
  cursor: pointer;
}

.auth {
  justify-content: space-between;
}

.auth__images {
  margin: 0;
}

.form-customize .form-control:focus {
  border-color: #58b84f !important;
}

/* end register v5 */

/* login */

.auth__des-link--right {
  justify-content: flex-end;
}

/* end login */

/* đa tài khoản */

.pathwayor {
  margin: 0;
}

@media (min-width: 992px) {
  .account-add__btn .button {
    width: 328px;
  }
}

.account-addional {
  margin-bottom: 30px;
}

/* end đa tài khoản */

/* fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

.space1.space-bottom,
.service-gtgt h2.title {
  margin-bottom: 60px;
}

/* end fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

/* thong tin tai khoan v5 */

.page-qltkdd .tabs h3.promotion__title,
.page-qltkdd .tabs h3.slider-search__description {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #44494d;
  text-transform: initial;
}

.user-mobile-pre-v5 .btabs.left.opened {
  z-index: 3;
}

.debt-charges__util-title {
  text-transform: uppercase;
}

.data-package__header-name.data-package__header-name--fs36 {
  font-size: 36px;
}

.service-list__total-price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-qltkdd .tabs h3.promotion__title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service-list__description.service-list__description--2lines {
  height: 48px;
}

.promotion__title.promotion__title--2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page-qltkdd .tabs-lookups {
  padding: 30px 0 0.1px;
  background: #e4e4e4;
}

.tabs-lookups .content.tabs {
  float: none;
}

@media (max-width: 991px) {
  .page-qltkdd .tabs h3.slider-search__description,
  .page-qltkdd .tabs h3.promotion__title {
    width: 100%;
    white-space: unset;
  }
  .debt-charges__util-img {
    margin: 0 auto;
    width: fit-content;
  }
}

.tab-title li a {
  margin: 0 0 -1px 0;
}

.service-list__title-top {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 110px);
  font-size: 18px;
}

.service-list__intro--bottom {
  display: flex;
  align-items: flex-end;
}

.service-list__description {
  overflow: hidden;
  width: 95%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.service-list__intro--bottom .service-list__intro--bottom-left {
  width: calc(100% - 105px);
}

.service-list__intro--bottom .service-list__intro--bottom-right {
  width: 105px;
}

.tabs-dvdsd__notfound__text {
  margin-bottom: 15px;
}

.debt-charges__avatar-user {
  border-radius: 50%;
}

.data-package__btn-box {
  margin-left: 0;
}

@media (min-width: 1154px) and (max-width: 1170px) {
  .debt-charges__header {
    padding: 20px;
  }
}

.debt-charges__end-term {
  margin-right: 73px;
}

.ud-viettel .content .tit h2.left {
  overflow: unset;
}

.data-package__title.data-package__title--custom {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .ud-viettel .owl-nav {
    display: none;
  }
}

@media (max-width: 1150px) {
  .manager-ftth__util {
    position: unset;
    margin: 0 20px 30px;
    border: 0;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

.bg-blur {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-full-screen {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.bg-fixed {
  position: fixed;
  z-index: 13;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.debt-charges__btn-pay {
  margin-bottom: 20px;
}

.debt-charges__type-point--mgt6 {
  margin-top: 6px;
}

.data-package__sms-description--2lines {
  height: 48px;
  overflow: hidden;
}

@media (max-width: 350px) {
  .button.button--rps-w68 {
    width: 68px;
    padding: 12px 0 8px;
    white-space: nowrap;
  }
}

@media (max-width: 370px) {
  .postpaid-slider__header-title--rps-370 {
    font-size: 19px;
  }
  .postpaid-slider__date--rps-350 {
    font-size: 15px;
  }
  .button.button--rps-w92 {
    width: 68px;
    padding: 12px 0 8px;
    white-space: nowrap;
  }
}

.postpaid-slider--center .owl-stage-outer {
  display: flex;
  justify-content: center;
}

.button--w180 {
  width: 180px !important;
}

/* end thong tin tai khoan v5 */

/* mobile services v5 */

.clear-padding.owl-carousel .owl-stage-outer {
  padding-bottom: 0 !important;
}

.mobile-services-page-v5 {
  overflow-y: hidden;
}

@media (max-width: 991px) {
  .mobile-services-page-v5 .content.tabs {
    margin-bottom: 0;
  }
  .mobile-services-page-v5 .service-vat {
    padding: 30px 0 40px;
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fee-pack__des.fee-pack__des--2lines {
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--3lines {
  height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--4lines {
  height: 96px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 991px) {
  .inter-service__des.inter-service__des--3lines {
    height: 63px;
  }
}

.owl-carousel.col4 .fee-pack__price {
  font-size: 26px;
}

.vat-service__images,
.vat-service__images + img {
  border-radius: 12px 12px 12px 0;
}

.hot-key__value {
  cursor: pointer;
}

@media (max-width: 1140px) {
  .net-switch__video {
    height: unset;
  }
  .switch-condition__banner-mobile {
    /* display: block; */
    width: 100%;
    float: left;
    /* display: flex; */
  }
}

@media (min-width: 390px) and (max-width: 767px) {
  .mobile-services-page-v5 .content .tit h2.left {
    text-overflow: unset;
    overflow: unset;
  }
}

.service-data__list.service-data__list--start-items {
  justify-content: flex-start;
}

.service-data__list.service-data__list--start-items.service-data .data-pack__des {
  height: 41.6px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    flex-direction: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -15px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(100% / 3 - 30px);
    flex: 0 0 calc(100% / 3 - 30px);
    margin: 0 15px 25px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -10px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

@media (max-width: 576px) {
  .vat-service__list.vat-service__list--rps .vat-service__item {
    flex: 0 0 calc(100% - 16px);
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
}

.pack-detail__box p {
  line-height: 24px;
}

#main-packs {
  margin: 0 -15px;
}

@media (min-width: 992px) {
  #main-packs:hover .slick-arrow {
    opacity: 1;
  }
}

#main-packs .pack-master__item {
  margin: 0 0 30px;
  padding: 0 15px;
}

#main-packs .slick-slide > div:first-child {
  margin-bottom: 42px;
  /* 42px: tổng 72px, các item đã có margin-bottom 30px */
}

#main-packs .pack-master__item .fee-pack__price {
  font-size: 25px;
}

#main-packs .slick-arrow {
  background: #ccc;
  border: 1px solid #e6e6e6;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  z-index: 1;
}

#main-packs .slick-arrow:hover {
  background-color: #fff;
  border-color: #ee0033;
  color: #ee0033;
}

#main-packs .owl-dots {
  margin-top: -10px;
}

#main-packs .owl-dots li button,
#main-packs .owl-dots li.slick-active button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

#main-packs .owl-dots li.slick-active button {
  background: #ee0033;
}

#main-packs .owl-dots li:hover button {
  background: #ee0033;
}

.package-intro > * {
  line-height: 24px;
  color: #44494d;
  font-size: 16px;
  opacity: 0.8;
}

.vat-service__des.vat-service__des--2lines {
  height: 48px;
}

@media (max-width: 991px) {
  .btabs.left.width-100-xs {
    width: 100% !important;
    max-width: 100% !important;
  }
  .vat-service__des.vat-service__des--2lines {
    height: 42px;
  }
}

.contact-now__close {
  display: block;
  right: -15px;
  top: -15px;
}

.box-same .gtgt h2 i.icon-i-next {
  position: absolute;
  top: 3px;
  right: 0;
}

.minmax .pr span {
  color: #ee0033;
}

.dn h2 {
  font-size: 16px;
}

.box-price .speci-price {
  font-size: 16px;
}

.main-packages .data-content p.descript {
  display: -webkit-box;
}

.row-top .link-top li {
  float: left;
  margin: 0px 40px 0px 0px;
}

.header {
  height: unset;
  z-index: 13;
}

.intro-code__content {
  border: none;
  border-radius: 0px;
  padding: 0px;
}

.intro-code__content input {
  color: #ee0033;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 5px;
  text-align: center;
}

.intro-time__btn.custom-search {
  width: 60%;
  margin: 0 auto;
  background-color: #ee0033;
  color: #ffffff;
}

.int .int-desc {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #576c8a;
  margin: 10px 30px 18px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #576c8a;
  margin: 10px 30px 18px;
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
}

.int {
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
}

.box-data h2,
.box-data h3 {
  width: 100%;
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info h2 a {
  float: left;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .rating-summary {
  width: auto;
  float: left;
  margin-bottom: 0;
}

.rating-summary .rating-result {
  height: 25px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .new-pr {
  width: 100%;
  float: left;
  font-size: 16px;
  margin: 10px 0;
}

.box-spillover__top {
  margin: 0;
  width: auto;
  float: left;
}

.box-spillover__top.history-spread {
  margin: 5px 0 20px;
  width: 100%;
  float: left;
}

/* common v5 */

.pathway.pathway-m0 {
  margin: 0;
}

@media (max-width: 550px) {
  .pathway.pathway-m0 {
    padding: 10px 0;
  }
  .pathway.pathway-m0 .content ul li {
    margin-bottom: 5px;
  }
  .service-voice--detail,
  .pack-master {
    padding-top: 10px;
  }
  .package-detail__images {
    width: 100%;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  top: calc(50% - ((16px + 18px) / 2));
  /* 16: height của dots; 18 là margin-top của dots */
}

/* end common v5 */

/* menu v5 */

.has-sub .submenu li a {
  display: flex !important;
}

.menu-slide__sub li {
  margin-bottom: 5px;
}

.list-language {
  top: 120%;
}

.list-language li a {
  display: block;
}

.login-register ul li > a > a {
  display: none !important;
}

.login-register ul li:hover {
  cursor: default;
}

.login-register ul li.active:hover > a.button-login-bacgroud {
  color: #fff !important;
}

.login-register ul li.active:hover > a.button-login-bacgroud:hover {
  color: #ee0033 !important;
}

.row-menu .boxmenu ul li a {
  font-weight: unset;
}

.bacgroud-header {
  box-shadow: unset !important;
}

a.off-canvas-toggle::before {
  display: none;
}

/* end menu v5 */

/* home v5 */

@media (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-next {
    left: unset;
  }
}

@media (max-width: 991px) {
  .apply-number .viewall {
    bottom: -40px;
  }
}

@media (max-width: 767px) {
  .content.tabs .btabs .menu_select_sp {
    color: #576c8a !important;
  }
}

@media (min-width: 443px) and (max-width: 767px) {
  .content .tit h2.left {
    width: 50%;
  }
}

.news-event__info {
  align-items: baseline;
}

.news-event__left .news-event__images a.imgs {
  width: 100%;
}

.sim-number th {
  text-align: left;
}

.radio-custom1 {
  margin: 0;
}

.fee-pack__price.custom-fontsize {
  font-size: 26px;
}

.fee-pack__price span {
  text-transform: lowercase;
}

.title-home.custom-lineheight {
  line-height: 40px;
}

.vat-service__des {
  height: 46px;
}

@media (max-width: 991px) {
  .vat-service__des {
    height: 36px;
  }
}

.vat-service__price span {
  text-transform: lowercase;
}

button.owl-dot.active span {
  width: 10px;
}

.news-event h5 {
  font-size: 12px;
}

.title-block {
  font-size: 26px;
}

.mobile-service.mobile-service-home {
  padding: 30px 0 20px;
}

.viettel-plush {
  margin: 55px 0 0;
}

.vat-service__price {
  align-items: flex-end;
}

.list-check li {
  padding: 0 0 0 28px;
  margin-bottom: 14px;
  height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.list-check li i {
  top: 1px;
  left: 1px;
  transform: unset;
}

.fee-pack__name:hover {
  color: #44494d;
}

.vat-service__btn .button {
  width: unset;
}

.viettel-plus__info {
  display: flex;
  align-items: center;
}

.row input {
  color: #576c8a;
}

@media only screen and (max-width: 1023px) {
  .mobile-service .content .tit .btabs {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .title-home {
    font-size: 18px !important;
  }
}

.vat-service__price--mt13 {
  margin-top: 13px;
}

/* end home v5 */

/* register v5 */

.form-input.form-input__2icon .icon-clear-field {
  right: 42px;
}

.form-input.form-input__2icon .form-control {
  padding-right: 70px;
}

.item-acc .edit {
  cursor: pointer;
}

.auth {
  justify-content: space-between;
}

.auth__images {
  margin: 0;
}

.form-customize .form-control:focus {
  border-color: #58b84f !important;
}

/* end register v5 */

/* login */

.auth__des-link--right {
  justify-content: flex-end;
}

/* end login */

/* đa tài khoản */

.pathwayor {
  margin: 0;
}

@media (min-width: 992px) {
  .account-add__btn .button {
    width: 328px;
  }
}

.account-addional {
  margin-bottom: 30px;
}

/* end đa tài khoản */

/* fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

.space1.space-bottom,
.service-gtgt h2.title {
  margin-bottom: 60px;
}

/* end fix margin title Các gói tương tự tại trang chi tiết gói cước, gói data, dịch vụ vat */

/* thong tin tai khoan v5 */

.page-qltkdd .tabs h3.promotion__title,
.page-qltkdd .tabs h3.slider-search__description {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #44494d;
  text-transform: initial;
}

.user-mobile-pre-v5 .btabs.left.opened {
  z-index: 3;
}

.debt-charges__util-title {
  text-transform: uppercase;
}

.data-package__header-name.data-package__header-name--fs36 {
  font-size: 36px;
}

.service-list__total-price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-qltkdd .tabs h3.promotion__title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service-list__description.service-list__description--2lines {
  height: 48px;
}

.promotion__title.promotion__title--2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page-qltkdd .tabs-lookups {
  padding: 30px 0 0.1px;
  background: #e4e4e4;
}

.tabs-lookups .content.tabs {
  float: none;
}

@media (max-width: 991px) {
  .page-qltkdd .tabs h3.slider-search__description,
  .page-qltkdd .tabs h3.promotion__title {
    width: 100%;
    white-space: unset;
  }
  .debt-charges__util-img {
    margin: 0 auto;
    width: fit-content;
  }
}

.tab-title li a {
  margin: 0 0 -1px 0;
}

.service-list__title-top {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 110px);
  font-size: 18px;
}

.service-list__intro--bottom {
  display: flex;
  align-items: flex-end;
}

.service-list__description {
  overflow: hidden;
  width: 95%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.service-list__intro--bottom .service-list__intro--bottom-left {
  width: calc(100% - 105px);
}

.service-list__intro--bottom .service-list__intro--bottom-right {
  width: 105px;
}

.tabs-dvdsd__notfound__text {
  margin-bottom: 15px;
}

.debt-charges__avatar-user {
  border-radius: 50%;
}

.data-package__btn-box {
  margin-left: 0;
}

@media (min-width: 1154px) and (max-width: 1170px) {
  .debt-charges__header {
    padding: 20px;
  }
}

.debt-charges__end-term {
  margin-right: 73px;
}

.ud-viettel .content .tit h2.left {
  overflow: unset;
}

.data-package__title.data-package__title--custom {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .ud-viettel .owl-nav {
    display: none;
  }
}

@media (max-width: 1150px) {
  .manager-ftth__util {
    position: unset;
    margin: 0 20px 30px;
    border: 0;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

.bg-blur {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-full-screen {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

.bg-fixed {
  position: fixed;
  z-index: 13;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.debt-charges__btn-pay {
  margin-bottom: 20px;
}

.debt-charges__type-point--mgt6 {
  margin-top: 6px;
}

.data-package__sms-description--2lines {
  height: 48px;
  overflow: hidden;
}

@media (max-width: 350px) {
  .button.button--rps-w68 {
    width: 68px;
    padding: 12px 0 8px;
    white-space: nowrap;
  }
}

@media (max-width: 370px) {
  .postpaid-slider__header-title--rps-370 {
    font-size: 19px;
  }
  .postpaid-slider__date--rps-350 {
    font-size: 15px;
  }
  .button.button--rps-w92 {
    width: 68px;
    padding: 12px 0 8px;
    white-space: nowrap;
  }
}

.postpaid-slider--center .owl-stage-outer {
  display: flex;
  justify-content: center;
}

.button--w180 {
  width: 180px !important;
}

/* end thong tin tai khoan v5 */

/* mobile services v5 */

.clear-padding.owl-carousel .owl-stage-outer {
  padding-bottom: 0 !important;
}

.mobile-services-page-v5 {
  overflow-y: hidden;
}

@media (max-width: 991px) {
  .mobile-services-page-v5 .content.tabs {
    margin-bottom: 0;
  }
  .mobile-services-page-v5 .service-vat {
    padding: 30px 0 40px;
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fee-pack__des.fee-pack__des--2lines {
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--3lines {
  height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inter-service__des.inter-service__des--4lines {
  height: 96px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 991px) {
  .inter-service__des.inter-service__des--3lines {
    height: 63px;
  }
}

.owl-carousel.col4 .fee-pack__price {
  font-size: 26px;
}

.vat-service__images,
.vat-service__images + img {
  border-radius: 12px 12px 12px 0;
}

.hot-key__value {
  cursor: pointer;
}

@media (max-width: 1140px) {
  .net-switch__video {
    height: unset;
  }
}

@media (min-width: 390px) and (max-width: 767px) {
  .mobile-services-page-v5 .content .tit h2.left {
    text-overflow: unset;
    overflow: unset;
  }
}

.service-data__list.service-data__list--start-items {
  justify-content: flex-start;
}

.service-data__list.service-data__list--start-items.service-data .data-pack__des {
  height: 41.6px;
}

@media (min-width: 576px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    flex-direction: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -15px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(100% / 3 - 30px);
    flex: 0 0 calc(100% / 3 - 30px);
    margin: 0 15px 25px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .service-data__list.service-data__list--rps {
    margin: 0 -10px;
  }
  .service-data__list.service-data__list--rps .service-data__item {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

@media (max-width: 576px) {
  .vat-service__list.vat-service__list--rps .vat-service__item {
    flex: 0 0 calc(100% - 16px);
  }
}

.data-pack__des.data-pack__des--2lines {
  height: 42px;
}

.pack-detail__box p {
  line-height: 24px;
}

#main-packs {
  margin: 0 -15px;
}

@media (min-width: 992px) {
  #main-packs:hover .slick-arrow {
    opacity: 1;
  }
}

#main-packs .pack-master__item {
  margin: 0 0 30px;
  padding: 0 15px;
}

#main-packs .slick-slide > div:first-child {
  margin-bottom: 42px;
  /* 42px: tổng 72px, các item đã có margin-bottom 30px */
}

#main-packs .pack-master__item .fee-pack__price {
  font-size: 25px;
}

#main-packs .slick-arrow {
  background: #ccc;
  border: 1px solid #e6e6e6;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  z-index: 1;
}

#main-packs .slick-arrow:hover {
  background-color: #fff;
  border-color: #ee0033;
  color: #ee0033;
}

#main-packs .owl-dots {
  margin-top: -10px;
}

#main-packs .owl-dots li button,
#main-packs .owl-dots li.slick-active button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

#main-packs .owl-dots li.slick-active button {
  background: #ee0033;
}

#main-packs .owl-dots li:hover button {
  background: #ee0033;
}

.package-intro > * {
  line-height: 24px;
  color: #44494d;
  font-size: 16px;
  opacity: 0.8;
}

.vat-service__des.vat-service__des--2lines {
  height: 48px;
}

@media (max-width: 991px) {
  .btabs.left.width-100-xs {
    width: 100% !important;
    max-width: 100% !important;
  }
  .vat-service__des.vat-service__des--2lines {
    height: 42px;
  }
}

.btabs.left.m0 {
  margin: 0;
}

@media (max-width: 350px) {
  .data-pack__detail.data-pack__detail--xxs .data-pack__info,
  .data-pack__detail.data-pack__detail--xxs .data-pack__btn {
    width: 50%;
  }
  .data-pack__detail.data-pack__detail--xxs .data-pack__info .data-pack__price {
    position: relative;
    top: 10px;
  }
}

@media (max-width: 1009px) {
  .pack-detail__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .pack-detail__img {
    background-size: cover;
    border-radius: 8px 8px 8px 0;
    height: 130px;
    margin: 0 0 20px;
    width: 100%;
  }
  .pack-detail__info {
    width: 100%;
  }
}

.nowrap {
  white-space: nowrap;
}

.btn-content-center {
  display: flex;
  justify-content: center;
}

.order-buy__value {
  text-align: right;
}

.delivery-contact__info {
  font-weight: 400;
}

.delivery-contact__form-item.delivery-contact__form-item--w130 {
  width: 130px;
  flex: 0 0 130px;
}

select.form-control.fix-arrow-bg {
  background-position-x: calc(100% - 15px);
}

input.date-select-picker.fix-arrow-bg {
  background-size: 12.5px;
}

input.form-control.fw-ph-400::placeholder {
  font-weight: 400;
}

@media (max-width: 767px) {
  .choose-pack .nav-midle .nav,
  .owl-nav button,
  .slick-arrow {
    display: none;
  }
  .delivery-contact__form-action {
    flex-direction: unset;
  }
  .delivery-contact__form-item:not(:last-child) {
    margin: 0 15px 0 0;
  }
}

@media (max-width: 400px) {
  .delivery-contact__form-action {
    flex-direction: column;
  }
  .delivery-contact__form-item:not(:last-child) {
    margin: 0 0 15px 0;
  }
  .delivery-contact__form-item.delivery-contact__form-item--w130 {
    flex: 1;
    width: 100%;
  }
}

.pack-detail__info--height-same-left {
  height: 113px;
}

.pack-detail__info--height-same-left ul.pack-detail__list {
  height: 100%;
}

.button-bottom {
  margin-top: 16px;
}

.button-mw241 {
  max-width: 241px;
}

.slick-next i,
.slick-prev i {
  font-size: 18px !important;
}

.package-detail__select-item--custom-margin {
  margin-bottom: 8px;
}

.package-detail__select-item--custom-margin .radio-title__name {
  margin-bottom: 4px;
}

@media (max-width: 1030px) {
  .main-full.main-full--breakpoint1030 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 30px 0 40px;
  }
  .main-full.main-full--breakpoint1030 .main-left {
    margin: 0 0 30px;
    width: 100%;
  }
  .main-full.main-full--breakpoint1030 .sidebar-right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    width: 100%;
  }
}

.service-list__title-top--fit-content {
  width: fit-content;
}

.vat-service--tt .vat-service__des {
  height: 42px;
}

.data-pack__detail.data-pack__detail--btn-flex-end {
  align-items: flex-end;
}

.vat-service__btn.vat-service__btn--mt10px {
  margin-top: 10px;
}

.vat-service__btn--mt6 {
  margin-top: 6px;
}

.radio-customize--custom-disabled .radio-custom1 {
  pointer-events: none;
}

.fee-pack__price--1line {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 991px) {
  .inter-service .inter-service__info--clear-right {
    align-items: normal;
  }
  .inter-service .inter-service__info--clear-right .inter-service__btn {
    justify-content: flex-end;
  }
}

.pack-detail__box--color-fm {
  color: #474747;
}

.package-detail__images--content-top {
  align-items: start;
}

/* end mobile services v5 */

/* tra cuu hoa don dien tu */

.message-bill-mass.message-no-data {
  text-align: center;
}

@media (max-width: 991px) {
  .w--209 {
    width: 209.6px;
  }
  .message-bill-mass.message-no-data {
    text-align: left;
  }
}

.nowrap {
  white-space: nowrap;
}

.btn-content-center {
  display: flex;
  justify-content: center;
}

.order-buy__value {
  text-align: right;
}

.delivery-contact__info {
  font-weight: 400;
}

.delivery-contact__form-item.delivery-contact__form-item--w130 {
  width: 130px;
  flex: 0 0 130px;
}

select.form-control.fix-arrow-bg {
  background-position-x: calc(100% - 15px);
}

input.date-select-picker.fix-arrow-bg {
  background-size: 12.5px;
}

input.form-control.fw-ph-400::placeholder {
  font-weight: 400;
}

@media (max-width: 767px) {
  .choose-pack .nav-midle .nav,
  .owl-nav button,
  .slick-arrow {
    display: none;
  }
  .delivery-contact__form-action {
    flex-direction: unset;
  }
  .delivery-contact__form-item:not(:last-child) {
    margin: 0 15px 0 0;
  }
}

@media (max-width: 400px) {
  .delivery-contact__form-action {
    flex-direction: column;
  }
  .delivery-contact__form-item:not(:last-child) {
    margin: 0 0 15px 0;
  }
  .delivery-contact__form-item.delivery-contact__form-item--w130 {
    flex: 1;
    width: 100%;
  }
}

.pack-detail__info--height-same-left {
  height: 113px;
}

.pack-detail__info--height-same-left ul.pack-detail__list {
  height: 100%;
}

.button-bottom {
  margin-top: 16px;
}

.button-mw241 {
  max-width: 241px;
}

.slick-next i,
.slick-prev i {
  font-size: 18px !important;
}

.package-detail__select-item--custom-margin {
  margin-bottom: 8px;
}

.package-detail__select-item--custom-margin .radio-title__name {
  margin-bottom: 4px;
}

@media (max-width: 1030px) {
  .main-full.main-full--breakpoint1030 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 30px 0 40px;
  }
  .main-full.main-full--breakpoint1030 .main-left {
    margin: 0 0 30px;
    width: 100%;
  }
  .main-full.main-full--breakpoint1030 .sidebar-right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    width: 100%;
  }
}

.service-list__title-top--fit-content {
  width: fit-content;
}

.vat-service--tt .vat-service__des {
  height: 42px;
}

.data-pack__detail.data-pack__detail--btn-flex-end {
  align-items: flex-end;
}

.vat-service__btn.vat-service__btn--mt10px {
  margin-top: 10px;
}

.vat-service__btn--mt6 {
  margin-top: 6px;
}

.radio-customize--custom-disabled .radio-custom1 {
  pointer-events: none;
}

.fee-pack__price--1line {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 991px) {
  .inter-service .inter-service__info--clear-right {
    align-items: normal;
  }
  .inter-service .inter-service__info--clear-right .inter-service__btn {
    justify-content: flex-end;
  }
}

.pack-detail__box--color-fm {
  color: #474747;
}

.package-detail__images--content-top {
  align-items: start;
}

.pack-detail__item--2lines {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 48px;
  line-height: 27px;
}

.focus-error-checkbox {
  border: 1px dotted red;
  width: fit-content;
}

.data-pack__info--custom-width {
  width: calc(100% - 130px);
}

.data-pack__price-value--long-text-1line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 21px);
}

/* end mobile services v5 */

/* tra cuu hoa don dien tu */

.message-bill-mass.message-no-data {
  text-align: center;
}

@media (max-width: 991px) {
  .w--209 {
    width: 209.6px;
  }
  .message-bill-mass.message-no-data {
    text-align: left;
  }
}

.lookup-invoice__form--have-msg .form-customize:not(:last-child) {
  margin-bottom: 28px;
}

.lookup-invoice__form--have-msg .form-customize .link-otp {
  margin: 20px 0 0;
}

.vdp-datepicker-customize__h40 input {
  height: 40px;
}

.vdp-datepicker-customize__h40 + .icon-calendar-month {
  top: 10px;
  transform: none;
}

/* end tra cuu hoa don dien tu */

.lookup-invoice__form--have-msg .form-customize:not(:last-child) {
  margin-bottom: 28px;
}

.lookup-invoice__form--have-msg .form-customize .link-otp {
  margin: 20px 0 0;
}

.vdp-datepicker-customize__h40 input {
  height: 40px;
}

.vdp-datepicker-customize__h40 + .icon-calendar-month {
  top: 10px;
  transform: none;
}

/* end tra cuu hoa don dien tu */

/* yeu cau doi sim */

.select-viettel-v5 span.select2-selection.select2-selection--single {
  height: 40px;
  border-radius: 8px 8px 8px 0;
  border: 1px solid #d1d1d1 !important;
}

.select-viettel-v5 span.select2-selection.select2-selection--single .select2-selection__arrow {
  top: 4px !important;
  border-radius: 50%;
}

.select-viettel-v5 .select2-container {
  width: 100% !important;
}

.delivery-contact__form-action--custom {
  align-items: flex-start;
}

@media (min-width: 401px) {
  .delivery-contact__form-action--custom .delivery-contact__form-item {
    flex: 0 0 143px;
    width: 143px;
  }
}

.delivery-contact__form-action--custom .delivery-contact__form-item .vdp-datepicker input {
  text-align: center;
}

/* end yeu cau doi sim */

/* ứng tiền */

.wrapper-2buttons-v5 {
  display: flex;
  width: 100%;
  margin: 25px -5px 0;
}

.wrapper-2buttons-v5 .button {
  width: 50%;
  margin: 0 5px;
}

/* end ứng tiền */

/* video hướng dẫn */

.get-link-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.get-link-btn span.status-copy {
  display: block;
  position: absolute;
  bottom: calc(100% + 10px);
  background: #333333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.get-link-btn span.status-copy::after {
  content: "";
  background: #333333;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* end video hướng dẫn */

/* ứng tiền */

.face-value--content-center {
  justify-content: center;
}

.service-list__title-top--fit-content {
  width: fit-content;
}

.vat-service--tt .vat-service__des {
  height: 42px;
}

.data-pack__detail.data-pack__detail--btn-flex-end {
  align-items: flex-end;
}

.vat-service__btn.vat-service__btn--mt10px {
  margin-top: 10px;
}

.vat-service__btn--mt6 {
  margin-top: 6px;
}

.radio-customize--custom-disabled .radio-custom1 {
  pointer-events: none;
}

.fee-pack__price--1line {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 991px) {
  .inter-service .inter-service__info--clear-right {
    align-items: normal;
  }
  .inter-service .inter-service__info--clear-right .inter-service__btn {
    justify-content: flex-end;
  }
}

.pack-detail__box--color-fm {
  color: #474747;
}

.package-detail__images--content-top {
  align-items: start;
}

/* end ứng tiền */

/* tra cuu hoa don dien tu */

.message-bill-mass.message-no-data {
  text-align: center;
}

@media (max-width: 991px) {
  .w--209 {
    width: 209.6px;
  }
  .message-bill-mass.message-no-data {
    text-align: left;
  }
}

.lookup-invoice__form--have-msg .form-customize:not(:last-child) {
  margin-bottom: 28px;
}

.lookup-invoice__form--have-msg .form-customize .link-otp {
  margin: 20px 0 0;
}

.vdp-datepicker-customize__h40 input {
  height: 40px;
}

.vdp-datepicker-customize__h40 + .icon-calendar-month {
  top: 10px !important;
  transform: none !important;
}

/* end tra cuu hoa don dien tu */

/* yeu cau doi sim */

.select-viettel-v5 span.select2-selection.select2-selection--single {
  height: 40px;
  border-radius: 8px 8px 8px 0;
  border: 1px solid #d1d1d1 !important;
}

.select-viettel-v5 span.select2-selection.select2-selection--single .select2-selection__arrow {
  top: 4px !important;
  border-radius: 50%;
}

.select-viettel-v5 .select2-container {
  width: 100% !important;
}

.delivery-contact__form-action--custom {
  align-items: flex-start;
}

@media (min-width: 401px) {
  .delivery-contact__form-action--custom .delivery-contact__form-item {
    flex: 0 0 143px;
    width: 143px;
  }
}

.delivery-contact__form-action--custom .delivery-contact__form-item .vdp-datepicker input {
  text-align: center;
}

/* end yeu cau doi sim */

/* ứng tiền */

.wrapper-2buttons-v5 {
  display: flex;
  width: 100%;
  margin: 25px -5px 0;
}

.wrapper-2buttons-v5 .button {
  width: 50%;
  margin: 0 5px;
}

/* end ứng tiền */

/* video hướng dẫn */

.get-link-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.get-link-btn span.status-copy {
  display: block;
  position: absolute;
  bottom: calc(100% + 10px);
  background: #333333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.get-link-btn span.status-copy::after {
  content: "";
  background: #333333;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* end video hướng dẫn */

/* ứng tiền */

.face-value--content-center {
  justify-content: center;
}

.service-list__title-top--fit-content {
  width: fit-content;
}

.vat-service--tt .vat-service__des {
  height: 42px;
}

.data-pack__detail.data-pack__detail--btn-flex-end {
  align-items: flex-end;
}

.vat-service__btn.vat-service__btn--mt10px {
  margin-top: 10px;
}

.vat-service__btn--mt6 {
  margin-top: 6px;
}

/* end ứng tiền */

/* tra cuu hoa don dien tu */

.message-bill-mass.message-no-data {
  text-align: center;
}

@media (max-width: 991px) {
  .w--209 {
    width: 209.6px;
  }
  .message-bill-mass.message-no-data {
    text-align: left;
  }
}

.lookup-invoice__form--have-msg .form-customize {
  position: relative;
}

.lookup-invoice__form--have-msg .form-customize .error {
  position: absolute;
}

.lookup-invoice__form--have-msg .form-customize:not(:last-child) {
  margin-bottom: 28px;
}

.lookup-invoice__form--have-msg .form-customize .link-otp {
  margin: 20px 0 0;
}

.vdp-datepicker-customize__h40 input {
  height: 40px;
}

/* end tra cuu hoa don dien tu */

/* yeu cau doi sim */

.select-viettel-v5 span.select2-selection.select2-selection--single {
  height: 40px;
  border-radius: 8px 8px 8px 0;
  border: 1px solid #d1d1d1 !important;
}

.select-viettel-v5 span.select2-selection.select2-selection--single .select2-selection__arrow {
  top: 4px !important;
  border-radius: 50%;
}

/* end yeu cau doi sim */

/* ứng tiền */

.wrapper-2buttons-v5 {
  display: flex;
  width: 100%;
  margin: 25px -5px 0;
}

.wrapper-2buttons-v5 .button {
  width: 50%;
  margin: 0 5px;
}

/* end ứng tiền */

/* video hướng dẫn */

.get-link-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.get-link-btn span.status-copy {
  display: block;
  position: absolute;
  bottom: calc(100% + 10px);
  background: #333333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.get-link-btn span.status-copy::after {
  content: "";
  background: #333333;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* end video hướng dẫn */

/* phân trang v5 */

.navpage.navpage-v5 ul li a,
.navpage.navpage-v5 ul li strong {
  border-radius: 4px 4px 4px 0;
  min-width: 30px;
  line-height: 30px;
  padding: 0;
}

.navpage.navpage-v5 ul li a {
  color: #727272;
  background: #eeeeee;
  padding: 0;
}

.navpage.navpage-v5 ul li:hover a {
  border-radius: 4px 4px 4px 0;
}

.navpage.navpage-v5 ul li.arrow a:hover,
.navpage.navpage-v5 ul li:first-child a:hover,
.navpage.navpage-v5 ul li:last-child a:hover {
  background: #ee0033;
  color: #fff;
}

.navpage.navpage-v5 ul li.arrow a {
  font-size: 10px;
  transform: translateY(-3px);
}

.navpage-v5-custom {
  display: flex;
  justify-content: center;
  margin: 55px 0 65px;
  font-size: 20px;
}

.navpage-v5-custom a,
.navpage-v5-custom span {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  color: #727272;
  padding: 0 3px;
}

.navpage-v5-custom a.arrow {
  font-size: 10px;
}

@media (min-width: 768px) {
  .navpage-v5-custom a.arrow-left {
    margin-right: 35px;
  }
  .navpage-v5-custom a.arrow-right {
    margin-left: 35px;
  }
}

@media (max-width: 768px) {
  .navpage-v5-custom {
    margin: 20px 0;
  }
}

.navpage-v5-custom a {
  background-color: #eeeeee;
  border-radius: 4px 4px 4px 0;
}

.navpage-v5-custom a:hover,
.navpage-v5-custom a.active {
  background-color: #ee0033;
  color: #fff;
}

/* end phân trang */

/* @media (max-width: 767px) { */

.time-orders {
  font-size: 14px;
}

.custom-item .gtgt {
  width: calc(100% / 3 - 30px) !important;
  margin: 0 15px 30px !important;
}

@media (max-width: 911px) {
  .gather-tet__last-btn {
    padding: 0 5px;
  }
  .pack-list__row--voice {
    /* background: url(../images_content/pexels-photo-583842.jpeg) center center no-repeat; */
  }
  .gather-tet__column {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .main-pay .radio-customize {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .row-menu .boxmenu ul li .mega-menu.submenu {
    width: 740px;
  }
  .mega-menu .sub-item:first-child {
    width: 33%;
  }
  .mega-menu .sub-item {
    width: 29%;
  }
  .sub-item:last-child {
    width: 38%;
  }
}

@media (max-width: 1039px) {
  .menu-pc,
  .row-top .link-top {
    display: none;
  }
  .row-top {
    background-color: unset;
  }
  .row-top .content::after {
    display: none;
  }
  .off-canvas-toggle,
  .menu-sp {
    display: block;
    position: unset;
  }
  .open .off-canvas-toggle:after {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 10;
  }
  .row-menu {
    display: none;
  }
  .open .row-menu {
    display: block;
    position: fixed;
    left: 0;
    width: 85%;
    top: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .off-canvas-toggle,
  .warp #row-top .search-top i.icon-search {
    color: #000000;
  }
  .row-top .language {
    display: none;
  }
  .row-top .content {
    padding: 25px 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .row-top .search-top i.icon-search {
    font-size: 26px;
    font-weight: 300;
  }
  .row-top .search {
    display: none;
  }
}

@media (min-width: 1039px) {
  .visible-xs {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .visible-xs {
    display: block;
  }
}

.link-otp__default-cursor {
  cursor: default;
}

.link-otp span {
  cursor: pointer;
}

.link-otp[disabled] {
  color: #e4e4e4;
}

.link-otp.add-account-otp {
  margin: 8px 0;
}

/* @media (max-width: 991px) {
.row-top {
    background-color: unset;
}
.off-canvas-toggle,
.warp #row-top .search-top i.icon-search {
    color: #000000;
}
} */

@media (max-width: 767px) {
  .content.tabs a.viewall {
    bottom: -30px;
  }
  .mobile-service {
    margin: 30px 0;
  }
  .phone-device {
    margin: 30px 0 !important;
  }
  .apply-number .content.tabs a.viewall {
    bottom: -40px;
  }
  .content .left.title-block {
    overflow: unset !important;
  }
  .modal-switch__radio .radio-customize {
    width: 100%;
  }
  .modal-switch .form-horizontal {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .modal-switch .form-horizontal .label-title {
    margin: 0;
    width: 100%;
    float: left;
    text-align: center;
  }
  .modal-switch .form-horizontal .form-control {
    width: 100%;
    float: left;
  }
  .box-entertainm .gtgt h3 {
    font-size: 16px;
  }
  .lists.slider .list-gtgt .item {
    margin: 0 12px;
  }
  .radio-customize {
    width: 100%;
    float: left;
  }
  .custom-item .gtgt {
    width: 100% !important;
    position: relative;
  }
  .custom-item .gtgt .imgs {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float: left;
    height: 100px;
    width: 100px;
  }
  .custom-item .gtgt .imgs img {
    -webkit-border-radius: 10px;
  }
  .custom-item .gtgt .intro-more {
    width: 70% !important;
    float: right;
    padding-left: 28px;
  }
  .box-news .list-news .gtgt {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
  }
  .w--190:not([checkebill]) {
    width: 50px;
  }
  .w--130:not([checkebill]) {
    width: 50px;
  }
  .w--150:not([checkebill]) {
    width: 50px;
  }
  .box-spillover__top {
    margin: 40px 0 20px;
    width: 100%;
  }
  .modal__code-images img {
    width: 100%;
  }
  .content .btabs {
    z-index: 10;
  }
  .header {
    height: 46px;
  }
  .hhv-list-internet.hhv-item-has-img .lists.slider .item .list-internet .child-item .inner-itm .item-img {
    position: relative;
  }
  .hhv-list-internet.hhv-item-has-img .lists.slider .item .list-internet .child-item .inner-itm .item-img a {
    width: 104px;
    height: 104px;
    /* float: left; */
    /* display: table-cell; */
    /* vertical-align: middle; */
    position: absolute;
    left: 50%;
    top: 50%;
    /* padding: 20px; */
    transform: translate(-50%, -50%);
  }
  .hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-img {
    height: 135px;
    margin-right: 15px;
    width: 104px;
    padding: 0;
    margin: auto;
  }
  .hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .pr .pr-old {
    display: block;
  }
  .button-404 {
    width: 100%;
    margin: 8px 0;
  }
  #service-postage-package .data-content p {
    min-height: unset;
  }
  .pathway .content ul {
    display: none;
  }
  .box-entertainm .gtgt .imgs {
    height: 136px;
  }
  .box-entertainm .intro-more h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .box-news .gtgt .imgs {
    height: 100px;
    width: 100px;
  }
  .box-music .goi-cuoc .imgs,
  .custom-imgs {
    height: 100px !important;
    width: 100px !important;
    margin: 0 10px;
  }
  .box-music .list-dt .goi-cuoc .minmax-custom {
    padding: 0 0 0 15px !important;
  }
  .minmax-custom .min-maxd {
    padding: 10px 10px 10px 0 !important;
    font-size: 14px !important;
  }
  .box-utilities .list-dt .goi-cuoc .minmax-custom,
  .custom-intro-more {
    width: calc(100% - 30%);
    display: block;
    padding: 0 0 0 25px;
    position: relative;
  }
  .list-gtgt .gtgt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100%);
    -ms-flex: 0 0 calc(100%);
    flex: 0 0 calc(100%);
    margin: 0 0px 30px;
    width: calc(100%);
  }
  .service-gtgt .list-gtgt .intro-more h2 {
    position: relative;
    font-size: 14px;
    padding: 0 15px 0 0;
  }
  .service-gtgt .list-gtgt .intro-more h2 i.icon-i-next {
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 14px;
  }
  .gtgt .intro-more h2 {
    line-height: 22px;
    height: 22px;
  }
  .minmax .pr span,
  .list-dt .min-maxd h2 span {
    display: inline;
  }
  .box-music .list-dt .min-maxd h2 {
    position: relative;
  }
  /* .box-music .list-dt .min-maxd h2 a {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
} */
  .box-music .list-dt .min-maxd h2 i.icon-i-next {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 14px;
  }
  .box-music .list-dt .min-maxd .min-data {
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .box-music .lists.slider .list-gtgt .item {
    display: block;
    display: block;
    display: -ms-flexbox;
    display: block;
  }
  .box-entertainm .lists.slider .list-gtgt .item {
    margin: 0 12px;
  }
  .box-entertainm .lists.slider .list-gtgt .item {
    margin: 0 12px;
  }
  .box-news .list-news .box-dk {
    right: 15px;
  }
  .box-utilities .goi-cuoc .imgs {
    height: 100px;
    width: 100px;
  }
  .box-entertainm {
    margin-bottom: 30px;
  }
  .box-news {
    margin-bottom: 30px;
  }
  .box-music {
    margin-bottom: 30px;
  }
  .box-utilities {
    margin-bottom: 30px;
  }
  .service-gtgt .btn,
  .box-gt .btn {
    padding: 5px 12px;
  }
  .list-news .box-dk {
    padding: 0;
    top: 40%;
  }
}

.packs-service__image img {
  height: 200px;
}

.packs-service__des {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packs-service__detail {
  width: 60%;
}

.packs-service__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packs-info__des {
  height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packs-other__item {
  margin: 15px 15px;
}

.upload-file__icon {
  margin: 0;
}

.js-create-signature {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.button-signature {
  -webkit-appearance: push-button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: buttontext;
  background-color: buttonface;
  box-sizing: border-box;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  -o-border-image: initial;
  border-image: initial;
}

.signature-pad {
  text-align: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.04);
  padding: 20px 15px;
}

canvas#signature-pad {
  background-color: #fff;
  border: 1px dashed #8894a4;
  border-radius: 6px;
  position: relative;
  text-align: center;
}

#ipcc-chat-minimum {
  bottom: 0;
  position: fixed;
  right: 24px;
  bottom: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 12;
  cursor: pointer;
}

#ipcc-chat-minimum .sale-person {
  /* display: flex;
align-items: flex-start; */
  width: 64px;
  height: 64px;
}

#ipcc-chat-minimum .launcher-button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 35px;
  cursor: pointer;
  background-position: center;
}

#ipcc-chat-minimum .launcher-button .launcher-initials {
  background-color: #ffb700;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: "SF Pro Text regular", sans-serif;
  font-size: 15px;
  line-height: 35px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.tbl-tech th {
  color: #576c8a;
  width: 25%;
  text-align: left;
  font-weight: bold;
}

.lp-search__form {
  position: relative;
}

@media (min-width: 991px) {
  .cus-tickets-buy__link-sp {
    display: none;
  }
  .error-text-smart-phone {
    position: absolute;
    top: 75px;
    color: red;
  }
  #cus-use-fee__price {
    display: contents;
  }
}

.custom-entertainment-tet__btn {
  width: 160px;
}

.cus-gather-tet__info-des {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 40px;
  -webkit-box-orient: vertical;
}

@media (min-width: 992px) {
  .cus-tet__column {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .custom-charges-history__chart {
    width: 700px;
  }
  .cus-charges-history__chart {
    width: 100%;
  }
  .custom-history-fee-action {
    padding-bottom: 40px;
  }
}

.custom-entertainment-tet__btn {
  width: 160px;
}

.cus-tet__column {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.cus-gather-tet__info-des {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 40px;
  -webkit-box-orient: vertical;
}

@media (max-width: 1071px) {
  .sim-so {
    flex-direction: column;
  }
  .sim-so__images {
    margin: 30px 0 0;
  }
}

@media (min-width: 609px) and (max-width: 990px) {
  .viettel-plus__info {
    min-height: 71.6px;
  }
}

@media (max-width: 991px) {
  .auth__images {
    justify-content: center;
  }
  .title-home {
    max-width: unset;
  }
  a.viewall {
    bottom: -35px;
  }
  .content.tabs {
    margin-bottom: 30px;
  }
  .content .tit {
    display: flex;
    align-items: baseline;
  }
  .content .tit > .left {
    width: 50%;
    max-width: 50%;
  }
  .content .left.title-block {
    overflow: unset;
  }
  .content .btabs.left {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
  .content .btabs .menu_select_sp {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .tab-title li a {
    white-space: nowrap;
  }
  .viettel-plush {
    background: unset;
    margin-top: 0;
  }
  .internet-tv {
    margin-bottom: 0;
  }
  .content .btabs ul.tab-title li {
    float: none;
  }
  .viettel-plus__info {
    width: 100%;
  }
  .contact-info.custom-contact-info .contact-info__form {
    padding: 20px 10px 0;
  }
  .cuss-use-fee__des {
    display: none !important;
  }
  .cus-tickets-air__link {
    display: none;
  }
  .custom-use-fee__info {
    flex-direction: inherit;
  }
  .custom-use-fee__des {
    display: block;
  }
  .cus-use-fee__content {
    display: block !important;
  }
  .custom-charges-history__chart {
    width: 100%;
  }
  .cus-charges-history__chart {
    width: 100%;
  }
  .cus-charges-history__chart {
    width: 100%;
  }
  .custom-use-fee__info {
    flex-direction: inherit;
  }
  .custom-use-fee__des {
    display: block;
  }
  .cus-use-fee__content {
    display: block !important;
  }
  .custom-charges-history__chart {
    width: 100%;
  }
  .cus-charges-history__chart {
    width: 100%;
  }
  .cus-charges-history__chart {
    width: 100%;
  }
  /* .cus-gather-tet__top {
    max-width: 80%;
} */
  .gather-tet__top-price {
    width: 55%;
    flex: 0 0 55%;
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
  }
  .cuss-gather-tet__top-info {
    max-width: 45%;
    flex: 0 0 45%;
    width: 45%;
  }
  .modal-tet__top-name {
    font-size: 10px;
  }
  .cus-logo-mobile {
    width: 145px !important;
  }
  .cus-gather-tet__info-des {
    line-height: 18px;
  }
  .cus-gather-tet__item {
    position: relative;
  }
  .cus-gather-tet__images {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-70%);
  }
  .cus-tet__images {
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-60%);
  }
  .cus-gather-tet__detail {
    z-index: 1;
  }
  .cus-detail {
    margin-left: 90px;
  }
  .custom-gather-tet__images {
    /* height: auto!important; */
  }
  .custom-gather-tet__last-btn {
    border-radius: 7px;
  }
  .custom-entertainment-tet__des {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-modal--tet {
    font-size: 12px;
  }
  .custom-modal-tet__title {
    border-radius: 10px;
  }
  .custom-modal-tet__chance-images {
    height: 100% !important;
  }
  .custom-modal-tet__top {
    height: 25px;
  }
  .custom-entertainment-tet__btn {
    width: 100px;
  }
  .contact-now {
    bottom: 35px;
  }
  .hotline-now__content {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .contact-now__content {
    padding: 24px 15px 12px 16px;
  }
  .menu-pc {
    display: none;
  }
  .buy-now {
    margin: 39px 0 0 !important;
  }
  #txtSearchProdAssign {
    margin-top: 25px !important;
  }
  .error-text-smart-phone {
    position: absolute;
    top: 94px;
  }
  /* .btn-blue {
    margin: 39px 0 0 !important;

} */
  #txtSearchProdAssign {
    margin-top: 25px !important;
  }
  .error-text-smart-phone {
    position: absolute;
    top: 94px;
    color: red;
    margin-top: 3px;
  }
}

.icon-menu {
  margin-top: 11px;
}

.off-canvas-toggle {
  font-size: 20px;
}

.btn-buy a {
  margin-top: 0px !important;
}

.tbl-tech td {
  padding-left: 20px;
}

.canvas-custom {
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  font-size: 23px;
}

.lp-product__des {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 93px;
}

.tbl-tech {
  margin-top: 30px;
}

.off-canvas-toggle {
  border: none;
  background: none;
}

@media only screen and (max-width: 991px) {
  .off-canvas-toggle {
    display: block !important;
  }
}

.border-top-pack-0 {
  background-color: #1aa187 !important;
}

.border-top-pack-1 {
  background-color: #3584ee !important;
}

.border-top-pack-2 {
  background-color: #0eb0eb !important;
}

.border-top-pack-3 {
  background-color: #ed7f2f !important;
}

.main-pay .content.tabs .tabs-inner .nav-tabs-custom {
  padding: 22px 0 65px;
  display: flex;
}

.main-pay .content.tabs .tabs-inner .nav-tabs-custom li {
  width: 18%;
}

.field .control-custom {
  padding: 0 0 0 195px;
}

.field .control-custom .errorBorder {
  border-color: #ff0000;
}

@media (max-width: 480px) {
  .main-pay .content.tabs .tabs-inner .nav-tabs-custom li {
    width: 48%;
    margin-right: 12px;
  }
}

.message-error {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  padding-left: 5px;
}

.action .disable-btn {
  background: #616161;
  border: 2px solid #616161;
}

.action .disable-btn:hover {
  background: #616161;
  border: 2px solid #616161;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn a {
  color: #13afb4;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn.active,
.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn:hover {
  background-color: #13afb4;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn {
  background-color: transparent;
  border: 1px solid #13afb4;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn.active a,
.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.price-btn:hover a {
  color: #fff;
}

.errorMes {
  color: #ff0000;
  font-size: 14px;
  margin-top: 8px;
  word-break: break-word;
}

.lp-search__form_custom {
  padding-bottom: 33px !important;
}

@media (max-width: 991px) {
  .customer_heared-popup {
    height: 30px;
    position: fixed;
  }
  .customer_heared-popup h5 {
    width: 93%;
    float: left;
    font-weight: bold;
    font-size: 15.625px;
    line-height: 22px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  #popup_quatet .modal-body-tet {
    padding-top: 28px;
  }
  .customer_heared-popup .modal__close {
    float: right;
    line-height: 20px;
  }
  .modal-tet__chance-des.custom-click-detail-trung-vang {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #444343;
    float: right;
  }
  .modal .inner-input p.t-cen a {
    padding: 12px 0;
    width: 100%;
    margin-bottom: 15px;
  }
  p.t-cen.actions .btn {
    margin: 0;
  }
  .modal .inner-input p.t-cen button {
    padding: 12px 0;
    width: 100%;
    margin-bottom: 15px;
  }
  .shop-viettel__top.shop-viettel__top_new {
    -webkit-box-align: unset;
    -webkit-align-items: unset;
    -ms-flex-align: unset;
    align-items: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -webkit-flex-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset;
    margin-bottom: 15px;
  }
}

.shop-viettel__nav {
  position: relative;
  z-index: 10;
}

.shop-viettel__nav.opened .nav-links {
  display: block;
}

.shop-viettel__title {
  margin: 0 15px 0 0;
}

.banner-shop__sp {
  display: none;
  width: 100%;
}

@media (max-width: 991px) {
  .banner-shop__sp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.banner-shop__sp img {
  width: 100%;
}

@media (max-width: 991px) {
  .banner-shop__sp img {
    height: 180px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.nav-sp {
  cursor: pointer;
  display: none;
}

@media (max-width: 991px) {
  .nav-sp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.nav-sp__name {
  color: #576c8a;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}

.nav-sp i {
  font-size: 8px;
  margin-left: 3px;
  position: relative;
  top: -2px;
}

@media (max-width: 991px) {
  .nav-links {
    overflow: unset;
    width: unset;
    background-color: #fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 5px;
    min-width: 160px;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 100%;
  }
}

@media (max-width: 991px) {
  .nav-links__item {
    padding: 8px 0;
  }
  .nav-links__item:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
}

.sim-shop .shop-viettel__top {
  margin-bottom: unset;
}

.internet-shop .label-title {
  color: #000;
  display: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0 0 5px;
}

@media (min-width: 991px) {
  .cus-tet {
    padding: 80px 0;
  }
  .custom-gather-tet__title {
    margin: 0 0 80px;
  }
}

@media (max-width: 991px) {
  .cus-title {
    width: 158px !important;
    height: 30px !important;
  }
  .internet-shop .label-title {
    display: block;
  }
  .gather-tet__pack {
    padding: 0px 0;
  }
  .gather-tet__info {
    margin: 5px 0px 10px 0px !important;
  }
  .gather-tet__name {
    margin: 0px 0 3px;
  }
}

.internet-shop__select {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 991px) {
  .gather-tet__top-des {
    font-size: 11px;
  }
  .gather-tet__top-des b {
    font-size: 12px;
  }
  .modal-tet__btn-sub {
    font-size: 11px;
  }
  .internet-shop__select select {
    background-position-y: 50%;
    border-radius: 6px;
    padding: 9px 16px;
    width: 100%;
  }
}

.device-shop .shop-viettel__title {
  margin: unset;
}

.device-shop .nav-links {
  background-color: transparent;
  box-shadow: none;
  display: block;
  margin: 0;
  min-width: auto;
  padding: 0;
  position: static;
  right: auto;
  top: auto;
  width: auto;
}

.search-shop__name {
  margin: 12px 0px;
}

@media (max-width: 991px) {
  .search-shop__name {
    margin: 12px 0px 8px 0px;
  }
}

@media (max-width: 991px) {
  .post-shop__item {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
    height: auto;
    margin: 0;
    padding: 15px;
    width: 100%;
  }
  .sim-shop__info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .search-shop__action {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 0 15px;
    width: 100%;
  }
  .device-list__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .device-list__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .device-list__images img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.device-list__images {
  height: 140px;
}

.device-list__images img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.flc-banner__inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.list-endow__images {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.device-list__price {
  text-decoration-line: line-through;
}

.pack-endow__des {
  font-size: 16px;
}

@media (max-width: 991px) {
  .pack-endow__des {
    color: #000;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.pack-endow__thumbnail img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

/* Style css for input disabled*/

.form-vertical .form-control:disabled {
  background-color: #ececec;
  color: #576c8a;
}

.form-horizontal .form-control:disabled {
  background-color: #ececec;
  color: #576c8a;
}

/* .btn-blue {
    margin:unset !important;
} */

/*fix css viettel-shop*/

@media (max-width: 991px) {
  .shop-viettel__top.shop-viettel__top_new {
    -webkit-box-align: unset;
    -webkit-align-items: unset;
    -ms-flex-align: unset;
    align-items: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -webkit-flex-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset;
    margin-bottom: 15px;
  }
}

.shop-viettel__nav {
  position: relative;
  z-index: 10;
}

.shop-viettel__nav.opened .nav-links {
  display: block;
}

.shop-viettel__title {
  margin: 0 15px 0 0;
}

.banner-shop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -15px;
}

.banner-shop__left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 15px;
}

@media (max-width: 991px) {
  .banner-shop__left {
    display: none;
  }
}

.banner-shop__right {
  margin: 0 15px;
}

@media (max-width: 991px) {
  .banner-shop__right {
    display: none;
  }
}

.banner-shop__sp {
  display: none;
  width: 100%;
}

@media (max-width: 991px) {
  .banner-shop__sp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.banner-shop__sp img {
  width: 100%;
}

@media (max-width: 991px) {
  .banner-shop__sp img {
    height: 180px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.nav-sp {
  cursor: pointer;
  display: none;
}

@media (max-width: 991px) {
  .nav-sp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.nav-sp__name {
  color: #576c8a;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}

.nav-sp i {
  font-size: 8px;
  margin-left: 3px;
  position: relative;
  top: -2px;
}

@media (max-width: 991px) {
  .nav-links {
    overflow: unset;
    width: unset;
    background-color: #fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 5px;
    min-width: 160px;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 100%;
  }
}

@media (max-width: 991px) {
  .nav-links__item {
    padding: 8px 0;
  }
  .nav-links__item:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
    margin-right: 0;
  }
}

.sim-shop .shop-viettel__top {
  margin-bottom: unset;
}

.internet-shop .label-title {
  color: #000;
  display: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0 0 5px;
}

@media (max-width: 991px) {
  .internet-shop .label-title {
    display: block;
  }
}

.internet-shop__select {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 991px) {
  .internet-shop__select select {
    background-position-y: 50%;
    border-radius: 6px;
    padding: 9px 16px;
    width: 100%;
  }
}

.device-shop .shop-viettel__title {
  margin: unset;
}

.device-shop .nav-links {
  background-color: transparent;
  box-shadow: none;
  display: block;
  margin: 0;
  min-width: auto;
  padding: 0;
  position: static;
  right: auto;
  top: auto;
  width: auto;
}

.search-shop__name {
  margin: 12px 0px;
}

@media (max-width: 991px) {
  .search-shop__name {
    margin: 12px 0px 8px 0px;
  }
}

@media (max-width: 991px) {
  .records-deal__item {
    flex: unset;
    width: 100%;
    -webkit-flex: unset;
  }
  .records-deal__row {
    font-size: 14px;
  }
  .records-deal__info {
    width: 50%;
    float: left;
    flex: unset;
    -webkit-flex: unset;
    -ms-flex: unset;
  }
  .table-customize {
    display: block;
  }
  .records-mail {
    display: unset;
  }
  .records-mail__label {
    margin: 15px 5px 15px 5px;
  }
  .records-noti__item {
    width: 100%;
    float: left;
    display: flex;
  }
  .records-noti__item .records-noti__link {
    display: contents;
  }
  .records-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .records-deal__value {
    width: 50%;
    float: left;
  }
  .post-shop__item {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
    height: 95px;
    margin: 0;
    padding: 15px;
    width: 100%;
  }
  .sim-shop__info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .search-shop__action {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 0 15px;
    width: 100%;
  }
  .device-list__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .device-list__images img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.device-list__images {
  height: 140px;
}

.device-list__images img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.device-list__price {
  text-decoration-line: line-through;
}

/*update css viettel-shop 22/09/2020*/

.service-shop__link:hover .service-shop__images {
  box-shadow: 0 1px 6px #ccd8db;
  opacity: 0.8;
}

.service-shop__link:hover .service-shop__title {
  color: #ee0033;
}

/*.sim-shop .nav-links {*/

/*    background-color: transparent;*/

/*    box-shadow: none;*/

/*    display: block;*/

/*    margin: 0;*/

/*    min-width: auto;*/

/*    padding: 0;*/

/*    position: static;*/

/*    right: auto;*/

/*    top: auto;*/

/*    width: auto;*/

/*}*/

.table-sim .radio-custom1 {
  margin: 0;
}

.switches-network {
  bottom: 120px;
  position: fixed;
  right: 15px;
}

@media (max-width: 991px) {
  .switches-network {
    bottom: 100px;
    right: 10px;
  }
}

.device-shop__bg {
  margin-right: 60px;
}

@media (max-width: 991px) {
  .device-shop__bg img {
    margin: 10px 30px;
  }
  .device-shop__bg {
    margin-right: 20px;
  }
}

.lp-advantages__left {
  z-index: 2;
}

.lp-left-custom {
  height: 460px;
}

#delete-smartphone {
  background: #ff1d1dcc;
  border: none;
  text-decoration: none;
  width: 63px;
  padding: 3px 10px;
}

#delete-smartphone :hover {
  color: #ffffff;
}

#delete-smartphone:hover {
  background: #f90606;
}

.sp-link {
  color: #0602ec;
  text-decoration: underline;
}

#img-stead {
  max-width: 80%;
}

.stead-youtube {
  text-align: center;
}

.color-child {
  color: #281affcc;
}

.color-child:hover {
  color: #281affcc;
  text-decoration: underline;
  cursor: pointer;
}

.boxcolor a.active {
  background: #0c0033;
}

.boxcolor.smart-phone a {
  width: 29px;
  height: 30px;
  margin: 0 10px 0 0;
}

.boxcolor.smart-phone {
  margin-top: 5px;
}

.boxcolor.smart-phone a span {
  width: 25px;
  height: 25px;
}

div.search.top-search div.form-search .history-search .inner-search .box-search p.title {
  color: #bfc4ce;
  font-size: 14px;
  margin-bottom: 10px;
}

p.search-sp__subtitle {
  color: #bfc4ce;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
  word-break: break-word;
}

.hidden-icon-carousel .flex-direction-nav {
  display: none;
}

.cus-hotline-content {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  background-color: #ee0033;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0 5px;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  line-height: 25px;
  min-width: 130px;
  text-align: center;
}

.cus-hotline-content:hover .tooltip-text {
  visibility: visible !important;
}

.tool-tip-phone {
  font-size: 16px;
}

.cus-hotline-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.cus-hotline-phone {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

/* .custom-contact-now {
bottom: 185px!important;
} */

.contact-viettel {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.form_contact-viettel {
  width: 439px;
  margin-top: 100px;
}

.header-contact {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-bottom: 20px;
}

.contact-viettel button {
  width: 164px;
}

.col_mobile {
  display: flex;
}

.input_contact1 {
  width: 100%;
}

input_contact2 {
  width: 50% !important;
}

.label_contact {
  margin-bottom: 5px !important;
}

.contact-viettel select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  line-height: 20px;
  padding: 10px 15px !important;
  /* background: #fff url(/images/icon_select.svg) no-repeat; */
  background-position-x: calc(100% - 4px);
  background-position-y: 46%;
  font-weight: 500;
  color: #576c8a;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 700px) {
  .content.tabs a.viewall {
    bottom: -35px;
  }
  .header-contact {
    font-size: 21px;
  }
  .image_contact-viettel {
    display: none;
  }
  .contact-viettel {
    display: block;
    width: 100%;
  }
  .form_contact-viettel {
    width: 100%;
    margin-top: 28px;
  }
  .contact-viettel.full-field.field label {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px !important;
  }
  .col_mobile {
    padding: 0px 5px !important;
  }
  .label_contact {
    font-size: 14px !important;
    line-height: 17px;
  }
  .contact-viettel button {
    width: 100%;
  }
  .col_mobile input {
    font-size: 14px !important;
  }
  .col_mobile select {
    font-size: 14px !important;
  }
  .col_mobile textarea {
    font-size: 14px !important;
  }
}

@media (max-width: 330px) {
  .label_contact {
    font-size: 12px !important;
  }
}

input:focus {
  border-color: 1px solid #ee0033 !important;
}

.login-register > ul > li {
  padding: 15px 0 15px;
}

.voucher-tet.qua {
  background-color: #ee0033;
}

.qua-img {
  width: 30%;
  float: left;
}

.qua-content {
  width: 70%;
  float: left;
}

.qua-content h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
}

.qua-content h6:before {
  content: "";
  /* background: transparent url(../../images/i-time.svg) no-repeat; */
  background-size: contain;
  top: 2px;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}

.qua-content p {
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
}

.qua-content p span {
  font-weight: 600;
  color: #f4cf66;
}

.gather-tet__info {
  margin: 10px 0px 10px 0px;
}

.trung-vang {
  color: #fff333;
}

@media (max-width: 991px) {
  .points-vt__item {
    padding: 10px 5px;
  }
  .points-vt__link {
    font-size: 12px;
  }
  .redeem-points__btn {
    margin: 15px 0 30px;
  }
  .custom-ul-data-tet .gather-tet__top {
    width: 75%;
  }
  .custom-ul-data-tet ul {
    width: 75%;
  }
  .voucher-tet.qua .voucher-tet__title img {
    height: 30px;
  }
  .custom-modal--tet.popup-qua-tet {
    height: 90%;
    overflow-y: scroll;
    position: relative;
    padding: 0;
    z-index: unset !important;
  }
  .custom-modal--tet.popup-qua-tet .custom-modal-tet__top {
    position: fixed;
    z-index: 1;
    width: 100%;
  }
  .custom-modal--tet.popup-qua-tet .modal-tet__pack {
    padding-top: 50px;
  }
  .custom-modal--tet.popup-qua-tet .modal-tet__footer {
    width: 100%;
    position: fixed;
    bottom: 30px;
    z-index: 1;
  }
  .modal-tet__footer {
    margin: 30px -15px 0;
  }
  .modal--tet.popup-qua-tet .modal__body {
    padding: 0 0 35px;
  }
  .voucher-tet.qua {
    background-color: #8b011a;
  }
  .qua-content h6 {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  .qua-content p {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  .qua-content h6:before {
    content: "";
    /* background: transparent url(../../images/i-time.svg) no-repeat; */
    background-size: contain;
    top: 2px;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
  }
  .content-trung-vang h4 {
    font-size: 15px;
    color: #ee0033;
  }
  .content-trung-vang p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .content-trung-vang strong {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .info-trung-vang {
    margin: 0 0 5px 0 !important;
  }
  .phone-device {
    margin: 50px 0;
  }
  .internet-tv {
    padding: 30px 0;
  }
  .apply-number {
    padding: 30px 0;
  }
}

.contract-form .radio-custom1 input:checked ~ .txt-radio {
  color: #000;
  font-weight: normal;
}

.tbl-sim td.sttCustom {
  width: 10%;
}

.tbl-sim td.nameCustom,
.tbl-sim td.priceCustom {
  width: 25%;
}

.tbl-sim td.timeCustom {
  width: 20%;
}

.custom-contact-now {
  bottom: 15px;
  z-index: 100;
}

#custom_use_fee_top {
  display: -webkit-box !important;
}

#custom_check_fee_sub {
  display: inline-block;
  margin-right: 0px !important;
}

.custom-open {
  background-color: #fff;
  bottom: 0;
  display: block;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 85%;
  z-index: 999;
}

.cus_tab_detail:before {
  background: none;
}

.custom-price {
  text-transform: none !important;
}

.cuss_tab_detail:before {
  background: none !important;
  width: 0px !important;
  margin-right: 0px !important;
}

.cuss-input-content {
  padding: 15px 0px !important;
}

.cus-form-group--otp {
  text-align: center !important;
}

.login-register > ul > li {
  padding: 15px 0 15px;
}

.cuss-use-fee__des {
  display: block;
}

.custom-amount {
  font-size: 12px;
}

.cuss-row-border:after {
  display: none !important;
  left: 0 !important;
}

.login-register > ul > li {
  padding: 15px 0 15px;
}

.voucher-tet.qua {
  background-color: #ee0033;
}

.qua-img {
  width: 30%;
  float: left;
}

.qua-content {
  width: 70%;
  float: left;
}

.qua-content h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
}

.qua-content h6:before {
  content: "";
  /* background: transparent url(../../images/i-time.svg) no-repeat; */
  background-size: contain;
  top: 2px;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
}

.qua-content p {
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
}

.qua-content p span {
  font-weight: 600;
  color: #f4cf66;
}

.gather-tet__info {
  margin: 10px 0px 10px 0px;
}

@media (max-width: 991px) {
  .modal-tet__content.cuss-modal-tet__content {
    max-height: 350px;
    overflow-y: scroll;
  }
  .modal-tet__footer {
    margin: 30px -15px 0;
  }
  .voucher-tet.qua {
    background-color: #8b011a;
  }
  .qua-content h6 {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  .qua-content p {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  .qua-content h6:before {
    content: "";
    /* background: transparent url(../../images/i-time.svg) no-repeat; */
    background-size: contain;
    top: 2px;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
  }
  .content-trung-vang h4 {
    font-size: 15px;
    color: #ee0033;
  }
  .content-trung-vang p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .content-trung-vang strong {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.tbl-sim td.sttCustom {
  width: 10%;
}

.tbl-sim td.nameCustom,
.tbl-sim td.priceCustom {
  width: 25%;
}

.tbl-sim td.timeCustom {
  width: 20%;
}

.custom-contact-now {
  bottom: 15px;
  z-index: 100;
}

#custom_use_fee_top {
  display: -webkit-box !important;
}

#custom_check_fee_sub {
  display: inline-block;
  margin-right: 0px !important;
}

.cus_tab_detail:before {
  background: none;
}

.custom-price {
  text-transform: none !important;
}

.cuss_tab_detail:before {
  background: none !important;
  width: 0px !important;
  margin-right: 0px !important;
}

.cuss-input-content {
  padding: 15px 0px !important;
}

.cus-form-group--otp {
  text-align: center !important;
}

.cuss-use-fee__des {
  display: block;
}

.custom-amount {
  font-size: 12px;
}

.cuss-row-border:after {
  display: none !important;
  left: 0 !important;
}

.custom-input-date {
  border-radius: 10px !important;
  padding: 0px 20px 0px 45px !important;
  background-color: white;
}

.custom-input-date input {
  border: none;
}

@media (min-width: 991px) {
  .custom-choose-place__col {
    flex: 0 0 calc(100% - 30px) !important;
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 991px) {
  .custom-calendar {
    left: -100px !important;
  }
  .cus-tickets-info__times {
    display: none !important;
  }
}

.radio-customize-flight {
  width: 100% !important;
}

.custom-radio-flight {
  margin: 0 0 0;
}

.custom-date input {
  border-radius: 6px !important;
  border: 1px solid #e7e7e7 !important;
  height: 40px !important;
}

.custom-date {
  padding: 0 !important;
}

.custom-ticket-date {
  z-index: 10;
}

.cus-steps-bar__item {
  width: calc(100% / 2);
}

.cus-tickets-info__times {
  font-size: 14px;
}

.cus-choose-place__item:hover {
  cursor: pointer;
}

.choose-packs .cus-radio-customize__sub {
  font-size: 11px !important;
}

.cus-passenger-info__choose {
  width: 100% !important;
  margin: 0 !important;
}

/* Chuong tr�nh woman day */

h2.give-package__title {
  /* position: absolute; */
  /* width: 206px; */
  height: 36px;
  left: 617px;
  top: 439px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #0c0033;
}

.contract-form .radio-custom1 input:checked ~ .txt-radio {
  color: #000;
  font-weight: normal;
}

.give-package__sub {
  max-width: 1076px;
  min-height: 75px;
  margin: 45px 0px;
}

p.give-package__note {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

strong.give-package__point {
  font-weight: 700;
}

strong.give-package__name {
  font-weight: 700;
  color: #ee0033;
}

input#date-range {
  width: 340px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}

label.label-title__phone {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0c0033;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 13px 0px;
}

.contract-form .contract-online .radio-customize {
  margin-bottom: 15px !important;
  width: 100% !important;
  float: none;
}

.contract-form label {
  margin: 0 !important;
}

.modal-pay__info label {
  font-weight: initial;
  margin: 0;
}

input.input-date-time:read-only {
  color: #576c8a;
}

.form-2column .infor-customer__btn {
  margin: 13px 0 0;
  display: contents;
}

.give-package__form .form-2column {
  margin: 13px 0 0;
}

.give-package__btn a {
  margin-left: 25px;
  width: 250px;
  height: 52px;
  border: 1px solid #e7e7e7;
  background: #ee0033;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-family: "Montserrat";
}

.give-package__history .package-history__title {
  margin-top: 46px;
  font-weight: 700;
  font-family: "Roboto";
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

table.package-history__table {
  left: 0%;
  right: 4.4%;
  top: 8.74%;
  bottom: 1.28%;
  background: #f9f9f9;
  border: 1px solid #ebedf1;
  box-sizing: border-box;
  border-radius: 6px;
  min-width: 1123px;
  border-spacing: 0;
  overflow: auto;
  width: 100%;
}

table.package-history__table thead tr td {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #44494d;
  padding-left: 25px;
}

table.package-history__table td {
  border-bottom: 1px solid #ebedf1;
}

table.package-history__table thead tr {
  height: 41px;
}

.package-history__list {
  margin-top: 20px;
  overflow-x: auto;
  margin-bottom: 99px;
}

table.package-history__table tbody {
  background: #ffffff;
  border: 1px solid #ebedf1;
  box-sizing: border-box;
  border-radius: 6px;
}

table.package-history__table tbody tr {
  height: 58px;
}

table.package-history__table tbody tr td {
  padding-left: 25px;
}

@media only screen and (max-width: 650px) {
  .give-package__input {
    width: 100%;
  }
  input#date-range {
    width: 100%;
  }
  .give-package__btn a {
    margin: 13px 0 0 0;
    width: 100%;
  }
  .give-package__btn {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  h2.give-package__title {
    margin-top: 20px;
  }
  .give-package__sub {
    margin: 25px 0px;
  }
}

.rs__gift-history {
  text-align: center;
}

.modal__8 {
  max-width: 472px;
  padding: 15px;
}

.modal__8 .modal__body {
  padding: 0 10px 15px;
}

.modal__8 .modal__content {
  padding: 0;
  box-shadow: none;
}

.modal__8 .icon-i-success {
  color: #26b2b8;
  font-weight: lighter;
}

.custom-input-date {
  border-radius: 10px !important;
  padding: 0px 20px 0px 45px !important;
  background-color: white;
}

.custom-input-date input {
  border: none;
}

@media (min-width: 991px) {
  .custom-choose-place__col {
    flex: 0 0 calc(100% - 30px) !important;
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 991px) {
  .custom-input-date {
    padding: 0px 5px 0px 30px !important;
  }
  .tickets-air--buy .tickets-date__icon {
    left: 10px;
  }
  .tickets-air--buy .custom-input-date input {
    padding: 15px 5px;
    font-size: 12px;
  }
  .choose-date__item:hover {
    background-color: unset !important;
    color: unset !important;
  }
  .choose-flight .choose-flight__btn {
    position: fixed;
    bottom: 15px;
    width: 92%;
  }
  .custom-calendar {
    left: -100px !important;
  }
  .modal__content {
    max-width: 90%;
    width: 90%;
  }
}

.contract-form .contract-online .radio-customize {
  margin-bottom: 15px !important;
  width: 100% !important;
  float: none;
}

.radio-customize-flight {
  width: 100% !important;
}

.custom-radio-flight {
  margin: 0 0 0;
}

.custom-date input {
  border-radius: 6px !important;
  border: 1px solid #e7e7e7 !important;
  height: 40px !important;
}

.custom-date {
  padding: 0 !important;
}

.custom-ticket-date {
  z-index: 10;
}

.cus-steps-bar__item {
  width: calc(100% / 2);
}

.cus-tickets-info__times {
  font-size: 14px;
}

.cus-choose-place__item:hover {
  cursor: pointer;
}

.choose-packs .cus-radio-customize__sub {
  font-size: 11px !important;
}

.cus-passenger-info__choose {
  width: 100% !important;
  margin: 0 !important;
}

.contract-form label {
  margin: 0 !important;
}

/* tra cuu the cao */

.table-tktc .box-table {
  padding: 10px 15px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

.table-tktc .box-table .tkdh-result {
  border: none;
  width: 100%;
  text-align: center;
  border-radius: 10px;
}

.table-tktc .box-table .tkdh-result tr th {
  padding: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ee0033;
}

.table-tktc .box-table .tkdh-result tr td {
  padding: 10px;
}

.table-tktc .txt-left {
  text-align: left;
  color: #576c8a;
}

.contract-form label {
  margin: 0 !important;
}

.modal-pay__info label {
  font-weight: initial;
  margin: 0;
}

input.input-date-time:read-only {
  color: #576c8a;
}

.table-tktc .txt-right {
  text-align: right;
  font-weight: bold;
}

.table-tktc .status-scratch-card {
  color: #ee0033;
}

.mb-table {
  margin: 75px 0 50px 0;
}

@media only screen and (max-width: 46.1875em) {
  .table-tktc .box-table {
    padding: 10px 0;
    box-shadow: none;
    border-radius: 0;
  }
  .mb-table {
    margin: 24px 0 20px 0;
  }
  .table-tktc .box-table .tkdh-result tr td {
    padding: 10px 0;
  }
}

/* tra cuu the cao */

@media (max-width: 767px) {
  .shopping-cart__icon i {
    color: #000000 !important;
    font-size: 20px;
  }
}

.error-404__info {
  max-width: 430px;
}

.error-404__des-custom:not(:last-child) {
  padding: 0;
}

.btn.btn-disabled {
  background: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .icon-flash-custom {
    font-size: 18px;
    margin: 0 -4px;
  }
}

.icon-flash-custom {
  font-size: 27px;
  margin: 0 -4px;
}

/* Custom Beelite.vue */

.errorMoblie {
  display: none;
}

.lp-banner__title-custom {
  margin: unset !important;
}

/*.footer-pc{*/

/*    padding: 25px 0px 20px;*/

/*}*/

@media (max-width: 991px) {
  .errorPC {
    display: none;
  }
  .errorMoblie {
    display: block;
  }
}

.fix-banner-images-beelite {
  height: auto !important;
  margin-top: 19px !important;
}

.lp-data__left-custom {
}

/* Custom GccSearch.vue */

.fix-text {
  font-size: 26px !important;
}

/* Custom InternetSearch.vue */

.fix-height {
  display: -webkit-box;
  height: 202px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1px !important;
}

.fix-text-title {
  font-size: 22px !important;
}

/* .owl-carousel .owl-stage-outer {
padding: 0 0 36px !important;
} */

/* Custom DvqtSearch.vue */

.fix-text-dvqt {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  min-height: 97px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom DataSearch.vue */

.title-data-fix {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  min-height: 48px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom SearchKeyWordNew.vue */

.custom-focus:focus {
  border-color: #58b84f !important;
}

/* Custom Beelite.vue */

.errorMoblie {
  display: none;
}

.lp-banner__title-custom {
  margin: unset !important;
}

/*.footer-pc{*/

/*    padding: 25px 0px 20px;*/

/*}*/

@media (max-width: 991px) {
  .errorPC {
    display: none;
  }
  .errorMoblie {
    display: block;
  }
}

.fix-banner-images-beelite {
  height: auto !important;
}

/* Custom GccSearch.vue */

.fix-text {
  font-size: 26px !important;
}

/* Custom InternetSearch.vue */

.fix-height {
  display: -webkit-box;
  height: 202px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1px !important;
}

.fix-text-title {
  font-size: 22px !important;
}

/* .owl-carousel .owl-stage-outer {
padding: 0 0 36px !important;
} */

/* Custom DvqtSearch.vue */

.fix-text-dvqt {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  min-height: 97px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom DataSearch.vue */

.title-data-fix {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  min-height: 48px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Custom SearchKeyWordNew.vue */

.custom-focus:focus {
  border-color: #58b84f !important;
}

/* chuyen mang giu so */

.process-switch__package-item {
  float: left;
  width: 100%;
}

.wrapper-content-cmgs {
  margin-bottom: 0;
  padding-bottom: 60px;
}

.switch-condition__banner-mobile {
  display: none;
  width: 100%;
  float: left;
  background: linear-gradient(153.54deg, #ef0134 7.09%, #d7002e 62.85%);
  padding: 15px;
}

@media (max-width: 1080px) {
  .switch-condition__banner-mobile {
    display: block;
  }
  .switch-condition__banner {
    display: none;
  }
}

@media (min-width: 1140px) {
  .net-switch__place-text--nowrap-1140 {
    white-space: nowrap;
  }
}

@media (min-width: 1182px) {
  .net-switch__steps-list--fix-padding-item .net-switch__step-description {
    padding: 0 27px;
  }
}

/* end chuyen mang giu so */

/* hcthangdev tối ứu seo home t6 */

.menu-sub__title.change-tag-t6 {
  font-weight: bold;
}

.modal__title--change-tag-t6 {
  text-align: center;
  font-size: 24px;
  font-family: "Montserrat";
  color: #0c0033;
  font-style: normal;
  font-weight: bold;
  line-height: 37px;
  letter-spacing: 0.2px;
}

.internet-tele__name--change-tag-t6 {
  font-weight: 600;
}

.switch-condition__banner-mobile {
  display: none;
  width: 100%;
  float: left;
  background: linear-gradient(153.54deg, #ef0134 7.09%, #d7002e 62.85%);
  padding: 15px;
}

@media (max-width: 1080px) {
  .switch-condition__banner-mobile {
    display: block;
  }
  .switch-condition__banner {
    display: none;
  }
}

@media (min-width: 1140px) {
  .net-switch__place-text--nowrap-1140 {
    white-space: nowrap;
  }
}

@media (min-width: 1182px) {
  .net-switch__steps-list--fix-padding-item .net-switch__step-description {
    padding: 0 27px;
  }
}

/* end chuyen mang giu so */

.switch-condition__banner-mobile {
  display: none;
  width: 100%;
  float: left;
  background: linear-gradient(153.54deg, #ef0134 7.09%, #d7002e 62.85%);
  padding: 15px;
}

@media (max-width: 1080px) {
  .switch-condition__banner-mobile {
    display: block;
  }
  .switch-condition__banner {
    display: none;
  }
}

/* end chuyen mang giu so */

/* tra cuu don hang */

.wrap-vdp-datepicker-h40 .vdp-datepicker input {
  padding: 9px 15px;
  color: #000 !important;
}

.wrap-vdp-datepicker-h40 .vdp-datepicker input::placeholder {
  color: #000;
}

.modal-fix-by-fm .modal__header {
  padding: 4px 0;
}

.modal-fix-by-fm .modal__body .modal-order__title {
  display: none;
}

.modal-fix-by-fm .modal__body .modal-order__des {
  margin: 0;
}

/* end tra cuu don hang */

/* lich su nap the */

.recharge-history__item--custom-with-old {
  position: relative;
  margin: 45px 15px 20px;
}

.recharge-history__item--custom-with-old .recharge-history__top {
  position: absolute;
  left: 0;
  bottom: 100%;
}

/* end lich su nap the */

/* thanh toan - nap tien bang tk ngan hang */

.payment-online--bill .payment-online__form.payment-online__form--2column {
  max-width: 780px;
}

@media (min-width: 575px) {
  .payment-online--is-static-account .payment-online__form.payment-online__form--2column {
    margin: 40px -15px 20px;
  }
}

.payment-online--is-mobile-account .payment-online__form.payment-online__form--2column {
  margin: 40px auto 20px;
}

.recent-trade__btn .button {
  width: 300px;
}

/* end thanh toan - nap tien bang tk ngan hang */

/* tra cuu bao hanh */

@media (min-width: 575px) {
  .lookup-component__sub--2lines {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    height: 56px;
  }
}

/* end tra cuu bao hanh */

.tab-title li a.change-tag-t6 {
  font-weight: normal;
}

/* end tối ưu seo home t6 */

.vacine-covid__link {
  padding: 10px 5px;
}

.row-menu .boxmenu ul li a {
  font-size: 14px;
  padding: 5px 10px;
}

.fontweight-normal {
  font-weight: normal;
}

.pack-master .txt-radio h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0c0033;
}

/* Xac thuc hop dong */

.electronic-records {
  padding: 25px 0 35px;
  word-break: break-word;
}

.electronic-records__top {
  margin: 0 0 25px;
  text-align: center;
}

.electronic-records__title {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 5px;
  text-align: center;
  text-transform: uppercase;
}

.electronic-records__sub {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 24px;
  margin: 0 0 15px;
}

.electronic-records__sub-name {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.electronic-records__sub-icon {
  color: #576c8a;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 20px;
}

.electronic-records__des {
  color: #576c8a;
  font-size: 14px;
  line-height: 18px;
}

.electronic-records__content {
  margin: 25px 0 35px;
}

.electronic-records__checkbox {
  margin: 20px 0 0;
}

.electronic-records__checkbox .checkbox__txt {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}

.electronic-records__list {
  margin: 31px 14px;
}

.electronic-records__item {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ee0033;
  border-radius: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px 15px;
  text-align: center;
}

.electronic-records__item:not(:last-child) {
  margin: 0 0 18px;
}

.electronic-records__item .upload-file__before {
  border: 0;
}

.electronic-records__txt {
  color: #ee0033;
  font-size: 14px;
  line-height: 20px;
}

.electronic-records__agree {
  margin: 25px 0;
}

.electronic-records__images {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 40px 0 30px;
}

.electronic-records__note {
  color: #576c8a;
  font-size: 14px;
  line-height: 21px;
  margin: 30px 15px 50px;
  text-align: center;
}

.electronic-records__order {
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
  margin: 0 0 30px;
  border: 1px solid #dedede;
  border-radius: 8px 8px 8px 0px;
}

.electronic-records .info-confirm {
  margin: 25px 0 0;
}

.electronic-records__signature {
  margin: 0 auto;
  text-align: center;
}

.electronic-records__button {
  margin: 24px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.electronic-records__button .btn {
  border-radius: 6px;
  border-width: 1px;
  padding: 12px 20px;
  width: 100%;
  border-radius: 8px 8px 8px 0px;
}

.electronic-records__button .btn:not(:last-child) {
  margin: 0 0 12px;
}

.electronic-records .form-input__icon {
  background-color: transparent;
  height: auto;
}

.electronic-records .error-text {
  font-size: 14px;
}

.electronic-records--signature {
  margin: 0;
}

.electronic-records--signature .electronic-records__content {
  margin: 50px;
}

.electronic-records-order__name {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  margin: 0 0 8px;
  font-family: "SF Pro Text", sans-serif;
}

.electronic-records-order__txt {
  color: #576c8a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
}

.electronic-records-order_status {
  color: #f0974c;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  font-family: "SF Pro Text", sans-serif;
}

.electronic-records-order__time {
  color: #000;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: "SF Pro Text", sans-serif;
}

.electronic-records_des {
  color: #576c8a !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.otp-countdown-btn {
  display: block;
  color: #979797;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}

.modal__btn.electronic-records_otp .btn {
  width: 100%;
}

.upload-file.upload-file--signature.icon--edit {
  justify-content: flex-end;
}

.electronic-records_img {
  margin-top: 16px;
}

.electronic-records__sub-icon .icon-edit:before {
  color: #ee0033;
}

span.electronic-service__txt {
  color: #576c8a;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
}

.electonic-service__title {
  margin-bottom: 11px;
}

li.electronic-records__item.signature {
  margin-top: 33px;
}

.sign-certification__images.signature {
  margin: 25px 20px 40px;
}

.form-group--otp.electronic-otp input {
  width: 42px;
  height: 42px;
  background: #fff;
}

@media (max-width: 767px) {
  .form-group--otp.electronic-otp input + input {
    margin-left: 12px;
  }
}

.form-group--otp.electronic-otp .wrap {
  margin-bottom: 30px;
}

.form-group--otp.electronic-otp p {
  margin-top: -20px;
}

.electronic-records__content.electronic-otp {
  text-align: center;
}

.electronic-records__top.signature {
  margin-bottom: 80px;
  padding: 0 15px;
}

.electronic-records__header {
  height: 89px;
  background: rgba(3, 166, 120, 0.1);
  border-radius: 8px 8px 8px 0px;
}

.electronic-records__title.title-success {
  padding: 5px 0 10px 0;
  color: #03a678;
  text-transform: none;
  font-weight: 700;
  font-family: "PF BeauSans Pro", sans-serif;
}

.electronic-records__des.title-des {
  padding: 0 14px 10px 14px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-family: "SF Pro Text", sans-serif;
  color: #44494d;
}

.electronic-records__slogan {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 20px;
}

.electronic-records__title.slogan {
  font-family: "PF BeauSans Pro", sans-serif;
  color: #ee0033;
  font-size: 18px;
  line-height: 22px;
}

.electronic-records__items {
  margin: 0 10px 8px 10px;
  width: calc(100% / 2 - 30px);
}

.electronic-records_before {
  min-height: 203px;
  background: #ffffff;
  height: 100%;
}

.electronic-records__block {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -30px 0 -10px;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.electronic-records__inner {
  height: 65px;
  width: 65px;
  margin: auto;
  padding-top: 15px;
}

.electronic-records-items__title {
  padding: 15px 0;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #44494d;
  font-family: "PF BeauSans Pro", sans-serif;
}

.electronic-records-items__title.block {
  margin: 0 2px;
}

p.electronic-contract__txt {
  font-weight: 400;
  font-size: 14px;
  font-family: "SF Pro Text", sans-serif;
  text-align: center;
  margin-top: 25px;
}

p.electronic-contract__txt a {
  color: #ee0033;
}

input.form-control.phonenumber {
  border-radius: 8px 8px 8px 0;
}

.electronic-records__form .form-vertical {
  margin-bottom: 59px;
}

.btn.btn-blue.btn--full {
  font-family: "PF BeauSans Pro", sans-serif !important;
  font-weight: 700;
}

/* hcthangdev tối ứu seo home t6 */

.menu-sub__title.change-tag-t6 {
  font-weight: bold;
}

.modal__title--change-tag-t6 {
  text-align: center;
  font-size: 24px;
  font-family: "Montserrat";
  color: #0c0033;
  font-style: normal;
  font-weight: bold;
  line-height: 37px;
  letter-spacing: 0.2px;
}

.internet-tele__name--change-tag-t6 {
  font-weight: 600;
}

.news-event .news-event__sub--change-tag-t6 {
  font-size: 12px;
  color: #ee0033;
  text-transform: uppercase;
}

.tab-title li a.change-tag-t6 {
  font-weight: normal;
}

/* end tối ưu seo home t6 */

/* bamboo */

@media (min-width: 576px) {
  .modal--flight.modal--flight-bamboo .modal__content {
    max-width: 80%;
  }
}

/* bamboo */

/*toan trinh co dinh*/

.delete-text {
  position: absolute;
  right: 12px;
  top: 8px;
  color: #576c8a;
  font-size: 22px;
  cursor: pointer;
}

/*toan trinh co dinh*/

@media (max-width: 991px) {
  .modal__content {
    max-width: 100% !important;
    width: 100% !important;
  }
}

/*tra cuoc*/

.history__using__item.item__pack {
  padding: 0 10px 0 0 !important;
}

.history__using__item {
  padding: 0 10px 0 0 !important;
}

/*xac thuc bien ban nghiem thu*/

.acceptance-electronic-records {
  padding: 25px 0 35px;
  word-break: break-word;
  background: #fbfbfb;
}

.acceptance-electronic-records__top {
  margin: 0 0 25px;
  text-align: center;
}

.acceptance-electronic-records__title {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 5px;
  text-align: center;
  text-transform: uppercase;
}

.acceptance-electronic-records__sub {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 24px;
  margin: 0 0 15px;
}

.acceptance-electronic-records__sub-name {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.acceptance-electronic-records__sub-icon {
  color: #576c8a;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 20px;
}

.acceptance-electronic-records__des {
  color: #576c8a;
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px;
}

.acceptance-electronic-records__content {
  margin: 25px 0 35px;
}

.acceptance-electronic-records__checkbox {
  margin: 20px 0 0;
}

.acceptance-electronic-records__checkbox .checkbox__txt {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}

.acceptance-electronic-records__list {
  margin: 31px 29px;
}

.acceptance-electronic-records__item {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ee0033;
  border-radius: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px 15px;
  text-align: center;
}

.acceptance-electronic-records__item:not(:last-child) {
  margin: 0 0 18px;
}

.acceptance-electronic-records__item .upload-file__before {
  border: 0;
}

.acceptance-electronic-records__txt {
  color: #ee0033;
  font-size: 14px;
  line-height: 20px;
}

.acceptance-electronic-records__agree {
  margin: 25px 0;
}

.acceptance-electronic-records__images {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 40px 0 30px;
}

.acceptance-electronic-records__note {
  color: #576c8a;
  font-size: 14px;
  line-height: 21px;
  margin: 30px 15px 50px;
  text-align: center;
}

.acceptance-electronic-records__order {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #d3d0d0;
  margin: 0 0 30px;
  padding: 15px 20px;
}

.acceptance-electronic-records .info-confirm {
  margin: 25px 0 0;
}

.acceptance-electronic-records__signature {
  margin: 0 auto;
  text-align: center;
}

.acceptance-electronic-records__button {
  margin: 24px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.acceptance-electronic-records__button .btn {
  border-radius: 6px;
  border-width: 1px;
  padding: 12px 20px;
  width: 100%;
  border-radius: 8px 8px 8px 0px;
}

.acceptance-electronic-records__button .btn:not(:last-child) {
  margin: 0 0 12px;
}

.acceptance-electronic-records .form-input__icon {
  background-color: transparent;
  height: auto;
}

.acceptance-electronic-records .error-text {
  font-size: 14px;
}

.acceptance-electronic-records--signature {
  margin: 0;
}

.acceptance-electronic-records--signature .electronic-records__content {
  margin: 50px;
}

.acceptance-electronic-records-order__name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin: 0 0 10px;
}

.acceptance-electronic-records-order__txt {
  color: #576c8a;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
}

.acceptance-electronic-records-order_status {
  color: #f0974c;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
}

.acceptance-electronic-records-order__time {
  color: #000;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.acceptance-electronic-records_des {
  color: #576c8a !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.otp-countdown-btn {
  display: block;
  color: #979797;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}

.modal__btn.electronic-records_otp .btn {
  width: 100%;
}

.upload-file.upload-file--signature.icon--edit {
  justify-content: flex-end;
}

.acceptance-electronic-records_img {
  margin-top: 16px;
}

.acceptance-electronic-records__sub-icon .icon-edit:before {
  color: #ee0033;
}

span.acceptance-electronic-service__txt {
  color: #576c8a;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
}

.acceptance-electonic-service__title {
  margin-bottom: 11px;
}

li.acceptance-electronic-records__item.signature {
  margin-top: 33px;
}

.sign-certification__images.signature {
  margin: 25px 20px 40px;
}

/* .form-group--otp.electronic-otp input {
    width: 48px;
    height: 48px;
    background: #fff;
} */

@media (max-width: 767px) {
  .form-group--otp.electronic-otp input + input {
    margin-left: 16px;
  }
}

.form-group--otp.electronic-otp .wrap {
  margin-bottom: 35px;
}

.form-group--otp.electronic-otp p {
  margin-top: -20px;
}

.acceptance-electronic-records__content.electronic-otp {
  text-align: center;
}

.acceptance-electronic-records__top.signature {
  margin-bottom: 80px;
}

.acceptance-electronic-records__header {
  height: 89px;
  background: rgba(3, 166, 120, 0.1);
  border-radius: 8px 8px 8px 0px;
}

.acceptance-electronic-records__title.title-success {
  padding: 5px 0 10px 0;
  color: #03a678;
  text-transform: none;
  font-weight: 700;
  font-family: "PF BeauSans Pro", sans-serif;
}

.acceptance-electronic-records__des.title-des {
  padding: 0 14px 10px 14px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-family: "SF Pro Text", sans-serif;
  color: #44494d;
}

.acceptance-electronic-records__slogan {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 20px;
}

.acceptance-electronic-records__title.slogan {
  font-family: "PF BeauSans Pro", sans-serif;
  color: #ee0033;
  font-size: 18px;
  line-height: 22px;
}

.acceptance-electronic-records__items {
  margin: 0 10px 8px 10px;
  width: calc(100% / 2 - 30px);
}

.acceptance-electronic-records_before {
  min-height: 203px;
  background: #ffffff;
  height: 100%;
}

.acceptance-electronic-records__block {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -30px 0 -10px;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.acceptance-electronic-records__inner {
  height: 65px;
  width: 65px;
  margin: auto;
  padding-top: 15px;
}

.acceptance-electronic-records-items__title {
  padding: 15px 0;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #44494d;
  font-family: "PF BeauSans Pro", sans-serif;
}

.acceptance-electronic-records-items__title.block {
  margin: 0 2px;
}

p.acceptance-electronic-contract__txt {
  font-weight: 400;
  font-size: 14px;
  font-family: "SF Pro Text", sans-serif;
  text-align: center;
  margin-top: 25px;
}

p.acceptance-electronic-contract__txt a {
  color: #ee0033;
}

input.form-control.phonenumber {
  border-radius: 8px 8px 8px 0;
}

.acceptance-electronic-records__form .form-vertical {
  margin-bottom: 59px;
}

@media screen (max-width: 1378px) {
  .row-menu .boxmenu ul li a {
    font-size: 14px;
  }
}

@media screen (max-width: 1150px) {
  .row-menu .boxmenu ul li a {
    font-size: 10px;
  }
}

/*xac thuc bien ban nghiem thu*/
