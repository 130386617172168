.table-product,
.table-phone {
  margin: auto;
  margin-top: 30px;
  padding: 0 10px;
  margin-bottom: 5rem;
  background-color: #fff;
  border-radius: 20px;
}

.table-title {
  padding: 20px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-title button {
  border-radius: 5px;
}

.buttonExport {
  display: flex;
  justify-content: flex-end;
  width: 73%;
}
