.wrapperPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("https://bcp.cdnchinhphu.vn/Uploaded/nguyendieuhuong/2021_01_11/%E1%BA%A3nh%20Minh%20Thi%20Viettel.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.textHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formLogin {
  padding: 35px !important;
  background-color: #fff;
  border-radius: 20px;
  width: 35vw;
  box-shadow: 10px 10px 10px #333 !important;
}

.loginFormButton {
  width: 100%;
  height: 50px !important;
}

.ant-input {
  height: 35px;
}
