/* css search keyword web mobile */

.search-sp {
    position: relative;
}

.search-sp__result {
    background-color: #fff;
    border-radius: 6px 0 6px 6px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    display: none;
    min-width: 356px;
    position: absolute;
    right: 3px;
    text-align: left;
    top: 37px;
    z-index: 99;
}

.search-sp__result:before {
    content: '';
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    height: 0;
    position: absolute;
    right: 0;
    top: -15px;
    width: 0;
}

.search-sp__box {
    padding: 16px;
}

.search-sp__box:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
}

.search-sp__input input {
    border: 0;
    border-radius: 0;
    color: #576C8A;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
}

.search-sp__input input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.search-sp__input input::-moz-placeholder {
    color: #bfc4ce;
}

.search-sp__input input:-ms-input-placeholder {
    color: #bfc4ce;
}

.search-sp__input input::placeholder {
    color: #bfc4ce;
}

.search-sp__subtitle {
    color: #bfc4ce;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 5px;
    word-break: break-word;
}

.search-sp__button .btn {
    background-color: #fff;
    border: 1px solid #ff0000;
    color: #ff0000;
    width: 100%;
}

.search-sp__button .btn:hover {
    background-color: #ff0000;
    color: #fff;
}

.search-sp.opened .search-sp__result {
    display: block;
}

.tag-search {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tag-search__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.tag-search__link {
    background-color: #fff;
    border: 1px solid #EE0033;
    border-radius: 10px;
    color: #EE0033;
    font-family: "Montserrat";
    font-size: 14px;
    margin: 5px;
    padding: 4px 18px;
}

.tag-search__link:hover {
    background-color: #EE0033;
    color: #fff;
}

.history-search__link {
    color: #576c8a;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0;
}

#button_search_mobile {
    position: absolute;
    top: 24px;
    left: 320px;
    background-color: transparent;
    border: 0;
}