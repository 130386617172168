.wrapperIntro {
  margin-top: 150px;
  padding: 20px 25%;
  font-size: 20px;
}

.wrapperIntro a {
  color: blue;
}

.wrapperImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleImg {
  margin-top: 10px;
}

@media screen and (max-width: 1100px) {
  .wrapperIntro {
    margin-top: 70px;
    font-size: 18px;
    padding: 0 10px;
  }
}

@media screen and (max-width: 1360px) {
  .wrapperIntro {
    margin-top: 150px;
    font-size: 18px;
    padding: 0 15%;
  }
}
