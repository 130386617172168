.wrapperQuestion {
  margin-top: 150px;
  padding: 20px 25%;
}

.wrapperContentClient {
  display: flex;
  min-height: 500px;
  justify-content: center;
}

.wrapperContentCustom {
  min-height: 500px;
}
