.sider-wrapper div {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-top: 20px;
}

.sider-link,
.sider-link-active {
  text-decoration: none;
  font-weight: 600;
  padding: 16px 16px;
  margin: 20px 0;
  border-radius: 20px;
  background: #ed1a36;
  color: #fff;
  transition: 0.5s;
}

.sider-link {
  background: transparent;
  color: #000;
}

.sider-link:hover {
  color: red;
  transition: 0.3s;
}

.sider-link-active:hover {
  color: #fff;
}
