/*=========================*/

/*=========================*/

/*=========================*/

/*=========================*/

/*=========================*/

/*=========================*/

/* @import "../css/fonts/iconfont/iconfont.css";
@import "../css/fonts/iconfont/iconfont.css";
@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro BoldItalic'), url('../fonts/FS PFBeauSansPro-BoldItalic.otf') format("opentype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro Light'), url('../fonts/FS PFBeauSansPro-Light.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro Bbook'), url('../fonts/FS PFBeauSansPro-Bbook.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro SemiBold'), url('../fonts/FS PFBeauSansPro-SemiBold.otf') format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro Bold'), url('../fonts/FS PFBeauSansPro-Bold.otf') format("opentype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro SemiBoldItalic'), url('../fonts/FS PFBeauSansPro-SemiBoldItalic.otf') format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro BbookItalic'), url('../fonts/FS PFBeauSansPro-BbookItalic.otf') format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro LightItalic'), url('../fonts/FS PFBeauSansPro-LightItalic.otf') format("opentype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro Italic'), url('../fonts/FS PFBeauSansPro-Italic.otf') format("opentype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'FS PFBeauSansPro';
    src: local('FS PFBeauSansPro Regular'), url('../fonts/FS PFBeauSansPro-Regular.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'FS Magistral';
    src: local('FS Magistral Light'), local('FS Magistral-Light'), url('../fonts/FS Magistral-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FS Magistral';
    src: local('FS Magistral Book'), local('FS Magistral-Book'), url('../fonts/FS Magistral-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FS Magistral';
    src: local('FS Magistral Medium'), local('FS Magistral-Medium'), url('../fonts/FS Magistral-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FS Magistral';
    src: local('FS Magistral BookItalic'), local('FS Magistral-BookItalic'), url('../fonts/FS Magistral-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'FS Magistral';
    src: local('FS Magistral Bold'), local('FS Magistral-Bold'), url('../fonts/FS Magistral-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun Light'), local('Sarabun-Light'), url('../fonts/Sarabun-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun Regular'), local('Sarabun-Regular'), url('../fonts/Sarabun-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun Medium'), local('Sarabun-Medium'), url('../fonts/Sarabun-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun Italic'), local('Sarabun-Italic'), url('../fonts/Sarabun-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun SemiBold'), local('Sarabun-SemiBold'), url('../fonts/Sarabun-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun Bold'), local('Sarabun-Bold'), url('../fonts/Sarabun-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
} */

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

ul li {
    list-style: none;
}

button,
a,
input {
    outline: none;
}

body {
    background: #fff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    padding: 0px;
    margin: 0px;
    color: #252525;
    font-size: 14px;
    line-height: 1.15;
}

.left {
    float: left;
}

.right {
    float: right;
}

.txt-right {
    text-align: right;
}

.txt-left {
    text-align: left;
}

.txt-center {
    text-align: center;
}

.txt-uppercase {
    text-transform: uppercase;
}

.font-light {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.2px;
    color: #576c8a;
}

h2 a {
    color: inherit;
}

.content {
    max-width: 100%;
    margin: 0 auto;
    width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
}

.main {
    background-color: #fbfbfb;
    width: 100%;
}

img {
    max-width: 100%;
}

* {
    padding: 0px;
    margin: 0px;
}

a {
    text-decoration: none;
    color: #576c8a;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e7e7e7;
    font-weight: 500;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #e7e7e7;
    font-weight: 500;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #e7e7e7;
    font-weight: 500;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #e7e7e7;
    font-weight: 500;
}

.search input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #576c8a;
    font-weight: normal;
}

.search input::-moz-placeholder {
    /* Firefox 19+ */
    color: #576c8a;
    font-weight: normal;
}

.search input:-ms-input-placeholder {
    /* IE 10+ */
    color: #576c8a;
    font-weight: normal;
}

.search input:-moz-placeholder {
    /* Firefox 18- */
    color: #576c8a;
    font-weight: normal;
}

.search1 input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #576c8a;
    font-weight: 500;
}

.search1 input::-moz-placeholder {
    /* Firefox 19+ */
    color: #576c8a;
    font-weight: 500;
}

.search1 input:-ms-input-placeholder {
    /* IE 10+ */
    color: #576c8a;
    font-weight: 500;
}

.search1 input:-moz-placeholder {
    /* Firefox 18- */
    color: #576c8a;
    font-weight: 500;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* background: url('../images/select.svg') no-repeat #fff; */
    background-position-x: calc(100% - 16px);
    background-position-y: 50%;
    background-size: 12px;
    border: 1px solid #d1d1d1;
    border-radius: 8px 8px 8px 0;
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 16px 8px;
    width: 100%;
}

input,
textarea {
    border: 1px solid #d1d1d1;
    border-radius: 8px 8px 8px 0;
    width: 100%;
    color: #44494d;
    line-height: 20px;
    padding: 9px 15px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #b5b4b4;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #b5b4b4;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #b5b4b4;
}

input::placeholder,
textarea::placeholder {
    color: #b5b4b4;
}

select,
input {
    outline: 0;
}

select.error,
input.error,
textarea.error,
select.error:focus,
input.error:focus,
textarea.error:focus {
    border-color: #ff0000;
    color: #576c8a;
}

div.error {
    color: #ff0000;
    font-style: italic;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    padding: 0 0 3px;
}

select:focus,
input:focus,
textarea:focus {
    border-color: #58b84f;
}

h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #252525;
}

div:after {
    content: "";
    display: block;
    clear: both;
}

.row-top .content {
    padding-top: 20px;
    padding-bottom: 30px;
}

.row-top .content .blogo {
    float: left;
    margin-right: 80px;
    padding: 10px 0px 0px;
}

.language i {
    font-size: 5px;
    position: absolute;
    right: 0;
    top: 6px;
}

.row-top .language {
    float: left;
    margin-top: 16px;
    position: relative;
}

.row-menu .content {
    position: relative;
}

.search {
    position: absolute;
    right: 0;
    top: -60px;
}

.row-top .search {
    float: right;
    background: rgba(244, 248, 248, 0.788188);
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
}

.row-top .link-top {
    float: left;
    padding: 16px 0px 0px;
}

.row-top .link-top li {
    float: left;
    margin: 0px 20px 0px 0px;
}

.row-top .link-top li a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #576c8a;
}

.row-top .link-top li a i {
    margin-right: 7px;
    vertical-align: middle;
}

.row-menu {
    background: #ee0033;
    padding: 0px 0px;
}

.row-menu .boxmenu ul li {
    float: left;
    position: relative;
}

.row-menu .boxmenu ul li a {
    font-style: normal;
    font-size: 16px;
    line-height: 44px;
    padding: 15px 12px;
    color: #ffffff;
    display: block;
    font-weight: bold;
}

.row-menu .boxmenu ul li .submenu {
    position: absolute;
    display: none;
    top: 74px;
    width: 284px;
    z-index: 99;
    background: #fff;
    left: 0px;
}

.row-menu .boxmenu ul li ul.submenu li {
    float: none;
    padding: 0;
}

.row-menu .boxmenu ul li ul.submenu li a {
    display: block;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #4d4d4d;
    font-weight: normal;
    padding: 12px 0 12px 18px;
}

.row-menu .boxmenu span.show-sub {
    display: none;
}

ul.mega-menu h2 {
    font-size: 18px;
    color: #4d4d4d;
    padding: 0 0 0 35px;
    line-height: 43px;
}

.row-menu .boxmenu ul li.active > a {
    background: #e9b6c1;
    color: #ee0033;
}

.row-menu .boxmenu ul li ul.submenu li.active a {
    color: #ee0033;
    background: #f3f3f3;
    font-weight: bold;
}

.login-register {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 74px;
}

.login-register ul li {
    float: left;
    position: relative;
    color: #fff;
    line-height: 19px;
    font-weight: 300;
    font-size: 16px;
    z-index: 99;
}

.login-register .hello .phone {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 16px;
    margin: 2px 0 0 0;
    display: block;
}

.notice {
    color: #fff;
}

.login-register ul > li > div.hello {
    position: relative;
    padding-right: 50px;
}

.login-register ul > li > div.hello span.txt {
    font-weight: 300;
    display: block;
    text-align: right;
    padding: 0 18px;
    width: 175px;
}

.login-register ul > li > div.hello span.txt b {
    font-weight: bold;
    text-align: left;
    display: block;
    position: relative;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.login-register ul > li > div.hello span.txt:after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    content: "";
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    margin-top: -3px;
}

.login-register ul > li > div.hello .icon {
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: -4px;
    top: 44%;
}

.login-register ul > li > div.hello .icon:after {
    content: "";
    background: #ff0000;
    position: absolute;
    right: 5px;
    top: -3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.login-register ul > li > div.hello .icon i {
    font-size: 19px;
}

.sub-notice {
    position: absolute;
    top: 84px;
    right: 0;
    width: 450px;
    background: #f7f7f7;
    border-radius: 10px;
    display: none;
}

.login-register ul li.open .sub-notice {
    display: block;
}

.box-notices {
    background: #fff;
    border-radius: 10px;
}

.sub-notice:before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: -15px;
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #f7f7f7;
}

.sub-notice h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #576c8a;
    background: #f7f7f7;
    border-radius: 10px 10px 0 0;
    padding: 15px;
}

.sub-notice .item {
    padding: 15px;
    color: #576c8a;
    position: relative;
}

.sub-notice .notice-title {
    font-weight: 500;
    display: block;
    font-size: 14px;
}

.item-notice .img {
    width: 60px;
    float: left;
}

.count-notice,
.notice-t .date {
    font-size: 12px;
    font-weight: 300;
}

.item.noted {
    border-radius: 10px 10px 0 0;
}

.list-notice {
    background: #f7f7f7;
    border-radius: 0 0 10px 10px;
    margin: 15px -15px 0;
    display: none;
}

.item.open .list-notice {
    display: block;
}

.notice-t {
    font-weight: 500;
    font-size: 14px;
}

.comment {
    font-size: 16px;
    color: #0c0033;
    font-weight: 400;
    line-height: 1.8;
}

.item-notice {
    padding: 20px 15px;
    border-bottom: 1px solid #c5c8db;
}

.news .item-notice:last-child {
    border: 0;
}

.sub-notice .item:last-child .open-sub {
    margin-bottom: 15px;
}

.sub-notice .item:last-child .list-notice {
    margin-top: 0;
}

.sub-notice .item .open-sub {
    display: inline-block;
    width: 100%;
}

.item-notice .img:after,
.notice-t:after {
    display: none;
}

.sub-notice .item i.i-left {
    background: #fff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    width: 42px;
    height: 42px;
    display: block;
    border-radius: 100%;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    color: #ee0033;
    font-weight: normal;
    float: left;
    margin: 0 15px 0 0;
}

.sub-notice i.show-sub.icon-i-next {
    display: inline-block;
    position: absolute;
    right: 20px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 14px;
    top: 18px;
    padding: 10px;
    cursor: pointer;
}

.sub-notice .open i.show-sub.icon-i-next {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.sub-notice .item i.i-left.i-list {
    /* background: #fff url(../images/news.svg) no-repeat center center; */
}

.sub-notice .item:last-child {
    padding-bottom: 0;
    border-radius: 0 0 10px 10px;
}

.notice-r {
    padding: 0 10px 0 80px;
}

.login-register ul li .sub-regis {
    background: #fff;
    border-radius: 10px;
    position: absolute;
    display: none;
    right: 10px;
    width: 280px;
    max-width: 100%;
    z-index: 99;
    top: 102px;
}

.login-register ul.open li .sub-regis {
    display: block;
}

.login-register .show-sub.icon-down {
    position: absolute;
    right: 53px;
    top: 13px;
    display: block;
    width: 20px;
    height: 20px;
    z-index: 8;
    cursor: pointer;
}

.login-register ul li .sub-regis .inner-sub-register {
    padding: 15px;
    position: relative;
}

.login-register ul li .sub-regis .inner-sub-register:before {
    content: "";
    border: 23px solid #fff;
    position: absolute;
    right: 25px;
    top: -7px;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    z-index: -1;
}

.login-register ul li .sub-regis .inner-sub-register .row-item {
    padding: 15px 0px;
    color: #ee0033;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    font-family: "Montserrat", sans-serif;
    cursor: auto;
}

.login-register ul li .sub-regis .inner-sub-register .row-item > a {
    color: #ee0033;
    display: block;
    position: relative;
}

.login-register ul li .sub-regis .inner-sub-register .row-item > a:after {
    content: "\e90e";
    font-family: "icon-theme";
    float: right;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.login-register ul li .sub-regis .inner-sub-register .row-item p {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}

.login-register ul li .sub-regis .inner-sub-register .row-item span b.font-sizebig {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    vertical-align: middle;
    margin: 0 5px 0 0;
}

.login-register ul li .sub-regis .inner-sub-register .row-item span b.font-sizebig1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    vertical-align: middle;
    margin: 0 5px 0 0;
}

i.show-sub.icon-next {
    display: none;
}

.login-register ul li .sub-regis .inner-sub-register div.l-out {
    text-align: center;
}

.login-register ul li .sub-regis .inner-sub-register div.l-out a {
    background: #ffffff;
    border: 2px solid #fb4600;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    display: block;
    font-size: 14px;
    line-height: 31px;
    text-align: center;
    color: #fb4600;
}

.login-register ul li .sub-regis .inner-sub-register .row-item:first-child {
    padding-top: 0px;
}

.login-register ul li .sub-regis .inner-sub-register .row-item.row-border {
    border-bottom: 0.5px solid #ee0033;
    border-top: 0.5px solid #ee0033;
    font-family: "Roboto", sans-serif;
    position: relative;
}

.login-register ul li .sub-regis .inner-sub-register .row-item.row-border span {
    display: block;
    padding: 5px 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}

.login-register ul li .sub-regis .inner-sub-register .row-item.row-border p {
    padding: 5px 0;
}

.login-register ul li > a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;
    display: block;
    padding: 0 20px;
    margin: 0 2px;
}

.login-register ul li .sub-regis .inner-sub-register .row-item p i.icon-i-coc {
    font-size: 17px;
}

.login-register ul li .sub-regis .inner-sub-register .row-item p.p-icon {
    margin: 0 0 7px;
}

.login-register ul li:hover > a,
.login-register ul li.active > a {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    color: #252525;
}

.login-register ul li.active > a {
    font-weight: bold;
}

.login-register ul li .sub-regis .inner-sub-register .row-item p.small-font {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
}

.login-register ul li .sub-regis .inner-sub-register .row-item p.small-font b.b1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    vertical-align: middle;
    margin: 0px 8px;
}

.login-register ul li .sub-regis .inner-sub-register .row-item {
    position: relative;
}

.login-register .sub-tk {
    display: none;
}

.login-register .row-item:hover .sub-tk {
    display: block;
}

span.tkc {
    vertical-align: middle;
    display: inline-block;
    border: 1px solid #ee0033;
    border-radius: 10px;
    padding: 3px 9px 2px;
    margin: 0px 10px 0px 0px;
    text-transform: uppercase;
}

.sub-tk {
    position: absolute;
    width: 170px;
    z-index: 99;
    left: 100%;
    top: 0px;
}

.sub-tk a span,
.sub-tk a.add-account {
    font-family: "Roboto", sans-serif;
}

.sub-tk .sub-inner-tk {
    padding: 15px 10px 10px;
    position: relative;
    text-align: left;
    font-weight: normal;
    margin: 0 0 0 20px;
    background: #fff;
    border-radius: 10px;
}

.sub-tk .sub-inner-tk:before {
    content: "";
    background: #ffffff;
    -webkit-transform: matrix(0.71, 0.69, 0.72, -0.71, 0, 0);
    -ms-transform: matrix(0.71, 0.69, 0.72, -0.71, 0, 0);
    transform: matrix(0.71, 0.69, 0.72, -0.71, 0, 0);
    width: 12px;
    height: 12px;
    position: absolute;
    left: -6px;
    top: 21px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sub-tk .sub-inner-tk .row-ftth {
    border-bottom: 1px solid #9f9f9f;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #929292;
    padding: 5px 0px 10px;
}

.sub-tk .sub-inner-tk .row-ftth a {
    color: #929292;
}

.sub-tk .sub-inner-tk .row-ftth span {
    font-size: 16px;
    color: #576c8a;
}

a.add-account {
    color: #ee0033;
    font-size: 14px;
    display: block;
    margin: 10px 0px 0px;
}

a.add-account:after {
    display: none;
}

.search {
    position: absolute;
    right: 15px;
    top: -65px;
}

.search input {
    border: none;
    background: rgba(244, 248, 248, 0.788188);
    border-radius: 9px;
    height: 44px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #576c8a;
    padding-left: 43px;
    width: 100%;
}

.search button {
    border: 0;
    background: none;
    position: absolute;
    font-size: 20px;
    line-height: 1;
    left: 18px;
    top: 13px;
    color: #576c8a;
}

.owl-dots {
    text-align: center;
}

button.owl-dot {
    margin: 0 3px;
}

button.owl-dot span {
    background-color: #e7e7e7;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 10px;
    width: 10px;
}

button.owl-dot.active span {
    background-color: #ee0033;
}

button.owl-dot:hover span {
    background-color: #ee0033;
}

.banner-slideshow button.owl-dot span {
    /* background: url(../images/Oval1.png) no-repeat center; */
    width: 19px;
    height: 22px;
}

.banner-slideshow button.owl-dot.active span {
    /* background: url(../images/Oval1-a.png) no-repeat center; */
}

.banner-slideshow button.owl-dot:hover span {
    /* background: url(../images/Oval1-a.png) no-repeat center; */
}

.banner-slideshow.owl-carousel .owl-dots {
    bottom: 20px;
}

.banner-slideshow.owl-carousel .owl-stage-outer {
    padding: 0;
}

.content .tit {
    margin: 0 0 20px;
}

.content .tit .btabs {
    margin: 10px 0 0 40px;
}

.content .tit.tit1 .btabs {
    margin-left: 0;
}

.tab-title {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #44494d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

@media (max-width: 991px) {
    .tab-title {
        border-bottom: 0;
    }
}

.tab-title li {
    float: left;
}

.tab-title li:first-child a {
    padding: 0 10px 0 0;
}

@media (max-width: 991px) {
    .tab-title li:first-child a {
        padding: 0 10px;
    }
}

.tab-title li:last-child a {
    padding: 0 0 0 10px;
}

@media (max-width: 991px) {
    .tab-title li:last-child a {
        padding: 0 10px;
    }
}

.tab-title li a {
    border-bottom: 1px solid transparent;
    color: #44494d;
    display: block;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 19px;
    margin-bottom: -1px;
    padding: 0 10px;
}

@media (max-width: 991px) {
    .tab-title li a {
        margin-bottom: 0;
    }
}

.tab-title li a:hover,
.tab-title li.active a {
    color: #ee0033;
    border-bottom: 1px solid #ee0033;
}

a.viewall {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin: 12px 0 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    a.viewall {
        bottom: -25px;
        font-size: 12px;
        left: 15px;
        margin: 0;
        position: absolute;
        right: auto;
        text-decoration: underline;
    }
}

a.viewall:hover {
    text-decoration: underline;
}

.grild-product.lists.slider {
    margin: 0;
    padding: 0 18px;
}

.grild-product.lists .owl-dots {
    display: none;
}

.grild-product.lists.slider .item,
.grild-product.lists.slider .item:hover {
    box-shadow: none;
}

.grild-product.lists h3 {
    font-size: 18px;
    font-weight: 400;
}

.grild-product.lists h3 a {
    color: #0c0033;
}

.grild-product.lists .infor .price-p {
    font-weight: bold;
}

.lists.slider .imgages-p {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    margin: 0 0 20px;
}

.lists.slider .item:hover .imgages-p {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.lists.slider {
    margin: 0 -30px;
    position: relative;
    z-index: 9;
}

.lists.slider .owl-carousel {
    padding: 0 15px;
}

.owl-carousel .owl-stage-outer {
    padding: 0 0 25px;
    margin: 0;
}

.owl-carousel .owl-dots {
    bottom: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
}

.lists.slider .item {
    margin: 0 15px;
    border-radius: 10px;
}

.lists.slider .row2 .item {
    box-shadow: none;
}

.rows .item:hover a.img img {
    opacity: 1;
}

.rows .item a.img:hover img {
    opacity: 0.5;
}

.mobile-service .item {
    position: relative;
}

.mobile-service #tabs-data .item:after {
    background: -webkit-linear-gradient(
        bottom,
        #00c295 0.34%,
        rgba(0, 194, 149, 0.53901) 62.52%,
        rgba(0, 194, 149, 0.0001) 99.81%
    );
    background: linear-gradient(
        0deg,
        #00c295 0.34%,
        rgba(0, 194, 149, 0.53901) 62.52%,
        rgba(0, 194, 149, 0.0001) 99.81%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    top: 54.03%;
    border-radius: 0 0 10px 10px;
}

.mobile-service #tabs-data .item:hover:before {
    background: rgba(0, 194, 149, 0.5);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    z-index: 9;
    border-radius: 10px;
}

.mobile-service .more-right.active .rg {
    background: #e1e1e1;
    color: #b2b2b2;
    border-color: #e1e1e1;
}

.mobile-service .item .text-intro {
    position: absolute;
    bottom: 20px;
    color: #fff;
    z-index: 9;
    left: 15px;
    right: 15px;
}

.lists img {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

.lists.radius-top img {
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
}

.mobile-service .more-right {
    display: inline-block;
    width: 100%;
    clear: both;
    margin: 20px 0 0 0;
}

.mobile-service .view-detail {
    color: #fff;
    font-size: 25px;
    float: right;
    margin: 7px 0 0 0;
}

.mobile-service .view-detail i {
    font-size: 18px;
}

.mobile-service .item .text-intro .right {
    border-left: 1px solid #fff;
    padding: 0 0 0 10px;
}

.mobile-service .item .left a {
    color: #fff;
}

.mobile-service .item .text-intro span {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    display: block;
}

.mobile-service .item .text-intro span.price {
    font-size: 15px;
    font-weight: 300;
    line-height: normal;
}

.mobile-service .item .text-intro span.price i {
    padding: 0 5px 0 0;
}

.mobile-service .item .text-intro div.right span {
    font-weight: 700;
}

a.img:hover img,
.item:hover a.img img {
    opacity: 0.5;
}

/*end add*/

a.arr {
    border: 2px solid #eef1f7;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    font-size: 0px;
    line-height: 0px;
}

a.rg,
b.rg {
    display: inline-block;
    background: #ffffff;
    border: 2px solid #eef1f7;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    line-height: 40px;
    padding: 0px 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #ee0033;
}

a.rg:hover,
b.rg:hover {
    background: #ee0033;
    color: #fff;
    border-color: #ee0033;
}

.dv-internet {
    margin: 0px 0px 50px;
}

.dv-internet .intro-more {
    padding: 30px 30px 45px;
}

.dv-internet .intro-more h2 {
    font-weight: bold;
    font-size: 34px;
    line-height: 57px;
    color: #252525;
    position: relative;
}

.dv-internet .intro-more h2 span {
    font-size: 14px;
    line-height: 16px;
    display: block;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    color: #979797;
}

.dv-internet .intro-more ol {
    padding: 2px 0px 6px 0;
    min-height: 98px;
}

.dv-internet .more-c a {
    border: 2px solid #ee0033;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    font-size: 14px;
    color: #ee0033;
    padding: 12px 40px;
    text-align: center;
    display: inline-block;
}

.dv-internet .more-c a:hover {
    background-color: #ee0033;
    color: #fff;
}

.list-check li {
    padding: 0px 0px 14px 28px;
    list-style: none;
    position: relative;
}

.list-check li {
    position: relative;
}

.list-check li i {
    position: absolute;
    left: 0;
    font-size: 16px;
    color: #ee0033;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
}

.dv-internet .list-check li i {
    top: 20%;
}

.dv-internet .intro-more ol li a {
    font-size: 14px;
    line-height: 16px;
    color: #252525;
    display: block;
}

.dv-internet .sub-tabs .item {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.12);
    border-radius: 12px;
}

.dv-internet .sub-tabs .item:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.dv-internet .images-plus {
    font-size: 0px;
    line-height: 0px;
}

.dv-internet .images-plus img {
    border-radius: 12px 12px 0 0;
}

.intro-more h2 {
    font-weight: bold;
    font-size: 34px;
    line-height: 57px;
    /* identical to box height, or 168% */
    color: #252525;
    margin: 0;
}

.intro-more .txt-dv {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #576c8a;
}

.intro-more .txt-dv > span {
    padding-right: 5px;
}

.intro-more .txt-dv span {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #ee0033;
}

.intro-more .more-c {
    text-align: center;
    border-top: 1px solid #e5e5e5;
    padding: 20px 0px 0px;
}

.home .btn {
    font-family: "Roboto";
}

.btn {
    display: inline-block;
    color: #ee0033;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    font-size: 14px;
    line-height: 18px;
    padding: 11px 30px;
    font-family: "Montserrat";
    border: 2px solid #ee0033;
    text-align: center;
}

.btn:hover {
    color: #fff;
    background: #ee0033;
}

.btn.btn-blue {
    color: #fff;
    background: #ee0033;
}

.btn.btn-blue:hover {
    background: #b70d02;
    border-color: #b70d02;
}

.btn.btn-large {
    padding-top: 15px;
    padding-bottom: 15px;
}

.more-c .btn {
    padding-left: 48px;
    padding-right: 48px;
}

.viettel-plush .viettel-content div.right {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #252525;
}

.viettel-plush .viettel-content div.right h2 {
    margin: 0px 0px 15px;
}

.viettel-plush .viettel-content div.right .more li {
    padding-bottom: 3px;
    padding-top: 3px;
}

.viettel-plush ul {
    padding: 30px 0;
}

.viettel-plush .viettel-content div.right .more li a {
    font-size: 14px;
    line-height: 16px;
    color: #252525;
}

.viettel-plush .viettel-content div.right h2 a {
    font-weight: bold;
    font-size: 34px;
    line-height: 1.7;
    color: #252525;
}

.viettel-plush {
    margin: 55px 0px 85px;
}

.viettel-plush .viet-tel {
    margin: 0px 0px 44px;
}

.viettel-plush .viettel-content > div.left {
    width: 48.2%;
}

.viettel-plush .viettel-content > div.right {
    width: 48.2%;
    padding: 50px 0 0 0;
}

.apply {
    margin: 0px 0px 70px;
}

a.applynow {
    background: #ee0033;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    -webkit-box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    -moz-box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    display: inline-block;
    padding: 14px 26px;
    color: #fff;
    line-height: 16px;
    font-size: 14px;
}

a.applynow:hover {
    background: #b70d02;
}

.mess-top div.ms {
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 57px;
    text-align: center;
    color: #ee0033;
    padding: 50px 0px;
}

.apply .sub-tabs .subleft {
    width: 48.2%;
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -webkit-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -moz-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 12px;
    min-height: 472px;
    line-height: 24px;
}

.apply .sub-tabs .subleft div.imgs img {
    border-radius: 12px 12px 0px 0px;
    width: 100%;
}

.apply .sub-tabs .subright {
    width: 48.2%;
}

.apply .sub-tabs .subright .items {
    margin-bottom: 25px;
    position: relative;
}

.subright .items {
    position: relative;
}

.subright .more-view {
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
    right: 23px;
    color: #576c8a;
}

.apply .sub-tabs .subright .items {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -webkit-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -moz-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
}

.apply .sub-tabs .subright .items div.imgs {
    width: 47%;
    font-size: 0%;
    line-height: 0%;
}

.apply .sub-tabs .subright .items div.imgs img {
    border-radius: 12px 0 0px 12px;
    -webkit-border-radius: 12px 0 0px 12px;
    -moz-border-radius: 12px 0 0px 12px;
    width: 100%;
}

.apply .sub-tabs .subright .items .text {
    float: left;
    padding: 10px 56px 0 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #252525;
    width: 53%;
}

.apply .sub-tabs .subright .items .text h3 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.apply .sub-tabs .subright .items .text h3 a {
    font-weight: bold;
    font-size: 20px;
    line-height: 57px;
    /* identical to box height, or 285% */
    color: #252525;
}

.apply .sub-tabs .subleft .intro h2 {
    width: 100%;
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 34px;
    line-height: 57px;
    color: #252525;
}

.apply .sub-tabs .subleft .intro h2 a {
    color: inherit;
}

.apply .sub-tabs .subleft .intro h2 a i {
    position: absolute;
    right: 0px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
    color: #576c8a;
}

.apply .sub-tabs .subleft .intro {
    padding: 10px 20px 0px 30px;
}

.apply i {
    font-size: 18px;
    float: right;
}

.page-bot .support {
    padding: 0px 0px 70px 0px;
}

.page-bot .support h1 {
    margin: 0px 0px 50px;
}

.page-bot .support ul li {
    float: left;
    width: 23%;
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -webkit-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    -moz-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    margin: 0px 2.667% 0px 0px;
    text-align: center;
}

.page-bot .support ul li img {
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    vertical-align: middle;
}

.page-bot .support ul li:nth-child(4n) {
    margin-right: 0px;
}

.page-bot .support ul li a {
    font-weight: bold;
    font-size: 24px;
    line-height: 57px;
    text-align: center;
    color: #252525;
}

/* viettel tra truoc */

.leftsim,
.contentsim {
    width: 48.7%;
}

.list-sim table {
    width: 100%;
}

.list-sim table tr:first-child td {
    padding-top: 0;
}

.list-sim table td {
    padding: 15px 0px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    border-bottom: 1px solid #ededed;
}

.list-sim table td.txt-blue {
    color: #ee0033;
}

.list-sim table td.font20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* or 285% */
    letter-spacing: 0.125px;
}

.list-sim table td.td-right {
    text-align: right;
}

.contentsim i {
    font-size: 15px;
    padding: 0 10px 0 0;
    color: #576c8a;
}

.radio-custom {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */

.radio-custom input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */

.radio-custom .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #576c8a;
    border-radius: 100%;
}

.radio-custom:hover input ~ .checkmark {
    border: 1px solid #ee0033;
}

.radio-custom input:checked ~ .checkmark {
    border: 0;
}

.radio-custom span {
    display: block;
}

.radio-custom .price {
    padding: 5px 0 0 0;
}

.radio-custom .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-custom input:checked ~ .checkmark ~ span,
.radio-custom:hover input ~ .checkmark ~ span {
    color: #ee0033;
}

.radio-custom input:checked ~ .checkmark:after {
    display: block;
    border: 0;
    font-family: "icon-theme";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e912";
    color: #ee0033;
    font-size: 17px;
    margin: -1px 0 0 0;
}

a.buynow {
    background: #ee0033;
    border-radius: 10px;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    line-height: 44px;
    padding: 0px 12px;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
}

a.buynow:hover {
    background: #b70d02;
}

.data-content {
    background: rgba(255, 255, 255, 0.893229);
    /*box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
	-webkit-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
	-moz-box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);*/
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    padding: 30px 30px 35px 30px;
}

.data-content h2 {
    position: relative;
    text-transform: uppercase;
}

.data-content h2 i {
    position: absolute;
    right: 0px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
    font-size: 18px;
}

.data-content div.time {
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
    color: #ee0033;
    margin: 15px 0px;
    padding: 15px 0px;
}

.data-content p {
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px 25px;
}

.box-rig {
    text-align: center;
}

.box-rig a {
    padding-left: 40px;
    padding-right: 40px;
}

.top-title {
    position: relative;
    margin: 0px 0px 12px;
}

.top-title .txt-dv {
    position: absolute;
    right: 0px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
}

.list-gtgt .gtgt {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    margin: 0 0 36px 0;
}

.list-gtgt .gtgt:hover,
.list-dt .items:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.gtgt .intro-more {
    padding: 20px 20px 30px;
    font-size: 16px;
}

.gtgt .intro-more h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 5px;
}

.gtgt .intro-more h2 i {
    font-size: 18px;
    margin: 0 0 0 20px;
    vertical-align: middle;
}

.introtv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.introtv .coll {
    width: 60%;
    line-height: 20px;
}

.introtv .coll .coll-inner {
    padding: 0px 20px 0px 0px;
}

.service-gtgt .introtv .coll .coll-inner {
    padding-right: 0;
}

.service-gtgt .intro-more h2 i {
    float: right;
}

.service-gtgt .intro-more h2 {
    color: #576c8a;
}

.list-news .introtv .coll {
    width: calc(100% - 95px);
}

.new-desc,
.box-tc .min-data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-news .introtv .coll .coll-inner strong {
    padding: 10px 0 0 0;
}

.introtv .coll .coll-inner .span-blue {
    color: #ee0033;
}

.introtv .coll .coll-inner strong {
    font-size: 20px;
    display: block;
    padding: 3px 0 0 0;
}

.box-dk {
    width: 40%;
    text-align: right;
}

.tabs-tb .box-btn a {
    padding-left: 16px;
    padding-right: 16px;
    min-width: 150px;
}

.intro-more .txt-more {
    min-height: 98px;
}

.intro-more h2 i {
    font-size: 18px;
    margin: 0 0 0 15px;
}

.introqt {
    padding: 0px 154px 0 0;
    position: relative;
}

.content-dvqt .item {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.introqt {
    padding: 0 145px 30px 20px;
}

.introqt h2 {
    margin: 0 0 8px;
}

.introqt .box-dk {
    position: absolute;
    right: 20px;
    top: 0px;
    width: auto;
}

.content-dvqt .imgs {
    margin: 0 0 22px;
}

.introqt h2 {
    font-size: 24px;
    line-height: 28px;
    color: #576c8a;
}

.dn {
    padding: 20px 20px 25px 10px;
    text-align: center;
}

.dn .th {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ee0033;
    margin: 0 0 5px;
}

.dn .th a {
    color: inherit;
}

.dn h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #576c8a;
    margin: 0 0 7px;
    text-transform: none;
}

.dn h2 i {
    font-size: 18px;
    float: right;
    margin: 3px 0px 0px;
}

.dn > p {
    margin: 0 0 15px;
}

.box-price {
    margin: 0 0 15px;
}

.box-price .speci-price {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #ee0033;
    margin: 0 5px 0 0;
}

.box-price .old-price {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #576c8a;
    text-decoration: line-through;
}

.box-btn {
    text-align: center;
}

/*18/9*/

.pathway {
    padding: 30px 0;
}

.pathway ul li {
    display: inline;
    font-size: 16px;
}

.pathway ul li span {
    padding: 0 5px;
}

.pathway ul li strong {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
}

.pathway ul li i.icon-i-next {
    font-size: 12px;
    vertical-align: middle;
}

.title-page {
    text-align: center;
    line-height: 28px;
    font-weight: bold;
    font-size: 24px;
    font-family: "Montserrat";
    margin: 30px 0;
}

h1.space1 {
    margin: 0 0 28px;
    font-family: "Montserrat";
    text-align: center;
}

h2.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ee0033;
    padding: 6px 0 0;
}

.box-login {
    width: 555px;
    margin: 50px auto;
    max-width: 100%;
}

.box-login a {
    color: #ee0033;
}

.box-login a:hover {
    color: #b70d02;
}

.box-login .btn-blue:hover {
    color: #fff;
}

.btn-shadow .btn:hover {
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.4);
}

p.t-cen.actions .btn {
    margin: 0 20px;
    min-width: 180px;
}

.field label {
    color: #0c0033;
    float: left;
    padding: 17px 0 0 0;
}

.field .control {
    padding: 0 0 0 195px;
    position: relative;
}

.field {
    margin: 0 0 12px;
}

.require > label:after {
    content: "*";
}

.field label,
.field .control input,
.field .control textarea,
.field .control select {
    font-size: 16px;
    font-weight: 500;
}

.field .control input,
.field .control textarea,
.field .control select {
    margin: 0 0 12px;
}

.full-field.field label {
    float: none;
    padding: 0 0 5px;
    display: block;
}

.full-field.field .control {
    padding: 0;
}

.full-field.field.actions .btn {
    width: 100%;
}

.full-field .remember.checkbox {
    float: left;
    padding-left: 25px;
    text-decoration: underline;
}

.code-otp .control {
    padding: 0 112px 0 195px;
}

.code-otp a {
    position: absolute;
    right: 0;
    top: 14px;
    font-size: 18px;
    text-decoration: underline;
}

.code-otp a.disable,
.code-otp a.disable:hover {
    color: #e7e7e7;
}

div.icon-continue {
    background: #ee0033;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: 122px;
    top: 9px;
    cursor: pointer;
}

div.icon-continue:hover {
    background: #b70d02;
}

div.icon-continue.disable,
div.icon-continue.disable:hover {
    background: #e7e7e7;
    cursor: none;
}

.field-policy .mgs-notice {
    padding-top: 16px;
}

.box-login .action-login {
    width: 165px;
    text-align: center;
    margin: 16px 0;
}

.remember.checkbox {
    font-size: 14px;
}

.popup-login .checkbox .checkmark {
    top: 4px;
    height: 17px;
    width: 17px;
    border-width: 1px;
    border-radius: 4px;
}

.popup-login .checkbox .checkmark:after {
    left: 5px;
    top: 1px;
}

.box-login .action-login,
.box-login .action-regis,
.box-login .forgot {
    font-size: 18px;
}

.action-regis-permanent {
    padding: 10px 0 0 0;
    font-size: 16px;
}

.box-login .action-login a,
.box-login .action-regis a {
    text-decoration: underline;
}

.popup-login .box-login {
    margin: 0 auto 10px;
}

.popup-login .field.actions {
    text-align: center;
    margin: 20px 0 0 0;
}

.popup-login .box-login .action-login {
    width: auto;
}

.mgs-notice {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 24px;
    padding: 0 0 3px;
}

.mgs-notice a {
    text-decoration: underline;
}

.alc-right.error {
    position: absolute;
    left: 100%;
    width: 230px;
    top: 14px;
    padding: 0 0 0 12px;
}

.alt-right.error {
    position: absolute;
    left: 100%;
    width: 230px;
    top: 8px;
    padding: 0 0 0 12px;
}

.alt-right span {
    display: inline-block;
    height: 10px;
    margin: 0 8px 0 0;
    width: 40px;
    background: #e7e7e7;
}

.alt-right span:first-child {
    display: block;
    margin: 0 0 10px;
    background: none;
    width: auto;
}

.weak span:nth-child(2) {
    background: #ff0000;
}

.medium.error {
    color: #fe9a00;
}

.strong.error {
    color: #ee0033;
}

.medium span:nth-child(2),
.medium span:nth-child(3) {
    background: #fe9a00;
}

.strong span:nth-child(2),
.strong span:nth-child(3),
.strong span:nth-child(4) {
    background: #ee0033;
}

.eye {
    /* background: url(../images/eye.svg) no-repeat center center; */
    position: absolute;
    width: 16px;
    height: 13px;
    right: 15px;
    top: 19px;
    cursor: pointer;
    z-index: 4;
}

.select-accounts {
    height: 1px;
    background: #e7e7e7;
    width: 27px;
    display: inline-block;
    margin: 30px 0 23px;
    cursor: pointer;
}

.item-accounts a.check-acc {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    background: #fff;
    padding: 16px 30px;
    margin: 30px 15px;
    border-radius: 10px;
    font-size: 24px;
    color: #576c8a;
    display: block;
    position: relative;
}

.item-accounts:first-child a {
    background: #ee0033;
    color: #fff;
}

.item-accounts .address {
    display: block;
    font-size: 16px;
    padding: 8px 0 0 0;
}

.item-accounts .address i {
    font-size: 18px;
}

.check-acc:after {
    content: "";
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 100%;
    position: absolute;
    right: 30px;
    top: 21px;
    font-size: 18px;
}

.item-accounts:first-child a:after {
    content: "\e92f";
    font-family: "icon-theme";
    color: #fff;
    background: none;
    border: 0;
}

.item-accounts:first-child a:before {
    content: "\e930";
    font-family: "icon-theme";
    position: absolute;
    right: 28px;
    top: 23px;
    font-size: 12px;
}

.item-accounts a.mulit:after {
    content: "\e92f";
    font-family: "icon-theme";
    color: #ee0033;
    background: none;
    border: 0;
}

.item-accounts a.mulit:before {
    content: "\e930";
    font-family: "icon-theme";
    position: absolute;
    right: 28px;
    top: 23px;
    font-size: 12px;
    color: #fe9a00;
}

.item-accounts:last-child a {
    margin-bottom: 15px;
}

.item-accounts strong {
    font-weight: bold;
    display: block;
    margin: 0 0 5px;
}

.item-acc .edit {
    color: #576c8a;
    position: absolute;
    right: 0;
    top: 0;
}

.ls-accounts {
    padding: 12px 0;
    max-height: 318px;
    overflow-y: auto;
}

.item-acc {
    font-size: 16px;
    line-height: 180%;
    position: relative;
    padding: 0 0 5px;
    margin: 0 0 8px;
    border-bottom: 1px solid #e7e7e7;
}

.one-list .item-acc {
    padding: 0;
    margin: 0;
    border-bottom: 0;
}

.item-acc strong {
    color: #0c0033;
    font-weight: bold;
}

.item-acc span {
    display: block;
    color: #576c8a;
}

.field.actions {
    margin: 60px 0 0 0;
}

.field.actions .btn {
    min-width: 165px;
}

.box-login .capcha input.txt-code {
    width: 48%;
}

.box-login .box-capcha {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    width: 48%;
    float: right;
    position: relative;
    padding-right: 36px;
    height: 52px;
}

.box-login .img img {
    height: 37px;
    max-height: none;
    margin: 7px 5px;
}

.box-login .capcha span.refesh {
    right: 6px;
    top: 13px;
}

.box-forgot .capcha .txt-code {
    float: right;
    margin: 0 0 8px 4%;
}

.service-gtgt h2.title,
.box-gt h2.title {
    color: #0e131e;
    margin: 0 0 40px;
}

.box-gtgt .list-dt .goi-cuoc .tien {
    width: 30%;
}

.box-gtgt .list-dt .goi-cuoc .minmax {
    padding: 0 30px;
}

.service-gtgt .btn,
.box-gt .btn {
    padding-left: 14px;
    padding-right: 14px;
}

.box-gtgt i.icon-i-,
.box-tc i.icon-i-tags {
    color: #576c8a;
    font-size: 16px;
    font-weight: 400;
}

.box-tc .min-data {
    padding: 5px 0;
}

.box-tc .list-dt .min-maxd {
    padding-right: 95px;
}

.box-gtgt .min-data {
    padding: 5px 0;
}

.select-data h2 a {
    color: inherit;
}

.select-data .data-content p {
    min-height: 75px;
}

.select-data-top {
    margin: 0 0 30px;
}

.select-data-top h2 {
    float: left;
}

.select-data-top .sort-by {
    float: right;
    padding: 0;
}

.select-data .list-dt .goi-cuoc .tien {
    width: 27.5%;
    font-size: 50px;
    min-width: 145px;
}

.select-data .list-dt .min-maxd h2 {
    margin: 0;
    padding: 0;
}

.select-data .list-dt .min-maxd {
    padding: 0 35px 0 0;
}

.select-data .minmax .pr {
    padding: 7px 0 0 0;
}

.select-data-bottom h2 {
    margin-bottom: 50px;
}

.box-cart-bottom h2.title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 30px;
    color: #576c8a;
}

.box-cart-bottom .data-content h2 {
    color: #0c0033;
    text-transform: none;
}

.list-item {
    margin: 0 -18px;
}

.list-item .data-content h2 {
    text-transform: none;
}

.col3 > .item {
    width: 33.33%;
    padding: 0 18px;
    float: left;
}

.col3 > .item .data-content {
    box-shadow: 0px 5px 16px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.col3 > .item .data-content:hover {
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.4);
}

.list-dt .goi-cuoc .minmax {
    position: relative;
}

.list-dt .checkbox {
    position: absolute;
    top: 0;
    right: 0;
}

.box-data .view {
    margin: 15px 0 5px 0;
}

.box-data .view a {
    width: 152px;
    max-width: 100%;
}

.box-data.box-data-cacgoi h2 a {
    padding: 10px;
}

.box-data.box-data-cacgoi .view a {
    width: 145px;
}

.box-data-cacgoi h2 span {
    display: block;
    font-size: 22px;
    font-weight: 300;
}

.box-data .item {
    border-radius: 10px;
}

.box-data.owl-carousel .item {
    float: none;
    width: auto;
}

.box-data .btn-blue {
    min-width: 50%;
    font-family: "Montserrat";
}

.box-data h2 {
    border-radius: 10px 10px 0 0;
}

.box-data .item:nth-child(3n) {
    margin: 0 0 0 0;
}

.inner-item h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}

.inner-item h2 a {
    position: relative;
    z-index: 3;
    display: block;
    padding: 35px 0px 31px;
}

.inner-item h2 i {
    font-size: 12px;
    position: absolute;
    right: 40px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    top: 50%;
}

.inner-item h2.h2-minmax {
    background: -webkit-linear-gradient(285.78deg, #29e2e6 0%, #009b94 98.92%);
    background: linear-gradient(164.22deg, #29e2e6 0%, #009b94 98.92%);
}

.inner-item h2.h2-minmax:after {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 33%;
    border-radius: 100%;
    width: 660px;
    height: 660px;
    bottom: -100%;
}

.inner-item h2.h2-minmax1 {
    background: -webkit-linear-gradient(106.26deg, #ff780f 1.81%, #ffba23 97.76%);
    background: linear-gradient(343.74deg, #ff780f 1.81%, #ffba23 97.76%);
}

.inner-item h2.h2-minmax1:after {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: -5%;
    border-radius: 100%;
    width: 389px;
    height: 379px;
    bottom: 14px;
}

.inner-item h2.h2-minmax2 {
    background: -webkit-linear-gradient(285.78deg, #29e2e6 0%, #00559b 98.78%);
    background: linear-gradient(164.22deg, #29e2e6 0%, #00559b 98.78%);
}

.inner-item h2.h2-minmax2:after {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    right: 34%;
    border-radius: 100%;
    width: 660px;
    height: 660px;
    bottom: -100%;
}

.int {
    padding: 20px;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #ee0033;
}

.int i {
    margin: 0 8px 0 0;
    font-weight: 400;
}

.int p {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #576c8a;
    margin: 10px 0 30px;
}

.hot-key .search1 {
    width: 44%;
    position: relative;
}

.hot-key .search1 input {
    border: none;
    background: rgba(244, 248, 248, 0.788188);
    border-radius: 9px;
    height: 52px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #576c8a;
    padding-left: 43px;
    width: 60.1%;
}

.hot-key .search1 button.btn-icon {
    border: 0;
    background: none;
    position: absolute;
    font-size: 20px;
    line-height: 1;
    left: 18px;
    top: 13px;
    color: #576c8a;
}

.cart-sim-so .hot-key .search1 button.btn-icon {
    top: 15px;
}

.cart-sim-so .tab-title li a {
    font-size: 20px;
    margin: 0 20px;
    padding: 0 0 5px;
}

.cart-sim-so .btabs {
    float: right;
}

.cart-sim-so .txt-hot {
    width: 51%;
}

.cart-sim-so .hot-key .search1 {
    width: 49%;
}

.cart-sim-so .hot-key .search1 input {
    width: 65%;
}

.cart-sim-so .tab-title li:last-child a {
    margin-right: 0;
}

.cart-sim-so .tab-title li:first-child a {
    margin-left: 0;
}

.hot-key .search1 button.btn-tk {
    background: #ee0033;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    padding: 11px 40px;
    margin: 0 0 0 20px;
    border: none;
}

.txt-hot {
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #ee0033;
    width: 56%;
}

.txt-hot p {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin: 0 0 0px;
}

.hot-key div.so {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.hot-key div.so span {
    padding: 0 22px;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #ee0033;
}

.hot-key div.so span:first-child {
    padding-left: 0;
}

.hot-key div.so span:last-child {
    padding-right: 0;
}

.list-sim-so .hot-key,
.list-sim-so .content .tit {
    width: 622px;
    margin: 0 auto;
    max-width: 100%;
}

.list-sim-so .hot-key .search1 {
    width: 100%;
    margin: 0px 0 40px;
}

.list-sim-so .txt-hot {
    width: 100%;
}

.list-sim-so .content .tit .btabs {
    margin: 0 0 30px;
    float: none;
    text-align: center;
}

.list-sim-so .tab-title {
    display: inline-block;
    padding: 0;
}

.list-sim-so .tab-title li a {
    font-size: 20px;
    padding: 0 0 8px;
    margin: 0 50px;
    line-height: normal;
}

.list-sim-so .hot-key .search1 input {
    width: calc(100% - 162px);
}

.list-sim-so .hot-key .search1 button.btn-tk {
    width: 145px;
    padding: 11px 10px;
    margin: 0 0 0 12px;
}

.list-sim-so .hot-key div.so span {
    padding: 0 25px;
}

.hot-key div.so span:first-child {
    padding-left: 0;
}

.hot-key div.so span:last-child {
    padding-right: 0;
}

.list-sim-so .hot-key div.so {
    padding: 10px 0 0 0;
}

.guide-search {
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    padding: 15px 10px 15px 15px;
    width: 570px;
    max-width: 100%;
    top: 100%;
    margin: 10px 0 0 0;
    line-height: 24px;
    color: #576c8a;
    z-index: 9;
}

.guide-search h3 {
    font-size: 14px;
    color: #0c0033;
    font-weight: 500;
    margin: 0 0 12px;
}

.c-guide p {
    margin: 8px 0 0 0;
}

.radio-custom1 {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 20px;
}

/* Hide the browser's default radio button */

.radio-custom1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */

.radio-custom1 .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #ee0033;
    border-radius: 100%;
}

.radio-custom1:hover input ~ .checkmark {
    border-color: #ee0033;
}

.radio-custom1 input:checked ~ .checkmark {
    border-color: #ee0033;
}

.radio-custom1 span {
    display: block;
}

.radio-custom1 .price {
    padding: 5px 0 0 0;
}

.radio-custom1 .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-custom1 input:checked ~ .checkmark ~ span {
    font-weight: 500;
}

.radio-custom1 input:checked ~ .checkmark:after {
    display: block;
    background: #ee0033;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
}

.sx .viewall {
    margin: 0;
    padding: 0;
    position: static;
    line-height: normal;
}

div.sx {
    margin: 40px 0 25px;
    font-size: 20px;
}

div.sx div.left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

div.sx div.left > div {
    margin: 0 50px 0 0;
}

div.sx div.left label {
    color: #0c0033;
}

div.sx div.left a {
    color: #ee0033;
    margin: 0 0 0 9px;
}

.tbl-sim {
    width: 100%;
}

.tbl-sim th {
    border-bottom: 2px solid #979797;
    font-weight: bold;
    line-height: 23px;
    color: #0c0033;
    border-right: none;
}

.tbl-sim th,
.tbl-sim td {
    padding: 12px 20px;
    text-align: center;
    font-size: 20px;
}

.tbl-sim td {
    font-size: 20px;
    color: #576c8a;
    border-bottom: 1px solid #e9e9e9;
}

.tbl-sim tr:last-child td {
    border-bottom: none;
}

.tbl-sim td.color-blue {
    color: #ee0033;
    font-weight: 500;
}

.tbl-sim td.color-blue i {
    color: #576c8a;
    font-size: 15px;
    line-height: 15px;
    vertical-align: top;
    margin: 4px 10px 0 0;
    display: inline-block;
}

.tbl-sim td.actions {
    padding: 12px 15px;
    text-align: right;
    width: 20%;
}

.tbl-sim td a.buynow {
    margin: 0 15px 0 0;
}

.tbl-sim td.name,
.tbl-sim td.price {
    width: 34%;
}

.tbl-sim td.stt {
    width: 12%;
}

.tbl-sim a.wishlist {
    color: #ee0033;
    float: right;
    margin: 12px 0 0 0;
}

.tbl-sim a.wishlist.active i::before {
    content: "\e917";
}

.tbl-sim a.wishlist i {
    font-weight: 400;
}

.tbl-simdep.tbl-sim td {
    width: auto;
}

.box-ct {
    text-align: center;
}

.box-ct a {
    border: 2px solid #ee0033;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ee0033;
    padding: 12px 26px;
    display: inline-block;
}

.navpage {
    text-align: center;
    margin: 55px 0 65px;
    font-size: 20px;
    clear: both;
}

.navpage ul li {
    display: inline;
}

.navpage ul li a,
.navpage ul li strong {
    padding: 0 14px;
    color: #e7e7e7;
    display: inline-block;
    line-height: 40px;
    min-width: 40px;
    text-align: center;
}

.navpage ul li.current strong,
.navpage ul li:not(.arrow):hover a {
    background: #b70d02;
    border-radius: 6px;
    color: #fff;
}

.navpage ul li.arrow a,
.navpage ul li:first-child a,
.navpage ul li:last-child a {
    color: #ee0033;
}

.navpage ul li.arrow a:hover,
.navpage ul li:first-child a:hover,
.navpage ul li:last-child a:hover {
    background: none;
}

.navpage ul li:first-child a {
    margin-right: 35px;
}

.navpage ul li:last-child a {
    margin-left: 35px;
}

.navpage ul li:first-child a,
.navpage ul li:last-child a {
    text-decoration: underline;
}

.navpage ul li.arrow a {
    text-decoration: none;
}

div.cg {
    color: #474747;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    padding: 0 0 40px;
}

.t-cen {
    text-align: center;
}

div.cg > p {
    margin: 0 0 20px;
}

.goi-cuoc {
    display: table;
    /*align-items: center;*/
    width: 100%;
    margin: 0 0 40px;
}

.goi-cuoc .tien {
    width: 31.5%;
    display: table-cell;
    vertical-align: middle;
}

.goi-cuoc .minmax {
    width: 68.5%;
    padding: 0 0 0 2.5%;
    display: table-cell;
    vertical-align: middle;
}

.goi-cuoc .tien {
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 54px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
}

.txt-blue {
    color: #ee0033;
}

.detail-cuoc .min-maxd a.btn-blue {
    min-width: 196px;
}

.min-maxd {
    position: relative;
    text-align: left;
}

.min-maxd p {
    font-weight: 500;
    margin: 5px 0;
}

.min-maxd a.btn-blue {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
}

.min-maxd h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
}

.min-maxd h2 a {
    color: #0c0033;
}

.ll-gg {
    margin: 20px 0 0;
}

.ll-gg .ll,
.ll-gg .gg {
    width: 48%;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0 0 16px 0;
}

.ll-gg .title {
    background: rgba(20, 175, 180, 0.15);
    border-radius: 6px 6px 0px 0px;
    color: #0c0033;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding: 16px 0;
    margin: 0 0 19px 0;
}

.ll-gg p {
    color: #ee0033;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
}

.ll-gg p i {
    margin: 0 10px 0 0;
    color: #576c8a;
    font-weight: 400;
}

.box-data-cacgoi .inner-item h2.h2-minmax {
    background: -webkit-linear-gradient(289.46deg, #29e2e6 0%, #009b94 98.92%);
    background: linear-gradient(160.54deg, #29e2e6 0%, #009b94 98.92%);
    position: relative;
}

.box-data-cacgoi .inner-item h2.h2-minmax:after {
    position: absolute;
    right: 0%;
    left: 30.24%;
    top: -152.69%;
    bottom: 50.54%;
    content: "";
    background: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.2;
}

.tit1 ul.tab-title li {
    float: none;
    display: inline-block;
}

.tit1 ul.tab-title li a {
    display: inline-block;
    font-size: 20px;
    padding-bottom: 8px;
    margin: 0 25px;
}

.sort-by {
    color: #576c8a;
    padding: 0 0 64px;
}

.sort-by .filter {
    float: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.sort-by .box-filter {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 16px;
    min-width: 177px;
    cursor: pointer;
}

.filter-left ul {
    padding: 0 15px;
}

.sort-by .order-sort .box-filter {
    padding-left: 45px;
}

.sort-by i {
    color: #ee0033;
}

.sort-by .filter-left {
    margin: 0 0 0 18px;
}

.filter {
    position: relative;
}

.sort-by .filter-left:before {
    position: absolute;
    left: -18px;
    top: 20px;
    background: #864fdf;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    content: "";
}

.sort-by .box-filter:after {
    position: absolute;
    font-family: "icon-theme";
    content: "\e90c";
    color: #ee0033;
    right: 16px;
    top: 14px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-weight: bold;
}

.filter-left .filter-content {
    width: 100%;
}

.filter-content {
    background: #ffffff;
    box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 212px;
    padding: 9px 15px;
    z-index: 10;
    display: none;
}

.filter-content ul li {
    padding: 3px 0;
}

.filter-content ul a {
    color: #576c8a;
}

.close-filter {
    position: absolute;
    color: #ee0033;
    right: 12px;
    top: 10px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
}

.close-filter:after {
    font-family: "icon-theme";
    content: "\e913";
    font-weight: 400;
    font-size: 18px;
}

.filter-label {
    font-weight: 500;
    line-height: 24px;
    color: #0c0033;
}

span.title-filter {
    float: left;
    margin: 16px 12px 0 34px;
}

.order-sort {
    float: left;
    position: relative;
}

.order-sort .icon-i-cart {
    position: absolute;
    left: 14px;
    top: 13px;
    font-size: 18px;
    font-weight: normal;
}

.rating-summary .rating-result {
    width: 112px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.rating-summary .rating-result:before {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    color: #d1d3d4;
    font-family: "icon-theme";
    font-size: 16px;
    height: 16px;
    letter-spacing: 8px;
    line-height: 16px;
    content: "\e915""\e915""\e915""\e915""\e915";
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none;
}

.rating-summary .rating-result > span {
    display: block;
    overflow: hidden;
}

.rating-summary .rating-result > span:before {
    position: relative;
    z-index: 2;
    -webkit-font-smoothing: antialiased;
    color: #ffd500;
    font-family: "icon-theme";
    font-size: 16px;
    height: 16px;
    letter-spacing: 8px;
    line-height: 16px;
    content: "\e915""\e915""\e915""\e915""\e915";
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none;
}

.rating-summary .rating-result > span span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.box-list-dt {
    position: relative;
}

.list-item.box-list-dt {
    margin: 0;
}

.list-item.box-list-dt .list-dt {
    margin: 0 -18px;
}

.select-pack.sx {
    margin: 30px 0 7px;
}

.select-pack .sort-by {
    padding: 0;
    font-size: 14px;
}

.select-pack .left {
    margin: 16px 0 0 0;
}

.shadow-item .slick-list {
    margin: 0 -18px;
}

.shadow-item .slide-slick .item {
    padding: 18px;
}

.shadow-item .slide-slick .item:hover {
    box-shadow: none;
}

.shadow-item .slide-slick .data-content {
    box-shadow: 0px 5px 16px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.shadow-item .slide-slick .data-content:hover {
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.4);
}

.shadow-item .slide-slick .owl-dots {
    margin: 0;
}

.owl-dots li {
    margin: 0 3px;
    display: inline-block;
}

.owl-dots li button {
    width: 12px;
    height: 8px;
    background: #e7e7e7;
    border-radius: 3px;
    display: inline-block;
    font-size: 0;
    line-height: 0;
}

.owl-dots li.slick-active button {
    width: 24px;
    background: #11aca5;
}

.owl-dots li:hover button {
    background: #11aca5;
}

.slick-arrow {
    margin-top: -8px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ccc;
    border: 1px solid #e6e6e6;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 20px;
    height: 56px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 56px;
    z-index: 1000;
}

@media (max-width: 991px) {
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
        background-color: #fff;
        border-color: #ee0033;
        color: #ee0033;
        font-size: 16px;
        height: 36px;
        width: 36px;
    }
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
    background-color: #fff;
    border-color: #ee0033;
    color: #ee0033;
}

.owl-carousel .owl-nav button.owl-prev {
    left: -65px;
}

@media (max-width: 1200px) {
    .owl-carousel .owl-nav button.owl-prev {
        left: 0;
    }
}

@media (max-width: 991px) {
    .owl-carousel .owl-nav button.owl-prev {
        left: 0;
    }
}

.owl-carousel .owl-nav button.owl-next {
    right: -65px;
}

@media (max-width: 1200px) {
    .owl-carousel .owl-nav button.owl-next {
        right: 0;
    }
}

@media (max-width: 991px) {
    .owl-carousel .owl-nav button.owl-next {
        right: 0;
    }
}

.owl-carousel:hover .owl-nav button.owl-prev,
.owl-carousel:hover .owl-nav button.owl-next {
    opacity: 1;
}

.banner-slideshow .owl-nav button.owl-prev,
.banner-slideshow .owl-nav button.owl-next {
    background-color: #fff;
    border-color: #fff;
    color: #ee0033;
    font-size: 18px;
    height: 50px;
    width: 50px;
}

.banner-slideshow .owl-nav button.owl-prev {
    left: 15px;
}

.banner-slideshow .owl-nav button.owl-next {
    right: 15px;
}

.shadow-item .owl-nav button.owl-next {
    right: 0;
}

.shadow-item .owl-nav button.owl-prev {
    left: 0;
}

.list-dt .rows {
    margin: 0 0 30px;
}

.list-dt .rows:last-child {
    margin: 0;
}

.list-dt .items {
    width: 49%;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    background: #fff;
}

.list-dt .items:hover {
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.4);
}

.bg-purple {
    background: #864fdf;
}

.bg-orange {
    background: #fe9a00;
}

.bg-blue {
    background: #ee0033;
}

.list-dt .items .goi-cuoc {
    padding: 15px;
    margin: 0px;
}

.list-dt .goi-cuoc .tien {
    font-size: 36px;
    width: 20%;
    border-radius: 6px;
}

.list-dt .goi-cuoc .minmax {
    width: 80%;
    padding-left: 4%;
}

.list-dt .min-maxd h2 {
    font-size: 24px;
    line-height: 28px;
    font-family: "Roboto", sans-serif;
    margin: 5px 0;
}

.list-dt .rating-summary {
    margin: 5px 0;
}

.list-dt .min-maxd h2 span,
.min-data {
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
}

.box-tc .goi-cuoc.gtgt {
    padding: 15px;
    display: block;
}

.service-gtgt .minmax .pr {
    font-size: 20px;
}

.minmax .pr {
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;
    color: #ee0033;
}

.minmax .pr span {
    color: #0c0033;
    font-size: 16px;
}

.list-dt .min-maxd {
    padding: 0 33% 0 0;
}

.list-dt .min-maxd a.btn-blue {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: auto;
}

.select-pay {
    text-align: center;
}

.select-pay .input-content {
    padding-top: 20px;
}

.pay-action {
    margin: 30px 0 25px;
}

.box-pop {
    margin: 0 1%;
}

.box-pop .items {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 30.33%;
    float: left;
    margin: 0 1.5%;
    text-align: center;
    padding: 30px 10px;
    min-height: 204px;
}

.box-pop .items:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.methor .box-pop {
    margin: 25px -2% 60px;
}

.methor .box-pop .items {
    width: 46%;
    margin: 0 2%;
}

.methor .radio-custom1 {
    display: inline-block;
    font-size: 24px;
    padding-left: 30px;
    max-width: 85%;
}

.methor .radio-custom1 input:checked ~ .checkmark ~ span,
.methor .radio-custom1 span {
    font-weight: bold;
}

.select-pay .radio-custom1 {
    display: inline-block;
    max-width: 250px;
    padding-left: 28px;
    line-height: normal;
}

.select-pay .radio-custom1 span {
    font-size: 24px;
    font-weight: bold !important;
}

.select-pay .radio-custom1 .checkmark {
    top: 3px;
}

.select-pay .radio-custom1 span.point {
    font-size: 14px;
    font-style: italic;
    font-weight: 400 !important;
    color: #576c8a;
    text-align: left;
    line-height: 1;
}

.img-pay {
    margin: 0 0 15px;
}

.inner-input {
    position: relative;
}

form.box-regis-form .btn-blue {
    min-width: 165px;
}

.inner-input .close {
    position: absolute;
    right: 0px;
    top: 0;
}

.inner-input p.t-cen {
    margin: 20px 0;
}

.inner-input p.modal-des {
    color: #474747;
    font-size: 16px;
    line-height: 180%;
    word-break: break-word;
}

.inner-input p.t-cen--btn {
    margin: 0;
}

.inner-input p.t-cen.w69 {
    max-width: 69%;
    margin-left: auto;
    margin-right: auto;
}

.i-success {
    color: #00c0a0;
}

.i-success i {
    font-size: 80px;
}

.i-fail {
    color: #ff0048;
}

.i-fail i {
    font-weight: normal;
}

.inner-input .row {
    margin: 0 0 15px;
}

.inner-input .row label {
    display: inline-block;
    vertical-align: middle;
    color: #0c0033;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    width: 36%;
    margin: 5px 0 0 0;
}

.row .capcha.control {
    padding-right: 115px;
    position: relative;
}

.capcha img {
    vertical-align: middle;
    max-height: 30px;
}

.capcha span.refesh {
    position: absolute;
    right: -32px;
    top: 5px;
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
}

.capcha .ma {
    position: absolute;
    right: 0;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 4px;
    overflow: hidden;
    width: 95px;
    text-align: center;
    vertical-align: middle;
    max-height: 38px;
    top: 0;
}

.inner-input h1 {
    color: #0c0033;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    margin: 10px 0 25px;
    font-family: "Montserrat";
    text-transform: none;
}

.inner-input .font25 {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 180%;
}

.row .control {
    width: 64%;
    float: right;
}

.row input {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 15px;
    color: #bfc4ce;
    width: 100%;
    font-size: 14px;
}

button {
    border: none;
    cursor: pointer;
}

/* add list */

.col-left {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 31.6%;
}

.col-right {
    width: 65.7%;
}

h1.h1-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    padding: 60px 0 0px 36.8%;
    margin: 0;
}

.col-left-inner {
    padding: 40px 30px;
}

.col-left-inner > a.viewmore {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.col-left-inner strong {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    display: block;
    margin: 0 0 30px;
    color: #0c0033;
}

.about-price {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    margin: 50px 0 50px;
}

input.txt-input {
    background: #ffffff;
    border: 2px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 6px;
    height: 35px;
    margin: 0;
    padding: 0;
}

.about-price .control {
    padding: 0 0 0 40px;
}

.about-price > div {
    float: left;
    width: 48%;
}

.about-price .to {
    float: right;
}

.about-price > div label {
    float: left;
    margin: 4px 8px 0 0;
}

div.type {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #576c8a;
    margin: 0 0 15px;
}

ul.list-type,
ul.list-product {
    margin: 0 0 40px;
    overflow: hidden;
}

.col-left-inner h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin: 0 0 20px;
}

.product-list {
    padding-bottom: 60px;
}

.product-list .navpage {
    margin-top: 40px;
}

.list-product {
    margin: 0 0 20px;
}

.list-product li,
.list-type li {
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    color: #474747;
    margin: 0 0 8px;
}

.list-product li a,
.list-type li a {
    color: #474747;
    position: relative;
    display: inline-block;
}

.list-product li a:before,
.list-type li a:before {
    content: "";
    border: 2px solid #e7e7e7;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    display: inline-block;
    float: left;
    margin: 5px 15px 0 0;
}

.list-product li a:hover:after,
.list-type li a:hover:after,
.list-product li a.active:after,
.list-type li a.active:after {
    background: #ee0033;
    position: absolute;
    content: "\e920";
    font-family: "icon-theme";
    font-size: 9px;
    color: #fff;
    left: 0;
    top: 5px;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 26px;
}

.list-type li {
    float: left;
    width: 50%;
}

.list-color li.color {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 0 8px 8px 0;
}

.list-color li.blue {
    background: #ee0033;
}

.list-color li.orange {
    background: #fe9a00;
}

.list-color li.gray {
    background: #576c8a;
}

.list-color li.purple {
    background: #b41457;
}

.list-color li.white {
    background: #fff;
    border: 1px solid #bdbdbd;
}

.list-color li.pink {
    background: #ffafa9;
}

div.sort .search input {
    background: rgba(244, 248, 248, 0.788188);
    border-radius: 10px;
    height: 52px;
}

.col-right .sort {
    padding: 0 0 15px 0;
    font-size: 20px;
}

div.sort .search {
    top: 0;
    right: 0;
    margin: 0 0 25px;
    width: 100%;
    position: relative;
}

div.sort strong {
    color: #0c0033;
}

div.sort a {
    color: #ee0033;
}

.grild-product:after {
    clear: both;
    display: block;
    content: "";
}

.grild-product {
    margin: 0 -2%;
}

.grild-product li {
    background: #fff;
    float: left;
    width: 29.33%;
    margin: 0 2% 15px;
}

.grild-product li:nth-child(3n + 1) {
    clear: both;
}

.grild-product li .imgages-p {
    width: 100%;
    height: 270px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: relative;
    background: #fff;
    margin: 0 0 15px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.grild-product li:hover .imgages-p {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
}

.grild-product .imgages-p a {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.infor .price-p i {
    font-size: 15px;
    font-weight: normal;
    color: #576c8a;
}

.grild-product .compare {
    border: 2px solid #e7e7e7;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 10px;
    background: #fff;
}

.grild-product .compare.active:before,
.grild-product .compare:hover:before {
    background: #ee0033;
    position: absolute;
    content: "\e920";
    font-family: "icon-theme";
    font-size: 9px;
    color: #fff;
    left: -2px;
    top: -2px;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
}

.grild-product li div.imgages-p input {
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 1;
}

.grild-product li .infor h3 a {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.grild-product li .infor h3 {
    margin: 0 0 5px;
}

.more-text {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(55, 55, 55, 0.84);
    border-radius: 10px;
    display: none;
    z-index: 8;
}

.grild-product li div.imgages-p:hover .more-text {
    display: block;
}

.grild-product li .btn-blue {
    padding-left: 20px;
    padding-right: 20px;
}

.infor .price-p {
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #ee0033;
}

.more-text > div {
    padding: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #fff;
    letter-spacing: -1px;
    text-align: left;
}

/*detail*/

.product-detail h1 {
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: 500;
}

.product-detail h2.title {
    color: #0c0033;
    margin: 0 0 30px;
}

.top-detail {
    margin: 10px 0 0 0;
}

.top-detail .rating-summary {
    margin: -2px 10px 0 0;
}

.top-detail .rating {
    padding: 5px 0 0 0;
}

.share a {
    margin-left: 20px;
    font-weight: bold;
}

.share .fb-like,
.share .share-link {
    color: #576c8a;
}

.share .fb {
    color: #3b5998;
}

.text-blue {
    color: #ee0033;
}

.share .text-blue {
    text-decoration: underline;
}

.share i {
    font-size: 20px;
}

.share .fb-like i {
    font-size: 16px;
    padding: 0 7px 0 0;
}

.pathwaydetail {
    margin-top: 30px;
}

.dtfull {
    margin: 0 0 50px;
}

.box-infor,
.box-images {
    width: 48%;
}

.box-images {
    padding: 26px;
}

.box-infor {
    padding: 30px 0;
}

.img-thumb {
    margin: 25px 77px;
}

.img-thumb .slides li {
    height: 86px;
    border: 1px solid #00a9c3;
    overflow: hidden;
    cursor: pointer;
}

.suport a.bh {
    border: 1px solid #fe9a00;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #fe9a00;
    float: left;
    padding: 10px 35px;
}

.suport a.tv {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    margin: 0 10px 0 25px;
    font-weight: bold;
}

.suport .icon-headphones-mic {
    font-weight: normal;
    color: #576c8a;
}

.suport span {
    font-style: normal;
    font-size: 24px;
    color: #ee0033;
    line-height: 44px;
    font-weight: 700;
}

.box-infor .box-price {
    margin: 25px 0;
}

.box-infor div.action .box-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.box-price .regular-price {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #ee0033;
}

.box-price .old-price {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
    color: #576c8a;
    vertical-align: middle;
    font-family: "Roboto", sans-serif;
}

.box-price .note {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #576c8a;
    padding: 0 0 0 60px;
}

.cart-mobile div.pk,
.cart-mobile div.qt label,
.cart-mobile .box-price .old-price {
    font-weight: 400;
}

div.qt > div {
    display: inline-block;
}

div.pk {
    margin: 30px 0 20px;
    font-weight: bold;
    font-size: 16px;
}

div.qt {
    margin: 0 0 20px 0;
}

div.qt .qty {
    margin: 0 45px 0 0;
}

div.qt input.txt-qt {
    border: 2px solid #e7e7e7;
    border-left: 0;
    border-right: 0;
    box-sizing: border-box;
    border-radius: 0px;
    text-align: center;
    width: 40px;
    height: 28px;
    float: left;
    padding: 0;
    color: #0c0033;
}

div.qt label {
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    vertical-align: middle;
    margin: 0 12px 0 0;
    float: left;
    line-height: 30px;
}

.box-infor .qt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.box-infor .qt .qty {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.box-infor .qt .qty__input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.box-infor .qt .qty__input a {
    height: 28px;
    width: 28px;
    border: 2px solid #e7e7e7;
    text-align: center;
    line-height: 24px;
    border-radius: 6px 0 0 6px;
    color: #0c0033;
}

.box-infor .qt .qty__input a:last-child {
    border-radius: 0 6px 6px 0;
}

.boxcolor {
    float: left;
    margin-top: -4px;
}

.boxcolor a {
    float: left;
    width: 38px;
    height: 38px;
    margin: 0 4px 0 0;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.boxcolor a span {
    border-radius: 50%;
    border: 3px solid #fff;
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 2px;
    top: 2px;
}

.boxcolor a.blue span {
    background: #ee0033;
}

.boxcolor a.blue.active {
    background: #ee0033;
}

.boxcolor a.yellow span {
    background: #fe9a00;
}

.boxcolor a.yellow.active {
    background: #fe9a00;
}

.boxcolor a.gray span {
    background: #576c8a;
}

.boxcolor a.gray.active {
    background: #576c8a;
}

.ghtime .ghbox {
    float: left;
    width: 100%;
    background: #ee0033;
    border-radius: 10px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    padding: 18px 0;
}

.ghtime {
    margin: 0 0 10px;
}

.ghtime .ghbox span {
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    display: block;
}

.ghtime .timebox {
    background: #fff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    float: right;
    width: 48%;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #0c0033;
    padding: 17px 0;
}

.ghtime .timebox span {
    color: #696969;
}

.ghtime .timebox span {
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    display: block;
    font-weight: 500;
}

.text-or {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.udsl {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    margin: 10px 0 30px;
}

.udsl h2 {
    color: #ee0033;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    padding: 0 0 10px;
}

.udsl ul li {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #0c0033;
    position: relative;
    padding: 2px 0 2px 25px;
    font-weight: bold;
}

.udsl ul li:before {
    color: #fe9a00;
    position: absolute;
    left: 0;
    top: 2px;
    font-family: "icon-theme";
    content: "\e920";
    font-weight: 400;
}

div.action button {
    font-style: normal;
    color: #fff;
}

div.action .box-button button.btn-buy {
    background: #fe9a00;
    font-family: Montserrat;
    border-radius: 10px;
    width: 83%;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 52px;
    float: left;
}

div.action .box-button button.add-cart {
    color: #ee0033;
    font-weight: bold;
    font-size: 16px;
    line-height: 52px;
    background: none;
}

.btn-wishlist {
    background: transparent;
}

.action .add-cart i {
    padding: 0 8px 0 0;
    font-weight: normal;
}

div.action .box-button {
    margin: 0 0 24px;
}

div.action button.btn-chosse {
    background: #ffffff;
    border: 1px solid #ee0033;
    box-sizing: border-box;
    border-radius: 10px;
    width: 83%;
    text-align: center;
    color: #ee0033;
    font-size: 20px;
    line-height: 52px;
    font-weight: normal;
}

.image-box {
    width: 57.3%;
}

.product-hot .banner-slideshow .owl-nav {
    font-size: 22px;
}

.product-hot .banner-slideshow .owl-nav button.owl-prev {
    left: 20px;
}

.product-hot .banner-slideshow .owl-nav button.owl-next {
    right: 20px;
}

.product-hot .banner-slideshow .owl-dots {
    display: none;
}

.tech {
    width: 40.1%;
}

.tech h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin: 0 0 20px;
}

div.tech {
    margin: 0 0 30px;
}

div.tech .tbl-tech th,
div.tech .tbl-tech td {
    line-height: 180%;
    font-size: 16px;
}

.tbl-tech {
    margin: 0 0 20px;
}

div.tech .tbl-tech th {
    color: #576c8a;
    width: 35.66%;
    text-align: left;
    font-weight: bold;
}

div.tech .tbl-tech td {
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    color: #0c0033;
}

a.view-detail {
    border: 1px solid #ee0033;
    box-sizing: border-box;
    border-radius: 10px;
    display: block;
    color: #ee0033;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 52px;
    text-align: center;
}

.product-detail .more-link {
    padding: 50px 25px 0;
}

.product-detail .more-link a {
    font-size: 20px;
    font-weight: bold;
    width: 29.33%;
    text-align: center;
    display: block;
    float: left;
    color: #0c0033;
    margin: 0 2%;
}

.product-detail .more-link i {
    font-weight: 400;
    color: #ee0033;
    display: block;
    font-size: 38px;
    height: 38px;
    margin: 0 0 23px;
}

.product-detail .more-link .icon-video {
    font-size: 30px;
    margin: 4px 0 23px;
}

.product-detail .more-link .icon-cycle {
    font-size: 40px;
}

.product-detail__banner {
    margin-top: 10px;
    float: left;
    width: 100%;
    text-align: right;
}

.product-detail__banner a {
    display: inline-block;
    box-shadow: 0px 5px 30px rgba(177, 186, 201, 0.2);
    border-radius: 10px;
}

.product-detail__banner a img {
    display: block;
    width: 100%;
}

.tabs-detail {
    margin: 0 0 40px;
    color: #474747;
    font-size: 16px;
    line-height: 180%;
}

.tabs-d {
    border-left: 2px solid #e7e7e7;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    margin: 0 0 30px;
}

.tabs-d li {
    float: left;
    line-height: 52px;
    border: 2px solid #e7e7e7;
    text-align: center;
    min-width: 215px;
    border-left: 0;
}

.tabs-d li:first-child {
    border-radius: 10px 0 0 10px;
}

.tabs-d li:last-child {
    border-radius: 0 10px 10px 0;
}

.tabs-d li a {
    font-size: 16px;
    color: #576c8a;
}

.tabs-detail .detail-content p.t-cen > a {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.view-start {
    width: 31.5%;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.view-startr {
    width: 65.8%;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.d-view {
    margin: 0 0 30px;
}

.start-inner {
    text-align: center;
    padding: 20px 20px 24px;
}

.start-inner h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin: 0 0 12px;
}

div.start-inner div.starts {
    font-family: "Montserrat";
    font-size: 36px;
    line-height: 54px;
    color: #ee0033;
    margin: 0 0 12px;
    font-weight: 700;
}

div.start-inner div.starts i {
    color: #ffd500;
    font-size: 24px;
    padding: 0 0 0 5px;
}

div.start-inner p a {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #576c8a;
    margin: 0 8px;
}

.view-startr-inner {
    padding: 20px 40px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.view-startr-inner dl {
    width: 48%;
    vertical-align: middle;
}

.view-startr-inner dl dt {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
}

.view-startr-inner dl dt {
    width: 20%;
    clear: left;
    float: left;
}

.view-startr-inner dl dd {
    width: 80%;
    float: left;
    margin: 7px 0 0;
}

.view-startr-inner dl dd .run {
    background: #e7e7e7;
    border-radius: 6px;
    height: 6px;
    width: 100%;
    position: relative;
}

.view-startr-inner dl dd .run .runing {
    position: absolute;
    top: 0;
    left: 0;
    background: #ee0033;
    border-radius: 6px;
    height: 6px;
}

.view-startr-inner .space {
    width: 4%;
    font-size: 0%;
    line-height: 0;
}

.linksend {
    width: 48%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.linksend label {
    color: #474747;
    font-size: 16px;
    line-height: 180%;
}

.linksend .rating-summary {
    margin-bottom: 15px;
}

.linksend .rating-summary .rating-result {
    width: auto;
}

.linksend .rating-summary .rating-result:before {
    font-size: 26px;
    height: 26px;
    line-height: 26px;
}

.linksend .rating-summary .rating-result > span:before {
    font-size: 26px;
    height: 26px;
    line-height: 26px;
}

.send-rate {
    background: #ee0033;
    border-radius: 10px;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 52px;
    text-align: center;
    color: #fff;
    display: inline-block;
}

.vote {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 60px;
}

.vote .navpage {
    margin: -20px 0 20px;
}

.vote .title-vote {
    margin: 0 0 10px;
}

.vote .title-vote h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #0c0033;
    float: left;
}

.vote .title-vote a {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.list-vote .item-vote {
    border-bottom: 1px solid #696969;
    padding: 22px 0;
    font-size: 16px;
    line-height: 180%;
    color: #474747;
}

.list-vote .item-vote:last-child {
    border-bottom: 0;
    margin-bottom: 15px;
}

.list-vote .item-vote p {
    font-weight: bold;
}

.start-v {
    font-weight: bold;
    color: #576c8a;
    font-size: 14px;
    line-height: 20px;
}

.start-v .fb-like {
    padding: 0 3px 0 0;
}

.start-v > span {
    padding: 0 15px;
}

.start-v .rating-summary .rating-result {
    width: 90px;
}

.start-v .rating-summary .rating-result:before,
.start-v .rating-summary .rating-result > span:before {
    font-size: 14px;
    letter-spacing: 5px;
}

.list-vote .item-vote h3 {
    margin: 0 0 5px;
}

.list-vote .item-vote h3 a {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
}

.list-vote .item-vote .start-v a {
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
}

.vote textarea {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 160px;
    margin: 0 0 30px;
}

.bot-vote a {
    border-radius: 10px;
    color: #fff;
    line-height: 52px;
    background: #ee0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 132px;
    display: inline-block;
}

h2.ss {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    margin: 0 0 25px;
}

div.cart {
    margin: 0 0 30px;
}

.col-cartl {
    width: 65.8%;
}

.cart-sim .cart-inner {
    padding: 0;
    display: table;
    width: 100%;
    margin: 0 0 20px;
}

.cart-sim .cart-infor {
    padding: 30px 30px 10px;
}

.cart-sim .cart-inner > div {
    display: table-cell;
    vertical-align: middle;
}

.cart-sim .cart-img {
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 54px;
    width: 35%;
    color: #fff;
}

.cart-sim .bg-orange {
    background: #ffba23;
}

.cart-sim .tit-cart h2 {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 10px;
}

.col-cartr .action .btn {
    margin: 0 0 30px;
    display: block;
}

.cart-data {
    font-size: 16px;
}

.cart-data a {
    color: #0c0033;
    font-weight: bold;
}

.cart-sim div.pk {
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    padding: 60px 0 0 0;
}

.cart-mobile .cart-inner {
    margin-bottom: 25px;
}

.cart-mobile .box-price {
    margin: 0;
}

.cart-inner {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
}

.cart-img {
    width: 31%;
    text-align: center;
}

.tit-cart {
    margin: 0 0 10px;
}

.tit-cart h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
}

.tit-cart .box-price {
    text-align: right;
}

.tit-cart .box-price .regular-price {
    font-size: 20px;
    line-height: 23px;
    display: block;
}

.tit-cart .box-price .old-price {
    font-size: 14px;
    line-height: 24px;
}

.cart-infor .qt span.min {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 18px;
    font-weight: 300;
    color: #576c8a;
}

.cart-infor div.qt input.txt-qt {
    color: #ee0033;
    width: 40px;
    height: 28px;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    border-width: 1px;
    margin: 0;
    padding: 0;
    text-align: center;
}

.cart-infor .qt .plush {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 0px 6px 6px 0px;
    margin: 0 35px 0 0;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: #576c8a;
}

.cart-infor .qt a {
    font-size: 14px;
    line-height: 28px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
    margin: 0 16px 0 0;
}

.cart-infor .qt a:hover {
    color: #ff0048;
}

.t-right {
    text-align: right;
}

.col-cartr {
    width: 31.5%;
}

.cartr-inner {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    margin: 0 0 40px;
    padding: 28px;
}

.tbl-cal {
    width: 100%;
}

.tbl-cal th,
.tbl-cal td {
    font-size: 16px;
    line-height: 180%;
    padding: 10px 0;
}

.tbl-cal th {
    color: #576c8a;
    text-align: left;
    font-weight: normal;
    width: 140px;
}

.tbl-cal td {
    color: #0c0033;
}

.tbl-cal td strong {
    display: block;
    font-size: 20px;
}

.tbl-cal td span.note {
    color: #576c8a;
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}

.tbl-cal .grand-total th,
.tbl-cal .grand-total td {
    padding-bottom: 0;
}

.txt-center a {
    margin: 0 5px;
}

.txt-center a.edit {
    color: #ee0033;
}

.cartr-inner td {
    font-weight: 500;
}

.cartr-inner h3 {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    padding: 0 0 15px;
}

.cartr-inner .inf h3 {
    padding: 0 0 5px;
    text-align: left;
}

.cartr-inner .delete {
    color: #ff0048;
}

.infor-cart {
    padding: 0 0 20px;
}

tr.grand-total td,
tr.grand-total th,
.cart-total td,
.cart-total th {
    border-top: 1px solid #696969;
    padding: 20px 0;
}

.cart-mobile .cart-total td,
.cart-mobile .cart-total th {
    border-top: 0;
    padding-top: 0;
}

.tbl-cal thead tr:last-child td,
.tbl-cal thead tr:last-child th {
    border-bottom: 1px solid #696969;
}

.tbl-cal tbody tr:first-child td,
.tbl-cal tbody tr:first-child th {
    padding-top: 20px;
}

.tbl-cal thead tr:last-child td,
.tbl-cal thead tr:last-child th,
.tbl-cal tbody tr:last-child td,
.tbl-cal tbody tr:last-child th,
.tbl-cal tfoot tr:last-child td,
.tbl-cal tfoot tr:last-child th {
    padding: 20px 0;
}

.tbl-cal tbody tr:last-child td,
.tbl-cal tbody tr:last-child th {
    padding-top: 10px;
}

.v-top td,
.v-top th {
    vertical-align: top;
}

.l-gtgt a {
    color: #0c0033;
}

.tbl-cal tfoot tr.note td {
    padding: 0;
}

.pathwayor {
    margin-top: 48px;
}

.h1-title-cart {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0c0033;
    margin: 40px 0 60px;
}

.h1-title-cart span {
    display: block;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #576c8a;
    font-family: "Roboto";
    text-transform: none;
}

.frm-cart h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.col-cartr a.btn-blue {
    display: block;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
}

h2.title-h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    margin: 0 0 20px;
}

h2.title-cart {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    margin: 0 0 40px;
}

h3.title-h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #576c8a;
    margin: 0 0 40px;
}

h1.h1-title span {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #576c8a;
}

.frm-cart .row-frm label,
.address label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
    display: block;
    margin: 0 0 14px;
}

.method .radio-custom1 input:checked ~ .checkmark ~ span {
    font-weight: 400;
}

.address label {
    font-size: 18px;
}

.method-ship .checkbox {
    font-size: 20px;
    padding-top: 2px;
    padding-left: 30px;
    margin: 0 0 30px;
}

.method-ship .checkbox .checkmark {
    box-shadow: none;
}

.frm-cart h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0c0033;
}

.frm-cart .personal h4 {
    border-radius: 10px;
}

.frm-cart .row-frm {
    margin: 0 0 30px;
}

.frm-cart .row-frm .radio-custom1 {
    font-size: 20px;
    font-weight: 400;
    padding: 0 0 0 30px;
    margin: 0;
}

.row-frm .control {
    position: relative;
}

.row-frm .control .delete-text {
    position: absolute;
    right: 12px;
    top: 14px;
    color: #576c8a;
    font-size: 22px;
    cursor: pointer;
}

.row-frm .control .delete-text i {
    font-weight: 400;
}

.method .left {
    width: 45%;
}

.per .rating-summary .rating-result:before {
    content: "\e92c""\e92c""\e92c""\e92c""\e92c";
    font-size: 15px;
}

.per .right {
    padding: 8px 0 0 0;
}

.count-rating {
    font-weight: 400;
    padding: 2px 0 0 6px;
    display: inline-block;
}

.per .address {
    position: absolute;
    right: 30px;
    bottom: 43px;
    font-size: 60px;
    color: #ee0033;
}

.per {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 30px;
    font-size: 16px;
    font-weight: bold;
    color: #576c8a;
    position: relative;
}

.i-add {
    padding: 13px 0;
}

.i-add i {
    padding: 0 8px 0 0;
    font-size: 18px;
}

.i-add:last-child {
    padding-bottom: 0;
}

.per h5 {
    font-size: 24px;
    line-height: 28px;
    color: #ee0033;
    font-weight: 400;
    margin: 0 0 10px;
}

.cf {
    width: 48%;
}

.agree {
    margin: 0 0 60px;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.agree a {
    color: #ee0033;
    font-style: normal;
}

.methor h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
}

.col-m {
    width: 48%;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.col-cartr .back {
    margin: 30px 0 0;
}

a.back {
    border: 2px solid #ee0033;
    box-shadow: 0px 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    display: block;
    padding: 19px 0 19px;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ee0033;
}

.infor-cart .img {
    width: 20.5%;
}

.inf {
    color: #0c0033;
    line-height: 180%;
    font-size: 16px;
    width: 76.66%;
}

.inf h3 a {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #0c0033;
}

.inf p a {
    font-size: 14px;
    line-height: 24px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
    margin: 0 16px 0 0;
}

.inf p a:hover {
    color: #ff0048;
}

.goi-dich-vu .age-price {
    margin: 40px 0 0;
    padding: 0 0 15px;
}

.goi-dich-vu .ll-gg .title {
    margin: 0 0 25px;
}

.goi-dich-vu .ll-gg.list-package .title {
    margin: 0;
}

.goi-dich-vu .ll-gg p {
    font-size: 20px;
    padding: 5px 0;
}

.goi-cuoc .tien img {
    border-radius: 10px;
}

/*add 14/10*/

.age-price {
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0 0 30px;
}

.sub-tabs-gc p {
    margin: 0 0 25px;
}

.goi-dich-vu div.list-package {
    margin: 0 0 40px;
    padding: 0;
}

.goi-dich-vu .ll-gg p i {
    margin: 0;
}

.goi-dich-vu .slider .owl-nav button.owl-prev {
    margin-top: -28px;
}

.goi-dich-vu .slider .owl-nav button.owl-next {
    margin-top: -28px;
}

.package .col-p {
    width: 33.3%;
    text-align: center;
}

.package .col-p.item,
.package .col-p.item:hover {
    width: 100%;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0;
    border-left: 1px solid #ededed;
    border-radius: 0;
}

.list-package .lists.slider .owl-carousel {
    padding: 0;
}

.list-package .lists.slider:before {
    content: "";
    position: absolute;
    background: #fff;
    width: 1px;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.package .lists.slider {
    margin: 0;
}

.package .owl-carousel .owl-dots {
    display: none !important;
}

.package .owl-carousel .owl-stage-outer {
    margin: 0;
    padding: 20px 0;
}

.package .op .radio-custom1 {
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
    padding-left: 30px;
}

.package .radio-custom1 span {
    font-weight: 500;
}

.package .op p {
    font-size: 20px;
    line-height: 23px;
}

.tabs-gc .tab-title {
    text-align: center;
    display: block;
}

.list-sim-so .tabs-gc {
    margin: 0 0 30px;
}

.list-sim-so .tabs-gc .tab-title li a {
    margin: 0 32px;
}

.tabs-gc .tab-title li {
    float: none;
    display: inline-block;
}

.min-maxd h2.t-detail {
    color: #0c0033;
}

.dxgc {
    padding: 40px;
}

.t-share {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0c0033;
    margin: 0 0 25px;
}

.t-title {
    background: rgba(20, 175, 180, 0.15);
    border-radius: 10px 10px 0px 0px;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #ee0033;
    padding: 17px 0;
    margin: 0 0 25px;
}

.tabs-pop ul {
    overflow: hidden;
    margin: 10px 0 0px;
}

.tabs-pop ul li {
    float: left;
    width: 33.3%;
}

.tabs-pop ul li a {
    padding: 13px 0;
    border: 1px solid #eef1f7;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ee0033;
    display: block;
}

.tabs-pop ul li.active a {
    font-weight: bold;
    background: #ee0033;
    color: #fff;
    border: 1px solid #eef1f7;
}

.tabs-pop ul li a:hover {
    border: 1px solid #eef1f7;
}

.tabs-pop ul li:first-child.active a {
    border-radius: 10px 0 0px 10px;
}

.tabs-pop ul li:last-child.active a {
    border-radius: 0px 10px 10px 0px;
}

.dxgc {
    font-size: 16px;
    line-height: 180%;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
}

.tbl-gc {
    width: 100%;
}

.tbl-gc td {
    line-height: 180%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid #eef1f7;
    border-bottom: 1px solid #eef1f7;
}

.tbl-gc tr:last-child td {
    border-bottom: none;
}

.tbl-gc td.td-last {
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    padding-left: 15px;
    border-right: none;
    width: 150px;
}

.main-detail .min-maxd a.btn-blue {
    position: static;
}

.main-detail .goi-cuoc h2 {
    text-transform: uppercase;
    color: #0c0033;
    line-height: normal;
}

.pack-detail h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    margin: 30px 0;
}

h2.space1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    margin: 0 0 30px 0;
}

.main-detail .detail-info {
    margin: 20px 0 30px;
}

.main-detail.detail-cuoc .min-maxd a.btn-blue {
    min-width: 260px;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.footer {
    background: #ee0033;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #ffffff;
    padding: 25px 0px 20px;
    font-style: normal;
    font-weight: normal;
}

.footer p {
    margin: 0px;
}

.footer p i {
    display: inline-block;
}

.footer i {
    font-weight: normal;
}

.hotline-footer {
    display: inline-block;
}

.hotline-footer i {
    display: inline-block;
    margin: 2px 5px 0 0;
    font-size: 18px;
    float: left;
}

ul.link-social {
    margin: 3px 0 0 0;
}

.footer p img {
    vertical-align: middle;
}

.link-social li {
    display: inline;
    padding: 0px 3px;
}

.link-social li a {
    color: #fff;
    font-size: 20px;
}

.support-all {
    padding: 0;
    text-align: right;
    position: fixed;
    right: 30px;
    bottom: 30px;
    border-radius: 100%;
    z-index: 100;
}

.support-all img {
    border-radius: 100%;
}

.list-language {
    position: absolute;
    display: none;
    z-index: 8;
    top: 100%;
    background: #fff;
}

.box-language {
    cursor: pointer;
    padding: 0 12px 0 0;
}

.box-language li {
    display: none;
}

.box-language li.active {
    display: block;
}

.language.open-language .list-language {
    display: block;
}

/* menu */

.off-canvas-toggle {
    color: #fff;
    display: none;
    font-size: 20px;
    z-index: 10;
}

.off-canvas-toggle i {
    display: block;
}

@media only screen and (min-width: 1024px) {
    .row-menu .boxmenu ul li .mega-menu.submenu {
        width: 766px;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu-right {
        right: 0;
        left: auto;
    }
    .row-menu .boxmenu ul li ul.mega-menu li.sub {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 15px 0;
    }
    .row-menu .boxmenu ul li ul.submenu.mega-menu li a {
        padding-left: 35px;
        padding-right: 20px;
    }
    .mega-menu .sub-item {
        width: 24%;
    }
    .sub-item:last-child {
        width: 44%;
    }
    .mega-menu .sub-item:first-child {
        width: 32%;
    }
}

@media only screen and (min-width: 1350px) {
    .goi-dich-vu .slider .owl-nav button.owl-prev {
        margin-top: -28px;
        left: -91px;
    }
    .goi-dich-vu .slider .owl-nav button.owl-next {
        margin-top: -28px;
        right: -91px;
    }
}

@media only screen and (min-width: 1170px) {
    .row-menu .boxmenu ul li:hover > a {
        background: #e9b6c1;
        color: #ee0033;
    }
    .row-menu .boxmenu ul li:hover .submenu {
        display: block;
    }
    .row-menu .boxmenu ul li ul.submenu li:hover > a {
        color: #ee0033;
        background: #f3f3f3;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1419px) {
    .login-register .sub-tk {
        left: auto;
        right: 100%;
    }
    .login-register .sub-tk .sub-inner-tk {
        margin: 0 20px 0 0;
    }
    .sub-tk .sub-inner-tk:before {
        left: auto;
        right: -6px;
    }
    .login-register ul li .sub-regis .inner-sub-register .row-item.parent a:after {
        left: 0;
        right: auto;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@media only screen and (max-width: 1169px) {
    .grild-product .owl-nav button.owl-prev {
        left: -18px;
    }
    .grild-product .owl-nav button.owl-next {
        right: -18px;
    }
    .grild-product .owl-nav button {
        width: 50px;
        height: 50px;
    }
    .grild-product li .imgages-p {
        height: 245px;
    }
    h2.ss {
        margin-bottom: 35px;
    }
    h1.h1-title {
        padding: 30px 0 0 36.8%;
    }
    .col-left-inner {
        padding: 20px;
    }
    .list-product li a:before,
    .list-type li a:before {
        margin-top: 1px;
    }
    .list-product li a:hover:after,
    .list-type li a:hover:after,
    .list-product li a.active:after,
    .list-type li a.active:after {
        top: 1px;
    }
    .list-product li,
    .list-type li {
        font-size: 16px;
    }
    .col-left {
        width: 295px;
    }
    .col-right {
        width: calc(100% - 305px);
    }
    .list-color li.color {
        width: 34px;
        height: 34px;
    }
    .mess-top div.ms {
        font-size: 32px;
    }
    .apply .sub-tabs .subleft .intro h2 a,
    .viettel-plush .viettel-content div.right h2 a,
    .dv-internet .intro-more h2 {
        font-size: 24px;
    }
    .introqt h2,
    .inf h3 a,
    h2.title-h2,
    .methor h3 {
        font-size: 20px;
    }
    h1,
    .page-bot .support ul li a {
        font-size: 18px;
    }
    .apply .sub-tabs .subright .items .text h3 a {
        font-size: 16px;
    }
    .sort-by {
        padding-bottom: 40px;
    }
    .row-menu .boxmenu ul li a {
        font-size: 14px;
        padding: 15px 15px;
    }
    .mess-top div.ms {
        padding: 25px 0;
        line-height: 1.3;
    }
    .apply .sub-tabs .subright .items .text {
        padding: 0 56px 10px 30px;
    }
    .page-bot .support h1 {
        margin: 0 0 20px;
    }
    .viettel-plush {
        margin: 0px 0px 45px;
    }
    .viettel-plush .viettel-content > div.right {
        padding: 30px 0 0 0;
    }
    .viettel-plush .viet-tel,
    .dv-internet {
        margin: 0px 0px 25px;
    }
    .dv-internet .owl-carousel {
        margin-top: -20px;
    }
    .apply {
        margin: 0 0 30px;
    }
    .support-all {
        padding: 15px;
        right: 0;
    }
    .page-bot .support {
        padding: 0 0 40px;
    }
    .apply .sub-tabs .subleft .intro h2 a {
        line-height: 1.3;
        margin: 0 0 15px;
    }
    .apply .sub-tabs .subleft {
        min-height: auto;
    }
    .apply .sub-tabs .subleft .intro {
        padding-bottom: 20px;
    }
    .dv-internet .intro-more {
        padding: 20px 20px 25px;
    }
    .data-content {
        padding: 20px 20px 25px 20px;
    }
    .lists.slider {
        margin: 0 -15px;
    }
    .lists.slider .owl-carousel {
        padding: 0;
    }
    h1.space1 {
        padding: 0 0 30px;
    }
    .grild-product .left,
    .grild-product .right {
        width: 100%;
    }
    .grild-product .infor .btn {
        width: 100%;
    }
    .box-gtgt .list-dt .goi-cuoc .minmax {
        padding: 0 15px;
    }
    .ghtime .timebox,
    .ghtime .ghbox {
        font-size: 22px;
    }
    .box-infor .suport {
        text-align: right;
    }
    .suport span {
        display: block;
        line-height: normal;
    }
    .suport a.tv {
        margin-right: 0;
    }
    .more-text > div {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1169px) {
    .row-top .content .blogo {
        margin-right: 40px;
    }
    .row-top .link-top li a {
        font-size: 12px;
    }
    .list-sim table td {
        font-size: 14px;
    }
    .list-sim table td.font20 {
        font-size: 18px;
    }
    .row-menu .boxmenu ul li:hover > a {
        background: #e9b6c1;
        color: #ee0033;
    }
    .row-menu .boxmenu ul li:hover .submenu {
        display: block;
    }
    .row-menu .boxmenu ul li ul.submenu li:hover a {
        color: #ee0033;
        background: #f3f3f3;
    }
    .row-menu .boxmenu ul li .mega-menu.submenu {
        width: 727px;
    }
    .row-menu .boxmenu ul li ul.submenu.mega-menu li a {
        padding-left: 30px;
        padding-right: 0;
    }
    ul.mega-menu h2 {
        padding-left: 30px;
    }
    .minmax .pr span {
        font-size: 14px;
    }
    .minmax .pr {
        font-size: 20px;
    }
    .hot-key .search1 button.btn-tk {
        margin-left: 15px;
    }
    .txt-hot {
        padding-left: 10px;
    }
    .hot-key div.so span {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .alc-right.error {
        position: static;
        padding: 0;
        width: auto;
    }
    .login-register ul.not-login li > a {
        margin: 0;
        padding: 0 10px;
    }
    .login-register ul.not-login li {
        float: left;
        width: 50%;
        margin: 10px 0;
    }
    .login-register ul.not-login li + li {
        border-left: 1px solid #fff;
    }
    .grild-product li {
        width: 46%;
    }
    .grild-product li:nth-child(3n + 1) {
        clear: none;
    }
    .grild-product li:nth-child(2n + 1) {
        clear: both;
    }
    .banner-slideshow .owl-nav button.owl-prev {
        left: 15px;
    }
    .banner-slideshow .owl-nav button.owl-next {
        right: 15px;
    }
    .goi-cuoc .tien {
        font-size: 70px;
    }
    h1,
    .dv-internet .intro-more h2,
    .inner-item h2 {
        font-size: 24px;
    }
    .ll-gg p {
        font-size: 20px;
    }
    .apply .sub-tabs .subleft .intro h2 a,
    .viettel-plush .viettel-content div.right h2 a,
    .inner-input .font25,
    .select-pay .radio-custom1 span,
    .box-data-cacgoi h2 span {
        font-size: 18px;
    }
    .introqt h2,
    .pathway ul li,
    .col-right .sort,
    .product-list .navpage {
        font-size: 15px;
    }
    .navpage ul li a,
    .navpage ul li strong {
        padding: 0 10px;
        line-height: 30px;
        min-width: 30px;
    }
    .box-data .view {
        margin: 5px 0;
    }
    .introqt .box-dk {
        right: 15px;
    }
    .introqt {
        padding-right: 105px;
    }
    .intro-more h2 i {
        font-size: 12px;
    }
    .page-bot .support ul li a,
    .login-register ul li,
    .row-menu .boxmenu ul li a {
        font-size: 16px;
    }
    .data-content p {
        font-size: 14px;
        min-height: 120px;
    }
    #tab-internet-th ol.list-check {
        min-height: 146px;
    }
    .apply .sub-tabs .subright .items .text {
        position: relative;
    }
    .apply .sub-tabs .subleft {
        width: 100%;
        margin: 0 0 25px;
    }
    .apply .sub-tabs .subright {
        width: 100%;
    }
    .apply {
        margin: 0;
    }
    .dv-internet .intro-more {
        padding: 10px 15px 25px;
    }
    .viettel-plush .viettel-content > div.left {
        width: 35%;
    }
    .viettel-plush .viettel-content > div.right {
        padding: 0;
        width: 62%;
    }
    .viettel-plush .viettel-content div.right h2,
    .viettel-plush .viettel-content div.right h2 a {
        line-height: 1;
    }
    .viettel-plush ul {
        padding: 15px 0;
    }
    .tabs h1.left {
        float: none;
        margin: 0 0 10px;
    }
    .off-canvas-toggle {
        display: block;
    }
    .row-menu {
        display: none;
    }
    .row-top .content {
        text-align: center;
        padding: 15px;
    }
    .row-top .content .blogo {
        display: block;
        margin: 0 auto;
        padding: 0;
    }
    .row-top .language {
        position: absolute;
        right: 15px;
        top: 17px;
        margin: 0;
        width: auto;
        float: none;
    }
    .language i {
        right: 0;
    }
    .row-top .link-top {
        float: none;
        padding: 15px 0 0;
        width: 100%;
    }
    .row-top .link-top li {
        float: none;
        display: inline-block;
    }
    .row-top .search {
        float: none;
        text-align: left;
        display: none;
    }
    .frm-cart .search {
        padding-left: 0;
        padding-right: 0;
    }
    .search {
        padding: 15px 15px 15px;
        border-bottom: 1px solid #fff;
        position: static;
    }
    .search input {
        padding-left: 32px;
        background: #fff;
    }
    .search button {
        left: 24px;
        top: 28px;
    }
    .row-top .link-top li a {
        font-size: 12px;
    }
    .lists.slider {
        margin: 0 -10px;
    }
    .login-register ul li .sub-regis .inner-sub-register .row-item:hover .sub-tk {
        position: static;
        width: auto;
    }
    .sub-tk .sub-inner-tk {
        margin: 0;
        padding: 0;
    }
    ul.mega-sub {
        border-bottom: 1px solid #ee0033;
    }
    /* menu mobile */
    .open .off-canvas-toggle:after {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        z-index: 10;
    }
    .open,
    .open body {
        overflow: hidden;
    }
    .open .row-menu {
        display: block;
        position: fixed;
        left: 0;
        width: 45%;
        top: 0;
        bottom: 0;
        z-index: 999;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .open .row-menu .content {
        padding: 0;
    }
    .open .row-menu .boxmenu ul li {
        float: none;
        border-bottom: 1px solid #fff;
    }
    .open .row-menu .boxmenu > ul > li:first-child {
        border-top: 1px solid #fff;
    }
    .open .boxmenu {
        float: none;
    }
    .row-menu .boxmenu ul li ul.submenu li {
        border-top: 1px solid #ee0033;
        border-bottom: 0;
    }
    .row-menu .boxmenu ul li ul.submenu li a {
        padding: 10px 10px 10px 35px;
    }
    .row-menu .boxmenu ul li:hover .submenu {
        display: none;
    }
    .row-menu .boxmenu ul li.active .submenu,
    .row-menu .boxmenu ul li.open .submenu {
        display: block;
        position: static;
        width: 100%;
    }
    .row-menu .boxmenu .active span.show-sub,
    .row-menu .boxmenu .open span.show-sub {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        top: 10px;
        right: 9px;
    }
    .sub-tk .sub-inner-tk:before {
        display: none;
    }
    .sub-regis .inner-sub-register .row-item.active .show-sub,
    .sub-regis .inner-sub-register .row-item.open .show-sub {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    .login-register ul li .sub-regis .inner-sub-register .row-item .show-sub + a:after,
    .login-register ul li .sub-regis .inner-sub-register .row-item:hover .sub-tk {
        display: none;
    }
    .login-register ul li .sub-regis .inner-sub-register .row-item.active .sub-tk,
    .login-register ul li .sub-regis .inner-sub-register .row-item.open .sub-tk {
        display: block;
        position: static;
    }
    .sub-regis .show-sub {
        position: absolute;
        right: -3px;
        padding: 3px;
        z-index: 4;
        width: auto;
    }
    i.show-sub.icon-next {
        display: block;
    }
    .row-menu .boxmenu span.show-sub,
    .row-menu .boxmenu .open.active span.show-sub {
        position: absolute;
        right: 12px;
        top: 8px;
        padding: 7px 3px;
        color: #fff;
        z-index: 10;
        display: block;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .row-menu .boxmenu ul li.open.active .submenu {
        display: none;
    }
    .row-menu .boxmenu li.open > span.show-sub:before {
        color: #fff;
    }
    .row-menu .boxmenu li.active > span.show-sub:before {
        color: #ee0033;
    }
    .row-menu .boxmenu span.show-sub:before {
        font-family: "icon-theme" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e90e";
    }
    .login-register ul li,
    .row-menu .boxmenu ul li a {
        padding: 15px;
        line-height: normal;
    }
    .login-register ul.open li .sub-regis {
        position: static;
        width: 100%;
        clear: both;
        margin: 0 0 15px;
    }
    .sub-notice {
        position: relative;
        top: auto;
        max-width: 100%;
    }
    .sub-notice:before {
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #f7f7f7;
        border-top: 0;
        left: 130px;
    }
    .login-register {
        float: none;
        height: auto;
    }
    .login-register ul li {
        float: none;
        display: inline-block;
        padding: 0 15px;
        width: 100%;
    }
    .login-register ul > li > div.hello {
        padding-right: 0;
    }
    .login-register ul > li > div.hello span.txt {
        padding: 3px 18px 3px 0;
        width: auto;
        float: left;
        max-width: 86%;
        text-align: left;
        position: relative;
    }
    .login-register .show-sub.icon-down {
        right: 2px;
        top: 4px;
    }
    .login-register ul > li > div.hello span.txt:after {
        margin-top: -8px;
        right: 7px;
    }
    .login-register ul > li > div.hello .icon {
        margin: 14px 0 0 5px;
        position: static;
        float: left;
    }
    .login-register ul li .sub-regis .inner-sub-register:before {
        left: 115px;
        right: auto;
    }
    .mobile-service .item .text-intro span.price,
    .mobile-service .item .text-intro .font-light {
        font-size: 12px;
    }
    .leftsim {
        width: 32%;
    }
    .contentsim {
        width: 65%;
    }
    .list-sim table td.font20 {
        font-size: 18px;
    }
    .list-sim table td {
        font-size: 14px;
    }
    .box-pop {
        margin: 0;
    }
    .msg-sentphone .box-regis-form {
        max-width: none;
    }
    .list-dt .items .goi-cuoc,
    .list-dt .goi-cuoc .tien,
    .list-dt .goi-cuoc .minmax,
    .box-gtgt .list-dt .goi-cuoc .tien,
    .select-data .list-dt .goi-cuoc .tien {
        display: block;
        width: 100%;
        margin: 0;
    }
    .select-data .list-dt .goi-cuoc .minmax {
        padding: 15px 0 0;
    }
    .list-dt .checkbox {
        top: 15px;
    }
    .list-dt .goi-cuoc .minmax {
        padding: 0;
    }
    .list-dt .min-maxd {
        padding: 10px 0;
    }
    .box-tc .list-dt .min-maxd {
        padding-right: 0;
    }
    .list-dt .min-maxd a.btn-blue {
        min-width: 50%;
        position: static;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
        margin: 10px 0 0 0;
    }
    .tit1 ul.tab-title li a {
        font-size: 16px;
        margin: 0;
    }
    .list-sim-so .tabs-gc .tab-title li a {
        margin: 0 15px;
    }
    .goi-dich-vu .age-price {
        margin: 20px 0 0 0;
    }
    .goi-dich-vu .min-maxd h2 {
        font-size: 24px;
    }
    .package .op .radio-custom1,
    .goi-dich-vu .ll-gg p {
        font-size: 16px;
    }
    .goi-dich-vu .ll-gg p i {
        margin: 0;
    }
    .hot-key .search1,
    .txt-hot,
    .cart-sim-so .hot-key .search1,
    .cart-sim-so .txt-hot {
        width: 100%;
        float: none;
    }
    .cart-sim-so .hot-key .search1 input {
        width: 60.1%;
    }
    .hot-key .search1 {
        margin: 0 0 15px;
    }
    .tbl-sim td.name,
    .tbl-sim td.price {
        width: 30%;
    }
    .tbl-simdep.tbl-sim tr,
    .tbl-simdep.tbl-sim th,
    .tbl-simdep.tbl-sim td {
        display: block;
    }
    .tbl-simdep.tbl-sim .col {
        float: left;
    }
    .tbl-simdep.tbl-sim th.col.actions {
        display: none;
    }
    .tbl-simdep.tbl-sim td.col.actions {
        width: 100%;
        float: none;
        clear: both;
        text-align: center;
    }
    .tbl-simdep.tbl-sim a.wishlist {
        float: none;
    }
    .tbl-simdep.tbl-sim .col.stt {
        width: 10%;
    }
    .tbl-simdep.tbl-sim .col.name {
        width: 25%;
    }
    .tbl-simdep.tbl-sim .col.price {
        width: 20%;
    }
    .tbl-simdep.tbl-sim .col.use {
        width: 20%;
    }
    .tbl-simdep.tbl-sim .col.level {
        width: 25%;
    }
    .tbl-simdep.tbl-sim th,
    .tbl-simdep.tbl-sim td {
        font-size: 16px;
        padding: 7px;
    }
    .tbl-simdep.tbl-sim td.color-blue i {
        margin-top: 1px;
    }
    .tbl-simdep.tbl-sim td a.buynow {
        padding: 0 30px;
    }
    .cart-sim div.pk {
        padding: 0;
    }
    .cart-sim .cart-img {
        font-size: 50px;
    }
    .tit-cart > div {
        float: none;
        text-align: left;
        padding: 0 0 15px;
    }
    .tit-cart .box-price {
        text-align: left;
    }
    .methor .radio-custom1 {
        font-size: 18px;
        max-width: 96%;
    }
    .per .right {
        float: none;
        margin: 0 0 20px;
    }
    .frm-cart .row-frm .radio-custom1,
    .method-ship .checkbox {
        font-size: 16px;
    }
    .method-ship .checkbox {
        padding-top: 4px;
    }
    .methor .box-pop .items {
        min-height: 180px;
    }
    .list-news .introtv {
        display: block;
    }
    .list-news .introtv .coll,
    .list-news .box-dk {
        width: 100%;
    }
    .list-news .box-dk {
        text-align: center;
        padding: 15px 0 0 0;
    }
    .list-item .data-content h2 {
        font-size: 24px;
    }
    .data-content div.time {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .col-cartl {
        width: 60%;
    }
    .col-cartr {
        width: 40%;
        padding-left: 20px;
    }
    .per .address {
        bottom: 20px;
    }
    .box-infor .suport {
        text-align: center;
    }
    .suport a.bh {
        display: block;
        float: none;
        margin: 0 0 10px;
    }
    .box-price .regular-price {
        line-height: 1;
    }
    .box-price .note {
        padding: 0;
    }
    div.qt > div {
        display: block;
    }
    div.qt .qty {
        margin: 0 0 20px;
    }
    .box-images,
    .product-detail .more-link {
        padding-left: 0;
        padding-right: 0;
    }
    .ghtime .timebox,
    .ghtime .ghbox {
        font-size: 22px;
        width: 100%;
        margin: 0 0 15px;
    }
    .view-startr-inner,
    .start-inner {
        padding: 15px;
    }
    div.start-inner p a {
        display: block;
    }
    .start-inner h4,
    div.start-inner div.starts {
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {
    .top-detail .rating,
    .top-detail .share {
        float: none;
        margin: 10px 0;
    }
    .share a {
        margin: 0 20px 0 0;
    }
    .box-infor,
    .box-images,
    .view-startr-inner dl,
    .linksend {
        width: 100%;
    }
    .linksend {
        margin: 15px 0 0 0;
    }
    div.start-inner p a {
        display: block;
    }
    .view-start {
        min-height: 231px;
    }
    .view-startr-inner {
        display: block;
    }
    .tbl-simdep.tbl-sim th {
        font-size: 12px;
    }
    .goi-cuoc.goi-dich-vu {
        display: block;
    }
    .goi-cuoc.goi-dich-vu .tien {
        margin: 0 0 10px;
    }
    .goi-cuoc.goi-dich-vu .minmax {
        width: 100%;
        display: block;
        padding: 0;
    }
    .viettel-plush .viettel-content div.right h2,
    .viettel-plush .viettel-content div.right h2 a {
        color: #000;
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0 10px;
    }
    .viettel-plush .viet-tel {
        margin: 0 0 15px;
    }
    .apply .sub-tabs .subleft div.imgs img {
        border-radius: 6px 6px 0 0;
        max-height: 110px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .intro-more .txt-more {
        min-height: 108px;
    }
    .page-bot .support ul li {
        width: 48%;
        margin: 0 0 20px;
    }
    .page-bot .support ul li:nth-child(2n) {
        float: right;
    }
    .page-bot .support ul li a {
        line-height: 42px;
        display: block;
    }
    .mess-top div.ms {
        font-size: 20px;
    }
    .subright .more-view {
        right: 15px;
    }
    .apply .sub-tabs .subright .items div.imgs,
    .apply .sub-tabs .subright .items .text {
        width: 100%;
    }
    .apply .sub-tabs .subright .items div.imgs img {
        border-radius: 12px 12px 0 0;
    }
    .apply .sub-tabs .subright .items .text {
        padding: 0 15px 20px;
    }
    .apply .sub-tabs .subleft .intro {
        padding: 10px 15px 20px;
    }
    .list-sim-so .hot-key div.so span {
        padding: 0 15px;
    }
    .list-sim-so .hot-key div.so {
        margin: 0 -15px;
        width: auto;
    }
    .tbl-sim td.color-blue i {
        margin-top: 0;
    }
    .list-sim-so .tab-title li a {
        margin: 0 20px;
    }
    .tabs .btabs {
        float: none;
    }
    ul.tab-title {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 0 0 10px;
    }
    .tab-title li {
        float: none;
        display: inline-block;
    }
    .tabs-d,
    .tabs-d li:first-child,
    .tabs-d li:last-child {
        border-radius: 0;
    }
    .banner-slideshow .owl-dots {
        bottom: 5px;
    }
    #tab-internet-th .intro-more .txt-dv {
        position: static;
        margin: 20px 0 0;
    }
    #tab-internet-th .top-title {
        margin: 0;
    }
    .leftsim {
        width: 100%;
        margin: 20px 0 0 0;
        text-align: center;
    }
    .contentsim,
    .col-right,
    .col-left {
        width: 100%;
    }
    .product-list .navpage {
        margin-top: 10px;
    }
    .introqt {
        padding-right: 0;
    }
    .introqt h2 {
        font-size: 16px;
    }
    .introqt .box-dk {
        position: static;
        margin: 20px 0 0;
        text-align: center;
    }
    .box-dk a.btn-dk {
        min-width: 50%;
        text-align: center;
    }
    .introtv {
        display: block;
    }
    .introtv .coll {
        width: 100%;
    }
    .introtv .coll .coll-inner {
        padding: 0 0 15px;
    }
    .box-dk {
        width: 100%;
        text-align: left;
    }
    .select-pay .radio-custom1 span,
    .navpage {
        font-size: 14px;
    }
    .box-pop .items {
        width: 32.33%;
        margin: 0 0.5%;
    }
    .navpage ul li:first-child a {
        margin-right: 0;
    }
    .navpage ul li:last-child a {
        margin-left: 0;
    }
    .navpage ul li a,
    .navpage ul li strong {
        padding: 0;
        line-height: 30px;
        min-width: 30px;
    }
    .pathway {
        display: none;
        margin: 15px 0;
    }
    .pathway ul li span {
        padding: 0 5px;
    }
    .pathway ul li {
        display: inline-block;
        padding: 0 0 5px 0;
    }
    .navpage {
        margin: 20px 0;
    }
    .hot-key div.so span {
        padding: 0 15px;
    }
    div.sx div.left > div {
        margin: 0 30px 0 0;
    }
    div.sx div.left > div:last-child {
        margin: 0;
    }
    .tbl-sim td.actions,
    .tbl-sim th,
    .tbl-sim td {
        padding: 12px;
    }
    .tbl-sim,
    .tbl-sim tbody,
    .tbl-sim tr,
    .tbl-sim td,
    .tbl-sim th {
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .tbl-sim th.actions {
        display: none;
    }
    .tbl-sim .col {
        float: left;
    }
    .tbl-sim .col.stt {
        width: 24%;
    }
    .tbl-sim .col.name,
    .tbl-sim .col.price {
        width: 38%;
    }
    .tbl-sim .col.actions {
        width: 100%;
        text-align: center;
    }
    .tbl-sim a.wishlist {
        float: none;
    }
    .tbl-sim td a.buynow {
        padding: 0 30px;
    }
    .col-cartl,
    .col-cartr {
        width: 100%;
        float: none;
    }
    .per .address {
        font-size: 40px;
        bottom: 25px;
    }
    .col3 > .item {
        width: 50%;
        margin: 0 0 25px;
    }
    .dxgc {
        padding: 0;
    }
    div.action .box-button button.btn-buy,
    .tabs-d li {
        width: 50%;
    }
    div.action button.btn-chosse,
    .image-box,
    .tech {
        width: 100%;
    }
    .image-box,
    .vote {
        margin: 0 0 30px;
    }
    .dtfull {
        margin: 15px 0 0 0;
        overflow: hidden;
    }
}

@media only screen and (max-width: 599px) {
    .inner-input p.t-cen.w69 {
        max-width: 100%;
    }
    .page-bot .support {
        padding: 0 0 10px;
    }
    .dn > p {
        min-height: 64px;
    }
    #tab-internet-th .intro-more .txt-dv {
        position: absolute;
        margin: 0;
    }
    .dv-internet #tab-internet-th .intro-more h2 {
        margin: 0 0 15px 0;
    }
    h1,
    .dv-internet .intro-more h2,
    .intro-more .txt-dv span {
        font-size: 15px;
    }
    .viettel-plush .viettel-content > div.left,
    .viettel-plush .viettel-content > div.right {
        width: 100%;
    }
    a.rg,
    b.rg {
        padding: 0 30px;
    }
    .intro-more .more-c a {
        display: block;
        padding: 12px 20px;
    }
    .tab-title li a,
    .list-dt .min-maxd h2 span,
    .min-data {
        font-size: 14px;
    }
    .footer {
        font-size: 13px;
    }
    .banner-slideshow .owl-nav {
        font-size: 20px;
    }
    .banner-slideshow .owl-nav button.owl-prev {
        left: 10px;
    }
    .banner-slideshow .owl-nav button.owl-next {
        right: 10px;
    }
    .row-top .link-top li {
        margin: 0 5px;
        font-size: 10px;
    }
    .product-detail h1 {
        font-size: 28px;
    }
    .goi-cuoc {
        display: block;
        margin: 0 0 15px;
    }
    div.cg {
        padding-bottom: 0;
    }
    .sort-by .filter-left {
        margin: 0 0 15px 18px;
        float: none;
    }
    span.title-filter {
        float: left;
        margin: 16px 8px 0 18px;
    }
    .sort-by .box-filter.filter {
        float: none;
    }
    .order-sort {
        width: calc(100% - 114px);
    }
    .minmax .pr span,
    .list-dt .min-maxd h2 span {
        display: block;
    }
    .list-dt .min-maxd a.btn-blue {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        min-width: 100%;
    }
    .hot-key div.so span {
        padding: 0 10px;
    }
    .tbl-sim td.color-blue i {
        margin-top: 0;
    }
    .tbl-simdep.tbl-sim .col.stt {
        display: none;
    }
    .tbl-simdep.tbl-sim .col.name {
        width: 35%;
    }
    .tbl-simdep.tbl-sim .col.use,
    .tbl-simdep.tbl-sim .col.level {
        line-height: 1;
    }
    .tbl-simdep.tbl-sim th {
        height: 40px;
    }
    .sx .radio-custom1 .checkmark {
        top: -2px;
    }
    div.sx div.left {
        float: none;
    }
    .sx .viewall {
        margin: 10px 0 0 0;
    }
    .methor .box-pop {
        margin-bottom: 40px;
    }
    .sub-notice i.show-sub.icon-i-next {
        right: 10px;
    }
    .item-notice .img {
        width: 40px;
    }
    .notice-r {
        padding: 0 0 0 55px;
    }
    .item-notice .comment {
        font-size: 14px;
        line-height: 1.2;
        padding: 6px 0 0 0;
    }
    .tabs-pop ul li a {
        border-radius: 0 !important;
    }
    .view-start {
        width: 100%;
        margin: 0 0 20px;
        min-height: unset;
    }
    .view-startr,
    .ghtime .timebox,
    .ghtime .ghbox,
    .tabs-d li,
    .tabs-pop ul li {
        width: 100%;
    }
    div.action .box-button {
        margin-bottom: 15px !important;
    }
    div.action .box-button button.btn-buy {
        width: calc(100% - 60px);
    }
    .ghtime .ghbox {
        margin: 0 0 20px;
    }
    .box-infor .suport,
    div.action .box-button {
        text-align: center;
    }
    .box-price .regular-price {
        line-height: 1;
    }
    .box-price .note,
    .box-images,
    .product-detail .more-link {
        padding: 0;
    }
    .product-detail .more-link a {
        font-size: 15px;
    }
    div.qt > div {
        display: block;
    }
    div.qt .qty {
        margin: 0 0 20px;
    }
    .list-vote .item-vote:last-child,
    div.action .box-button {
        margin: 0;
    }
    .udsl {
        padding: 15px;
    }
    .vote {
        padding: 15px;
        margin: 0 0 20px;
    }
    .vote .title-vote {
        text-align: center;
    }
    .vote .title-vote h4 {
        float: none;
        padding: 0 0 20px;
    }
    .vote .title-vote a,
    .start-v .rating {
        float: none;
    }
    .start-v > span {
        padding: 0 15px 0 0;
    }
    .bot-vote a {
        padding: 0;
        width: 100%;
    }
    .vote textarea {
        margin: 0 0 10px;
    }
    .box-price .regular-price {
        display: block;
        font-size: 28px;
    }
    .img-thumb {
        margin: 25px 0;
    }
    .col-right div.sort .search {
        margin: 0;
    }
    .box-login {
        margin: 30px auto;
    }
    .box-login .field label {
        float: none;
    }
    .box-login .field .control {
        padding-left: 0;
        margin: 10px 0 0;
    }
    .select-accounts {
        margin: 0 0 5px;
    }
    .field.actions {
        margin: 20px 0 0 0;
    }
    p.t-cen.actions .btn {
        min-width: 135px;
    }
    .field.actions .btn {
        width: 100%;
    }
    .box-login .action-login {
        margin: 16px auto;
    }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
    #tab-internet-th ol.list-check {
        min-height: 194px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
    .data-content h2 {
        font-size: 20px;
    }
    .int {
        font-size: 18px;
    }
    .data-content div.time {
        font-size: 16px;
    }
    .data-content {
        padding: 10px 10px 15px 10px;
    }
    .int p {
        font-size: 14px;
    }
    .introqt h2 {
        font-size: 13px;
    }
    .introqt {
        padding: 0 10px 15px;
    }
    .box-pop .items {
        width: 47%;
        margin: 0 1.5% 10px;
    }
    .inner-item h2 i {
        right: 20px;
    }
}

@media only screen and (max-width: 479px) {
    #select-accounts h2 {
        font-size: 16px;
    }
    .check-acc:after {
        right: 12px;
        top: 12px;
    }
    .item-accounts a.mulit:before,
    .item-accounts:first-child a:before {
        right: 10px;
        top: 15px;
    }
    .item-accounts .address {
        font-size: 13px;
    }
    .item-accounts a.check-acc {
        padding: 15px;
        margin: 15px 0;
        font-size: 18px;
    }
    .item-accounts .address i {
        font-size: 16px;
        padding-right: 8px;
        padding-bottom: 6px;
    }
    div.icon-continue {
        right: 118px;
        top: 14px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 11px;
    }
    p.t-cen.actions .btn {
        min-width: unset;
    }
    .suport a.bh {
        display: block;
        float: none;
        margin: 0 0 10px;
    }
    .page-bot .support ul li a {
        font-size: 14px;
        font-weight: 500;
    }
    .page-bot .support ul li img {
        max-height: 130px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .apply h1.left {
        float: none;
        margin: 0 0 10px;
    }
    .apply .tit .btabs {
        margin-left: 0;
    }
    .tabs h1 {
        font-size: 13px;
    }
    .contentsim tr,
    .contentsim tbody,
    .list-sim table td.font20 {
        display: block;
    }
    .list-sim table td.font20 {
        padding-top: 0;
    }
    .list-sim table td {
        display: block;
        width: 50%;
        float: left;
        padding: 10px 0;
    }
    .list-sim table td.font20,
    .list-sim table td.td-right {
        text-align: center;
        float: none;
        width: 100%;
        clear: both;
    }
    .list-sim table tr:first-child td {
        padding-top: 10px;
    }
    .contentsim tr {
        margin: 0 0 10px;
    }
    .list-sim table td.font20,
    .product-detail h1 {
        font-size: 22px;
    }
    a.buynow {
        min-width: 150px;
    }
    .box-pop .items {
        width: 97%;
        min-height: auto;
        margin: 0 1.5% 10px;
    }
    .detail-cuoc .min-maxd a.btn-blue {
        width: 35%;
        padding-left: 0;
        padding-right: 0;
        min-width: unset;
    }
    .navpage ul li:first-child a,
    .navpage ul li:last-child a {
        display: none;
    }
    .list-dt .items,
    .col3 > .item {
        width: 100%;
    }
    .list-dt .items.right {
        margin: 30px 0 0;
    }
    .hot-key .search1 button.btn-tk {
        margin-left: 10px;
        padding: 11px 15px;
    }
    .txt-hot {
        text-align: center;
    }
    .hot-key div.so {
        display: block;
        text-align: center;
    }
    div.sx div.left {
        display: block;
    }
    div.sx div.left > div {
        display: inline-block;
    }
    div.sx div.left > div.order-by {
        margin: 15px 0 0;
        display: block;
    }
    .sx .viewall {
        margin: -19px 0 0 0;
    }
    .tbl-sim td.actions,
    .tbl-sim th,
    .tbl-sim td {
        padding: 11px 5px;
        font-size: 14px;
    }
    .tbl-sim .col.stt {
        width: 14%;
    }
    .tbl-sim .col.name,
    .tbl-sim .col.price {
        width: 43%;
    }
    .tbl-sim td.color-blue i {
        margin-right: 5px;
        font-size: 10px;
    }
    .tbl-sim td a.buynow {
        padding: 0 20px;
        line-height: 32px;
        min-width: unset;
    }
    .tbl-simdep.tbl-sim th,
    .tbl-simdep.tbl-sim td {
        font-size: 11px;
        padding: 12px 7px;
        line-height: 1 !important;
        height: 32px;
    }
    .tbl-simdep.tbl-sim td.col.actions {
        height: auto;
    }
    .tbl-simdep.tbl-sim th.col {
        height: 45px;
    }
    .tbl-simdep.tbl-sim td.color-blue i {
        margin-top: 2px;
        font-size: 8px;
        line-height: 1;
    }
    .list-sim-so .hot-key .search1 button.btn-tk {
        width: 110px;
    }
    .list-sim-so .hot-key .search1 input {
        width: calc(100% - 127px);
    }
    .list-sim-so .tab-title li a {
        margin: 0 10px;
        font-size: 16px;
    }
    .nav-midle .nav,
    .owl-nav button,
    .slick-arrow {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
    }
    .cart-sim .cart-infor,
    .cartr-inner {
        padding: 15px;
    }
    .cart-sim .cart-img {
        font-size: 35px;
    }
    .cart-sim .tit-cart h2 {
        font-size: 25px;
    }
    .methor .box-pop .items {
        width: 96%;
    }
    .methor .box-pop .items + .items {
        margin-top: 20px;
    }
    .method .left {
        width: 100%;
        margin: 0 0 10px;
    }
    .frm-cart .method .row-frm {
        margin: 0;
    }
    .frm-cart .row-frm .cf.right {
        margin: 20px 0 0 0;
    }
    .h1-title-cart {
        margin-bottom: 30px;
    }
    .method-ship .checkbox {
        margin: 0 0 15px;
    }
    .more-text > div {
        padding: 10px;
        font-size: 10px;
    }
}

@media only screen and (max-width: 374px) {
    .mess-top div.ms br {
        display: none;
    }
    #tab-internet-th ol.list-check {
        min-height: 178px;
    }
    .intro-more .txt-more {
        min-height: 148px;
    }
    .top-title {
        margin: 0;
    }
    .h1-title-cart {
        font-size: 22px;
    }
    .box-login .capcha input.txt-code,
    .box-login .box-capcha {
        width: 100%;
        margin-left: 0;
    }
    p.t-cen.actions .btn {
        margin: 0 0 10px;
        min-width: 100%;
    }
}

.db-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.hhv-row-field {
    margin: 0 -15px;
}

.hhv-col50 {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.hhv-col25 {
    width: 25%;
    float: left;
    padding: 0 15px;
}

.hhv-col20 {
    width: 20%;
    float: left;
    padding: 0 15px;
}

.hhv-col33 {
    width: 33.33%;
    float: left;
    padding: 0 15px;
}

.fs24 {
    font-size: 24px;
}

.fs20 {
    font-size: 20px;
}

.fs16 {
    font-size: 16px;
}

.fs14 {
    font-size: 14px;
}

.field.full label {
    display: block;
    width: 100%;
    float: none;
    margin-bottom: 5px;
}

.field.full .control {
    width: 100%;
    float: none;
    padding: 0;
}

.field-error {
    border-color: #ff0000;
}

textarea:focus,
input:focus,
select:focus {
    outline: 0;
}

.hhv-hide {
    display: none;
}

#service-register a.btn {
    min-width: 230px;
}

.hhv-mobile-service .title-box {
    position: relative;
    z-index: 10;
}

.hhv-mobile-service .title-box h2.space1 {
    margin-bottom: 0;
}

.hhv-mobile-service .inner-item h2 {
    padding: 15px 10px;
}

.hhv-mobile-service .inner-item h2 span {
    display: block;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: 10px;
}

.hhv-mobile-service .inner-item .int {
    font-size: 22px;
    font-weight: 500;
}

.hhv-mobile-service .inner-item .int .sales {
    color: #fe9a00;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.15);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: bottom;
    margin-right: 6px;
}

.hhv-mobile-service .inner-item .int .price-old {
    font-size: 14px;
    line-height: 24px;
    color: #bfc4ce;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
    font-weight: normal;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft {
    min-height: initial;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more {
    padding: 20px 30px 30px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more h2 {
    margin-bottom: 10px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more .introtv .coll .coll-inner {
    font-size: 14px;
    line-height: 16px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more .introtv .coll .coll-inner .item-desc {
    margin-bottom: 20px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more .introtv .coll .coll-inner .item-price {
    font-size: 12px;
    color: #576c8a;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft .gtgt .intro-more .introtv .coll .coll-inner .item-price .price {
    font-weight: bold;
    font-size: 24px;
    color: #ee0033;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.apply.hhv-apply-dvgtgt .sub-tabs .subleft:hover .imgs img {
    opacity: 0.5;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .imgs {
    margin-right: 20px;
    line-height: 0;
    border-radius: 10px;
    overflow: hidden;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 10px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text h3 {
    margin-bottom: 15px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text h3 a {
    color: #252525;
    font-size: 20px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text .desc {
    margin-bottom: 15px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text .price {
    font-weight: normal;
    font-size: 12px;
    color: #576c8a;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-text .price strong {
    font-weight: bold;
    font-size: 24px;
    color: #ee0033;
    padding-right: 5px;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text .box-dk {
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
}

.apply.hhv-apply-dvgtgt .sub-tabs .subright .item:last-child {
    border-bottom: 0;
}

.hhv-info-adress .title-box h2.space1 {
    margin-bottom: 20px;
}

.hhv-submemnu {
    margin-bottom: 50px;
}

.hhv-submemnu .list-menu {
    text-align: center;
}

.hhv-submemnu .list-menu li {
    display: inline-block;
    vertical-align: top;
    padding: 20px 30px;
}

.hhv-submemnu .list-menu li a {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #576c8a;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
}

.hhv-submemnu .list-menu li.active a,
.hhv-submemnu .list-menu li:hover a {
    color: #ee0033;
    border-color: #ee0033;
}

.hhv-list-internet .lists.slider .item {
    box-shadow: none;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item {
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(39, 52, 125, 0.12);
    margin-bottom: 30px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm {
    padding: 15px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-img {
    width: 104px;
    float: left;
    margin-right: 20px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info h2 a {
    font-weight: bold;
    font-size: 24px;
    color: #0c0033;
    padding-right: 10px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info h2 span {
    font-size: 16px;
    color: #0c0033;
    font-weight: normal;
    vertical-align: middle;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .rating-summary {
    margin-bottom: 10px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .pr {
    color: #ee0033;
    font-weight: 500;
    font-size: 22px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .pr .sales {
    color: #fe9a00;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.2);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: bottom;
    margin-right: 6px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-info .pr .pr-old {
    padding-left: 10px;
    font-size: 14px;
    color: #bfc4ce;
    font-weight: normal;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.hhv-data-internet .goi-cuoc .minmax .min-maxd h2 {
    color: #0c0033;
    margin-bottom: 20px;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg {
    margin-top: 30px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg .ll {
    border-right: 1px solid #ddd;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg .ll,
.hhv-data-internet .goi-cuoc .minmax .ll-gg .gg {
    width: 50%;
    border-radius: 0;
    box-shadow: none;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg .title {
    border-radius: 0;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg .pc-old .price-old {
    color: #576c8a;
    font-size: 14px;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
    font-weight: normal;
}

.hhv-data-internet .goi-cuoc .minmax .ll-gg .pc-old .sales {
    color: #fe9a00;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.15);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: text-bottom;
    margin-left: 6px;
    -webkit-text-decoration-line: none;
    -moz-text-decoration-line: none;
    text-decoration-line: none;
}

.hhv-data-internet .goi-cuoc .minmax .note {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    margin-top: 15px;
    color: #576c8a;
}

.box-element-detail {
    margin-bottom: 40px;
}

ul.list-info {
    line-height: 28px;
    font-size: 16px;
}

.hhv-phuong-an .content.tabs {
    padding: 0;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li {
    margin-right: 100px;
    padding-bottom: 40px;
    padding-left: 5px;
    position: relative;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li a {
    padding: 0;
    border: 0;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li .radio-custom1 {
    padding-left: 28px;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li .radio-custom1 .checkmark {
    top: -1px;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li.active:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    border-width: 14px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #f7f7f7;
    border-style: solid;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li.active a,
.hhv-phuong-an .content.tabs .btabs .tab-title li:hover a {
    color: #576c8a;
    font-weight: normal;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li.active .radio-custom1 .checkmark,
.hhv-phuong-an .content.tabs .btabs .tab-title li:hover .radio-custom1 .checkmark {
    border-color: #ee0033;
}

.hhv-phuong-an .content.tabs .btabs .tab-title li.active .radio-custom1 .checkmark:after,
.hhv-phuong-an .content.tabs .btabs .tab-title li:hover .radio-custom1 .checkmark:after {
    display: block;
    background: #ee0033;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
}

.hhv-phuong-an .content.tabs .tabs-content {
    position: relative;
    background-color: #f7f7f7;
}

.hhv-phuong-an .content.tabs .tabs-content:before {
    left: 100%;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-color: #f7f7f7;
    content: "";
    position: absolute;
}

.hhv-phuong-an .content.tabs .tabs-content:after {
    right: 100%;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-color: #f7f7f7;
    content: "";
    position: absolute;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent {
    padding: 0;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs {
    padding: 40px 0;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs .inner-item .radio-cs input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs .inner-item .radio-cs .checkmark1 {
    box-shadow: 0px 8px 15px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    text-align: center;
    padding: 60px 15px;
    background-color: #fff;
    color: #ee0033;
    font-size: 20px;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs .inner-item .radio-cs .checkmark1 h4 {
    color: #0c0033;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
}

.hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs .inner-item .radio-cs .checkmark1:hover {
    background: rgba(20, 175, 180, 0.15);
    box-shadow: none;
    cursor: pointer;
}

.hhv-phuong-an
    .content.tabs
    .tabs-content
    .sub-tabs
    .tabcontent
    .inner-tabs
    .inner-item
    .radio-cs
    input:checked
    ~ .checkmark1 {
    background: rgba(20, 175, 180, 0.15);
    box-shadow: none;
}

.hhv-pay-total {
    position: relative;
    background-color: rgba(20, 175, 180, 0.15);
    padding: 30px 0;
}

.hhv-pay-total:before {
    left: 100%;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-color: rgba(20, 175, 180, 0.15);
    content: "";
    position: absolute;
}

.hhv-pay-total:after {
    right: 100%;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-color: rgba(20, 175, 180, 0.15);
    content: "";
    position: absolute;
}

.hhv-pay-total .el-total {
    font-size: 32px;
    color: #0c0033;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #576c8a;
    font-weight: bold;
}

.hhv-pay-total .el-phi {
    font-size: 20px;
    font-weight: normal;
    color: #576c8a;
    padding: 10px 0;
}

.hhv-form-hopdong .hhv-hopdong {
    margin: 0 -15px 30px;
}

.hhv-form-hopdong .hhv-hopdong .items {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 {
    padding: 30px 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 .img-pay {
    margin-bottom: 0;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 .info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    padding-left: 30px;
    margin-left: 30px;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 .info span {
    font-weight: normal;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 .info .txt-sim {
    font-weight: 500;
    font-size: 18px;
    color: #ee0033;
}

.hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 .info .note {
    font-size: 14px;
    font-style: italic;
    color: #576c8a;
    margin-top: 5px;
}

.hhv-form-hopdong .hhv-hopdong .items .form-info {
    margin-top: 20px;
    display: none;
}

.hhv-form-hopdong .hhv-hopdong .items .form-info .row-frm {
    padding: 15px 0;
}

.hhv-form-hopdong .hhv-hopdong .items .form-info .row-frm label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

div.page-result-search .form-saerch {
    position: relative;
    margin-bottom: 30px;
}

div.page-result-search .form-saerch .ipt-search {
    padding-left: 50px;
}

div.page-result-search .form-saerch .btn-search {
    position: absolute;
    top: 18px;
    left: 20px;
    background-color: transparent;
    border: 0;
}

div.page-result-search .form-saerch .btn-search .icon-i-search {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    color: #576c8a;
}

div.page-result-search div.menu-search {
    margin-bottom: 30px;
}

div.page-result-search div.menu-search ul.listmenu li {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    font-size: 16px;
}

div.page-result-search div.menu-search ul.listmenu li.active a,
div.page-result-search div.menu-search ul.listmenu li:hover a {
    color: #ee0033;
    text-decoration: underline;
}

div.page-result-search .inner-search .combo-result h2.space1 {
    color: #252525;
}

div.page-result-search .inner-search .combo-result .lists.slider .item {
    box-shadow: none;
}

div.page-result-search .inner-search .combo-result .lists.slider .item:hover {
    box-shadow: none;
}

div.page-result-search .inner-search .combo-result .post {
    margin-bottom: 20px;
}

div.page-result-search .inner-search .combo-result .post .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
    margin-bottom: 10px;
}

div.page-result-search .inner-search .combo-result .post .link {
    color: #007bff;
    text-decoration: underline;
    font-size: 16px;
}

div.page-result-search .inner-search .child-item {
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(39, 52, 125, 0.12);
    margin-bottom: 30px;
}

div.page-result-search .inner-search .child-item .inner-itm {
    padding: 15px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-img {
    width: 104px;
    height: 104px;
    line-height: 104px;
    float: left;
    margin-right: 20px;
    font-family: Montserrat;
    color: #ffffff;
    font-weight: 500;
    font-size: 36px;
    background-color: #864fdf;
    text-align: center;
    border-radius: 6px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-img.bg_orange {
    background-color: #ffba23;
}

div.page-result-search .inner-search .child-item .inner-itm .item-img.bg_red {
    background-color: #fb4600;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info h2 a {
    font-weight: bold;
    font-size: 24px;
    color: #0c0033;
    padding-right: 10px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info h2 span {
    font-size: 16px;
    color: #0c0033;
    font-weight: normal;
    vertical-align: middle;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info .rating-summary {
    margin-bottom: 10px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info .pr {
    color: #ee0033;
    font-weight: 500;
    font-size: 22px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info .pr .sales {
    color: #fe9a00;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.2);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: bottom;
    margin-right: 6px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info .pr .pr-old {
    padding-left: 10px;
    font-size: 14px;
    color: #bfc4ce;
    font-weight: normal;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

div.page-result-search .inner-search .child-item:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.hhv-employees .avata {
    overflow: hidden;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    margin: 0 auto 15px;
}

.hhv-employees .avata img {
    width: 100%;
    height: 100%;
}

.hhv-employees .name {
    font-weight: bold;
    font-size: 16px;
    color: #252525;
}

.hhv-btn-icon a.btn.btn-large {
    padding: 0 20px 0 0;
    border: 2px solid #ee0033;
    width: 280px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 14px;
    color: #ee0033;
}

.hhv-btn-icon a.btn.btn-large span.img {
    height: 50px;
    width: 70px;
    line-height: 48px;
    text-align: center;
    padding: 8px;
    margin-right: 20px;
    border-right: 2px solid #ee0033;
}

.hhv-btn-icon a.btn.btn-large svg {
    --main-color: #ee0033;
    --sub-color: #fe9a00;
}

.hhv-btn-icon a.btn.btn-large:hover {
    background-color: #ee0033;
    color: #fff;
}

.hhv-btn-icon a.btn.btn-large:hover span.img {
    border-color: #fff;
}

.hhv-btn-icon a.btn.btn-large:hover svg {
    color: #fff;
    --main-color: #fff;
    --sub-color: #fff;
}

.hhv-field-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    margin-bottom: 20px;
}

.hhv-field-radio .radio-custom1 {
    width: 33.33%;
    margin: 10px 0;
    padding-left: 30px;
    line-height: 22px;
}

.hhv-field-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.hhv-field-three .field-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.hhv-field-three .field-three label {
    margin-right: 10px;
}

.hhv-field-three .field-three .control {
    min-width: 120px;
}

.hhv-info-private .hhv-info-top {
    margin-bottom: 50px;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-image {
    display: inline-block;
    margin-bottom: 15px;
    position: relative;
    line-height: 0;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-image .img {
    display: inline-block;
    line-height: 0;
    width: 100px;
    border-radius: 100%;
    overflow: hidden;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-image .edit-img {
    position: absolute;
    bottom: 0;
    right: -10px;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-image.no-image .img {
    border: 4px solid #576c8a;
    height: 100px;
    line-height: 100px;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-image.no-image .img i.fs-user {
    font-size: 46px;
    color: #576c8a;
    line-height: 0;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
}

.hhv-info-private .hhv-info-top .hhv-inner .item-phone {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #ee0033;
}

.hhv-info-private .box-login a.btn.btn-blue {
    width: 360px;
    max-width: 100%;
}

.hhv-info-private .box-login p.t-cen {
    margin-bottom: 25px;
}

.hhv-info-private a.link-info {
    color: #ee0033;
    font-weight: normal;
    line-height: 23px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
}

.hhv-info-private a.link-info:hover {
    color: #b70d02;
    text-decoration: none;
}

.support-all #btn-boxChat,
.support-all #close-boxChat {
    cursor: pointer;
}

.support-all .support-inner {
    position: relative;
}

.support-all .support-inner .tool-tip {
    display: none;
    margin-bottom: 15px;
}

.support-all .support-inner .tool-tip .inner-tool-tip {
    background: #ffffff;
    border: 2px solid #eef1f7;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    text-align: left;
}

.support-all .support-inner .tool-tip .inner-tool-tip .item-img {
    margin-right: 15px;
}

.support-all .support-inner .tool-tip .inner-tool-tip .item-info {
    width: 170px;
    font-size: 12px;
}

.support-all .support-inner .tool-tip .inner-tool-tip .item-info .title {
    color: #000000;
    line-height: 24px;
    font-weight: bold;
}

.support-all .support-inner .tool-tip .inner-tool-tip .item-info .desc {
    line-height: 18px;
}

.support-all .support-inner .tool-tip .inner-tool-tip:before {
    position: absolute;
    content: "";
    top: 100%;
    right: 20px;
    border-width: 16px;
    border-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: #eef1f7;
}

.support-all .support-inner .tool-tip .inner-tool-tip:after {
    position: absolute;
    content: "";
    top: 100%;
    right: 24px;
    border-width: 12px;
    border-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: #fff;
}

.support-all .support-inner:hover .tool-tip {
    display: block;
}

.support-all .support-inner #box-chat {
    display: none;
    width: 410px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.12);
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
}

.support-all .support-inner #box-chat img {
    border-radius: 0;
}

.support-all .support-inner #box-chat.open {
    display: block;
    visibility: visible;
    opacity: 1;
}

.support-all .support-inner #box-chat .head-chat {
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.support-all .support-inner #box-chat .head-chat .item-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.support-all .support-inner #box-chat .head-chat .item-info .img-chat {
    width: 40px;
    margin-right: 20px;
    line-height: 0;
    border-radius: 100%;
    overflow: hidden;
}

.support-all .support-inner #box-chat .head-chat .item-info .title-chat {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4d4d4d;
}

.support-all .support-inner #box-chat .head-chat .item-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.support-all .support-inner #box-chat .head-chat .item-close a {
    line-height: 0;
    margin-left: 15px;
}

.support-all .support-inner #box-chat .head-chat:after {
    display: none;
}

.support-all .support-inner #box-chat .content-chat {
    height: 420px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #f7f7f7;
    padding: 20px;
    font-weight: normal;
    font-size: 16px;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat {
    margin-top: 30px;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat .item-avata {
    margin-right: 10px;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat .item-avata img {
    width: 44px;
    border-radius: 100%;
    overflow: hidden;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat .text-chat {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex: 1;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat .text-chat .text {
    margin-bottom: 10px;
    padding: 10px 15px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    color: #252525;
    line-height: 24px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .myfriend-chat .info-chat .text-chat .time {
    color: #979797;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .your-chat {
    margin-top: 30px;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .your-chat .text-chat {
    padding: 10px 15px;
    margin-bottom: 10px;
    background: #ee0033;
    border-radius: 10px;
    color: #fff;
    line-height: 24px;
    display: inline-block;
}

.support-all .support-inner #box-chat .content-chat div.inner-chat .your-chat .time {
    color: #979797;
    font-weight: normal;
    font-size: 12px;
}

.support-all .support-inner #box-chat .bottom-chat {
    background-color: #fff;
    position: relative;
}

.support-all .support-inner #box-chat .bottom-chat .area-chat {
    resize: none;
    border-radius: 0;
    border: 0;
    font-size: 16px;
    padding-right: 120px;
    line-height: 24px;
    font-weight: normal;
}

.support-all .support-inner #box-chat .bottom-chat .btn-wp {
    position: absolute;
    top: -5px;
    right: 25px;
}

.support-all .support-inner #box-chat .bottom-chat .btn-wp .el-btn {
    display: inline-block;
    vertical-align: top;
}

.support-all .support-inner #box-chat .bottom-chat .btn-wp .el-btn.btn-attach {
    padding: 15px;
}

.support-all .support-inner #box-chat .bottom-chat .btn-wp .el-btn.btn-send > a {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 70px;
    text-align: center;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
}

.support-all .support-inner #box-chat .footer-chat {
    border-top: 1px solid #c5c8db;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.support-all .support-inner #box-chat .footer-chat .menuchat {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-first {
    margin-right: 20px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat.menuleft {
    border-right: 1px solid #c5c8db;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat {
    position: relative;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat #menuChat {
    padding: 15px 20px;
    cursor: pointer;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu {
    position: absolute;
    bottom: 100%;
    left: 0;
    min-width: 200px;
    display: none;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu:before {
    position: absolute;
    bottom: 0;
    right: 88px;
    content: "";
    border-width: 11px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: left;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu > li {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-bottom: 1px solid #c5c8db;
    position: relative;
}

.support-all
    .support-inner
    #box-chat
    .footer-chat
    .menuchat
    .menu-boxchat
    div.wp-submenu
    ul.sub-menu
    > li.hhv-arrow:after {
    content: "";
    position: absolute;
    top: 16px;
    right: 5px;
    border-style: solid;
    border-width: 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #e7e7e7;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu > li a {
    display: block;
    padding: 15px 15px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu > li:hover a {
    background-color: rgba(20, 175, 180, 0.5);
    color: #fff;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu > li:hover:after {
    border-left-color: #ffff;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu ul.sub-menu > li:last-child {
    border-bottom: 0;
}

.support-all
    .support-inner
    #box-chat
    .footer-chat
    .menuchat
    .menu-boxchat
    div.wp-submenu
    ul.sub-menu
    .in-submenu
    .back-submenu {
    border-bottom: 1px solid #c5c8db;
}

.support-all
    .support-inner
    #box-chat
    .footer-chat
    .menuchat
    .menu-boxchat
    div.wp-submenu
    ul.sub-menu
    .in-submenu
    .back-submenu
    a {
    display: block;
    padding: 10px 15px;
}

.support-all
    .support-inner
    #box-chat
    .footer-chat
    .menuchat
    .menu-boxchat
    div.wp-submenu
    ul.sub-menu
    .in-submenu
    .back-submenu
    i.icon-i-prev {
    font-size: 11px;
    margin-right: 6px;
    font-weight: bold;
}

.support-all
    .support-inner
    #box-chat
    .footer-chat
    .menuchat
    .menu-boxchat
    div.wp-submenu
    ul.sub-menu
    .in-submenu
    .chil-menu
    li
    a {
    display: block;
    padding: 10px 15px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu.open {
    display: inline-block;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom {
    position: relative;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom #menuInfo {
    padding: 15px 20px;
    cursor: pointer;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu {
    text-align: left;
    position: absolute;
    bottom: 100%;
    left: 0;
    min-width: 200px;
    display: none;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu:before {
    position: absolute;
    bottom: 0;
    right: 88px;
    content: "";
    border-width: 11px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu .form-user {
    padding: 20px 10px 10px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: left;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu .form-user .control {
    margin-bottom: 15px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu .form-user .control label {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #0c0033;
    margin-bottom: 10px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu .form-user .control input {
    padding: 6px 15px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu .form-user .control a.btn {
    padding: 8px 30px;
}

.support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom div.wp-submenu.open {
    display: inline-block;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .hhv-content {
    width: 1140px;
    margin: 0 auto;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 10;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
}

.page-qltkdd .tabs-pack-data.hhv-like-product .hhv-content .pathway {
    padding: 30px 85px 10px;
    margin-bottom: 0;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .hhv-content .pathway .content {
    width: auto;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item {
    padding: 20px 100px;
    border-bottom: 1px solid #e7e7e7;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item div:after {
    display: none;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content .box-img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    width: 160px;
    min-height: 160px;
    float: left;
    margin-right: 30px;
    padding: 15px 20px;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    font-size: 46px;
    line-height: 54px;
    color: #ffffff;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content .box-info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 20px;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content .box-info h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin-bottom: 10px;
}

.page-qltkdd
    .tabs-pack-data.hhv-like-product
    .list-productlike
    .child-item
    .inner-item
    .item-content
    .box-info
    h4:hover {
    color: #ee0033;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content .box-info .desc {
    color: #0c0033;
    font-size: 16px;
    line-height: 24px;
}

.page-qltkdd
    .tabs-pack-data.hhv-like-product
    .list-productlike
    .child-item
    .inner-item
    .item-content.before-pay
    .box-img {
    background-color: #864fdf;
    box-shadow: none;
}

.page-qltkdd
    .tabs-pack-data.hhv-like-product
    .list-productlike
    .child-item
    .inner-item
    .item-content.after-pay
    .box-img {
    background-color: #fe9a00;
    box-shadow: none;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-price {
    text-align: right;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-price .price {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #ee0033;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-price .pr-old {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
    color: #576c8a;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .btn-delete {
    color: #576c8a;
    font-size: 16px;
    padding: 5px;
    cursor: pointer;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabs-inner .nav-tabs {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabs-inner .nav-tabs li {
    margin: 0 10px;
    /*&.disable {
					cursor: default;
				    box-shadow: none;
				    pointer-events: none;
				}*/
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabs-inner .nav-tabs li:hover a {
    color: #ee0033;
}

.main.main-pay.hhv-nttc-cd .hhv-info-top {
    margin-bottom: 30px;
}

.main.main-pay.hhv-nttc-cd .hhv-info-top .hhv-inner .item-image {
    display: inline-block;
    margin-bottom: 15px;
    position: relative;
    line-height: 0;
}

.main.main-pay.hhv-nttc-cd .hhv-info-top .hhv-inner .item-image .img {
    display: inline-block;
    line-height: 0;
    width: 100px;
    border-radius: 100%;
    overflow: hidden;
}

.main.main-pay.hhv-nttc-cd .hhv-info-top .hhv-inner .item-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
}

.main.main-pay.hhv-nttc-cd .hhv-info-top .hhv-inner .item-phoneEmail {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #ee0033;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc div:after {
    display: none;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc label {
    padding-top: 45px;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-kt {
    margin-bottom: 20px;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-kt a {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-kt.disable a {
    color: #e7e7e7;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-kt-kq {
    color: #e7e7e7;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-kt-kq.active {
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.main.main-pay.hhv-nttc-cd .hhv-nttk-tc .hhv-ck {
    font-size: 12px;
    line-height: 18px;
    color: #fe9a00;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li {
    background-color: transparent;
    border: 1px solid #b70d02;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li a {
    color: #b70d02;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.active,
.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li:hover {
    background-color: #b70d02;
}

.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li.active a,
.main.main-pay.hhv-nttc-cd .content.tabs .tabcontent .col-payl .row-group .control .currency ul li:hover a {
    color: #fff;
}

.hhv-find-nd49 {
    margin-bottom: 50px;
}

.hhv-find-nd49 .content {
    width: 780px;
    padding: 0 15px;
}

.hhv-find-nd49 .content .hhv-find-phone {
    margin-bottom: 60px;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone label {
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #576c8a;
    margin-bottom: 15px;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input {
    position: relative;
    padding-right: 30px;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input .icon-i-search {
    position: absolute;
    top: 16px;
    left: 15px;
    font-size: 18px;
    color: #576c8a;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input input {
    width: 450px;
    max-width: 100%;
    padding-left: 40px;
    background: rgba(244, 248, 248, 0.788188);
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control a.btn {
    width: 100%;
    line-height: 24px;
}

.hhv-find-nd49 .content .hhv-content-nd49 .hhv-el {
    margin-bottom: 30px;
}

.hhv-find-nd49 .content .hhv-content-nd49 .hhv-el h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin-bottom: 20px;
}

.hhv-find-nd49 .content .hhv-content-nd49 .hhv-el .desc {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #0c0033;
}

.hhv-find-nd49 .content .hhv-content-nd49 .hhv-el .desc a {
    color: #b70d02;
    text-decoration: underline;
}

.recent-trade .content-trade tbody tr {
    display: block;
}

.hhv-update-tk2 .desc {
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    color: #576c8a;
}

.hhv-update-tk2 .desc a {
    color: #b70d02;
    text-decoration: underline;
}

.hhv-update-tk2 div.box-login {
    width: 100%;
}

.hhv-update-tk2 div.box-login a.btn {
    width: 260px;
    max-width: 100%;
    margin: 10px 10px;
}

.hhv-update-account .form-group.form-group--upload {
    margin: 0 -10px 30px;
}

.hhv-update-account .form-group.form-group--upload .form-group-item {
    padding: 0 10px;
    width: 33.33%;
}

.hhv-update-account .form-group.form-group--upload .form-group-item .label {
    font-size: 14px;
    font-weight: normal;
}

.hhv-update-account .form-group.form-group--upload .form-group-item .btn-upload {
    max-width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.hhv-update-account .form-group.form-group--upload .form-group-item .btn-upload .preview {
    position: relative;
    z-index: 1;
}

.hhv-update-account .field.form-group-upload-chuky {
    margin-bottom: 30px;
}

.hhv-update-account .field.form-group-upload-chuky .btn_upload {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    width: 100%;
    padding: 15px 16px;
    color: #ee0033;
}

.hhv-update-account .field.form-group-upload-chuky .btn_upload input {
    cursor: pointer;
    height: 100%;
    position: absolute;
    filter: alpha(opacity=1);
    -moz-opacity: 0;
    opacity: 0;
}

.sub-title-italic {
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #ee0033;
    text-align: center;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh {
    padding-top: 30px;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-title {
    margin-bottom: 50px;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh {
    width: 100%;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border: 0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr {
    border: 0;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #576c8a;
    border: 0;
    padding: 0 50px;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td.td-title {
    background: rgba(20, 175, 180, 0.15);
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td > span {
    display: block;
    padding: 30px 0 15px;
    border-bottom: 1px solid #b70d02;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td > span.last {
    border: 0;
    padding-bottom: 30px;
}

.main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .hhv-note {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #576c8a;
    margin-bottom: 30px;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content:before {
    content: "";
    width: 94%;
    height: 2px;
    background-color: #e7e7e7;
    position: absolute;
    top: 34px;
    left: 20px;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li {
    width: auto;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li .bar__progress__check {
    width: 64px;
    height: 64px;
    padding: 16px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border: 0;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li .bar__progress__text {
    padding: 0;
}

.hhv-step-by-step.search__maintain
    .block__speed__bar
    .bar__progress__content
    li
    .bar__progress__text
    .bar__progress__text__bold {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li .bar__progress__text__normal {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li:after {
    display: none;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.checked .bar__progress__text__normal,
.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.checked .bar__progress__text__bold {
    color: #b70d02;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.check .bar__progress__text__normal,
.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.check .bar__progress__text__bold {
    color: #576c8a;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.waiting .bar__progress__text__normal,
.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.waiting .bar__progress__text__bold {
    color: #e7e7e7;
}

.hhv-ctdh {
    margin-bottom: 70px;
}

.hhv-ctdh div:after {
    display: none;
}

.hhv-ctdh h3.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    margin-bottom: 30px;
}

.hhv-ctdh .content-ctdh {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hhv-ctdh .content-ctdh .left-ctdh {
    width: 62%;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product {
    padding: 20px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 30px;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info .img {
    width: 130px;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info .detail {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info .detail .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info .detail .desc {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
}

.hhv-ctdh .content-ctdh .left-ctdh .item-product .price {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #ee0033;
}

.hhv-ctdh .content-ctdh .right-ctdh {
    width: 35%;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh {
    width: 100%;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    overflow: hidden;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr.hhv-header {
    background: rgba(20, 175, 180, 0.15);
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr.hhv-bottom td {
    border-top: 1px solid #eaeaea;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr td {
    padding: 13px 20px;
    font-size: 16px;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr td.td-title {
    font-weight: normal;
    line-height: 180%;
    color: #576c8a;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr td.td-info {
    text-align: right;
    font-weight: bold;
    color: #111;
}

.hhv-ctdh .content-ctdh .right-ctdh table.table-ctdh tbody tr td.td-info.fs20 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

#hhv-edit-avata .popup-upload {
    position: absolute;
    top: 100%;
    z-index: 100;
    left: -20px;
    width: 180px;
    padding: 10px 10px 12px;
    border-radius: 6px;
    background-color: #fff;
    margin-top: 15px;
    display: none;
    cursor: pointer;
}

#hhv-edit-avata .popup-upload .svg-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

#hhv-edit-avata .popup-upload:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 18px;
    border-style: solid;
    border-width: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #fff;
}

#hhv-edit-avata.open .popup-upload {
    display: block;
}

.popup-edit.open {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
}

.form-group--upload-ver2.hhv-fix-upload .form-group-item .btn-upload {
    height: auto;
    min-height: 50px;
    overflow: hidden;
    line-height: 0;
}

.box-desc-popup {
    margin-bottom: 30px;
}

.box-desc-popup .desc-date,
.box-desc-popup .desc-name-account {
    font-size: 16px;
    color: #576c8a;
}

.hhv-popup-ctgdv {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 30px;
}

.hhv-popup-ctgdv .td-title {
    padding: 8px 0;
}

.hhv-popup-ctgdv .td-title .name {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #0c0033;
}

.hhv-popup-ctgdv .td-title .time {
    font-size: 12px;
    line-height: 20px;
    color: #474747;
}

.hhv-popup-ctgdv .td-price {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    text-align: right;
    color: #ee0033;
}

.popup-data-sharing {
    font-size: 16px;
    width: 490px;
    padding-bottom: 30px;
}

.popup-data-sharing .box-desc-popup .desc-phone {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #ee0033;
}

.popup-data-sharing .box-content {
    margin-bottom: 30px;
}

.popup-data-sharing div.filed-data {
    width: 210px;
    margin: 0 auto;
}

.popup-data-sharing div.filed-data .control {
    position: relative;
}

.popup-data-sharing div.filed-data .control .note {
    position: absolute;
    top: 10px;
    right: 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
}

.popup-data-sharing div.filed-data .control .input-data {
    padding: 12px 50px 12px 15px;
}

.popup-data-sharing div.filed-data .note-stick {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
}

.btn.btn-red {
    border-color: #ff0000;
    color: #ff0000;
}

.btn.btn-red:hover {
    background-color: #ff0000;
    color: #fff;
}

.hhv-group-btn div:after {
    display: none;
}

.hhv-group-btn .t-cen--btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.hhv-group-btn .t-cen--btn .btn {
    width: 180px;
    max-width: 100%;
    border-width: 1px;
}

.box-capcha {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    width: 193px;
    position: relative;
    padding-right: 36px;
    height: 58px;
    margin-top: 8px;
}

.box-capcha.no-margin {
    margin-top: 0;
}

.box-capcha .img {
    display: inline-block;
}

.box-capcha .img img {
    height: 37px;
    max-height: none;
    margin: 10px 5px;
}

.box-capcha .refesh {
    position: absolute;
    right: 9px;
    top: 16px;
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
}

.popup-notification {
    padding: 30px;
}

.popup-notification .notification__item {
    padding: 0;
    box-shadow: none;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}

.popup-notification .notification__item .notification__img {
    margin-right: 10px;
}

.popup-notification .notification__item .notification__info {
    width: 100%;
}

.popup-notification .notification__item .notification__info h3.title {
    margin-bottom: 10px;
}

.popup-notification .notification__item .notification__info div.time {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
    border-radius: 6px;
}

.popup-error-service-fixed .info-account {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.popup-error-service-fixed .info-account span {
    font-weight: normal;
}

.popup-error-service-fixed .svg-iclock {
    height: 70px;
}

.popup-error-service-fixed .btn.btn-disable {
    background: #e7e7e7;
    border-color: #e7e7e7;
    color: #fff;
}

.hhv-list-address-cn {
    padding: 20px;
    height: 540px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 30px;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
}

.hhv-list-address-cn::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

.hhv-list-address-cn::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

.hhv-list-address-cn::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 5px;
}

.hhv-list-address-cn::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.hhv-list-address-cn .per .radio-custom1 .checkmark {
    margin-top: 2px;
}

.hhv-list-address-cn .per .radio-custom1 .col-l.left {
    padding-left: 20px;
}

.hhv-list-address-cn .per .choose-time {
    border-top: 1px solid #ddd;
    padding: 30px 0 0 30px;
    margin-top: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-weight: normal;
}

.hhv-list-address-cn .per .choose-time div:after {
    display: none;
}

.hhv-list-address-cn .per .choose-time .boxleft {
    width: 50%;
}

.hhv-list-address-cn .per .choose-time .boxleft .datepicker {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
}

.hhv-list-address-cn .per .choose-time .boxleft .datepicker .item-date {
    width: calc(50% - 15px);
}

.hhv-list-address-cn .per .choose-time .boxleft .note {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.hhv-list-address-cn .per .choose-time .boxright {
    width: 50%;
    padding-left: 30px;
}

.date-select-picker {
    /* background: #fff url(../images/select.svg) no-repeat; */
    background-position-x: calc(100% - 16px);
    background-position-y: 46%;
}

a.btn.btn-full-width {
    display: block;
}

.hhv-result-error .list-result li {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.hhv-result-error .list-result li .title {
    color: #ee0033;
}

.popup-change-position-resignter {
    padding: 30px;
}

.popup-change-position-resignter .hhv-change-position-resignter .head-popup {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #0c0033;
}

.popup-change-position-resignter .hhv-change-position-resignter .icon-hour {
    font-size: 22px;
}

.popup-change-position-resignter .hhv-change-position-resignter .icon-img {
    margin-right: 15px;
}

.popup-change-position-resignter a.btn {
    width: 360px;
    max-width: 100%;
    margin: 0 15px;
}

.btn-two-full-width {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.btn-two-full-width a.btn {
    width: 50%;
    margin: 0 10px;
}

.popup-register-pending {
    padding: 30px;
}

.popup-register-pending h3.title-adress {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: -0.5px;
    color: #ee0033;
    margin-bottom: 20px;
}

.popup-register-pending i.icon {
    font-size: 24px;
    color: #ee0033;
    margin-right: 15px;
}

.popup-register-pending .choose-time div:after {
    display: none;
}

.popup-register-pending .choose-time .datepicker {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.popup-register-pending .choose-time .datepicker div.item-date {
    width: calc(50% - 10px);
    position: relative;
}

.popup-register-pending .choose-time .datepicker div.item-date i.icon {
    top: 10px;
    right: 10px;
    position: absolute;
    margin: 0;
    background: #fff;
}

.field.form-upload-fullimage {
    margin-bottom: 30px;
}

.field.form-upload-fullimage div:after {
    display: none;
}

.field.form-upload-fullimage .btn_upload {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    width: 100%;
    color: #ee0033;
    height: auto;
    min-height: 50px;
    overflow: hidden;
    line-height: 0;
    padding: 0;
}

.field.form-upload-fullimage .btn_upload .fileUpload {
    display: none;
}

.field.form-upload-fullimage .btn_upload .img-preview {
    line-height: 0;
    position: relative;
    z-index: 1;
}

.field.form-upload-fullimage .btn_upload span.text-upload {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

a.btn.btn160 {
    min-width: 160px;
}

a.btn.btn360 {
    width: 360px !important;
    max-width: 100%;
}

a.btn.btn145 {
    width: 145px;
    max-width: 100%;
}

a.btn.min145 {
    min-width: 145px;
}

a.btn.btn150 {
    width: 150px;
    max-width: 100%;
}

a.btn.btn107 {
    width: 107px;
    max-width: 100%;
    padding: 12px 5px;
}

a.btn.btn169 {
    width: 169px;
    max-width: 100%;
}

a.btn.btn-gray {
    background: #d8d8d8;
    border-color: #d8d8d8;
    color: #fff;
}

a.btn.btn-gray:hover {
    background: #ee0033;
    border-color: #ee0033;
}

a.btn.disabled {
    background: #d8d8d8;
    border-color: #d8d8d8;
    color: #fff;
    pointer-events: none;
}

.modal .inner-input p.t-cen a.btn169 {
    width: 169px;
}

.hhv-info-private {
    color: #576c8a;
}

.hhv-info-private .txt500 {
    font-weight: 500;
    line-height: 24px;
    color: #0c0033;
}

.hhv-info-private .ds-block {
    display: block;
}

.hhv-info-private table {
    width: 100%;
}

.hhv-info-private table tbody tr td.info {
    text-align: right;
    font-weight: 500;
    line-height: 24px;
    color: #0c0033;
}

.hhv-info-private table tbody tr td.total-price {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

.hhv-info-private table tbody tr.bd-top td {
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.popup-register-package div:after {
    display: none;
}

.popup-register-package .inner-input h1 {
    margin-bottom: 40px;
}

.popup-register-package .hhv-register-pk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.popup-register-package .hhv-register-pk .items {
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.12);
    width: 330px;
    margin: 0 10px;
    max-width: 100%;
    border-radius: 10px;
    padding: 30px 10px;
}

.popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 {
    padding-left: 0;
    text-align: center;
}

.popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info {
    position: relative;
    padding-left: 30px;
    display: inline-block;
    text-align: left;
    margin: 0 20px;
}

.popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info .checkmark {
    top: 4px;
}

.popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info .txt-sim {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info .txt-sim .txt-small {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.popup-register-package .hhv-register-pk .items:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.hhv-combo-all .hhv-combo-service .lists.slider .item {
    box-shadow: none;
}

.hhv-combo-all .hhv-combo-service .lists.slider .item:hover {
    box-shadow: none;
}

.hhv-combo-all .hhv-combo-service .child-item {
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(39, 52, 125, 0.12);
    margin-bottom: 30px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm {
    padding: 15px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-img {
    width: 104px;
    height: 104px;
    line-height: 104px;
    float: left;
    margin-right: 20px;
    font-family: Montserrat;
    color: #ffffff;
    font-weight: 500;
    font-size: 36px;
    background-color: #864fdf;
    text-align: center;
    border-radius: 6px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-img.bg_orange {
    background-color: #ffba23;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-img.bg_red {
    background-color: #fb4600;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info h2 a {
    font-weight: bold;
    font-size: 24px;
    color: #0c0033;
    padding-right: 10px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info h2 span {
    font-size: 16px;
    color: #0c0033;
    font-weight: normal;
    vertical-align: middle;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info .rating-summary {
    margin-bottom: 10px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info .pr {
    color: #ee0033;
    font-weight: 500;
    font-size: 22px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info .pr .sales {
    color: #fe9a00;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.2);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: bottom;
    margin-right: 6px;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info .pr .pr-old {
    padding-left: 10px;
    font-size: 14px;
    color: #bfc4ce;
    font-weight: normal;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
}

.hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
}

.hhv-combo-all .hhv-combo-service .child-item:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.hhv-service-call-international .item .intro-more .introqt {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.hhv-service-call-international .item .intro-more .introqt h2 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.2125px;
    color: #252525;
    margin-bottom: 15px;
    line-height: 28px;
}

.hhv-service-call-international .item .intro-more .introqt .box-dk {
    top: auto;
    bottom: 30px;
}

.hhv-service-message-international .item .intro-more .introqt {
    padding: 0 30px 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.hhv-service-message-international .item .intro-more .introqt h2 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.2125px;
    color: #252525;
    margin-bottom: 15px;
    line-height: 28px;
    text-transform: uppercase;
}

.hhv-service-message-international .item .intro-more .introqt h2 .icon-i-next {
    font-size: 14px;
    vertical-align: middle;
}

.hhv-service-message-international .item .intro-more .introqt .box-dk {
    margin-top: 20px;
    position: static;
}

.hhv-rules-policy.viettel-intro .tabs .tit {
    margin-bottom: 50px;
}

.hhv-rules-policy.viettel-intro h2.title-h2 {
    margin-bottom: 50px;
}

.wp-service-security h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
    margin-bottom: 20px;
}

.wp-service-security p {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: justify;
    color: #474747;
    margin-bottom: 30px;
}

.warp-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 39px;
}

.warp-share span.share-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #474747;
    margin-right: 12px;
}

.warp-share ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.warp-share ul li a {
    text-indent: -9999px;
    display: block;
    margin-right: 12px;
}

.warp-share ul li.social-zalo a {
    /* background: url('../images/icon-zalo.svg') no-repeat; */
    width: 24px;
    height: 23px;
}

.warp-share ul li.social-facebook a {
    /* background: url('../images/icon-facebook.svg') no-repeat; */
    width: 24px;
    height: 24px;
}

.warp-share ul li.social-youtube a {
    /* background: url('../images/icon-youtube.svg') no-repeat; */
    width: 24px;
    height: 23px;
}

.box-question-rols-policy {
    background: #f7f7f7;
    padding: 30px 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #474747;
}

.box-question-rols-policy .wp-btn a.btn {
    margin: 0 10px;
}

.box-question-rols-policy a.link {
    color: #ee0033;
    text-decoration: underline;
}

.box-question-rols-policy a.link:hover {
    text-decoration: none;
}

.hhv-flash-sale {
    padding-top: 30px;
}

.box-countdown {
    text-align: center;
    padding-bottom: 30px;
}

.box-countdown .countdown {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
}

.box-countdown .countdown .item {
    position: relative;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #ee0033;
    float: left;
    padding: 0 15px;
}

.box-countdown .countdown .item:after {
    content: ":";
    position: absolute;
    right: -5px;
    top: 0;
}

.box-countdown .countdown .item .text {
    font-size: 12px;
    line-height: 18px;
    color: #576c8a;
    font-weight: normal;
}

.box-countdown .countdown .item:last-child:after {
    display: none;
}

.box-countdown .note {
    text-align: left;
}

.hhv-slide-flash-sale .item {
    box-shadow: 0px 0px 10px rgba(39, 52, 125, 0.12) !important;
}

.hhv-slide-flash-sale .item div:after {
    display: none;
}

.hhv-slide-flash-sale .item .item-inner {
    padding: 15px;
}

.hhv-slide-flash-sale .item .item-inner .content-top {
    border-bottom: 1px solid #e7e7e7;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .item-img {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .item-img .img {
    width: 50px;
    float: left;
    margin-right: 15px;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .item-img .item-title h2 {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .item-img .item-title .price {
    font-size: 14px;
    line-height: 18px;
    color: #ee0033;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .item-img .item-title .price .old {
    display: block;
    font-size: 12px;
    line-height: 16px;
    -webkit-text-decoration-line: line-through;
    -moz-text-decoration-line: line-through;
    text-decoration-line: line-through;
    color: #576c8a;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-info .np-register {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #fe9a00;
}

.hhv-slide-flash-sale .item .item-inner .content-top .item-note {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.hhv-slide-flash-sale .item .item-inner .content-bottom {
    padding-top: 20px;
}

.hhv-slide-flash-sale .item .item-inner .content-bottom .item-desc {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #ee0033;
    margin-bottom: 15px;
}

.hhv-slide-flash-sale .item .item-inner .content-bottom .nr-purchases {
    font-size: 12px;
    line-height: 18px;
    color: #fe9a00;
}

.hhv-slide-flash-sale .item .item-inner .content-bottom .item-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.hhv-list-internet.hhv-item-has-img .lists.slider .item .list-internet .child-item .inner-itm .item-img {
    background-color: transparent;
}

.box-ccts {
    margin-bottom: 100px;
}

.note-txt-warning {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #fe9a00;
}

.convert-pay-after .title-h2 {
    text-transform: uppercase;
    margin-bottom: 50px;
}

.convert-pay-after .convert-pay-after__item {
    box-shadow: none;
    padding: 0;
}

.convert-pay-after .convert-pay-after__item .item-report-detail {
    margin-bottom: 30px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    font-size: 16px;
    line-height: 1.8;
    color: #576c8a;
}

.convert-pay-after .convert-pay-after__item .item-report-detail > p {
    margin-bottom: 15px;
}

.convert-pay-after .convert-pay-after__item .item-report-detail .address h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.convert-pay-after .convert-pay-after__item .item-report-detail .address .note {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #979797;
}

.convert-pay-after .convert-pay-after__item .item-report-detail .address .field.datatime .control i.fs-date {
    position: absolute;
    top: 5px;
    right: 14px;
    color: #ee0033;
    background-color: #fff;
    font-size: 22px;
}

.convert-pay-after .convert-pay-after__item .item-report-detail p strong {
    color: #0c0033;
}

.convert-pay-after-detail-right .field label {
    color: #0c0033;
    margin-bottom: 10px;
}

.convert-pay-after-detail-right .el-item-detail h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.convert-pay-after-tabs .btabs .tab-title li {
    width: 50%;
}

.convert-pay-after-tabs .btabs .tab-title li a.radio-custom {
    padding: 0 0 0 30px;
    border: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.convert-pay-after-tabs .btabs .tab-title li a.radio-custom .txt {
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.convert-pay-after-tabs .btabs .tab-title li a.radio-custom .checkmark {
    top: 8px;
}

.convert-pay-after-tabs .btabs .tab-title li:hover a.radio-custom {
    color: #ee0033;
}

.convert-pay-after-tabs .btabs .tab-title li:hover a.radio-custom .checkmark {
    border-color: #ee0033;
}

.convert-pay-after-tabs .btabs .tab-title li.active a.radio-custom {
    color: #ee0033;
}

.convert-pay-after-tabs .btabs .tab-title li.active a.radio-custom .checkmark {
    border: 0;
}

.convert-pay-after-tabs .btabs .tab-title li.active a.radio-custom .checkmark:after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.08496 1.28571C4.81143 1.28571 1.37068 4.72647 1.37068 9C1.37068 13.2735 4.81143 16.7143 9.08496 16.7143C13.3585 16.7143 16.7992 13.2735 16.7992 9C16.7992 8.64971 16.7556 8.34777 16.6987 7.955C16.6917 7.90654 16.6845 7.85669 16.6771 7.8052C16.6269 7.45373 16.8711 7.1281 17.2226 7.07789C17.5741 7.02768 17.8997 7.2719 17.9499 7.62337C17.9569 7.6725 17.9641 7.72172 17.9713 7.77114C18.0273 8.15547 18.085 8.55121 18.085 9C18.085 13.9836 14.0686 18 9.08496 18C4.10135 18 0.0849609 13.9836 0.0849609 9C0.0849609 4.01639 4.10135 0 9.08496 0C10.6008 0 11.9951 0.345647 13.2543 1.04518C13.5647 1.21761 13.6765 1.60898 13.5041 1.91934C13.3316 2.2297 12.9403 2.34152 12.6299 2.1691C11.5748 1.58292 10.3977 1.28571 9.08496 1.28571Z' fill='%2314AFB4'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.8937 1.47048C18.1464 1.71989 18.149 2.12691 17.8996 2.3796L9.01652 11.3796C8.89573 11.502 8.73094 11.5709 8.55899 11.5709C8.38703 11.5709 8.22225 11.502 8.10146 11.3796L4.77029 8.0046C4.52088 7.75191 4.52354 7.34489 4.77623 7.09548C5.02892 6.84608 5.43594 6.84874 5.68535 7.10143L8.55899 10.0129L16.9846 1.47643C17.234 1.22374 17.641 1.22108 17.8937 1.47048Z' fill='%23FE9A00'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.search-store .search-address {
    margin-bottom: 20px;
}

.search-store .search-address .inner-search-address {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.search-store .search-address .inner-search-address:after {
    display: none;
}

.search-store .search-address .inner-search-address .txt {
    margin-right: 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.search-store .search-address .inner-search-address .ctl-search {
    position: relative;
    width: 400px;
    max-width: 100%;
    margin-right: 30px;
}

.search-store .search-address .inner-search-address .ctl-search i.icon-i-search {
    position: absolute;
    top: 13px;
    left: 12px;
    color: #576c8a;
    font-size: 18px;
}

.search-store .search-address .inner-search-address .ctl-search .inpt-search {
    padding-left: 40px;
}

.control .inpt-small {
    padding: 11px 16px;
}

.tabcontentsv {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    display: none;
}

.tabcontentsv.active {
    display: block;
}

.search-service {
    margin-bottom: 35px;
}

.search-service .tab-service li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 32px;
}

.search-service .tab-service li a {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: justify;
    color: #474747;
    border-radius: 6px;
}

.search-service .tab-service li:before {
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    border: 2px solid #e7e7e7;
}

.search-service .tab-service li:after {
    position: absolute;
    content: "";
    top: 5px;
    left: 3px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #fff;
}

.search-service .tab-service li.active:before {
    border-color: #ee0033;
}

.search-service .tab-service li.active:after {
    background-color: #ee0033;
}

.search-store_content .box-search .tabcontentsv .inner-tabcontentsv h4.title {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #474747;
    margin-bottom: 10px;
    position: relative;
}

.search-store_content .box-search .tabcontentsv .inner-tabcontentsv h4.title:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #92fe9d;
    vertical-align: text-bottom;
    margin-right: 10px;
}

.search-store_content .box-search .tabcontentsv .inner-tabcontentsv h4.title.style1:before {
    background-color: #29e2e6;
}

.search-store_content .box-search .tabcontentsv .inner-tabcontentsv h4.title.style2:before {
    background-color: #ffb400;
}

.search-store_content .box-search .tabcontentsv .inner-tabcontentsv div.desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    color: #576c8a;
}

.hhv-code-private h3 {
    font-size: 22px;
    margin-bottom: 30px;
}

.hhv-code-private div.el {
    margin-bottom: 30px;
    line-height: 180%;
}

.hhv-location-service-provision .form-search {
    margin-bottom: 20px;
}

.hhv-location-service-provision .form-search .inner-form {
    margin: 0 -15px;
}

.hhv-location-service-provision .form-search .inner-form div.field {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.hhv-location-service-provision .form-result {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.hhv-location-service-provision .form-result .item-search {
    width: 33.33%;
    float: left;
    padding: 15px;
}

.hhv-location-service-provision .form-result .item-search .inner {
    box-shadow: 0px 6px 18px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #474747;
}

.hhv-location-service-provision .form-result .item-search .inner h4.title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}

.hhv-location-service-provision .form-result .item-search .inner svg {
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 10px;
    margin-top: 3px;
}

.hhv-location-service-provision .form-result .item-search .inner p {
    overflow: hidden;
}

.hhv-location-service-provision .form-result .item-search .inner .address svg {
    width: 20px;
    height: 20px;
}

.hhv-location-service-provision .form-result .item-search .inner .note {
    margin: 5px 0;
}

.hhv-location-service-provision .form-result .item-search .inner .time-phone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-location-service-provision .form-result .item-search .inner .time-phone .time {
    margin-right: 20px;
}

.table-tkdh-result .box-table {
    padding: 30px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
}

.table-tkdh-result .box-table .tkdh-result {
    border: 2px solid #eef1f7;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    border-radius: 10px;
}

.table-tkdh-result .box-table .tkdh-result tr th,
.table-tkdh-result .box-table .tkdh-result tr td {
    border: 2px solid #eef1f7;
}

.table-tkdh-result .box-table .tkdh-result tr th {
    padding: 15px 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ee0033;
}

.table-tkdh-result .box-table .tkdh-result tr td {
    padding: 25px 10px;
}

.table-tkdh-result .box-table .tkdh-result tr td > a {
    font-weight: 500;
    font-size: 14px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.control.datetime_ipt {
    position: relative;
}

.control.datetime_ipt:before {
    position: absolute;
    content: "";
    top: 17px;
    right: 15px;
    width: 16px;
    height: 16px;
    /* background: url(../images/i-input-date.svg) no-repeat; */
}

.hhv-tchdbh .tab-title li a {
    font-size: 20px;
}

form.hhv-custom-form .field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
}

form.hhv-custom-form .field label {
    width: 180px;
    padding-top: 0;
}

form.hhv-custom-form .field .control {
    padding: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

form.hhv-custom-form .field .control input {
    margin-bottom: 0;
}

.field .capcha.one-row .txt-code {
    width: 48%;
    margin-right: 2%;
    float: left;
}

.field .box-capcha {
    width: 48%;
    float: right;
    height: 52px;
}

.field .box-capcha .refesh {
    position: absolute;
    right: 10px;
    top: 16px;
    width: 25px;
    height: 25px;
}

.hhv-box-gysp {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    width: 790px;
    max-width: 100%;
    margin: 70px auto;
    padding-top: 50px;
    border-radius: 10px;
}

.hhv-head-tracuocth {
    width: calc(50% - 15px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-head-tracuocth .charges-acc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 100%;
}

.hhv-head-tracuocth .charges-acc .charges-if {
    width: calc(50% - 15px);
    padding: 30px 20px;
    border-right: 1px solid #e7e7e7;
}

.hhv-head-tracuocth .charges-acc .charges-pr {
    width: calc(50% - 15px);
    padding: 30px 20px;
}

.hhv-head-tracuocth .charges-choose-month {
    width: 100%;
}

.hhv-head-tracuocth .charges-choose-month .charges-choose-month__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 100%;
    padding: 30px;
}

.hhv-head-tracuocth .charges-choose-month .charges-choose-month__inner .hhv-field-radio {
    margin-bottom: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.hhv-head-tracuocth .charges-choose-month .charges-choose-month__inner .hhv-field-radio .radio-custom1 {
    width: 50%;
}

.hhv-head-chitietcuoc .box-sctt .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    padding-right: 10px;
}

.box-choose-time-search .box-choose-time-search-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.box-choose-time-search .box-choose-time-search-inner .hhv-field-radio {
    margin-bottom: 0;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.box-choose-time-search .box-choose-time-search-inner .hhv-field-radio .radio-custom1 {
    width: auto;
    padding-right: 20px;
}

.box-choose-time-search .box-choose-time-search-inner .charges-price__date {
    max-width: initial;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: initial;
    -ms-flex-flow: initial;
    flex-flow: initial;
}

.box-choose-time-search .box-choose-time-search-inner .charges-price__date .field {
    margin: 0 10px;
    width: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.box-choose-time-search .box-choose-time-search-inner .charges-price__date .field label {
    margin-right: 10px;
}

.box-choose-time-search .box-choose-time-search-inner .charges-price__date .field .control {
    width: 180px;
}

.hhv-title-ycds {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hhv-title-ycds a.choose-form {
    color: #ee0033;
    font-weight: 500;
    font-size: 14px;
    padding-top: 5px;
}

.hhv-title-ycds a.choose-form i {
    color: #576c8a;
    padding-left: 5px;
}

.hhv-title-ycds:after {
    display: none;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column:after {
    display: none;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column div.field {
    width: calc(33.33% - 15px);
    float: left;
    display: block;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column div.field label {
    margin-bottom: 10px;
    width: 100%;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column div.field div.control {
    width: 100%;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column:after {
    display: none;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field {
    width: calc(50% - 15px);
    float: left;
    display: block;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field label {
    margin-bottom: 10px;
    width: 100%;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field div.control {
    width: 100%;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field a.btn {
    display: block;
    padding: 15px;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field div.btn-upload {
    width: 100%;
    height: auto;
    min-height: 52px;
    border: 2px solid #ee0033;
    background-color: #fff;
    overflow: hidden;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field div.btn-upload label {
    font-size: 14px;
    line-height: 20px;
    color: #ee0033;
    font-weight: normal;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field div.btn-upload img {
    position: relative;
    width: 100%;
    background-color: #fff;
}

.hhv__trade__sim__request__content_ptgh {
    width: 65.8%;
}

.hhv__trade__sim__request__content_ptgh .method .row-frm {
    margin-bottom: 30px;
}

.hhv__trade__sim__request__content_ptgh .method .row-frm .radio-custom1 {
    padding-left: 30px;
}

.hhv__trade__sim__request__content_ptgh .method .row-frm .radio-custom1 .checkmark {
    top: -1px;
}

.hhv__trade__sim__request__content_ptgh .method-ship .row-frm {
    margin-bottom: 30px;
}

.hhv__trade__sim__request__content_ptgh .method-ship .hhv-list-address-cn .per {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.5);
    font-weight: normal;
}

.hhv__trade__sim__request__content_ptgh .method-ship .hhv-list-address-cn .per .radio-custom1 .col-l.right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-error .control input {
    border-color: #ff0048;
}

.hhv-error p.error {
    margin-top: 8px;
    font-style: italic;
    font-weight: 300;
}

.wp-hhv-choose-form {
    margin-bottom: 100px;
}

.wp-hhv-choose-form div:after {
    display: none;
}

.wp-hhv-choose-form .title-h2 {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.wp-hhv-choose-form .list-choose-form-three-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wp-hhv-choose-form .list-choose-form-three-column .item-form {
    width: calc(50% - 15px);
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.2);
    padding: 20px 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    margin-bottom: 30px;
}

.wp-hhv-choose-form .list-choose-form-three-column .item-form div.name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0c0033;
    margin-bottom: 8px;
}

.wp-hhv-choose-form .list-choose-form-three-column .item-form div.cmt {
    margin-bottom: 5px;
}

.wp-hhv-choose-form .list-choose-form-three-column .item-form i {
    color: #576c8a;
}

.wp-hhv-choose-form .list-choose-form-three-column .item-form:hover {
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.4);
}

.method-total {
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.2);
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.method-total div:after {
    display: none;
}

.method-total .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin-bottom: 30px;
}

.method-total .total-top {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.method-total .total-top .el-block {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.method-total .total-top .el-block .money {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
}

.method-total .total-bottom .el-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.method-total .total-bottom .el-block div.money {
    text-align: right;
}

.method-total .total-bottom .el-block div.money .price {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #0c0033;
    margin-bottom: 10px;
}

.method-total .total-bottom .el-block div.money .vat {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.hhv-search-result-roaming {
    width: 600px;
    max-width: 100%;
}

.hhv-table-result-roaming .hhv-table-roaming {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-radius: 10px;
    text-align: center;
}

.hhv-table-result-roaming .hhv-table-roaming thead {
    background: rgba(20, 175, 180, 0.15);
}

.hhv-table-result-roaming .hhv-table-roaming thead th {
    padding: 10px;
}

.hhv-table-result-roaming .hhv-table-roaming tbody tr td {
    padding: 10px;
}

.hhv-note-roaming {
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    color: #576c8a;
}

.hhv-note-roaming a {
    color: #ee0033;
}

.product-hot table.tbl-tech {
    height: 314px;
    display: block;
    overflow: hidden;
}

.product-hot table.tbl-tech.open {
    height: auto;
    overflow: visible;
}

.product-hot .tech .view-detail {
    cursor: pointer;
}

.product-hot .tech .view-detail:hover {
    color: #fff;
    background-color: #ee0033;
}

.tabs__detail__content__mid_tk {
    margin: 0 auto;
}

.tabs-utilities .list-tracuu {
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    margin: 0;
    padding: 30px;
}

.tabs-utilities .list-tracuu .list-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tabs-utilities .list-tracuu .list-inner .item {
    width: 20%;
    padding: 15px;
}

.tabs-utilities .list-tracuu .list-inner .item .imgs {
    margin-bottom: 10px;
}

.tabs-utilities .list-tracuu .list-inner .item .imgs img {
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 100%;
}

.hhv-tracuc-ct {
    width: 750px;
    max-width: 100%;
    margin: 0 auto;
}

.hhv-tracuc-ct .hhv__trade__sim__request__content .hhv__trade__sim__request__content__captcha_column div.field {
    display: block;
}

.hhv-tracuc-ct .hhv__trade__sim__request__content .hhv__trade__sim__request__content__captcha_column div.field label {
    margin-bottom: 10px;
    width: 100%;
}

.hhv-tracuc-ct
    .hhv__trade__sim__request__content
    .hhv__trade__sim__request__content__captcha_column
    div.field
    div.control {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hhv-tracuc-ct
    .hhv__trade__sim__request__content
    .hhv__trade__sim__request__content__captcha_column
    div.field
    div.control
    .box-capcha {
    margin-top: 0;
    width: 220px;
    margin-left: 30px;
}

.hhv-tracuc-ct .note-tc {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
    margin-bottom: 30px;
}

.hhv-tracuc-ct .support {
    margin-bottom: 30px;
}

.hhv-tracuc-ct .support ul li {
    margin-bottom: 15px;
    width: 33.33%;
    float: left;
}

.hhv-tracuc-ct .support ul li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    color: #0c0033;
    position: relative;
}

.hhv-tracuc-ct .support ul li a:before {
    content: "";
    height: 24px;
    width: 24px;
    display: block;
    float: left;
    margin-right: 10px;
    background-size: 80% !important;
}

.hhv-tracuc-ct .support ul li.support-cskh a:before {
    /* background: url('../images/icon-cskh.png') no-repeat; */
}

.hhv-tracuc-ct .support ul li.support-mb a:before {
    /* background: url('../images/icon-phone.png') no-repeat; */
}

.hhv-tracuc-ct .support ul li.support-mn a:before {
    /* background: url('../images/icon-phone.png') no-repeat; */
}

.hhv-tracuc-ct .support ul li.support-mail a:before {
    /* background: url('../images/icon-mail.png') no-repeat; */
}

.hhv-tracuc-ct .support ul li.support-mess a:before {
    /* background: url('../images/icon-mess.png') no-repeat; */
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table {
    padding: 0;
    box-shadow: none;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table .tkdh-result {
    border-color: #e9e9e9;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table thead {
    background: rgba(20, 175, 180, 0.15);
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table thead tr th {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #0c0033;
    padding: 15px;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table tbody tr td {
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #576c8a;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table tfoot {
    background: rgba(20, 175, 180, 0.15);
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table tfoot tr td {
    padding: 15px;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table tfoot tr td.title {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #576c8a;
    text-align: left;
    padding-left: 30px;
}

.hhv-tracuoc-ct-result .table-tkdh-result .box-table tfoot tr td.total {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #0c0033;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__one_column div.field {
    display: block;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__one_column div.field label {
    margin-bottom: 10px;
    width: 100%;
}

.hhv__trade__sim__request__content .hhv__trade__sim__request__content__one_column div.field div.control {
    width: 100%;
}

.login-register.style2 ul > li > div.hello span.txt:after {
    margin-top: 5px;
}

.login-register.style2 ul li .sub-regis {
    top: 65px;
    right: 14px;
    width: 120px;
}

.login-register.style2 ul li .sub-regis .inner-sub-register {
    padding: 10px;
}

.login-register.style2 ul li .sub-regis .inner-sub-register div.l-out a {
    border: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    box-shadow: none;
}

.login-register.style2 ul li .sub-regis .inner-sub-register div.l-out a img {
    margin-right: 5px;
    vertical-align: text-top;
}

.login-register.style2 ul li .sub-regis .inner-sub-register:before {
    border: 10px solid #fff;
    right: 38px;
}

.login-register.style2 ul > li > div.hello .icon {
    top: 65%;
}

footer.footer {
    background-color: transparent;
    padding: 0;
}

footer.footer .footer-top {
    background: #ee0033;
    padding: 30px 0;
}

footer.footer .footer-top .footer-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    margin: 0 -15px;
}

footer.footer .footer-top .column {
    padding: 0 15px;
}

footer.footer .footer-top .column.first .infotop {
    width: 300px;
    max-width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-bottom: 50px;
}

footer.footer .footer-top .column.first .infotop .logo-fotter {
    margin-bottom: 20px;
}

footer.footer .footer-top .column.first .infobellow {
    font-size: 12px;
}

footer.footer .footer-top .column .menu {
    margin-bottom: 30px;
}

footer.footer .footer-top .column .menu h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-bottom: 15px;
}

footer.footer .footer-top .column .menu .sub-menu li {
    line-height: 18px;
}

footer.footer .footer-top .column .menu .sub-menu li a {
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #ffffff;
}

footer.footer .footer-bottom {
    background: #0aa5aa;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding: 30px 0;
}

footer.footer .footer-bottom .address-support {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

footer.footer .footer-bottom .address-support li {
    line-height: 14px;
}

footer.footer .footer-bottom .address-support li i {
    margin-top: -2px;
    margin-right: 5px;
}

footer.footer .footer-bottom .address-support li img {
    margin-right: 5px;
}

footer.footer .footer-bottom .address-support li:first-child {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #fff;
}

@media (max-width: 1199px) {
    .page-qltkdd .tabs-pack-data.hhv-like-product .hhv-content .pathway {
        padding: 20px 15px 10px;
    }
    .page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item {
        padding: 20px 15px;
    }
}

@media (max-width: 991px) {
    .hhv-list-address-cn .per .radio-custom1 .checkmark {
        margin-top: 8px;
    }
    .hhv-list-address-cn .per .radio-custom1 .col-l.left {
        padding-left: 0;
    }
    .hhv-list-address-cn .per .choose-time {
        display: block;
        padding-left: 0;
    }
    .hhv-location-service-provision .form-result .item-search {
        width: 50%;
    }
    .hhv-head-tracuocth {
        width: 100%;
        margin: 15px 0;
    }
    .box-choose-time-search .box-choose-time-search-inner {
        display: block;
    }
    .box-choose-time-search .box-choose-time-search-inner .hhv-field-radio {
        margin-bottom: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .box-choose-time-search .box-choose-time-search-inner .charges-price__date {
        margin-bottom: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    footer.footer .footer-top .footer-inner {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    footer.footer .footer-top .footer-inner .column {
        width: 50%;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .hhv-hidden-pc {
        display: block;
    }
    .hhv-hidden-sp {
        display: none;
    }
    .hhv-list-address-cn .per .choose-time .boxright,
    .hhv-list-address-cn .per .choose-time .boxleft {
        width: 100%;
    }
    .hhv-list-address-cn .per .choose-time .boxleft {
        margin-bottom: 20px;
    }
    .hhv-list-address-cn .per .choose-time .boxright {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .hhv-hidden-pc {
        display: none;
    }
    .hhv-hidden-sp {
        display: block;
    }
    .hhv-hidensp {
        display: none;
    }
    .hhv-xs-col100 {
        width: 100%;
    }
    .hhv-mobile-service .title-box h2.space1 {
        font-size: 16px;
    }
    .apply.hhv-apply-dvgtgt .sub-tabs .subright .item .imgs {
        margin-bottom: 15px;
    }
    .apply.hhv-apply-dvgtgt .sub-tabs .subright .item .text {
        width: 100%;
    }
    .main-pay .content.tabs.hhv-info-private .tabcontent .col-payl .row-group label {
        width: 100%;
        margin-bottom: 15px;
    }
    .main-pay .content.tabs.hhv-info-private .tabcontent .field .control {
        padding-left: 0;
    }
    .hhv-data-internet .goi-cuoc .minmax .ll-gg {
        display: block;
    }
    .hhv-data-internet .goi-cuoc .minmax .ll-gg .ll {
        border-right: 0;
    }
    .hhv-phuong-an .content.tabs .tabs-content .sub-tabs .tabcontent .inner-tabs .hhv-row-field .hhv-col33 {
        width: 100%;
        margin: 10px 0;
    }
    .hhv-form-hopdong .hhv-hopdong .items {
        width: 100%;
        margin: 10px 0;
    }
    .hhv-form-hopdong .hhv-hopdong .items .inner-item .radio-custom1 {
        padding: 10px;
    }
    .support-all .support-inner #box-chat {
        width: 320px;
    }
    .support-all .support-inner #box-chat .head-chat {
        padding: 10px;
    }
    .support-all .support-inner #box-chat .head-chat .item-info .title-chat {
        font-size: 16px;
    }
    .support-all .support-inner #box-chat .footer-chat {
        display: block;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat {
        float: left;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-first {
        margin-right: 0;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat #menuChat {
        padding: 10px 30px;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom #menuInfo {
        padding: 10px 30px;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat div.wp-submenu:before {
        right: auto;
        left: 30px;
    }
    .page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item {
        display: block;
    }
    .page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-content .box-img {
        margin-right: 15px;
        margin-bottom: 20px;
    }
    .page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .inner-item .item-price {
        text-align: left;
    }
    .hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control {
        display: block;
    }
    .hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input {
        padding: 0 10px 0;
    }
    .hhv-ktdh-ttdh .box-table .inner-table {
        overflow: scroll;
    }
    .hhv-ktdh-ttdh .box-table .inner-table .table-ktdh-ttdh {
        width: 560px;
    }
    .main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh {
        box-shadow: none;
        border-radius: 10px;
        border: 1px solid #ddd;
    }
    .main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td {
        padding: 0 15px;
        font-size: 15px;
    }
    .main-ktdh-ttdh .hhv-ktdh-ttdh .box-table .table-ktdh-ttdh tr td > span {
        padding: 15px 0;
    }
    .hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content:before {
        width: 80%;
    }
    .hhv-ctdh .content-ctdh {
        display: block;
    }
    .hhv-ctdh .content-ctdh .left-ctdh {
        margin-bottom: 30px;
    }
    .hhv-ctdh .content-ctdh .right-ctdh,
    .hhv-ctdh .content-ctdh .left-ctdh {
        width: 100%;
    }
    .hhv-ctdh .content-ctdh .left-ctdh .item-product {
        display: block;
    }
    .hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info {
        margin-bottom: 10px;
    }
    .hhv-ctdh .content-ctdh .left-ctdh .item-product .item-info .detail .title {
        font-size: 18px;
    }
    .hhv-list-address-cn .per .choose-time .boxleft .datepicker .item-date {
        width: calc(50% - 5px);
    }
    div.page-result-search div.menu-search ul.listmenu li {
        display: block;
        margin: 10px 0;
    }
    div.page-result-search .inner-search .child-item .inner-itm .item-img {
        width: 100%;
    }
    div.page-result-search .inner-search .child-item .inner-itm .item-content {
        display: block;
        overflow: visible;
    }
    div.page-result-search .inner-search .child-item .inner-itm .item-content .box-info .pr .pr-old {
        display: block;
        padding: 10px 0;
    }
    div.page-result-search .inner-search .child-item {
        box-shadow: 0px 0px 15px rgba(39, 52, 125, 0.12);
    }
    .view-all-uds .uds__box__content .item-inner .imgs img {
        width: 100%;
    }
    .popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info {
        margin: 0 auto;
    }
    .popup-register-package .hhv-register-pk .items .inner-item .radio-custom1 .info .txt-sim {
        font-size: 14px;
        line-height: 18px;
    }
    .hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-img {
        width: 100%;
        height: auto;
        float: none;
    }
    .hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content {
        overflow: visible;
        display: block;
    }
    .hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info h2 span {
        display: block;
    }
    .hhv-combo-all .hhv-combo-service .child-item .inner-itm .item-content .box-info .pr .pr-old {
        display: block;
        padding: 10px 0;
    }
    .hhv-combo-all .title-box a.viewall {
        position: static;
    }
    .convert-pay-after .convert-pay-after__item .item-report-detail {
        padding: 30px 15px;
    }
    .convert-pay-after-tabs .btabs .tab-title li a.radio-custom {
        font-size: 18px;
    }
    .convert-pay-after-tabs .address .field .control {
        display: inline-block;
        padding: 0;
        width: 100%;
    }
    .convert-pay-after-detail-right .field .control {
        display: inline-block;
        padding: 0;
        width: 100%;
    }
    .search__maintain .tab-title li a,
    .transfers__wrapper .tab-title li a,
    .transfers__wrapper .tab-title li a {
        font-size: 16px !important;
    }
    .search-store .search-address .inner-search-address {
        display: block;
    }
    .search-store .search-address .inner-search-address .ctl-search {
        margin: 10px 0;
    }
    .hhv-location-service-provision .form-search .inner-form div.field {
        width: 100%;
    }
    .hhv-location-service-provision .form-search .inner-form div.field label {
        display: block;
        margin-bottom: 5px;
    }
    .hhv-location-service-provision .form-search .inner-form div.field .control {
        padding-left: 0;
    }
    .hhv-location-service-provision .form-result .item-search {
        width: 100%;
        padding: 15px 20px;
    }
    .form-tchddt .field .control {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding: 0;
    }
    .form-tchddt .field label {
        padding: 0 0 10px;
    }
    .table-tkdh-result .box-table {
        overflow-x: scroll;
        border-right: 1px solid #ddd;
    }
    form.hhv-custom-form .field {
        display: block;
        margin-bottom: 20px;
    }
    .hhv-tchdbh .error-report-detail__item + div {
        margin-top: 0;
    }
    form.hhv-custom-form .field label {
        width: 100%;
    }
    h3.sub-title {
        font-size: 18px;
    }
    .hhv-head-tracuocth .charges-acc {
        display: block;
    }
    .hhv-head-tracuocth .charges-acc .charges-if {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #efefef;
        padding: 20px;
    }
    .hhv-head-tracuocth .charges-acc .charges-pr {
        width: 100%;
        padding: 20px;
    }
    .hhv-head-tracuocth .charges-choose-month .charges-choose-month__inner {
        display: block;
        padding: 20px;
    }
    .hhv-head-tracuocth .charges-choose-month .charges-choose-month__inner .hhv-field-radio {
        margin-bottom: 20px;
    }
    .box-choose-time-search .box-choose-time-search-inner .hhv-field-radio {
        display: block;
    }
    .box-choose-time-search .box-choose-time-search-inner .charges-price__date {
        display: block;
    }
    .box-choose-time-search .box-choose-time-search-inner .charges-price__date .field {
        margin: 10px 0;
    }
    .box-choose-time-search .box-choose-time-search-inner .charges-price__date .field label {
        min-width: 90px;
    }
    .wp-hhv-choose-form .list-choose-form-three-column .item-form,
    .hhv__trade__sim__request__content_ptgh,
    .hhv__trade__sim__request__content .hhv__trade__sim__request__content__two_column div.field,
    .hhv__trade__sim__request__content .hhv__trade__sim__request__content__three_column div.field {
        width: 100%;
    }
    .method-total {
        border: 1px solid #ddd;
        box-shadow: none;
    }
    .hhv-table-result-roaming {
        overflow: scroll;
    }
    .hhv-table-result-roaming .hhv-table-roaming {
        width: 720px;
    }
    .hhv-search-result-roaming .field label {
        padding: 0 0 10px;
    }
    .hhv-search-result-roaming .field .control {
        padding: 0;
    }
    .tabs-utilities .list-tracuu .list-inner .item {
        width: 50%;
    }
    .tabs-utilities .list-tracuu .list-inner .item .item__title {
        font-size: 14px;
        line-height: 24px;
    }
    footer.footer .footer-top .footer-inner .column.first {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .main-pay .content.tabs .tabcontent .field .control {
        padding: 0 !important;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group label {
        padding: 0 0 10px;
        display: block;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency {
        margin: 10px 0;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency > ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency > ul li {
        width: 32% !important;
        margin: 5px 0 !important;
    }
}

.j-tabs .tab-link {
    cursor: pointer;
}

.j-tabs .tab-content {
    display: none;
}

.j-tabs .tab-content.current {
    display: inherit;
}

.d-flex__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
}

.d-flex__item {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 1;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
}

[class*="__flex"] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

[class*="__flex"]--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

[class*="__flex"]::after {
    display: none;
}

.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-spacebetween {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.flex-spacebetween::after {
    display: none;
}

.align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

input:-moz-read-only {
    color: #e7e7e7;
    cursor: default;
}

input:read-only {
    color: #e7e7e7;
    cursor: default;
}

.radio-custom .checkmark--right {
    right: 0;
    left: auto;
}

.radio-custom .checkmark--top {
    top: 0 !important;
    -webkit-transform: translate(0) !important;
    -ms-transform: translate(0) !important;
    transform: translate(0) !important;
}

.btn {
    font-weight: 300;
}

.thumb-img {
    position: relative;
    height: 0;
    overflow: hidden;
}

.thumb-img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.thumb-1x1 {
    padding-bottom: 100%;
}

.thumb-5x3 {
    padding-bottom: 60%;
}

.thumb-16x9 {
    padding-bottom: 56.25%;
}

a {
    color: inherit;
}

.center {
    margin: 0 auto;
}

.txt-title {
    font-family: "Montserrat";
}

.sub-title {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: calc(28 / 24);
}

.clearfix::before,
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.box-shadow {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
}

.txt-black {
    color: #0c0033;
}

.txt-blue-light {
    color: #14d6cf;
}

.txt-blue {
    color: #ee0033;
}

.txt-grey {
    color: #576c8a;
}

.txt-red {
    color: #ff0000;
}

.txt-gold {
    color: #fe9a00;
}

.txt-medium {
    font-weight: 500;
}

.txt-bold {
    font-weight: 700;
}

.txt-20 {
    font-size: 20px;
}

.txt-24 {
    font-size: 24px;
}

.txt-18 {
    font-size: 18px;
}

.txt-16 {
    font-size: 16px;
}

.txt-12 {
    font-size: 12px;
}

.txt-36 {
    font-size: 36px;
}

.txt-underline {
    text-decoration: underline;
}

.txt-uppercase {
    text-transform: uppercase;
}

.big-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
}

.form-note {
    display: block;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #576c8a;
    font-family: "Roboto";
    text-transform: none;
}

.w-100 {
    width: 100%;
}

@media (min-width: 1200px) {
    .lg\:w-100 {
        width: 100% !important;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .md\:w-100 {
        width: 100% !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .sm\:w-100 {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .xs\:w-100 {
        width: 100% !important;
    }
}

.radio-custom input:checked ~ .check-mark--multicolor::after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.08496 1.28571C4.81143 1.28571 1.37068 4.72647 1.37068 9C1.37068 13.2735 4.81143 16.7143 9.08496 16.7143C13.3585 16.7143 16.7992 13.2735 16.7992 9C16.7992 8.64971 16.7556 8.34777 16.6987 7.955C16.6917 7.90654 16.6845 7.85669 16.6771 7.8052C16.6269 7.45373 16.8711 7.1281 17.2226 7.07789C17.5741 7.02768 17.8997 7.2719 17.9499 7.62337C17.9569 7.6725 17.9641 7.72172 17.9713 7.77114C18.0273 8.15547 18.085 8.55121 18.085 9C18.085 13.9836 14.0686 18 9.08496 18C4.10135 18 0.0849609 13.9836 0.0849609 9C0.0849609 4.01639 4.10135 0 9.08496 0C10.6008 0 11.9951 0.345647 13.2543 1.04518C13.5647 1.21761 13.6765 1.60898 13.5041 1.91934C13.3316 2.2297 12.9403 2.34152 12.6299 2.1691C11.5748 1.58292 10.3977 1.28571 9.08496 1.28571Z' fill='%2314AFB4'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.8937 1.47048C18.1464 1.71989 18.149 2.12691 17.8996 2.3796L9.01652 11.3796C8.89573 11.502 8.73094 11.5709 8.55899 11.5709C8.38703 11.5709 8.22225 11.502 8.10146 11.3796L4.77029 8.0046C4.52088 7.75191 4.52354 7.34489 4.77623 7.09548C5.02892 6.84608 5.43594 6.84874 5.68535 7.10143L8.55899 10.0129L16.9846 1.47643C17.234 1.22374 17.641 1.22108 17.8937 1.47048Z' fill='%23FE9A00'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.btn.txt-red {
    border-color: #ff0000;
    border-width: 1px;
}

.btn.txt-red:hover {
    background-color: #ff0000;
}

.btn-small-border {
    border-width: 1px;
}

.btabs.txt-center .tab-title li {
    float: none;
    display: inline-block;
}

.btabs.txt-center .tab-title li + li {
    margin-left: 70px;
}

.btabs.txt-center .tab-title li a {
    padding-left: 0;
    padding-right: 0;
}

.form-group-item .btn-upload .preview {
    position: relative;
    z-index: 1;
}

.tab-title--ver2 li + li {
    margin-left: 40px;
}

.tab-title--ver2 li a {
    padding-left: 0;
    padding-right: 0;
}

.col-360 {
    width: 360px;
    max-width: 100%;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #b5b4b4;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #b5b4b4;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #b5b4b4;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

input::placeholder,
textarea::placeholder {
    color: #b5b4b4;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.data__sharing__content .control input {
    color: inherit;
}

.box-login a:not(.btn) {
    text-decoration: underline;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-150 {
    margin-top: 150px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-150 {
    margin-bottom: 150px;
}

@media (max-width: 767px) {
    .mb-150 {
        margin-bottom: 50px;
    }
}

.pt-0 {
    padding-top: 0;
}

.c-personal-info {
    margin-bottom: 70px;
}

.c-personal-info .content .tit .btabs {
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .c-personal-info {
        padding-top: 20px;
    }
    .c-personal-info .tit {
        margin-bottom: 60px;
    }
}

.c-personal-info .tab-title {
    clear: both;
    text-align: center;
}

.c-personal-info .tab-title::before,
.c-personal-info .tab-title::after {
    content: "";
    display: table;
    clear: both;
}

.c-personal-info .tab-title:after {
    display: block;
    content: "";
    clear: both;
}

.c-personal-info .tab-title li {
    float: none;
    display: inline-block;
}

.c-personal-info .tab-title li a {
    padding-left: 0;
    padding-right: 0;
    font-size: 20px;
    line-height: 180%;
}

@media (min-width: 992px) {
    .c-personal-info .tab-title li + li {
        margin-left: 83px;
    }
}

.c-personal-info .tbl-cal th {
    width: auto;
}

.c-personal-info .col-cartr a.btn-blue {
    font-weight: 300;
}

.frm-cart--personal-info .form-field {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 40px 30px;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .frm-cart--personal-info .cf {
        width: 100%;
    }
    .frm-cart--personal-info .cf + .cf {
        margin-top: 20px;
    }
}

.form-group--action .btn {
    width: 165px;
    max-width: 100%;
    border-width: 1px;
}

.form-group--action .btn.btn-large {
    padding-top: 16px;
    padding-bottom: 16px;
    min-width: 218px;
}

@media (max-width: 479px) {
    .form-group--action .btn {
        width: auto;
    }
}

.form-group--action .btn:not(.btn-blue) {
    font-family: "Roboto";
}

.form-group--action .btn + .btn {
    margin-left: 30px;
}

.btn-upload {
    width: 180px;
    height: 180px;
    background: #e7e7e7;
    border-radius: 10px;
    position: relative;
    margin: 0 auto;
}

.btn-upload label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.btn-upload label i {
    font-size: 32px;
    color: #ee0033;
}

.file-upload {
    display: none;
}

.frm-upload form {
    width: 89%;
    margin: 0 auto 64px;
    text-align: center;
}

.frm-upload .txt-blue-light {
    margin-bottom: 60px;
}

.frm-upload .txt-black {
    margin-bottom: 40px;
}

.frm-upload .upload-note {
    color: #14d6cf;
    margin-bottom: 60px;
    display: block;
}

.frm-upload .agree {
    font-style: normal;
}

.frm-upload .checkbox {
    display: inline-block;
    margin-bottom: 30px;
}

.form-group--upload {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.form-group--upload::after {
    display: none;
}

.form-group--upload .label {
    color: #576c8a;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 767px) {
    .form-group--upload .form-group-item {
        width: 100%;
        text-align: center;
    }
    .form-group--upload .form-group-item + .form-group-item {
        margin-top: 30px;
    }
}

.modal .field .control input,
.modal .field .control textarea,
.modal .field .control select {
    font-size: 14px;
}

.ll-gg--v2 {
    border-radius: 10px;
}

.ll-gg--v2 > .title {
    font-size: 24px;
    padding: 25px 30px;
    text-align: left;
}

.ll-gg--v2 .body {
    padding: 32px 30px;
}

.ll-gg--v2 + .ll-gg--v2 {
    margin-top: 60px;
}

.ll-gg--v2 .field label.pt-0 {
    padding-top: 0;
}

.ll-gg--v2 .field + .field {
    margin-top: 15px;
}

.ll-gg--v2 .field .control {
    min-height: 50px;
    padding-left: calc((100% - 180px * 3) / 2 + 180px);
}

.ll-gg--v2 .field .control strong {
    font-size: 18px;
    color: #0c0033;
}

.ll-gg--v2 .field .control--w100 {
    width: 100%;
    float: left;
}

.ll-gg--v2 .field .control--w100 .ll-gg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 767px) {
    .ll-gg--v2 .field label {
        padding-top: 0;
        margin-bottom: 10px;
    }
    .ll-gg--v2 .field + .field {
        margin-top: 0;
    }
    .ll-gg--v2 .field .control {
        padding-left: 0;
        width: 100%;
    }
    .ll-gg--v2 .field--method {
        margin-top: 20px !important;
        margin-bottom: 20px;
    }
}

.o-goicuoc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px 0 0 10px;
}

.o-goicuoc__name {
    width: 30%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #ededed;
    padding: 16px;
}

.o-goicuoc__name h2 {
    color: #0c0033;
    font-size: 36px;
    font-family: "Montserrat";
}

.o-goicuoc__speed {
    width: 30%;
    padding-bottom: 20px;
    border-right: 1px solid #ededed;
}

.o-goicuoc__speed .title {
    border-radius: 0;
}

.o-goicuoc__price {
    width: 40%;
    padding-bottom: 20px;
}

.o-goicuoc__price .title {
    border-top-left-radius: 0;
}

@media (max-width: 991px) and (min-width: 768px) {
    .o-goicuoc__name h2 {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .o-goicuoc > div {
        width: 100% !important;
    }
}

.field--method label {
    font-size: 18px;
    display: block;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid #576c8a;
}

.field--method select {
    border: none;
    padding-left: 0;
    padding-right: 45px;
    font-size: 20px;
    color: #576c8a;
    line-height: 1.8;
    font-weight: 400;
    padding-top: 4px;
    padding-bottom: 4px;
    width: auto;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.863231 0.863231C1.12358 0.602881 1.54569 0.602881 1.80604 0.863231L8.0013 7.05849L14.1966 0.863231C14.4569 0.602881 14.879 0.602881 15.1394 0.863231C15.3997 1.12358 15.3997 1.54569 15.1394 1.80604L8.47271 8.47271C8.21236 8.73306 7.79025 8.73306 7.5299 8.47271L0.863231 1.80604C0.602881 1.54569 0.602881 1.12358 0.863231 0.863231Z' fill='%23576C8A'/%3e%3c/svg%3e ");
}

.hhv-pay-total--v2 {
    background: rgba(255, 213, 0, 0.15);
    padding: 30px;
    width: calc(100% + 60px);
    margin: 0 -30px -32px;
}

.hhv-pay-total--v2 .el-total {
    margin: 0 -30px 20px;
    padding: 0 30px 20px;
}

.hhv-pay-total--v2::before,
.hhv-pay-total--v2::after {
    display: none;
}

.row-group--method label {
    padding-top: 0;
}

.row-group--method .radio-custom1 {
    width: 100%;
    padding: 2px 0 0 32px;
    font-weight: 400;
}

.row-group--method .radio-custom1:not(:last-child) {
    margin-bottom: 20px;
}

.row-group--method .radio-custom1 input:checked ~ .checkmark ~ span {
    font-weight: 400;
}

.customer-info {
    padding: 10px 30px 30px;
}

.customer-info li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.customer-info li + li {
    margin-top: 20px;
}

.customer-info li .label {
    width: calc((100% - 180px * 3) / 2 + 180px);
    max-width: 100%;
    color: #576c8a;
}

.customer-info li .label i {
    display: inline-block;
    width: 20px;
}

.customer-info li .value {
    color: #0c0033;
}

@media (max-width: 767px) {
    .customer-info li {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .customer-info li .label {
        width: 100%;
        margin-bottom: 10px;
    }
}

.control--capcha {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.control--capcha input {
    width: calc(100% - 4.4% - 1.3% - 130px);
}

@media (max-width: 767px) {
    .control--capcha input {
        width: 50%;
    }
}

.control--capcha__img {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    height: 52px;
    padding: 5px 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 4.4%;
    width: 106px;
}

.control--capcha__img img {
    display: block;
    max-width: 100%;
}

.control--capcha__reset {
    width: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 1.3%;
    background: transparent;
}

.viettel-intro {
    margin-bottom: 40px;
}

.viettel-intro h2.title-h2 {
    margin-bottom: 40px;
    text-transform: uppercase;
}

.viettel-intro .tabs .tit {
    margin-bottom: 67px;
    margin-bottom: 4.65277778vw;
}

@media (max-width: 767px) {
    .viettel-intro .tabs .tit {
        padding: 0 15px;
    }
}

.viettel-intro .tab-title {
    text-align: center;
    clear: both;
}

.viettel-intro .tab-title::before,
.viettel-intro .tab-title::after {
    content: "";
    display: table;
    clear: both;
}

.viettel-intro .tab-title:after {
    display: block;
    content: "";
    clear: both;
}

.viettel-intro .tab-title li {
    float: none;
    display: inline-block;
}

.viettel-intro .tab-title li + li {
    margin-left: 100px;
}

.viettel-intro .tab-title li a {
    padding: 0 0 8px;
    font-size: 20px;
}

.e-cover-img {
    border-radius: 10px;
    overflow: hidden;
}

.intro-text .big-title {
    margin-bottom: 30px;
}

.intro-text p {
    margin-bottom: 30px;
    line-height: 180%;
    color: #474747;
}

.intro-view-file a {
    font-size: 20px;
    color: #576c8a;
}

.intro-view-file a i {
    margin-right: 10px;
}

.intro-view-file a:hover {
    color: #0c0033;
}

.intro-view-file span {
    color: #696969;
}

@media (max-width: 767px) {
    .intro-view-file a {
        font-size: 18px;
        display: block;
        margin-bottom: 10px;
    }
}

.intro-statistics {
    background: #f7f7f7;
    padding: 64px 0;
}

.intro-statistics__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.intro-statistics__item {
    width: calc(50% - 15px);
    padding: 45px 25px;
    text-align: center;
}

.intro-statistics__item .big-title a {
    line-height: 1.5;
}

.intro-statistics__item .big-title a:hover {
    color: #14d6cf;
}

.intro-statistics__item .icon {
    display: inline-block;
    margin-bottom: 30px;
}

.intro-statistics__item .icon + * {
    margin-bottom: 8px;
}

.intro-statistics__item > a {
    color: #0c0033;
    text-decoration: underline;
    line-height: calc(54 / 16);
    font-weight: 700;
}

.intro-statistics__item > a:hover {
    color: #ee0033;
}

.intro-statistics__item:nth-child(-n + 2) {
    margin-bottom: 30px;
}

.intro-statistics__item:nth-child(even) {
    margin-left: 30px;
}

@media (max-width: 767px) {
    .intro-statistics__item {
        width: 100%;
    }
    .intro-statistics__item:nth-child(even) {
        margin-left: 0;
    }
    .intro-statistics__item:nth-child(3) {
        margin-bottom: 30px;
    }
}

.intro-brand {
    margin-top: 60px;
}

.intro-brand__wrap {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    padding: 30px 20px;
    border-radius: 10px;
}

.intro-brand__title {
    font-size: 24px;
    color: #ee0033;
    margin-bottom: 48px;
}

.intro-brand__desc {
    line-height: 1.8;
    color: #0c0033;
}

.service-link-slider h3 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.service-link-slider h3 a {
    color: #ee0033;
}

.service-link-slider h3.active {
    font-weight: 700;
}

.service-link-slider h3.active a {
    color: #576c8a;
}

@media (min-width: 992px) {
    .service-link-slider .owl-carousel {
        padding: 0 90px;
    }
}

@media (max-width: 767px) {
    .service-link-slider .owl-carousel .owl-stage-outer {
        padding-top: 30px;
    }
    .service-link-slider .owl-carousel h3 {
        font-size: 16px;
    }
}

.service-steps {
    background: #f7f7f7;
    padding: 30px 0;
    margin-bottom: 64px;
    margin-bottom: 4.44444444vw;
}

.service-steps__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.service-steps__item {
    width: calc((100% - 90px) / 4);
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px 15px;
    text-align: center;
}

.service-steps__item .icon {
    display: block;
    margin-bottom: 16px;
}

.service-steps__item > p {
    font-weight: 500;
    font-size: 18px;
    color: #ee0033;
    line-height: 1.33;
}

.service-steps__item:hover {
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
}

@media (min-width: 992px) {
    .service-steps__item + div:not(:nth-child(4n + 1)) {
        margin-left: 30px;
    }
    .service-steps__item:nth-child(n + 5) {
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    .service-steps__item {
        width: calc(50% - 15px);
    }
    .service-steps__item + div:not(:nth-child(2n + 1)) {
        margin-left: 30px;
    }
    .service-steps__item:nth-child(n + 3) {
        margin-top: 20px;
    }
}

.service-steps-details .content > h3 {
    font-size: 24px;
    color: #0c0033;
    margin-bottom: 20px;
    line-height: 1.33;
}

.service-steps-details .content > h3 ~ p {
    padding-left: 30px;
    padding-left: 2.08333333vw;
}

.service-steps-details .content > h3 + h3 {
    margin-top: 64px;
}

.service-steps-details .content > h4 {
    padding-left: 30px;
    padding-left: 2.08333333vw;
    font-size: 20px;
    font-weight: 500;
    color: #0c0033;
    margin-bottom: 20px;
    line-height: 1.33;
}

.service-steps-details .content > h4 ~ p {
    padding-left: 60px;
    padding-left: 4.16666667vw;
}

.service-steps-details .content > p {
    margin-bottom: 10px;
    color: #576c8a;
    line-height: 1.8;
}

.service-steps-details .content > p.dv-cungcap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.service-steps-details .content > p.dv-cungcap label {
    font-size: 20px;
    font-weight: 500;
}

.service-steps-details .content > p.dv-cungcap a {
    font-size: 20px;
    letter-spacing: 0.125px;
    color: #ee0033;
    text-decoration: underline;
}

.service-steps-details .content > p + h3 {
    margin-top: 64px;
    margin-top: 4.44444444vw;
}

.service-steps-details .content > p + h4 {
    margin-top: 30px;
    margin-top: 2.08333333vw;
}

.service-steps-details .content > p + p {
    padding-top: 10px;
    border-top: 1px solid rgba(197, 200, 219, 0.3);
}

.service-report {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    margin-bottom: 64px;
    margin-bottom: 4.44444444vw;
}

.service-report__head {
    background: rgba(20, 175, 180, 0.15);
    border-radius: 10px 10px 0px 0px;
    height: 71px;
    line-height: 71px;
    text-align: center;
    font-size: 24px;
}

.service-report__head h3 {
    color: #ee0033;
}

.service-report__body {
    padding: 30px 30px 60px;
}

@media (max-width: 767px) {
    .service-report__body {
        padding: 15px 15px 30px;
    }
}

.report-tabs__nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40px;
}

.report-tabs__nav li {
    width: 25%;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    color: #ee0033;
    padding: 15px;
    border: 2px solid #eef1f7;
}

.report-tabs__nav li:first-child {
    border-radius: 10px 0px 0px 10px;
}

.report-tabs__nav li:last-child {
    border-radius: 0px 10px 10px 0px;
}

.report-tabs__nav li + li {
    border-left: 0;
}

.report-tabs__nav li:hover,
.report-tabs__nav li.current {
    background: #ee0033;
    border-color: #ee0033;
    color: #ffffff;
}

.report-tabs__content {
    color: #0c0033;
}

.report-tabs__content p + p {
    margin-top: 14px;
}

.dv-truyenhinh {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 64px;
}

.dv-truyenhinh li {
    width: calc(100% / 3 - 20px);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
}

.dv-truyenhinh li strong {
    display: block;
    font-size: 24px;
    color: #ee0033;
    margin-bottom: 20px;
    text-align: center;
}

.dv-truyenhinh li p {
    line-height: 1.8;
    color: #0c0033;
}

.dv-truyenhinh li p + p {
    margin-top: 4px;
}

.dv-truyenhinh li + li {
    margin-left: 30px;
}

@media (max-width: 767px) {
    .dv-truyenhinh {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .dv-truyenhinh li {
        width: 100%;
    }
    .dv-truyenhinh li + li {
        margin-left: 0;
        margin-top: 20px;
    }
}

.dv-thongtinll {
    margin-bottom: 48px;
}

.dv-thongtinll a {
    font-size: 20px;
    color: #576c8a;
    font-weight: 500;
}

.dv-thongtinll a:hover {
    color: #ee0033;
}

.dv-thongtinll a:first-child {
    float: left;
}

.dv-thongtinll a:last-child {
    float: right;
}

.dv-thongtinll a i {
    display: inline-block;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 179, 182, 0.18);
    width: 48px;
    height: 48px;
    margin-right: 12px;
    text-align: center;
    line-height: 48px;
    color: #ee0033;
}

@media (max-width: 767px) {
    .dv-thongtinll a {
        width: 100%;
    }
    .dv-thongtinll a + a {
        margin-top: 20px;
    }
}

.account-addional {
    min-height: 380px;
    max-height: 100%;
}

.account-addional h2.title-h2 {
    font-family: "Montserrat";
}

.account-addional .section-desc {
    font-size: 18px;
    color: #0c0033;
}

.account-addional__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.account-addional__item {
    width: calc((100% - 60px) / 3);
    padding: 15px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.account-addional__item + div {
    margin-left: 30px;
}

.account-addional__item .radio-custom {
    padding-left: 0;
}

.account-addional__item .radio-custom .checkmark {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.account-addional .owl-carousel .account-addional__item {
    width: 100%;
}

.account-addional .owl-carousel .owl-nav button.owl-prev {
    -webkit-transform: translate(-105%, -50%);
    -ms-transform: translate(-105%, -50%);
    transform: translate(-105%, -50%);
}

.account-addional .owl-carousel .owl-nav button.owl-next {
    -webkit-transform: translate(105%, -50%);
    -ms-transform: translate(105%, -50%);
    transform: translate(105%, -50%);
}

.account-addional__verification {
    width: 750px;
    max-width: 100%;
    margin: 60px auto;
}

.account-addional__verification h3 {
    font-size: 24px;
    line-height: calc(28 / 24);
    margin-bottom: 30px;
}

.account-addional__verification .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.account-addional__verification .form-group .btn {
    width: calc((100% - 30px) / 2);
    font-weight: 300;
    font-family: "Montserrat";
}

.account-addional__verification .form-group .btn + .btn {
    margin-left: 30px;
}

.account-addional__verification > p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.8;
    color: #474747;
}

@media (max-width: 767px) {
    .account-addional__wrap {
        margin-bottom: 30px;
    }
    .account-addional__item {
        width: 100%;
    }
    .account-addional__item + div {
        margin-left: 0;
        margin-top: 20px;
    }
    .account-addional__verification .form-group .btn {
        width: 100%;
    }
    .account-addional__verification .form-group .btn + .btn {
        margin-left: 0;
        margin-top: 20px;
    }
}

.txt-account__phone {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
}

.txt-account__user {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.txt-account__address {
    color: #576c8a;
}

.txt-account__address i {
    margin-right: 10px;
}

.form-info-verification .row-frm label {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 14px;
    display: block;
}

.form-info-verification .row-frm__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.form-info-verification .row-frm__item {
    width: calc((100% - 32px) / 3);
}

.form-info-verification .row-frm__item + div {
    margin-left: 16px;
}

.form-info-verification .row-frm + .row-frm {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .form-info-verification .row-frm__item {
        width: 100%;
    }
    .form-info-verification .row-frm__item + div {
        margin-left: 0;
        margin-top: 20px;
    }
}

#otp-verification {
    text-align: center;
}

#otp-verification .input-content {
    padding: 15px 60px;
}

#otp-verification .t-cen--btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#otp-verification .row-frm {
    text-align: left;
    margin-top: 65px;
}

#otp-verification .row-frm label {
    font-weight: 500;
    font-size: 16px;
    color: #0c0033;
}

#otp-verification .control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#otp-verification .box-capcha {
    height: 50px;
    margin-top: 0;
}

#otp-verification .box-capcha + input {
    width: calc(100% - 193px - 20px);
    margin-left: 20px;
}

#otp-verification .btn {
    width: 180px;
    max-width: 100%;
    border-width: 1px;
}

#otp-verification .btn + .btn {
    margin-left: 45px;
}

@media (max-width: 767px) {
    #otp-verification .white-popup-block {
        padding-top: 30px;
    }
    #otp-verification .input-content {
        padding: 0;
    }
    #otp-verification .t-cen--btn {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    #otp-verification .btn {
        width: 100%;
    }
    #otp-verification .btn + .btn {
        margin-left: 0;
        margin-top: 10px;
    }
}

.form-group--otp {
    display: inline-block;
    margin: 0 auto;
}

.form-group--otp .wrap {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto 30px;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
}

.form-group--otp input {
    background: #e7e7e7;
    box-sizing: border-box;
    border-radius: 6px;
    width: 67px;
    max-width: 67px;
    height: 76px;
    font-size: 30px;
    text-align: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 1;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
}

.form-group--otp input:focus {
    border: 1px solid #ee0033;
}

.form-group--otp input + input {
    margin-left: 30px;
}

.form-group--otp .otp-resend-btn {
    display: block;
    color: #ee0033;
    text-decoration: underline;
}

@media (max-width: 767px) {
    .form-group--otp input {
        width: calc((100% - 60px) / 4);
    }
    .form-group--otp input + input {
        margin-left: 20px;
    }
}

.j-accordion-btn {
    position: relative;
    cursor: pointer;
}

.j-accordion-btn::after {
    content: "";
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.20662 10.6404L0.329122 3.09411C-0.108597 2.61408 -0.108597 1.83578 0.329122 1.35598C0.76645 0.876133 1.47575 0.876133 1.91305 1.35598L7.99858 8.0333L14.0839 1.35617C14.5214 0.876327 15.2306 0.876327 15.668 1.35617C16.1055 1.83601 16.1055 2.61427 15.668 3.09431L8.79036 10.6406C8.57159 10.8805 8.28517 11.0004 7.99861 11.0004C7.71191 11.0004 7.42528 10.8803 7.20662 10.6404Z' fill='%2314D6CF'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.j-accordion-btn.active::after {
    -webkit-transform: translate(0, -50%) rotate(-180deg);
    -ms-transform: translate(0, -50%) rotate(-180deg);
    transform: translate(0, -50%) rotate(-180deg);
}

:root {
    --sub-color: #fe9a00;
    --main-color: #ee0033;
}

.svg-icon {
    max-width: 100%;
    max-height: 100%;
    fill: currentColor;
    color: inherit;
}

.svg-icon--trans {
    color: transparent;
}

[class*="sub-color"] {
    stroke: var(--sub-color);
}

.faq > .title-h2 {
    margin-bottom: 25px;
}

.faq-link-slider .owl-carousel .owl-stage {
    margin: 0 auto;
}

.faq-link-slider .item h3 {
    color: #576c8a;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.faq-link-slider .item h3.active {
    color: #ee0033;
    font-weight: 700;
}

@media (min-width: 992px) {
    .faq-link-slider .owl-carousel {
        padding: 0 90px;
    }
}

@media (max-width: 767px) {
    .faq-link-slider .owl-carousel .owl-stage-outer {
        padding: 30px 0;
    }
    .faq-link-slider .item h3 {
        font-size: 16px;
    }
}

.faq-form-search {
    margin-bottom: 57px;
}

.faq-form-search form label {
    color: #576c8a;
    font-size: 18px;
    display: block;
    margin-bottom: 16px;
}

.faq-form-search form .form-group {
    margin: 0 auto;
}

@media (min-width: 1200px) {
    .faq-form-search form .form-group {
        width: 66%;
    }
}

.faq-form-search form .form-group .control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.faq-form-search form .form-group .control input {
    padding-left: 35px;
    height: 44px;
    width: calc(100% - 165px - 30px);
    background: rgba(244, 248, 248, 0.788188);
    border-radius: 9px;
}

.faq-form-search form .form-group .control input:focus + .icon-i-search {
    color: #ee0033;
}

.faq-form-search form .form-group .control .btn {
    width: 165px;
    margin-left: 30px;
}

.faq-form-search form .form-group .control .icon-i-search {
    position: absolute;
    left: 12px;
    top: 16px;
    color: #576c8a;
}

.faq-list .svg-icon {
    width: 1em;
    height: 1em;
}

.faq-list__title {
    color: #0c0033;
    font-size: 24px;
    margin-bottom: 20px;
    padding-right: 20px;
}

.faq-list__title::after {
    top: 12px;
}

.faq-list__item + div {
    margin-top: 20px;
}

.faq-list__content {
    padding-left: 30px;
}

.faq-list__content .e-interaction {
    float: right;
}

.faq-list__label {
    float: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.faq-list__answer {
    clear: both;
    color: #252525;
    font-size: 16px;
    line-height: 180%;
    margin-bottom: 34px;
    margin-top: 5px;
    float: left;
}

.faq-list__answer::before,
.faq-list__answer::after {
    content: "";
    display: table;
    clear: both;
}

.faq-list__answer:after {
    display: block;
    content: "";
    clear: both;
}

.e-interaction {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #576c8a;
}

.e-interaction__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.e-interaction__item + .e-interaction__item {
    margin-left: 40px;
}

.e-interaction____like-amount {
    font-size: 14px;
}

.e-interaction span {
    font-size: 12px;
    line-height: 1;
    margin-left: 5px;
}

.e-comment {
    padding-left: 34px;
    margin-bottom: 60px;
    clear: both;
}

.e-comment__title {
    font-size: 18px;
    color: #576c8a;
    line-height: calc(24 / 18);
    font-weight: 400;
    padding: 4px 10px 6px;
    background: #f7f7f7;
}

.e-comment__item {
    padding: 5px 0 10px;
    color: #252525;
    border-bottom: 1px solid rgba(197, 200, 219, 0.3);
}

.e-comment__account {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}

.e-comment__account > * + * {
    margin-left: 10px;
}

.e-comment__reply {
    font-size: 16px;
    line-height: 1.8;
    padding-left: 10px;
}

.e-comment__avatar {
    border-radius: 50%;
    overflow: hidden;
    display: block;
    line-height: 1;
}

.e-comment__avatar img {
    display: block;
}

.e-comment__name {
    font-size: 16px;
}

.e-comment__name a:hover {
    color: #576c8a;
}

.e-comment__time {
    font-size: 12px;
    line-height: calc(14 / 12);
}

.navpage ul li.next a,
.navpage ul li.prev a {
    border-radius: 6px;
}

.navpage ul li.next a:hover,
.navpage ul li.prev a:hover {
    background: #14d6cf;
    color: #fff;
}

.page-qltkdd .pack-data__data,
.page-qltkdd .pack-data__date {
    margin-top: -130px;
}

.page-qltkdd .pack-data__data .highcharts-container,
.page-qltkdd .pack-data__date .highcharts-container {
    -webkit-transform: translate(0px, 88px);
    -ms-transform: translate(0px, 88px);
    transform: translate(0px, 88px);
}

@media only screen and (max-width: 767px) {
    .list-pack-data .nav,
    .list-pack-data .owl-nav button {
        top: 45%;
    }
}

.store-list .title-h2 {
    margin-bottom: 65px;
}

.store-list .dv-thongtinll {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.store-list .dv-thongtinll a {
    font-size: 16px;
    line-height: 180%;
    font-weight: 400;
}

.store-list .dv-thongtinll::after {
    display: none;
}

@media (max-width: 767px) {
    .store-list .title-h2 {
        margin-bottom: 25px;
    }
    .store-list .search-store {
        margin-bottom: 25px;
    }
}

/* Rating component */

.star-rating {
    position: relative;
    display: inline-block;
    font-size: 12px;
}

.star-rating i {
    color: #e7e7e7;
}

.star-rating i.active {
    position: absolute;
    color: #ffd500;
    overflow: hidden;
    display: inline-block;
}

.search-store-result__title {
    background: rgba(20, 175, 180, 0.15);
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    padding: 17px 20px;
    text-align: center;
    margin-bottom: 30px;
}

.search-store-result .row {
    margin-bottom: 58px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.search-store-result .row::after {
    display: none;
}

.store-info h4 {
    font-size: 24px;
    line-height: calc(28 / 24);
    color: #ee0033;
    font-weight: normal;
    margin-bottom: 17px;
}

.store-info > p {
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    font-weight: bold;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-info > p i {
    width: 36px;
    font-size: 24px;
}

.store-info > p + p {
    margin-top: 17px;
}

@media (max-width: 767px) {
    .store-info {
        margin-bottom: 30px;
    }
}

.store-rating-amount {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-rating-amount .star-rating {
    font-size: 16px;
    margin-right: 10px;
    float: left;
    margin-top: -2px;
}

.store-rating-amount__number {
    color: #576c8a;
    font-size: 16px;
}

.store-rating-list__item {
    padding-bottom: 10px;
    margin-bottom: 35px;
    border-bottom: 1px solid rgba(105, 105, 105, 0.3);
}

.store-rating-list__item h4 {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 5px;
    color: #0c0033;
}

.store-rating-list__item h4 a:hover {
    color: #576c8a;
}

.store-rating-list__activity {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #576c8a;
    font-weight: 700;
    margin-bottom: 6px;
}

.store-rating-list__activity > * + * {
    margin-left: 18px;
}

.store-rating-list__activity .like-btn i {
    margin-right: 8px;
}

.store-rating-list__comment {
    font-weight: bold;
    font-size: 16px;
}

.btn-view-all {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125px;
    color: #ee0033;
    text-decoration: underline;
}

.store-rating-detail {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 60px;
}

.store-rating-detail::after {
    display: none;
}

.store-rating-detail__left {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 31.5%;
    padding: 15px 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-rating-detail__left label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: calc(23 / 20);
}

.store-rating-detail__left strong {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-rating-detail__left strong span {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #ee0033;
    font-family: "Montserrat";
    margin-right: 10px;
}

.store-rating-detail__left strong .fs-star {
    font-size: 22px;
    color: #ffd500;
}

.store-rating-detail__right {
    width: calc(100% - 31.5% - 30px);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 15px 40px;
}

.store-rating-detail__right .inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.store-rating-detail__chart {
    width: 54%;
    padding-right: 30px;
}

.store-rating-detail__chart li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-rating-detail__chart li + li {
    margin-top: 4px;
}

.store-rating-detail__chart label {
    color: #576c8a;
    width: 60px;
    line-height: calc(24 / 14);
}

.store-rating-detail__chart .chart-bar {
    display: inline-block;
    width: calc(100% - 60px);
    background: #e7e7e7;
    border-radius: 6px;
    height: 6px;
    position: relative;
}

.store-rating-detail__chart .chart-bar span {
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #ee0033;
}

.store-rating-detail__btn {
    width: 46%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.store-rating-detail__btn .btn {
    width: 100%;
}

@media (max-width: 991px) {
    .store-rating-detail__chart {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }
    .store-rating-detail__btn {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .store-rating-detail__left {
        width: 100%;
        margin-bottom: 20px;
    }
    .store-rating-detail__right {
        width: 100%;
    }
}

.comment-list-title {
    font-size: 20px;
    line-height: calc(23 / 20);
}

.form-comment .btn {
    width: 360px;
    max-width: 100%;
}

.error-report {
    min-height: 380px;
    max-height: 100%;
}

.error-report h2.title-h2 {
    font-family: "Montserrat";
}

.error-report .section-desc {
    font-size: 18px;
    color: #0c0033;
}

.error-report__wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.error-report__item {
    width: calc((100% - 60px) / 3);
    padding: 15px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.error-report__item + div {
    margin-left: 30px;
}

.error-report__item .radio-custom {
    padding-left: 0;
}

.error-report__item .radio-custom .checkmark {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.error-report .owl-carousel .account-addional__item {
    width: 100%;
}

.error-report .owl-carousel .owl-nav button.owl-prev {
    -webkit-transform: translate(-105%, -50%);
    -ms-transform: translate(-105%, -50%);
    transform: translate(-105%, -50%);
}

.error-report .owl-carousel .owl-nav button.owl-next {
    -webkit-transform: translate(105%, -50%);
    -ms-transform: translate(105%, -50%);
    transform: translate(105%, -50%);
}

.error-report__verification {
    width: 750px;
    max-width: 100%;
    margin: 60px auto;
}

.error-report__verification h3 {
    font-size: 24px;
    line-height: calc(28 / 24);
    margin-bottom: 30px;
}

.error-report__verification .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.error-report__verification .form-group .btn {
    width: calc((100% - 30px) / 2);
    font-weight: 300;
    font-family: "Montserrat";
}

.error-report__verification .form-group .btn + .btn {
    margin-left: 30px;
}

.error-report__verification > p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.8;
    color: #474747;
}

@media (max-width: 767px) {
    .error-report__wrap {
        margin-bottom: 30px;
    }
    .error-report__item {
        width: 100%;
    }
    .error-report__item + div {
        margin-left: 0;
        margin-top: 20px;
    }
    .error-report__verification .form-group .btn {
        width: 100%;
    }
    .error-report__verification .form-group .btn + .btn {
        margin-left: 0;
        margin-top: 20px;
    }
}

.error-report h2.title-h2 {
    font-family: "Montserrat";
    text-transform: uppercase;
    margin-bottom: 60px;
}

.error-report .txt-account__phone {
    font-size: 24px;
    line-height: calc(28 / 24);
}

.error-report .radio-custom .checkmark--top {
    top: 4px !important;
}

.error-report .content .tit {
    margin-bottom: 60px;
}

.error-report .content .tit .btabs .tab-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.error-report .content .tit .btabs .tab-title li a {
    padding-left: 0;
    padding-right: 0;
    font-size: 20px;
}

.error-report .content .tit .btabs .tab-title li + li {
    margin-left: 144px;
}

.error-report__wrap {
    clear: both;
}

.error-report__wrap::before,
.error-report__wrap::after {
    content: "";
    display: table;
    clear: both;
}

.error-report__wrap:after {
    display: block;
    content: "";
    clear: both;
}

.error-report__item {
    padding: 30px;
    font-size: 16px;
    position: relative;
}

.error-report__item--request-history > p {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #576c8a;
    line-height: 180%;
}

.error-report__item--request-history > p i {
    font-size: 24px;
    margin-right: 18px;
}

.error-report__status {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: calc(23 / 20);
    font-weight: 700;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.error-report__status i {
    font-size: 24px;
    margin-right: 15px;
}

.error-report .txt-account__phone + .txt-account__address {
    margin-top: 60px;
}

.error-report__form {
    margin-top: 60px;
    margin-bottom: 60px;
}

.error-report__form form {
    width: calc((100% - 60px) / 3);
    margin: 0 auto;
}

.error-report__form form select {
    margin-bottom: 30px;
}

.error-report__form form .btn {
    width: 100%;
}

@media (max-width: 991px) {
    .error-report .content .tit .btabs .tab-title {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .error-report .content .tit .btabs .tab-title li + li {
        margin-left: 0;
    }
    .error-report__item {
        padding: 15px;
    }
    .error-report .txt-account__phone {
        word-break: break-all;
        padding-right: 30px;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .error-report__form form {
        width: 100%;
    }
}

.error-report-kqpt {
    line-height: 1.8;
    font-size: 16px;
    color: #576c8a;
}

.error-report-kqpt p {
    margin-bottom: 30px;
}

.error-report-kqpt p strong {
    color: #ee0033;
}

.error-report-kqpt p > .btn {
    width: calc((100% - 60px) / 3);
    max-width: 100%;
    border-width: 1px;
}

@media (max-width: 767px) {
    .error-report-kqpt p > .btn {
        width: 100%;
    }
}

.error-report-detail h2.title-h2 {
    margin-bottom: 40px;
}

.error-report-detail__info {
    color: #576c8a;
    font-size: 20px;
    text-align: center;
}

.error-report-detail__info li {
    display: inline-block;
}

.error-report-detail__info li + li {
    margin-left: 64px;
}

.error-report-detail__flex {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.error-report-detail__flex::after {
    display: none;
}

.error-report-detail__item {
    width: calc(50% - 15px);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    font-size: 16px;
    line-height: 1.8;
    color: #576c8a;
}

.error-report-detail__item.no-shadow {
    box-shadow: none;
    padding: 0;
}

.error-report-detail__item .edit-btn {
    position: absolute;
    color: #576c8a;
    font-size: 20px;
    right: 30px;
    top: 30px;
}

.error-report-detail__item .sub-title {
    clear: both;
    margin-bottom: 40px;
    color: #0c0033;
}

@media (min-width: 992px) {
    .error-report-detail__item .sub-title {
        width: calc(100% - 160px);
    }
}

.error-report-detail__item > p + p {
    margin-top: 15px;
}

.error-report-detail__item > p i {
    font-size: 24px;
    width: 40px;
    display: inline-block;
}

.error-report-detail__item > p strong {
    color: #0c0033;
}

.error-report-detail__item .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-report-detail__item .form-group__col {
    width: calc(50% - 15px);
    position: relative;
}

.error-report-detail__item .form-group input {
    padding-right: 40px;
}

.error-report-detail__item .form-group i {
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-size: 24px;
}

.error-report-detail__item .form-group i.fs-date {
    bottom: 10px;
}

.error-report-detail__item .form-group label {
    display: block;
    color: #0c0033;
    font-weight: 700;
    margin-bottom: 6px;
}

.error-report-detail__item .form-group:after {
    display: none;
}

.error-report-detail label.btn-white {
    width: calc(50% - 15px);
    border-width: 1px;
}

.error-report-detail .txt-center .btn-blue {
    width: 360px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .error-report-detail__item .form-group__col {
        width: 100%;
    }
    .error-report-detail__item .form-group__col + div {
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .error-report-detail__info li {
        width: 100%;
    }
    .error-report-detail__info li + li {
        margin-left: 0;
        margin-top: 10px;
    }
    .error-report-detail__item {
        width: 100%;
    }
    .error-report-detail__item + div {
        margin-top: 20px;
    }
    .error-report-detail__item .form-group__col {
        width: 100%;
    }
    .error-report-detail__item .form-group__col + div {
        margin-top: 10px;
    }
    .error-report-detail label.btn-white {
        width: 100%;
    }
}

.c-bar-process {
    padding: 0 0 45px;
}

.c-bar-process .inner {
    overflow: hidden;
}

.c-bar-process__content {
    max-width: 1100px;
}

.c-bar-process__content li {
    width: 25%;
    float: left;
    position: relative;
}

.c-bar-process__content li:first-child:after {
    width: 50%;
    left: 50%;
}

.c-bar-process__content li:last-child:after {
    width: 50%;
    left: 0;
}

.c-bar-process__content li.active .c-bar-process__check,
.c-bar-process__content li.activated .c-bar-process__check {
    background-color: #ee0033;
    color: #fff;
}

.c-bar-process__content li.active .c-bar-process__check:before,
.c-bar-process__content li.activated .c-bar-process__check:before {
    content: "\e920";
    font-family: "icon-theme" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13.5px;
}

.c-bar-process__content li.active .c-bar-process__text__bold,
.c-bar-process__content li.activated .c-bar-process__text__bold {
    color: #ee0033;
}

.c-bar-process__content li.activated:after {
    width: 100%;
    border-color: #d65470;
}

.c-bar-process__content li:after {
    background-color: #b70d02;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    top: 13px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.c-bar-process__content li .c-bar-process__check {
    background-color: #fff;
    border-radius: 50%;
    display: block;
    height: 26px;
    line-height: 26px;
    position: relative;
    text-align: center;
    width: 26px;
    z-index: 1;
    margin: 0 auto;
    border: 2px solid #ee0033;
}

.c-bar-process__text {
    text-align: center;
    display: block;
    padding: 0 20px;
}

.c-bar-process__text__bold {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    display: block;
    padding: 15px 0 2px;
}

.c-bar-process__text__normal {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #576c8a;
}

@media (max-width: 767px) {
    .c-bar-process__text {
        padding: 0 3px;
    }
}

.notification div:after {
    display: none;
}

.notification h2.title-h2 {
    margin-bottom: 65px;
}

.notification .tit {
    margin-bottom: 65px;
}

.notification__filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
}

.notification__filter select {
    width: 165px;
}

.notification__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
}

.notification__img {
    width: 135px;
}

.notification__info {
    width: calc(100% - 135px - 125px - 30px);
}

.notification__info > p {
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
}

.notification__title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.notification__title h3 {
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    margin-right: 15px;
}

.notification__title span {
    color: #576c8a;
    font-weight: 500;
}

.notification__action {
    width: 95px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.notification__action > a {
    font-size: 28px;
    color: #576c8a;
}

.notification__action > a:hover,
.notification__action > a.active {
    color: #ff0000;
}

.notification__action > a.fs-heart.active::before,
.notification__action > a.fs-heart:hover::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='28' height='27' viewBox='0 0 28 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M19.8329 0.00419878C18.745 0.0102752 17.6691 0.233333 16.6678 0.660414C15.6664 1.0875 14.7596 1.71009 14 2.49201C12.8723 1.32164 11.4223 0.515899 9.8357 0.178005C8.24908 -0.15989 6.59811 -0.0145504 5.09428 0.595404C3.59045 1.20536 2.30223 2.25216 1.39465 3.6017C0.487068 4.95124 0.00144869 6.54208 0 8.17042C0 15.6377 12.7196 26.1307 13.2618 26.5703C13.4705 26.7405 13.7312 26.8333 14 26.8333C14.2688 26.8333 14.5295 26.7405 14.7382 26.5703C15.2804 26.1307 28 15.6377 28 8.17042C27.9929 6.00103 27.1291 3.92302 25.5981 2.39214C24.0671 0.861258 21.9937 0.00249547 19.8329 0.00419878Z' fill='%23FF0048'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-size: contain;
}

.notification .tab-title li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.notification .tab-title li a:before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: #ff0000;
    margin-right: 8px;
}

.notification .tab-title li.active a::before {
    display: none;
}

.notification-qv {
    background: #ffffff;
    z-index: 99;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    color: #0c0033;
    position: absolute;
    width: 612px;
    right: -40px;
    display: none;
    margin-top: 21px;
}

.open .notification-qv {
    display: block;
}

.notification-qv::before {
    content: "";
    width: 0;
    height: 0;
    right: 40px;
    position: absolute;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #ffffff transparent;
    -webkit-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    transform: translate(0, -100%);
}

@media (max-width: 1199px) {
    .notification-qv {
        right: 0px;
    }
    .notification-qv::before {
        right: 0;
    }
}

@media (max-width: 991px) {
    .notification-qv {
        max-width: 100%;
    }
    .notification-qv::before {
        right: 160px;
    }
}

.notification-qv__list {
    max-height: calc(536px - 105px);
    padding: 25px 25px 0;
    position: relative;
}

.notification-qv__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.notification-qv__item::after {
    display: none;
}

.notification-qv__item:last-child {
    padding-bottom: 0;
}

.notification-qv__item + .notification-qv__item {
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #eaeaea;
}

.notification-qv__item--notseen .notification-qv__title h3 {
    font-weight: 700;
}

.notification-qv__item--notseen .notification-qv__info p {
    font-weight: 500;
}

.notification-qv__item--notseen .notification-qv__img {
    position: relative;
}

.notification-qv__item--notseen .notification-qv__img::after {
    content: "";
    width: 15px;
    height: 15px;
    background: #ff0000;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    -webkit-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.notification-qv__footer {
    padding: 25px;
}

.notification-qv__footer a {
    margin-left: calc(100px + 23px);
}

.notification-qv__img {
    width: 100px;
}

.notification-qv__info {
    width: calc(100% - 100px - 20px);
}

.notification-qv__info p {
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
}

.notification-qv__info span {
    color: #576c8a;
    font-size: 14px;
}

.notification-qv__title h3 {
    font-size: 24px;
    line-height: calc(28 / 24);
    font-weight: 500;
}

@media (max-width: 767px) {
    .notification__item {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 20px;
    }
    .notification__title {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .notification__title h3 {
        margin-right: 0;
        margin-bottom: 5px;
    }
    .notification__info {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.charges h2.title-h2 {
    margin-bottom: 65px;
}

.charges__col {
    width: calc(50% - 15px);
}

.charges-form .field input,
.charges-form .field select {
    font-size: 14px;
}

.charges-form__title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.charges-form__title::after {
    display: none;
}

.charges-form__title .icon-edit {
    font-size: 24px;
    color: #576c8a;
}

.charges-form + .charges-form {
    margin-top: 65px;
}

.charges-account {
    width: 263px;
    padding: 25px;
    text-align: center;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 6px;
}

.charges-account__image {
    display: inline-block;
    width: 100px;
    margin-bottom: 20px;
    border-radius: 50%;
    position: relative;
}

.charges-account__edit-img {
    position: absolute;
    bottom: -4px;
    right: -16px;
}

.charges-account__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #0c0033;
    margin-bottom: 15px;
}

.charges-account__phone {
    font-size: 18px;
    line-height: 21px;
    color: #ee0033;
}

.charges-account__phone span {
    display: inline-block;
    position: relative;
}

.charges-account__phone span + span {
    margin-left: 12px;
    padding-left: 12px;
}

.charges-account__phone span + span::before {
    content: "";
    width: 2px;
    height: 17px;
    background: #e7e7e7;
    position: absolute;
    left: 0;
}

.charges-account--ver2 {
    box-shadow: none;
    margin: 0 auto -10px;
    width: auto;
}

.charges-checkout {
    width: 360px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.charges-checkout::after {
    display: none;
}

.charges-checkout__debit {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 6px;
    padding: 25px;
    height: calc(100% - 52px - 16px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.charges-checkout__debit ul li span {
    display: inline-block;
    width: 132px;
}

.charges-checkout__debit ul li + li {
    margin-top: 15px;
}

.charges-checkout__account {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 6px;
    padding: 25px 30px;
    height: calc(100% - 52px - 42px);
}

.charges-checkout__account .item h4 {
    margin-bottom: 8px;
}

.charges-checkout__account .item strong {
    display: block;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    margin-bottom: 8px;
    font-weight: 400;
}

.charges-checkout__account .item span {
    line-height: 180%;
    color: #576c8a;
}

.charges-checkout__account .owl-carousel .owl-stage-outer {
    margin: 0;
    padding: 0;
}

.charges-checkout__account .owl-carousel .owl-dots {
    bottom: -55px;
}

.charges-checkout__account .owl-carousel .owl-dots .owl-dot {
    margin: 0 5px;
}

.charges-checkout__account .owl-nav button {
    box-shadow: none;
    background: transparent !important;
}

.charges-checkout__account .owl-nav button.owl-next {
    right: -20px;
    -webkit-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
}

.charges-checkout__account .owl-nav button.owl-prev {
    left: -20px;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}

.charges-checkout__account button.owl-dot span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    background: #c4c4c4;
}

.charges-checkout__account button.owl-dot.active span {
    width: 10px;
    background: #ee0033;
}

.charges-price {
    padding: 25px;
    width: calc(100% - 263px - 360px - 60px);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.charges-price .checkbox .checkmark {
    box-shadow: none;
}

.charges-price strong {
    display: block;
    margin-top: 5px;
}

.charges-price p {
    line-height: 180%;
}

.charges-price select {
    margin-bottom: 20px;
    width: 460px;
    max-width: 100%;
}

.charges-price--ver2 {
    display: block;
    width: auto !important;
    box-shadow: none;
}

.charges-price--ver2 strong {
    margin-top: 15px;
}

.charges-history {
    width: 42.8%;
}

.charges-history .btn {
    width: 360px;
    max-width: 100%;
    border-width: 1px;
}

.charges-history .btn + .btn {
    margin-top: 30px;
}

.charges-payment {
    width: 48.6%;
}

.charges-payment__header {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.charges-payment__header > a {
    margin-top: 5px;
}

.charges-payment__header::after {
    display: none;
}

.charges-payment__item {
    padding: 25px 30px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    margin-bottom: 30px;
}

.charges-payment__item:hover {
    box-shadow: none;
}

.charges-payment__flex {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e7e7e7;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.charges-payment__method label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
}

.charges-payment__method span {
    font-size: 12px;
    color: #576c8a;
}

.charges-payment .btn {
    width: 360px;
    max-width: 100%;
}

.charges-chitietsudung {
    width: calc((100% - 30px) / 2);
}

.charges-chitietsudung__list {
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 40px;
}

.charges-chitietsudung__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.charges-chitietsudung__item + li {
    margin-top: 24px;
}

.charges-chitietsudung__item > p {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.charges-chitietsudung__item > p span {
    display: inline-block;
    width: 40px;
}

.charges-chitietsudung__item > p strong {
    font-weight: 500;
    font-size: 16px;
    color: #0c0033;
}

.charges-chitietsudung__item > p strong em {
    font-size: 12px;
    color: #576c8a;
    font-style: normal;
    display: block;
    margin-top: 5px;
    font-weight: 400;
}

.charges-chitietsudung__item > strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
}

.charges-chitietcuoc {
    width: calc((100% - 30px) / 2);
}

.charges-chitietcuoc__item {
    padding: 15px 25px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.charges-chitietcuoc__item > span {
    display: inline-block;
    width: 50px;
}

.charges-chitietcuoc__item > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 50px);
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.charges-chitietcuoc__item > a i {
    font-size: 14px;
    color: #ee0033;
}

.charges-chitietcuoc__item + li {
    margin-top: 25px;
}

.charges-chitietcuoc__item:hover {
    box-shadow: none;
}

.charges--ver2 h2.title-h2 {
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .charges-price {
        width: calc(100% - 293px);
    }
    .charges-checkout {
        width: 100%;
        margin-top: 30px;
    }
    .charges-checkout__debit {
        height: auto;
        margin-bottom: 10px;
    }
    .charges-checkout__account {
        height: auto;
        margin-bottom: 40px;
    }
    .charges-checkout__account .owl-nav button.owl-prev {
        left: 10px;
    }
    .charges-checkout__account .owl-nav button.owl-next {
        right: 10px;
    }
}

@media (max-width: 767px) {
    .charges-chitietsudung {
        width: 100%;
    }
    .charges-chitietcuoc {
        width: 100%;
        margin-top: 30px;
    }
    .charges-account {
        width: 100%;
    }
    .charges-price {
        width: 100%;
        margin-top: 30px;
    }
    .charges-checkout {
        width: 100%;
        margin-top: 30px;
    }
    .charges-checkout__account .owl-nav button.owl-prev {
        left: 0px;
    }
    .charges-checkout__account .owl-nav button.owl-next {
        right: 0px;
    }
    .charges-history {
        width: 100%;
        margin-bottom: 30px;
    }
    .charges-payment {
        width: 100%;
    }
}

.form-group--upload-ver2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.form-group--upload-ver2::after {
    display: none;
}

.form-group--upload-ver2 .form-group-item {
    width: calc(50% - 15px);
    margin-bottom: 20px;
}

.form-group--upload-ver2 .btn-upload {
    width: 100%;
    max-width: 100%;
    height: 52px;
}

.wifi__col {
    width: calc(50% - 15px);
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.wifi-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wifi-card__left {
    width: 163px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #e7e7e7;
    padding: 20px 10px;
}

.wifi-card__status {
    font-size: 12px;
    color: #ee0033;
    margin-top: 28px;
    font-weight: 500;
}

.wifi-card__status::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    background: #6fcf97;
    margin-right: 5px;
}

.wifi-card__right {
    width: calc(100% - 163px);
}

.wifi-card__info {
    font-size: 16px;
    color: #576c8a;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 30px;
    min-height: 79px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.wifi-card__info ul {
    width: 100%;
}

.wifi-card__info li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.wifi-card__info li + li {
    margin-top: 25px;
}

.wifi-card__action {
    padding: 30px;
}

.wifi-card__action .btn {
    width: 300px;
    max-width: 100%;
    border-width: 1px;
}

.wifi-card__action .btn + .btn {
    margin-top: 20px;
}

.wifi-form {
    width: 596px;
    max-width: 100%;
    margin: 65px auto 0;
}

.wifi-form .box-capcha {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    min-width: 195px;
    position: relative;
    padding: 0 10px;
    height: 58px;
    margin-top: 6px;
}

@media (max-width: 991px) {
    .wifi__col + .wifi__col {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .wifi-card__right {
        width: 100%;
    }
    .wifi-form {
        margin: 30px auto 0;
        width: 100%;
    }
    .wifi-form .field label {
        color: #000;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        padding: 0;
        width: 100%;
    }
    .wifi-form .field .control {
        width: 100%;
        padding-left: 0;
    }
}

.location-change .btn--dang-ky-moi {
    width: calc((100% - 60px) / 3);
}

.location-change .btn--follow {
    font-size: 24px;
    color: #576c8a;
    position: absolute;
    top: 30px;
    right: 30px;
}

.location-change .error-report__form form {
    width: 750px;
    max-width: 100%;
}

.location-change form label {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 14px;
    display: block;
}

.location-change form .row-frm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.location-change form .row-frm::after {
    display: none;
}

.location-change form .row-frm__50 {
    width: calc(50% - 15px);
}

.location-change form .row-frm .control {
    width: 100%;
}

.location-change form .row-frm label {
    width: 100%;
}

.location-change .form-group--action .btn {
    width: calc((100% - 60px) / 3);
}

.location-change .error-report__form form select {
    margin-bottom: 0;
}

.location-change .txt-account__phone + .txt-account__address {
    margin-top: 10px;
}

@media (max-width: 767px) {
    .location-change .btn--dang-ky-moi {
        width: 100%;
    }
    .location-change form .row-frm__50 {
        width: 100%;
    }
    .location-change form .row-frm__50 + div {
        margin-top: 20px;
    }
    .location-change .form-group--action .btn {
        width: 100%;
    }
}

.form-thongtinthuebao {
    width: 750px;
    max-width: 100%;
}

.hhv-col50 .field label {
    width: 115px;
    margin-right: 15px;
    color: #0c0033;
    font-weight: 500;
    font-size: 16px;
    line-height: calc(24 / 16);
}

.hhv-col50 .field .control {
    width: calc(100% - 130px);
}

.hhv-info-adress h2.space1 {
    font-size: 20px;
    line-height: calc(23 / 20);
}

.form-info-adress .field label,
.form-info-adress .field .control input,
.form-info-adress .field .control textarea,
.form-info-adress .field .control select {
    font-size: 14px;
}

@media (min-width: 1200px) {
    .form-info-adress .hhv-col25 {
        width: 324px;
    }
    .form-info-adress .hhv-col75 {
        width: calc(100% - 294px - 30px - 70px);
    }
}

.hhv-submemnu {
    margin-bottom: 0;
}

.hhv-submemnu .list-menu li {
    padding: 12px 0;
}

.hhv-submemnu .list-menu li + li {
    margin-left: 40px;
}

@media (max-width: 991px) {
    .hhv-submemnu {
        overflow-x: scroll;
    }
    .hhv-submemnu .list-menu {
        white-space: nowrap;
    }
}

.hhv-col75 {
    width: 75%;
    float: left;
    padding: 0 15px;
}

@media (max-width: 767px) {
    .hhv-col75.hhv-xs-col100 {
        width: 100%;
    }
}

.hhv-list-internet a.viewall {
    font-size: 18px;
    line-height: calc(21 / 18);
}

@media (max-width: 767px) {
    .hhv-list-internet a.viewall {
        margin-bottom: 30px;
    }
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-content .box-btn .btn {
    min-width: 150px;
}

.box-element-detail .btn-large {
    width: 360px;
    max-width: 100%;
}

.row-frm--paymethod .row-frm__item:first-child {
    width: 230px;
    max-width: 100%;
}

.row-frm--paymethod .row-frm__item:last-child {
    width: calc(100% - 230px - 30px);
    max-width: 100%;
}

.row-frm--paymethod .control--capcha__img {
    margin-left: 30px;
    width: 100px;
}

.row-frm--paymethod .control--capcha__reset {
    margin-left: 15px;
}

.row-frm--paymethod .control--capcha input {
    width: calc(100% - 45px - 130px);
    max-width: 100%;
}

@media (max-width: 991px) {
    .row-frm--paymethod .row-frm__item:first-child {
        width: 100%;
        margin-bottom: 20px;
    }
    .row-frm--paymethod .row-frm__item:last-child {
        width: 100%;
    }
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-img {
    width: 104px;
    height: 104px;
    line-height: 104px;
    float: left;
    margin-right: 20px;
    font-family: Montserrat;
    color: #ffffff;
    font-weight: 500;
    font-size: 36px;
    background-color: #864fdf;
    text-align: center;
    border-radius: 6px;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-img.bg_orange {
    background-color: #ffba23;
}

.hhv-list-internet .lists.slider .item .list-internet .child-item .inner-itm .item-img.bg_red {
    background-color: #fb4600;
}

.hhv-mobile-service .inner-item .int strong {
    color: #576c8a;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    display: block;
}

.ll-gg p .sales {
    color: #fe9a00;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    background: rgba(255, 213, 0, 0.15);
    border-radius: 6px;
    padding: 5px 7px;
    vertical-align: text-bottom;
    margin-left: 6px;
    -webkit-text-decoration-line: none;
    -moz-text-decoration-line: none;
    text-decoration-line: none;
    margin-left: 0;
    margin-right: 5px;
}

.ll-gg--ver2 .ll {
    padding-bottom: 0;
    background: rgba(20, 175, 180, 0.15);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 0 !important;
}

.ll-gg--ver2 .ll .title {
    background: transparent;
    margin-bottom: 0;
}

.ll-gg--ver2 .gg {
    padding: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ll-gg--ver2 .gg .price {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.service-gtgt .list-gtgt .intro-more h2 {
    display: inline-block;
}

.service-gtgt .list-news .introtv {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.service-gtgt .list-news .introtv .coll {
    width: 60%;
}

.service-gtgt .intro-more h2 {
    color: #0c0033;
}

.service-gtgt .intro-more h2 i {
    margin-top: 5px;
}

.service-gtgt .introtv .coll .coll-inner > p {
    margin: 10px 0;
}

.service-gtgt .introtv .coll .coll-inner strong {
    font-size: 24px;
    line-height: 28px;
    padding: 0;
}

.service-gtgt .introtv__price {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-weight: bold;
}

.service-gtgt .introtv__label {
    color: #576c8a;
    margin-right: 8px;
}

.gtgt .intro-more__title .btn {
    width: 150px;
}

.service-gtgt .introtv .coll .coll-inner .new-desc {
    margin-bottom: 10px;
}

.has-sub {
    position: relative;
}

.has-sub .icon-i-triangle {
    margin-left: 5px;
    font-size: 5px;
}

.has-sub .current {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.has-sub .current span {
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.has-sub .current span i {
    margin-right: 5px;
    font-size: 12px;
}

.has-sub .submenu {
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    color: #576c8a;
    position: absolute;
    min-width: 126px;
    font-size: 14px;
    top: 30px;
    z-index: 98;
    display: none;
}

@media (max-width: 767px) {
    .has-sub .submenu {
        top: 20px;
    }
}

.has-sub .submenu::before {
    content: "";
    width: 15px;
    height: 15px;
    background: #ffffff;
    box-shadow: 0px -16px 16px rgba(0, 0, 0, 0.08);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: -7px;
    right: 29px;
}

.has-sub .submenu li {
    margin-right: 0;
}

.has-sub .submenu li a {
    display: block;
    padding: 8px 10px;
}

.has-sub .submenu li a i {
    font-size: 10px;
    float: left;
    margin-top: -1px;
}

.has-sub .submenu li a span {
    position: relative;
    background: #fff;
    z-index: 99;
}

.has-sub.active .submenu {
    display: block;
}

.clearfix {
    clear: both;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

.main-pay {
    margin-bottom: 74px;
}

.main-pay .pathway {
    margin-top: 41px;
}

.row-menu.menu-pay .boxmenu ul li a {
    padding: 15px 20px;
}

.h1-title-pay {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #0c0033;
    line-height: 36px;
    text-align: center;
    margin-top: 65px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 22px 0 65px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    width: 18%;
    height: 156px;
    text-align: center;
    position: relative;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab {
    width: 100%;
    display: block;
    margin-bottom: 16px;
    margin-top: 32px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab.icon-tc {
    /* background: url('../images/icon-tc.png') no-repeat center; */
    height: 48px;
    margin-top: 34px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab.icon-tk {
    /* background: url('../images/icon-tk.png') no-repeat center; */
    height: 54px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab.icon-ut {
    /* background: url('../images/icon-ut.png') no-repeat center; */
    height: 56px;
    margin-top: 30px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab.icon-tctt {
    /* background: url('../images/icon-tctt.png') no-repeat center; */
    height: 56px;
    margin-top: 30px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li .icon-tab.icon-xlnt {
    /* background: url('../images/icon-xlnt.png') no-repeat center; */
    height: 56px;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active a {
    color: #ee0033;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active .icon-tc {
    /* background-image: url('../images/icon-tc-active.png'); */
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active .icon-tk {
    /* background-image: url('../images/icon-tk-active.png'); */
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active .icon-ut {
    /* background-image: url('../images/icon-ut-active.png'); */
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active .icon-tctt {
    /* background-image: url('../images/icon-tctt-active.png'); */
}

.main-pay .content.tabs .tabs-inner .nav-tabs li.active .icon-xlnt {
    /* background-image: url('../images/icon-xlnt-active.png'); */
}

.main-pay .content.tabs .tabs-inner .nav-tabs li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #576c8a;
    width: 140px;
    display: inline-block;
    word-break: break-all;
}

.main-pay .content.tabs .tabs-inner .nav-tabs li span.sale-off {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    background: #ff0048;
    border-radius: 50%;
    position: absolute;
    top: 18px;
    right: 48px;
}

.main-pay .content.tabs .tabcontent {
    padding: 0 141px 0 98px;
}

.main-pay .content.tabs .tabcontent .col-payl {
    width: 62%;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group {
    margin: 0 0 16px;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0c0033;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .form-control {
    background-position-x: calc(96%);
    background-position-y: 50%;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control input,
.main-pay .content.tabs .tabcontent .col-payl .row-group .control select {
    font-size: 14px;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .box-capcha {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    width: 193px;
    position: relative;
    padding-right: 36px;
    height: 58px;
    margin-top: 8px;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .box-capcha .img {
    display: inline-block;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .box-capcha .img img {
    height: 37px;
    max-height: none;
    margin: 10px 5px;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .box-capcha .refesh {
    position: absolute;
    right: 9px;
    top: 16px;
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency {
    margin-top: 8px;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li {
    float: left;
    width: 109px;
    height: 44px;
    background: #b70d02;
    border-radius: 10px;
    margin-right: 16px;
    margin-bottom: 16px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 44px;
    display: block;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li:nth-child(3n + 3) {
    margin: 0;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li:hover {
    background: #ee0033;
}

.main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency span {
    font-family: SF Pro Text;
    font-size: 12px;
    line-height: 18px;
    display: block;
    clear: both;
    color: #ee0033;
}

.main-pay .content.tabs .tabcontent .col-payl .note-pay {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #576c8a;
    margin-top: 25px;
    padding-left: 195px;
}

.main-pay .content.tabs .tabcontent .col-payl .note-pay span {
    color: #ff0048;
}

.main-pay .content.tabs .tabcontent .col-payl .action {
    padding-left: 195px;
    margin-top: 23px;
}

.main-pay .content.tabs .tabcontent .col-payl .action .action-pay {
    padding: 15px 50px;
    margin-right: 23px;
}

.main-pay .content.tabs .tabcontent .col-payl .action .action-back {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.main-pay .content.tabs .tabcontent .col-payl .action .action-back:hover {
    color: #b70d02;
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li {
    margin-bottom: 34px;
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
    position: relative;
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li a:before {
    content: "";
    height: 24px;
    width: 24px;
    display: block;
    float: left;
    margin-right: 16px;
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li.support-cskh a:before {
    /* background: url('../images/icon-cskh.png') no-repeat; */
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li.support-mb a:before {
    /* background: url('../images/icon-phone.png') no-repeat; */
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li.support-mn a:before {
    /* background: url('../images/icon-phone.png') no-repeat; */
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li.support-mail a:before {
    /* background: url('../images/icon-mail.png') no-repeat; */
}

.main-pay .content.tabs .tabcontent .col-pay2 .support ul li.support-mess a:before {
    /* background: url('../images/icon-mess.png') no-repeat; */
}

/* Nạp tiền/ TKNH trả sau */

.recent-trade h3.title-trade {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
    margin-bottom: 29px;
    margin-left: -98px;
}

.recent-trade .content-trade {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.recent-trade .content-trade tbody {
    width: 50%;
}

.recent-trade .content-trade tbody tr {
    margin-bottom: 19px;
}

.recent-trade .content-trade tbody tr td {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
}

.recent-trade .content-trade tbody tr td.price {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
    padding-right: 110px;
}

.recent-trade .content-trade tbody tr td.date {
    font-size: 16px;
    line-height: 26px;
    color: #576c8a;
    vertical-align: bottom;
}

.recent-trade .content-trade tbody.tbody-col2 {
    text-align: right;
    text-align: -moz-right;
    text-align: -webkit-right;
}

/* Nạp tiền/ ứng tiền */

.des-ut {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    color: #576c8a;
    margin-bottom: 55px;
}

/* SDN- trang quản lý tk FTTH/internet */

.page-ftth.page-qltkdd .tabs__detail__content__left__text {
    font-weight: 500;
    margin-right: 26px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    padding: 0px 8px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid__price i.icon-arrow {
    /* background: url('../images/icon-arrow.svg') no-repeat; */
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 19px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid__bot {
    padding: 16px 0 0;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid__bot2 {
    margin-top: 19px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid__bot2 a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ffffff;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__price {
    font-weight: 500;
    font-size: 22px;
    line-height: 50px;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__price__sale {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    margin-left: 101px;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__date {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__date i {
    /* background: url('../images/icon-clock.svg') no-repeat; */
    width: 14px;
    height: 16px;
    display: inline-block;
    margin-right: 7px;
    margin-left: 101px;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__cart {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.125px;
    text-transform: capitalize;
    color: #ffffff;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__cart i {
    font-size: 13px;
    font-weight: 100;
    margin-right: 11px;
    margin-left: 101px;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__mid {
    margin-bottom: 20px;
}

.page-ftth.page-qltkdd .tabs__detail__content__left__mid2 {
    margin-top: 23px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt {
    padding: 8px 0 0px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt span i.icon-i-success {
    font-weight: 100;
    margin-right: 8px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt span.tabs__detail__content__text {
    margin-right: 10px;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt .tabs__detail__content__success {
    color: #3cfef7;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt .tabs__detail__content__ban {
    color: #ff0000;
}

.page-ftth.page-qltkdd .tabs__detail__content__mid-tt .tabs__detail__content__ban i {
    background: #ff0000;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    margin-right: 7px;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__text,
.page-ftth.page-qltkdd .tabs__detail__content__right__point {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.85;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__top-channel {
    padding-right: 52px;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__top-channel .tabs__detail__content__channel__text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin-right: 7px;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__top-channel .tabs__detail__content__channel__point {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 50px;
    text-transform: uppercase;
    color: #ffffff;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__bot {
    padding: 4px 0 0;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__bot2 {
    padding: 11px 0 0;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__bot2 span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.125px;
    text-transform: capitalize;
    color: #ffffff;
    padding-right: 8%;
}

.page-ftth.page-qltkdd .tabs__detail__content__right__bot2 span i {
    /* background: url('../images/icon-favorite.svg') no-repeat; */
    width: 16px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
}

.page-ftth.page-qltkdd .tabs-utilities {
    margin-top: 25px;
    margin-bottom: 64px;
    position: relative;
}

.page-ftth.page-qltkdd .tabs-utilities:before {
    content: "";
    background: #ee0033;
    position: absolute;
    border-radius: 100%;
    height: 850px;
    left: -10%;
    right: 0;
    top: -640px;
    width: 120%;
    z-index: -1;
}

.page-ftth.page-qltkdd .tabs-utilities .list-utilities {
    width: 61%;
    padding-top: 78px;
}

.page-ftth.page-qltkdd .tabs-utilities .utilities__image {
    width: 39%;
    padding: 28px 0 36px 77px;
}

.page-ftth.page-qltkdd .tabs-utilities .content .lists.slider {
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    margin: 0;
}

.page-ftth.page-qltkdd .tabs-utilities .content .lists.slider .item {
    margin-left: 42px;
}

.page-ftth.page-qltkdd .item__utilities__img {
    padding: 20.5px;
}

.page-ftth.page-qltkdd .item__wifi .item__utilities__img {
    padding-top: 23px;
    padding-bottom: 23px;
}

.page-ftth.page-qltkdd .item__utilities {
    margin-bottom: 38px;
    margin-right: 30px;
}

.page-ftth.page-qltkdd .item__utilities:nth-child(2n + 2) {
    margin-right: 0;
}

.page-ftth.page-qltkdd .item__utilities {
    width: 42%;
    float: left;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales {
    margin: 0 0 55px;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales .tabs-dvdsd .lists.slider .item {
    box-shadow: none;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales .item__box {
    padding: 20px 0 0;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales .item__box .item__title {
    padding-bottom: 10px;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales .item__box .item__price {
    padding-top: 0;
}

.page-ftth.page-qltkdd .mobile-service.hot-sales .owl-nav {
    display: none;
}

.page-ftth.page-qltkdd .ud-viettel {
    margin: 0 0 60px;
}

.page-ftth.page-qltkdd.page-ftth3 .item__utilities {
    float: none;
    margin: 0;
    width: 359px;
}

.page-ftth.page-qltkdd.page-ftth3 .tabs-utilities .utilities__image,
.page-ftth.page-qltkdd.page-ftth3 .tabs-utilities .list-utilities {
    width: auto;
}

.page-ftth.page-qltkdd.page-ftth3 .tabs-utilities .list-utilities {
    padding: 73px 0;
}

.page-ftth.page-qltkdd.page-ftth3 .tabs-utilities .content .lists.slider {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-ftth.page-qltkdd.page-ftth3 .tabs__detail__content__mid__bot .btn-tracuoc {
    width: 63%;
}

.error-service {
    margin-bottom: 64px;
}

.error-service .error-service__title h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #252525;
    margin-bottom: 28px;
}

.error-service .error-service__content .error-service__content-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item {
    width: 33.33%;
    margin-right: 30px;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item:last-child {
    margin-right: 0;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item a {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item a img {
    border-radius: 10px;
    display: block;
    width: 100%;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item a span {
    position: absolute;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 57px;
    letter-spacing: 0.15625px;
    color: #ffffff;
    bottom: 20px;
    text-align: center;
    left: calc(100% - 100%);
    width: 100%;
}

.error-service .error-service__content .error-service__content-inner .error-service__content-item a:hover {
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.4);
}

/* TIN TUC SU KIEN */

.page-ttsk .pathway {
    margin: 40px 0 40px;
}

.page-ttsk .content.tabs .tit {
    margin: 0px 0px 68px;
}

.page-ttsk .content.tabs .tabcontent .content-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item {
    max-width: 360px;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item.first-item {
    max-width: 750px;
    position: relative;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item.first-item .item-image:before {
    border-radius: 10px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item.first-item .item-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 48%;
    border-radius: 0px 0px 0px 10px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(2),
.page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(5) {
    margin-right: 0;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item:hover .item-image:before {
    opacity: 1;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-image:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px 10px 0 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 0 0 10px 10px;
    padding: 20px 20px 24px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content h3.title {
    margin-bottom: 12px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content h3.title a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #ee0033;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content .des {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0c0033;
    margin-bottom: 20px;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content .date {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #576c8a;
}

.page-ttsk .navpage {
    margin: 35px 0 100px;
}

.page-ttsk .navpage ul li span {
    color: #e7e7e7;
}

.page-ttsk-detail .detail-news .detail-news__content h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 54px;
    text-transform: uppercase;
    color: #0c0033;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta {
    margin-bottom: 33px;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta span.entry-date {
    border-right: 1px solid #576c8a;
    margin-right: 11px;
    padding-right: 11px;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta span.entry-link {
    margin-right: 9px;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta span.entry-link:before {
    content: "";
    /* background: url('../images/icon-get-link.svg') no-repeat; */
    width: 28px;
    height: 13px;
    display: inline-block;
    margin-right: 7px;
}

.page-ttsk-detail .detail-news .detail-news__content .entry-meta span.entry-like:before {
    content: "";
    /* background: url('../images/icon-like.svg') no-repeat; */
    width: 21px;
    height: 20px;
    display: inline-block;
    margin-right: 7px;
}

.page-ttsk-detail .detail-news .detail-news__content .cont {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #474747;
    margin-bottom: 20px;
}

.page-ttsk-detail .detail-news .detail-news__content .cont img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
}

.page-ttsk-detail .detail-news .warp-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 39px;
}

.page-ttsk-detail .detail-news .warp-share span.share-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #474747;
    margin-right: 12px;
}

.page-ttsk-detail .detail-news .warp-share ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.page-ttsk-detail .detail-news .warp-share ul li a {
    text-indent: -9999px;
    display: block;
    margin-right: 12px;
}

.page-ttsk-detail .detail-news .warp-share ul li.social-zalo a {
    /* background: url('../images/icon-zalo.svg') no-repeat; */
    width: 24px;
    height: 23px;
}

.page-ttsk-detail .detail-news .warp-share ul li.social-facebook a {
    /* background: url('../images/icon-facebook.svg') no-repeat; */
    width: 24px;
    height: 24px;
}

.page-ttsk-detail .detail-news .warp-share ul li.social-youtube a {
    /* background: url('../images/icon-youtube.svg') no-repeat; */
    width: 24px;
    height: 23px;
}

.page-ttsk-detail .related-post.content.tabs {
    padding: 0;
    width: auto;
    margin: 0 0 34px;
    max-width: none;
}

.page-ttsk-detail .related-post.content.tabs h3.related-post_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    margin-bottom: 30px;
}

.page-ttsk-detail .related-post.content.tabs .tabcontent {
    display: block;
}

.page-ttsk-detail .related-post.content.tabs .tabcontent .content-tab .item:nth-child(2) {
    margin-right: 30px;
}

.page-ttsk-detail .related-post.content.tabs .tabcontent .content-tab .item:nth-child(3) {
    margin-right: 0px;
}

/* HO TRO KHACH HANG */

.banner-slider {
    margin-bottom: 46px;
    text-align: center;
}

.banner-slider img {
    width: 100%;
}

.main-htkh .htkh-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #0c0033;
}

.main-htkh .support-online {
    margin-bottom: 58px;
}

.main-htkh .support-online .wrap-title {
    margin-bottom: 28px;
    clear: both;
}

.main-htkh .support-online .wrap-title h2 {
    margin-bottom: 10px;
    float: left;
}

.main-htkh .support-online .wrap-title .view-all {
    float: right;
    margin-top: 8px;
}

.main-htkh .support-online .wrap-title .view-all a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.main-htkh .support-online .support-online_content .owl-dots {
    margin: 0;
}

.main-htkh .support-online .support-online_content .owl-dots li button {
    vertical-align: top;
}

.main-htkh .support-online .support-online_content .slick-slider .slick-arrow {
    display: none !important;
}

.main-htkh .support-online .support-online_content .slick-slider .slick-list {
    margin: 0 -15px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    > div:first-child
    .item:nth-child(3n + 0)
    .item-content {
    padding: 15px 15px 22px;
}

.main-htkh .support-online .support-online_content .slick-slider .slick-list .slick-track .slick-slide .item {
    padding: 0 15px;
    margin-bottom: 30px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-inner {
    box-shadow: 0px 10px 16px rgba(39, 52, 125, 0.12);
    border-radius: 12px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-image {
    position: relative;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-image:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 194, 149, 0.5);
    position: absolute;
    opacity: 0;
    border-radius: 10px 10px 0 0;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-content {
    border-radius: 0 0 12px 12px;
    padding: 25px 15px 22px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-content
    h3.title {
    margin-bottom: 16px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-content
    h3.title
    a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.2125px;
    color: #252525;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 26px;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-content
    h3.title
    a:before {
    content: "\e90c";
    font-family: "icon-theme";
    font-size: 14px;
    position: absolute;
    right: 0;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-content
    .des {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item:hover
    .item-image:before {
    opacity: 1;
}

.support-service .support-service_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.support-service .support-service_content .support-service_item {
    width: 25%;
    text-align: center;
}

.support-service .support-service_content .support-service_item--content {
    margin-top: -26px;
}

.support-service .support-service_content .support-service_item--content a {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.5px;
    color: #ee0033;
    max-width: 181px;
    margin: 0 auto;
    display: block;
}

.search-store {
    margin-bottom: 60px;
}

.search-store .search-store_title {
    margin-bottom: 30px;
}

.search-store .search-store_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.search-store .search-store_content .map {
    display: none;
    margin-right: 30px;
}

.search-store .search-store_content .box-search {
    width: 32%;
}

.search-store .search-store_content .box-search .control {
    margin-bottom: 24px;
}

.search-store .search-store_content .box-search .control .form-control {
    padding: 14px 16px;
    background-position-x: calc(95%);
    background-position-y: 50%;
}

.search-store .search-store_content .box-search .control input::-webkit-input-placeholder {
    color: #576c8a;
}

.search-store .search-store_content .box-search .control input::-moz-placeholder {
    color: #576c8a;
}

.search-store .search-store_content .box-search .control input:-ms-input-placeholder {
    color: #576c8a;
}

.search-store .search-store_content .box-search .control input::placeholder {
    color: #576c8a;
}

.search-store .search-store_content .box-search .action a {
    width: 100%;
    padding: 11px 30px;
    margin: 0;
}

/* DOI TU VAN BAN */

.main-dtvb .team-busy {
    text-align: center;
}

.main-dtvb .team-busy img {
    margin-top: 87px;
}

.main-dtvb .team-busy p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #252525;
    margin: 14px 0 87px;
}

.main-dtvb .action {
    margin-bottom: 69px;
}

.main-dtvb .action a {
    padding: 12.5px 20px;
    width: 398px;
}

/* CHAT ONLINE */

.main-chatonline {
    background: #e5e5e5;
}

.main-chatonline .support-client .support-client_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #979797;
    padding: 26px 0 20px 40px;
    margin-bottom: 47px;
    margin-left: 89px;
    margin-right: 89px;
    border-bottom: 1px solid rgba(197, 200, 219, 0.3);
}

.main-chatonline .support-client .support-client_content::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
}

.main-chatonline .support-client .support-client_content::-webkit-scrollbar-track {
    background: #e5e5e5;
}

.main-chatonline .support-client .support-client_content::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 10px;
}

.main-chatonline .support-client .support-client_content {
    max-height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 89px;
}

.main-chatonline .support-client .support-client_content .date-mess {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #979797;
    margin-left: 61px;
    margin-top: 9px;
    display: block;
}

.main-chatonline .support-client .support-client_content .box-mess-client {
    margin-bottom: 25px;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner img {
    border-radius: 50%;
    margin-right: 20px;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner .mess-client {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 8px;
    padding: 6.5px 81px 6.5px 20px;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner .mess-client span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #252525;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner .mess-client-record {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 8px;
    padding: 10px 44px 10px 63px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner .mess-client-record img {
    border-radius: unset;
    margin-right: 26px;
}

.main-chatonline .support-client .support-client_content .box-mess-client .box-mess_inner .mess-client-record span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline
    .support-client
    .support-client_content
    .box-mess-client
    .box-mess_inner
    .mess-client-record
    span
    .icon-play {
    /* background: url('../images/icon-play.svg') no-repeat; */
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-left: 8px;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-bottom: 17px;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors .box-mess_inner .mess-counselors {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #fff;
    background: #ee0033;
    display: block;
    border-radius: 10px;
    padding: 13px 81px 13px 20px;
    max-width: 567px;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors .box-mess_inner .date-mess {
    margin-left: 0;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors .box-mess_inner .mess-client-record {
    background: rgba(20, 175, 180, 0.15);
    border-radius: 8px;
    padding: 10px 44px 10px 63px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors .box-mess_inner .mess-client-record img {
    border-radius: unset;
    margin-right: 26px;
}

.main-chatonline .support-client .support-client_content .box-mess-counselors .box-mess_inner .mess-client-record span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline
    .support-client
    .support-client_content
    .box-mess-counselors
    .box-mess_inner
    .mess-client-record
    span
    .icon-play {
    /* background: url('../images/icon-play.svg') no-repeat; */
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-left: 8px;
}

.main-chatonline .support-client .support-client_content .box-mess-time {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    text-align: center;
    color: #979797;
    position: relative;
    margin-bottom: 21px;
}

.main-chatonline .support-client .support-client_content .box-mess-time:before {
    content: "";
    width: 45%;
    height: 1px;
    background: #c5c8db;
    position: absolute;
    opacity: 0.3;
    left: -5px;
    top: 50%;
    margin-top: -0.5px;
}

.main-chatonline .support-client .support-client_content .box-mess-time:after {
    content: "";
    width: 45%;
    height: 1px;
    background: #c5c8db;
    position: absolute;
    opacity: 0.3;
    right: -5px;
    top: 50%;
    margin-top: -0.5px;
}

.main-chatonline .action-mess {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid rgba(197, 200, 219, 0.3);
    padding-top: 19px;
    margin-top: 103px;
    margin-bottom: 129px;
    margin-left: 89px;
    margin-right: 89px;
}

.main-chatonline .action-mess i.icon-smile {
    /* background: url('../images/icon-smile.svg') no-repeat; */
    width: 26px;
    height: 25px;
    display: inline-block;
    margin-right: 28px;
}

.main-chatonline .action-mess .write-mess {
    background: transparent;
    border: none;
    width: 85%;
}

.main-chatonline .action-mess input::-webkit-input-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #979797;
}

.main-chatonline .action-mess input::-moz-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #979797;
}

.main-chatonline .action-mess input:-ms-input-placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #979797;
}

.main-chatonline .action-mess input::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #979797;
}

.main-chatonline .action-mess .upload {
    margin-right: 27px;
}

.main-chatonline .action-mess .upload input {
    display: none;
}

.main-chatonline .action-mess .upload .btn-upload {
    /* background: url('../images/icon-upload.svg') no-repeat; */
    width: 18px;
    height: 19px;
    display: block;
    cursor: pointer;
}

.main-chatonline .action-mess .btn-send {
    height: 48px;
    width: 48px;
    background: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-chatonline .action-mess .btn-send a {
    /* background: url('../images/icon-send.svg') no-repeat; */
    width: 27px;
    height: 26px;
    display: block;
    font-size: 0;
}

/*  video CSKH - Xem chi tiết 1 video */

.main-video-ct .faq-form-search {
    margin-bottom: 64px;
}

.main-video-ct .guide-service {
    margin-bottom: 60px;
}

.main-video-ct .guide-service .guide-service_left {
    width: 653px;
    margin-right: 30px;
    float: left;
}

.main-video-ct .guide-service .guide-service_right {
    overflow: hidden;
}

.main-video-ct .guide-service .guide-service_right .top-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #0c0033;
    margin-bottom: 27px;
}

.main-video-ct .guide-service .guide-service_right .bottom-title {
    margin-bottom: 19px;
}

.main-video-ct .guide-service .guide-service_right .bottom-title h3 a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #0c0033;
}

.main-video-ct .guide-service .guide-service_right .des {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #474747;
    margin-bottom: 25px;
}

.main-video-ct .guide-service .guide-service_right .entry-meta span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #576c8a;
    display: inline-block;
}

.main-video-ct .guide-service .guide-service_right .entry-meta span:first-child {
    margin-right: 30px;
}

.main-video-ct .guide-service .guide-service_right .entry-meta span:before {
    content: "";
    display: inline-block;
    margin-right: 6px;
}

.main-video-ct .guide-service .guide-service_right .entry-meta span.entry-get-link:before {
    /* background: url('../images/icon-get-link.svg') no-repeat; */
    width: 28px;
    height: 13px;
}

.main-video-ct .guide-service .guide-service_right .entry-meta span.entry-like:before {
    /* background: url('../images/icon-like.svg') no-repeat; */
    width: 21px;
    height: 20px;
}

.main-video-ct .video-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #0c0033;
    margin-bottom: 30px;
}

.main-video-ct .video-tt {
    margin-bottom: 33px;
}

.main-video-ct .video-tt .videott-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.main-video-ct .video-tt .videott-content .videott-content_item {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 32px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image {
    position: relative;
    margin-bottom: 17px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image img {
    display: block;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image .time {
    position: absolute;
    z-index: 2;
    left: 20px;
    bottom: 20px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image .time span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.4);
    width: 35px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: block;
    border-radius: 6px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image .btn-see {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    text-align: center;
    line-height: 60px;
    opacity: 0;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image .btn-see a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .content-title {
    margin-bottom: 10px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .content-title a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #252525;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta span:first-child {
    margin-right: 22px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta span:before {
    content: "";
    display: inline-block;
    margin-right: 4px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta span.entry-view:before {
    /* background: url('../images/icon-view.svg') no-repeat; */
    width: 21px;
    height: 18px;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-content .entry-meta span.entry-comment:before {
    /* background: url('../images/icon-comment.svg') no-repeat; */
    width: 19px;
    height: 16px;
}

.main-video-ct .video-tt .videott-content .videott-content_item:hover .item-image .btn-see {
    opacity: 1;
}

.main-video-ct .best-video {
    margin-bottom: 64px;
}

.main-video-ct .best-video .best-video_title {
    margin-bottom: 39px;
}

.main-video-ct .best-video .best-video_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -15px;
}

.main-video-ct .best-video .best-video_content .best-video_content--item {
    width: 50%;
    padding: 0 15px;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-image {
    position: relative;
    width: 264px;
    float: left;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-image img {
    display: block;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-image .btn-see {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: 100%;
    height: 39px;
    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: 0px;
    text-align: center;
    line-height: 39px;
    opacity: 0;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-image .btn-see a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-content {
    background: rgba(255, 255, 255, 0.893229);
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 0 10px 10px 0;
    padding: 13px 30px 20px 28px;
    overflow: hidden;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-content .content-title {
    margin-bottom: 6px;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-content .content-title a {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #252525;
    position: relative;
    display: block;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-content .content-title a:after {
    content: "\e90c";
    font-family: "icon-theme";
    font-size: 14px;
    color: #576c8a;
    position: absolute;
    left: 100%;
    top: 56%;
}

.main-video-ct .best-video .best-video_content .best-video_content--item .item-content .des {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.main-video-ct .best-video .best-video_content .best-video_content--item:hover .item-image .btn-see {
    opacity: 1;
}

/* POPUP */

.modal.pin-puk__popup {
    max-width: 613px;
}

.modal.pin-puk__popup .white-popup-block {
    padding: 15px 8px;
}

.modal.pin-puk__popup h1.title-popup {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0c0033;
    margin: 11px 0 37px;
}

.modal.pin-puk__popup .field {
    margin: 0 0 20px;
}

.modal.pin-puk__popup .field label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
    float: none;
}

.modal.pin-puk__popup .field .wrap-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 33px;
}

.modal.pin-puk__popup .field .wrap-box input {
    width: 50%;
}

.modal.pin-puk__popup .field .control {
    padding: 0;
}

.modal.pin-puk__popup .field .control .box-capcha {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    width: 193px;
    position: relative;
    padding-right: 36px;
    height: 50px;
    width: 50%;
    margin-right: 11px;
}

.modal.pin-puk__popup .field .control .box-capcha .img {
    display: inline-block;
}

.modal.pin-puk__popup .field .control .box-capcha .img img {
    height: 37px;
    max-height: none;
    margin: 6px 5px;
}

.modal.pin-puk__popup .field .control .box-capcha .refesh {
    position: absolute;
    right: 9px;
    top: 12px;
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
}

.modal.pin-puk__popup .field .control input {
    font-size: 14px;
    color: #bfc4ce;
    padding: 14px 16px;
}

.modal.pin-puk__popup .action a {
    width: 100%;
    padding: 15px 30px;
    margin-bottom: 10px;
}

.modal.pin-puk__popup .modal-close {
    top: 12px;
}

.modal.pin-puk__popup .close-modal {
    display: none;
}

.modal.cancel-service__popup {
    max-width: 600px;
}

.modal.cancel-service__popup h1.title-popup {
    text-align: center;
}

.modal.cancel-service__popup .action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.modal.cancel-service__popup .action a.btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ee0033;
    background: transparent;
    width: 36%;
}

.modal.cancel-service__popup .action a.btn.action-back {
    margin-right: 40px;
}

.modal.cancel-service__popup .action a.btn:hover {
    background: #ee0033;
    border-color: #ee0033;
    color: #fff;
}

.modal.cancel-service__popup .close-modal {
    display: none;
}

.wrap-pinpuk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.wrap-pinpuk .wrap-pinpuk__inner {
    width: 50%;
    background: #fff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.wrap-pinpuk .wrap-pinpuk__inner.inner {
    margin-right: 30px;
}

.wrap-pinpuk .wrap-pinpuk__inner ul li {
    line-height: 18px;
    display: inline-block;
    border-right: 1px solid #f3f3f3;
    padding: 0px 23px;
    margin: 25px 0;
}

.wrap-pinpuk .wrap-pinpuk__inner ul li span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #576c8a;
}

.wrap-pinpuk .wrap-pinpuk__inner ul li span.title-pinpuk {
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    color: #0c0033;
}

.wrap-pinpuk .wrap-pinpuk__inner ul li:last-child {
    border-right: 0;
}

/* responsive */

@media only screen and (max-width: 1169px) {
    .row-menu.menu-pay .boxmenu ul li a {
        font-size: 14px;
        padding: 15px 15px;
    }
}

@media (max-width: 1199px) {
    .main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li {
        float: none;
        display: inline-block;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group .control .currency ul li:nth-child(3n + 3) {
        margin-right: 16px;
        margin-bottom: 16px;
    }
    .recent-trade .content-trade tbody tr td.price {
        padding-right: 50px;
    }
    .page-ftth.page-qltkdd .tabs__detail__content__left__text {
        margin-right: 15px;
    }
    .page-ftth.page-qltkdd .tabs__detail__content__left__price__sale,
    .page-ftth.page-qltkdd .tabs__detail__content__left__date i {
        margin-left: 91px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item {
        max-width: 311px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item.first-item {
        max-width: 653px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item .item-content {
        padding: 16px 20px 20px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item .item-content h3.title {
        margin-bottom: 5px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item .item-content .des {
        margin-bottom: 12px;
    }
    .main-htkh
        .support-online
        .support-online_content
        .slick-slider
        .slick-list
        .slick-track
        .slick-slide
        .item
        .item-content
        h3.title
        a {
        padding-right: 0px;
    }
    .main-chatonline .action-mess .write-mess {
        width: 82%;
    }
    .main-video-ct .guide-service .guide-service_left {
        width: 50%;
    }
    .main-video-ct .guide-service .guide-service_right .top-title {
        margin-bottom: 15px;
    }
    .main-video-ct .guide-service .guide-service_right .bottom-title h3 a {
        font-size: 30px;
        line-height: 45px;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-image {
        width: 50%;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-content .content-title a {
        font-size: 15px;
        line-height: 25px;
    }
}

@media (max-width: 991px) {
    .recent-trade h3.title-trade {
        margin-left: 0px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item {
        width: 47.5%;
        max-width: unset;
        margin-right: 5%;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(2) {
        margin-right: 5%;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(3) {
        margin-right: 0;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item.first-item {
        width: 100%;
        max-width: unset;
        margin-right: 0;
    }
    .page-ttsk-detail.page-ttsk .content.tabs .tabcontent .content-tab .item {
        width: 30%;
    }
    .search-store .search-store_content {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .search-store .search-store_content .map {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
    }
    .search-store .search-store_content .map iframe {
        width: 100%;
    }
    .search-store .search-store_content .box-search {
        width: 100%;
    }
    .main-chatonline .support-client .support-client_title {
        margin-left: 0;
        margin-right: 0;
    }
    .main-chatonline .support-client .support-client_content {
        padding-left: 0;
        padding-right: 0;
    }
    .main-chatonline .action-mess {
        margin-left: 0;
        margin-right: 0;
    }
    .main-video-ct .guide-service .guide-service_right .top-title {
        margin-bottom: 5px;
    }
    .main-video-ct .guide-service .guide-service_right .bottom-title {
        margin-bottom: 6px;
    }
    .main-video-ct .guide-service .guide-service_right .bottom-title h3 a {
        font-size: 18px;
        line-height: 22px;
    }
    .main-video-ct .guide-service .guide-service_right .des {
        margin-bottom: 0px;
    }
    .main-video-ct .best-video .best-video_content {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item {
        width: 100%;
        margin-bottom: 30px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-image {
        width: 264px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .main-pay .content.tabs .tabcontent {
        padding: 0 20px 0 20px;
    }
    .main-pay .content.tabs .tabcontent .field .control {
        padding: 0 0 0 130px;
    }
    .main-pay .content.tabs .tabcontent .col-payl .note-pay,
    .main-pay .content.tabs .tabcontent .col-payl .action {
        padding-left: 130px;
    }
    .main-pay .content.tabs .tabcontent .col-payl .action .action-pay {
        padding: 15px 19px;
    }
}

@media (max-width: 767px) {
    .main-pay .content.tabs .tabs-inner .nav-tabs {
        overflow: scroll;
    }
    .main-pay .content.tabs .tabs-inner .nav-tabs li {
        width: 25%;
        margin-right: 20px;
    }
    .main-pay .content.tabs .tabcontent {
        padding: 0;
    }
    .main-pay .content.tabs .tabcontent .field .control {
        padding: 0 0 0 130px;
    }
    .main-pay .content.tabs .tabcontent .col-payl {
        width: 100%;
        float: none;
    }
    .main-pay .content.tabs .tabcontent .col-payl .note-pay,
    .main-pay .content.tabs .tabcontent .col-payl .action {
        padding-left: 130px;
    }
    .main-pay .content.tabs .tabcontent .col-pay2 {
        float: none;
        margin-top: 50px;
    }
    .recent-trade .content-trade tbody tr td.price {
        font-size: 19px;
        padding-right: 20px;
    }
    .page-ttsk-detail.page-ttsk .content.tabs .tabcontent .content-tab .item {
        width: 100%;
        margin: 0 0 30px;
    }
    .page-ttsk-detail.page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(2) {
        margin-right: 0px;
    }
    .page-ttsk-detail.page-ttsk .content.tabs .tabcontent .content-tab .item .item-image img {
        width: 100%;
    }
    .support-service .support-service_content {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .support-service .support-service_content .support-service_item {
        width: 50%;
    }
    .main-chatonline .action-mess .write-mess {
        width: 73%;
    }
    .main-video-ct .guide-service .guide-service_left {
        width: 100%;
        margin: 0 0 30px;
        float: none;
    }
    .main-video-ct .video-tt .videott-content .videott-content_item {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .main-pay .content.tabs .tabs-inner .nav-tabs li {
        width: 48%;
        margin-right: 12px;
    }
    .main-pay .content.tabs .tabcontent {
        padding: 0;
    }
    .main-pay .content.tabs .tabcontent .field .control {
        padding: 0 0 0 105px;
    }
    .main-pay .content.tabs .tabcontent .col-payl {
        width: 100%;
        float: none;
    }
    .main-pay .content.tabs .tabcontent .col-payl .note-pay {
        padding-left: 0;
    }
    .main-pay .content.tabs .tabcontent .col-payl .action {
        padding-left: 0;
        text-align: center;
    }
    .main-pay .content.tabs .tabcontent .col-pay2 {
        float: none;
        margin-top: 50px;
    }
    .recent-trade .content-trade {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .recent-trade .content-trade tbody {
        width: 100%;
    }
    .recent-trade .content-trade tbody tr {
        display: block;
    }
    .recent-trade .content-trade tbody.tbody-col2 {
        text-align: unset;
    }
    .page-ttsk .content.tabs {
        margin: 0px 0px 20px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item {
        width: 100%;
        margin: 0 0 30px;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item:nth-child(2) {
        margin-right: 0;
    }
    .page-ttsk .content.tabs .tabcontent .content-tab .item.first-item .item-content {
        position: static;
        width: 100%;
        border-radius: 0 0 10px 10px;
    }
    .main-htkh .support-online .wrap-title h2,
    .main-htkh .support-online .wrap-title .view-all {
        float: none;
    }
    .support-service .support-service_content .support-service_item {
        width: 100%;
    }
    .main-dtvb .action a {
        width: 100%;
    }
    .main-chatonline .action-mess i.icon-smile {
        margin-right: 5px;
    }
    .main-chatonline .action-mess .write-mess {
        width: 63%;
    }
    .main-chatonline .action-mess .upload {
        margin-right: 10px;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-image {
        width: 100%;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-image img {
        width: 100%;
    }
    .main-video-ct .best-video .best-video_content .best-video_content--item .item-content {
        border-radius: 0 0 10px 10px;
    }
}

.hide__text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.banner-slideshow button.owl-dot span {
    width: 20px;
    height: 20px;
}

.banner-slideshow button.owl-dot:hover span,
.banner-slideshow button.owl-dot.active span {
    /* background: transparent url("../images/i-dot.svg") no-repeat; */
    background-position: -17px 0;
    background-size: cover;
    top: -1px;
    position: relative;
}

.login-register ul > li > div.hello span.txt b {
    text-align: right;
}

.login-register ul > li > div.hello span.txt .phone {
    padding-right: 5px;
}

.page-qltkdd.page-ftth .tabs__detail__content__left__price__sale {
    text-decoration: line-through;
    font-weight: 300;
}

.page-qltkdd.page-ftth .error-service__content-item {
    position: relative;
}

.page-qltkdd.page-ftth .error-service__content-item:after {
    content: "";
    background: -webkit-linear-gradient(
        bottom,
        #00c295 0.34%,
        rgba(0, 194, 149, 0.53901) 41.39%,
        rgba(0, 194, 149, 0.0001) 99.81%
    );
    background: linear-gradient(
        0deg,
        #00c295 0.34%,
        rgba(0, 194, 149, 0.53901) 41.39%,
        rgba(0, 194, 149, 0.0001) 99.81%
    );
    border-radius: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
}

.page-qltkdd.page-ftth .error-service__content-item a span {
    position: relative;
    z-index: 1;
}

.page-qltkdd .owl-carousel .owl-stage-outer {
    padding-top: 33px;
}

.page-qltkdd .tabs h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #252525;
}

.page-qltkdd .tabs.tabs-dvdsd .btn {
    padding-left: 22px;
    padding-right: 22px;
    min-width: 100px;
}

.page-qltkdd .tab-title li {
    padding: 0 15px;
}

.page-qltkdd .tab-title li a {
    padding: 0;
}

.page-qltkdd .content .tit .btabs {
    margin: 4px 0 0 44px;
}

.page-qltkdd .tabs__detail__content__left__date i {
    position: relative;
    top: 2px;
}

.page-qltkdd .tabs__detail {
    background: #ee0033;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
}

.page-qltkdd .tabs__detail__info {
    text-align: center;
    padding: 18px 0;
}

.page-qltkdd .tabs__detail__info__name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__info__name:after {
    content: "";
    /* background: transparent url("../images/i-check.svg") no-repeat left top; */
    background-size: contain;
    width: 17px;
    height: 11px;
    display: inline-block;
    margin-left: 8px;
}

.page-qltkdd .tabs__detail__info__phone,
.page-qltkdd .tabs__detail__info__manage--order {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__info__phone:before,
.page-qltkdd .tabs__detail__info__manage--order:before {
    content: "|";
    margin-left: 3px;
    margin-right: 10px;
}

.page-qltkdd .tabs__detail__info__phone:after,
.page-qltkdd .tabs__detail__info__manage--order:after {
    content: "";
    /* background: transparent url("../images/i-check.svg") no-repeat left top; */
    background-size: contain;
    width: 10px;
    height: 8px;
    display: inline-block;
    margin-left: 7px;
}

.page-qltkdd .tabs__detail__info__bot {
    padding: 17px 0 0;
}

.page-qltkdd .tabs__detail__info__cart,
.page-qltkdd .tabs__detail__info__wishlist {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.125px;
    text-transform: capitalize;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__info__cart:before,
.page-qltkdd .tabs__detail__info__wishlist:before {
    content: "";
    width: 17px;
    height: 16px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 3px;
}

.page-qltkdd .tabs__detail__info__cart:before {
    /* background: transparent url("../images/i-cart.svg") no-repeat left top; */
    background-size: contain;
}

.page-qltkdd .tabs__detail__info__cart:after {
    content: "|";
    margin-left: 10px;
    margin-right: 9px;
}

.page-qltkdd .tabs__detail__info__wishlist:before {
    /* background: transparent url("../images/i-wishlist.svg") no-repeat left top; */
    background-size: contain;
}

.page-qltkdd .tabs__detail__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.page-qltkdd .tabs__detail__content__left,
.page-qltkdd .tabs__detail__content__right {
    width: 30%;
}

.page-qltkdd .tabs__detail__content__mid {
    width: 40%;
}

.page-qltkdd .tabs__detail__content__left {
    padding: 5px 0 0;
}

.page-qltkdd .tabs__detail__content__left__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    position: relative;
    top: -2px;
    margin-right: 10px;
}

.page-qltkdd .tabs__detail__content__left__price {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    text-transform: uppercase;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__content__left__price.before--money:after {
    content: "";
    /* background: transparent url("../images/i-check.svg") no-repeat left top; */
    background-size: contain;
    width: 18px;
    height: 12px;
    display: inline-block;
    margin-left: 10px;
}

.page-qltkdd .tabs__detail__content__mid {
    text-align: center;
}

.page-qltkdd .tabs__detail__content__mid__text {
    font-family: "SF Pro Text";
    background: #fe9a00;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    padding: 4px 8px 2px;
    position: relative;
    top: -10px;
    margin-right: 20px;
}

.page-qltkdd .tabs__detail__content__mid__price {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__content__mid__price:after {
    content: "";
    /* background: transparent url("../images/i-arrow.svg") no-repeat left top; */
    background-size: contain;
    width: 18px;
    height: 24px;
    display: inline-block;
    margin-left: 15px;
}

.page-qltkdd .tabs__detail__content__mid__bot {
    padding: 23px 0 0;
}

.page-qltkdd .tabs__detail__content__mid__bot .btn {
    font-family: "Montserrat";
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    min-width: 180px;
    margin: 0 10px;
}

.page-qltkdd .tabs__detail__content__mid__bot .btn-tracuoc {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid #eef1f7;
}

.page-qltkdd .tabs__detail__content__mid__bot .btn-tracuoc:hover,
.page-qltkdd .tabs__detail__content__mid__bot .btn-tracuoc:focus {
    background: #fff;
    border: 2px solid #fff;
    color: #ee0033;
}

.page-qltkdd .tabs__detail__content__mid__bot .btn-checkout {
    background: #ffffff;
    border: 2px solid #eef1f7;
    color: #ee0033;
}

.page-qltkdd .tabs__detail__content__mid__bot .btn-checkout:hover,
.page-qltkdd .tabs__detail__content__mid__bot .btn-checkout:focus {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.page-qltkdd .tabs__detail__content__right {
    text-align: right;
}

.page-qltkdd .tabs__detail__content__right__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    padding-right: 10px;
    position: relative;
    top: -3px;
}

.page-qltkdd .tabs__detail__content__right__point {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    text-transform: uppercase;
    color: #ffffff;
}

.page-qltkdd .tabs__detail__content__right__bot {
    padding: 8px 0 0;
}

.page-qltkdd .tabs__detail__content__right__member {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.8;
    display: inline-block;
    padding-right: 16%;
}

.page-qltkdd .tabs__detail__content__right__member i {
    margin-right: 10px;
    position: relative;
    top: 1px;
}

.page-qltkdd .tabs-pack-data {
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
}

.page-qltkdd .tabs-pack-data:before {
    content: "";
    background: #ee0033;
    position: absolute;
    border-radius: 100%;
    height: 850px;
    left: -10%;
    right: 0;
    top: -640px;
    width: 120%;
}

.page-qltkdd .tabs-pack-data .content {
    width: 780px;
}

.page-qltkdd .tabs-pack-data .owl-stage-outer {
    margin: 0;
    padding: 40px 0;
    overflow: inherit;
}

.page-qltkdd .tabs-pack-data .item {
    background: #fff;
    margin: 0 25px;
}

.page-qltkdd .pack-data__tit {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #576c8a;
    text-align: center;
    padding: 30px 0 0;
}

.page-qltkdd .pack-data__detail {
    overflow: hidden;
    padding: 0 0 34px;
}

.page-qltkdd .pack-data__data,
.page-qltkdd .pack-data__date {
    float: left;
    padding: 0 50px;
    width: 50%;
}

.page-qltkdd .pack-data__data span,
.page-qltkdd .pack-data__date span {
    display: block;
}

.page-qltkdd .pack-data__data .pack-data__circle {
    /* background: transparent url("../images/i-pack-circle-sort1.svg") no-repeat left top; */
    background-size: contain;
}

.page-qltkdd .pack-data__date .pack-data__circle {
    /* background: transparent url("../images/i-pack-circle-sort2.svg") no-repeat left top; */
    background-size: contain;
}

.page-qltkdd .pack-data__circle {
    height: 138px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 25px 5px;
}

.page-qltkdd .pack-data__text__big {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0c0033;
    padding-bottom: 2px;
}

.page-qltkdd .pack-data__text__small {
    font-size: 16px;
    line-height: 18px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #bfc4ce;
}

.page-qltkdd .pack-data__action {
    border-top: 1px solid #ededed;
    text-align: center;
    padding: 26px 0 28px;
}

.page-qltkdd .pack-data__action__link {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ee0033;
    width: calc(100% / 3);
    float: left;
}

.page-qltkdd .pack-data__action__link:before {
    content: "";
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
}

.page-qltkdd .pack-data__action__link.pack__share:before {
    /* background: transparent url("../images/i-pack-share.svg") no-repeat; */
    top: 7px;
    width: 16px;
}

.page-qltkdd .pack-data__action__link.pack__circle:before {
    /* background: transparent url("../images/i-pack-circle.svg") no-repeat; */
    top: 5px;
    width: 20px;
}

.page-qltkdd .pack-data__action__link span {
    text-decoration: underline;
}

.page-qltkdd .box-data-cacgoi h2 span {
    line-height: 26px;
}

.page-qltkdd .tabs-utilities {
    margin: 0 0 -8px;
}

.page-qltkdd .tabs-utilities .lists.slider .owl-stage-outer {
    padding-top: 28px;
    margin: 0;
}

.page-qltkdd .tabs-utilities .owl-nav button {
    top: 46%;
}

.page-qltkdd .tabs-utilities .tit {
    margin: 0 0 13px;
}

.page-qltkdd .tabs-utilities .utilities__image {
    float: left;
    padding-left: 48px;
    width: 45%;
}

.page-qltkdd .tabs-utilities .list-utilities {
    float: right;
    width: 52%;
}

.page-qltkdd .item__utilities {
    box-shadow: 0 10px 20px rgba(39, 52, 125, 0.12);
    border-radius: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 35px;
    height: 90px;
}

.page-qltkdd .item__utilities.item__box1 .item__utilities__img {
    background: -webkit-linear-gradient(135.9deg, #ff780f 1.81%, #ffba23 97.76%);
    background: linear-gradient(314.1deg, #ff780f 1.81%, #ffba23 97.76%);
}

.page-qltkdd .item__utilities.item__box2 .item__utilities__img {
    background: -webkit-linear-gradient(314.37deg, #29e2e6 0%, #009b94 98.92%);
    background: linear-gradient(135.63deg, #29e2e6 0%, #009b94 98.92%);
}

.page-qltkdd .item__utilities:last-child {
    margin-bottom: 0;
}

.page-qltkdd .item__utilities:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.page-qltkdd .item__utilities:hover .intro__tit,
.page-qltkdd .item__utilities:hover .intro__text {
    color: #ee0033;
}

.page-qltkdd .item__utilities__img {
    padding: 22px;
    width: 90px;
}

.page-qltkdd .item__utilities__intro {
    padding: 13px 10px 5px 13px;
    width: calc(100% - 90px);
}

.page-qltkdd .intro__tit {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
}

.page-qltkdd .intro__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0875px;
    color: #252525;
    padding-right: 30px;
    padding-top: 8px;
}

.page-qltkdd .tabs-lookups {
    background: -webkit-linear-gradient(bottom, rgba(20, 175, 180, 0.15), rgba(20, 175, 180, 0.15)), #ffffff;
    background: linear-gradient(0deg, rgba(20, 175, 180, 0.15), rgba(20, 175, 180, 0.15)), #ffffff;
    margin-bottom: 40px;
    padding: 58px 0 28px;
}

.page-qltkdd .tabs-lookups .lists.slider .owl-carousel {
    padding: 0 37px;
}

.page-qltkdd .tabs-lookups .lists.slider .owl-stage-outer {
    padding-bottom: 55px;
    margin-bottom: 0;
}

.page-qltkdd .tabs-lookups .item {
    box-shadow: inherit;
    margin: 0 15px;
}

.page-qltkdd .tabs-lookups .item:hover {
    box-shadow: inherit;
}

.page-qltkdd .tabs-lookups .item:hover img {
    opacity: 1;
}

.page-qltkdd .tabs-lookups .item__title {
    padding-top: 18px;
    padding-bottom: 3px;
}

.page-qltkdd .tabs-lookups .item__text--small {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.page-qltkdd .tabs-lookups .imgs img {
    border-radius: 50%;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    width: inherit;
}

.page-qltkdd .tabs-lookups button.owl-dot span {
    background: #fff;
}

.page-qltkdd .tabs-lookups button.owl-dot.active span {
    background: #11aca5;
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax:after {
    top: inherit;
    left: -12%;
    right: 0;
    bottom: -7%;
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax1:after {
    top: inherit;
    left: -25%;
    right: 0;
    bottom: 38%;
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax2 {
    background: -webkit-linear-gradient(110.05deg, #ff780f 1.81%, #ffba23 97.76%);
    background: linear-gradient(339.95deg, #ff780f 1.81%, #ffba23 97.76%);
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax2:after {
    top: inherit;
    right: 7%;
    bottom: 31%;
    width: 350px;
    height: 350px;
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax3 {
    background: -webkit-linear-gradient(289.48deg, #29e2e6 0%, #009b94 98.92%);
    background: linear-gradient(160.52deg, #29e2e6 0%, #009b94 98.92%);
}

.page-qltkdd .box-data-cacgoi .inner-item h2.h2-minmax3:after {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 20%;
    border-radius: 100%;
    width: 660px;
    height: 660px;
    bottom: -100%;
}

.page-qltkdd .box-data-cacgoi .inner-item h2 span {
    text-transform: none;
}

.page-qltkdd .tabs-dvdsd .btn {
    float: right;
}

.page-qltkdd .item__box {
    padding: 20px 22px 28px;
}

.page-qltkdd .item__title {
    color: #4d4d4d;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 7px;
}

.page-qltkdd .item__content {
    float: left;
}

.page-qltkdd .item__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    padding-top: 1px;
}

.page-qltkdd .item__price {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    padding-top: 7px;
}

.page-qltkdd .item__price i {
    font-weight: normal;
    margin-right: 5px;
}

.page-qltkdd .ud-viettel .item {
    box-shadow: inherit;
    margin: 0 15px;
}

.page-qltkdd .ud-viettel .item:hover {
    box-shadow: inherit;
}

.page-qltkdd .ud-viettel .item:hover .images_content-plus {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.page-qltkdd .ud-viettel .item:hover .images_content-plus img {
    opacity: 0.5 !important;
}

.page-qltkdd .ud-viettel .item .images_content-plus {
    border-radius: 10px;
}

.page-qltkdd .ud-viettel .item__title {
    padding-top: 16px;
    padding-bottom: 0;
}

.page-qltkdd .tabs-dvdsd .tabs-dvdsd__notfound {
    text-align: center;
}

.page-qltkdd .tabs-dvdsd .tabs-dvdsd__notfound .btn-list-pack {
    float: none;
    margin: 20px 0;
    background: #ee0033;
    color: #fff;
    min-width: 192px;
}

.page-qltkdd .tabs-dvdsd .tabs-dvdsd__notfound .btn-list-pack:hover,
.page-qltkdd .tabs-dvdsd .tabs-dvdsd__notfound .btn-list-pack:focus {
    background: #fff;
    color: #ee0033;
}

.page-qltkdd .tabs-dvdsd__notfound__text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0875px;
    color: #252525;
}

.page-qltkdd .tabs__detail__content__left__time,
.page-qltkdd .tabs__detail__content__mid__time {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.85;
    display: block;
}

.page-qltkdd .tabs__detail__content__left__time:before,
.page-qltkdd .tabs__detail__content__mid__time:before {
    content: "";
    /* background: transparent url("../images/i-time.svg") no-repeat; */
    background-size: contain;
    top: 2px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 9px;
    position: relative;
}

.page-qltkdd .tabs__detail__content__mid__time {
    text-align: center;
    padding: 7px 0 0;
}

.page-qltkdd .tabs__detail__content__left__time {
    text-align: left;
    padding: 12px 0 0 16%;
}

.view-all-uds .row-menu .login-register a.active {
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    color: #252525;
}

.view-all-uds .uds__intro {
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
    padding: 35px 0 40px;
}

.view-all-uds .uds__intro h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #0c0033;
    padding: 0 0 20px;
}

.view-all-uds .uds__intro p {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #576c8a;
}

.view-all-uds .uds__box {
    margin: 0 -15px;
    padding: 15px 0 0;
}

.view-all-uds .uds__box__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.view-all-uds .uds__box__content .item {
    width: calc(100% / 3);
    padding: 0 15px;
    margin: 0;
}

.view-all-uds .uds__box__content .item:hover .item-inner {
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.4);
}

.view-all-uds .uds__box__content .item:hover .item__btn .btn {
    border-color: #b70d02;
    background: #b70d02;
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
}

.view-all-uds .uds__box__content .item-inner {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.view-all-uds .uds__box__content .item__content {
    padding: 15px;
}

.view-all-uds .uds__box__content .item__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.view-all-uds .uds__box__content .item__btn {
    text-align: center;
    padding: 17px 0 0;
}

.view-all-uds .uds__box__content .item__btn .btn {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    background: #ee0033;
    border-radius: 10px;
    padding: 10px 64px;
}

.view-all-uds .uds__box__content .item__btn .btn:hover,
.view-all-uds .uds__box__content .item__btn .btn:focus {
    border-color: #b70d02;
    background: #b70d02;
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
}

.view-all-uds .navpage ul li.next:hover a,
.view-all-uds .navpage ul li.prev:hover a {
    background: #b70d02;
}

.viettel__detail.detail__onme .section__content .detail__content__title {
    padding-right: 0;
}

.viettel__detail.detail__onme .section__content--right.section .detail__content__title {
    padding-left: 0;
}

.viettel__detail .row-menu .login-register a.active {
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    color: #252525;
}

.viettel__detail__banner img {
    width: 100%;
}

.viettel__detail .pathway ul li i {
    position: relative;
    top: -1px;
}

.viettel__detail .section__content {
    text-align: left;
}

.viettel__detail .section__content--right.section {
    background: rgba(20, 175, 180, 0.15);
    text-align: right;
}

.viettel__detail .section__content--right.section .detail__image {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    padding-right: 20px;
    padding-left: 0;
    text-align: left;
}

.viettel__detail .section__content--right.section .detail__image img {
    float: none;
}

.viettel__detail .section__content--right.section .detail__content {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.viettel__detail .section__content--right.section .detail__content__title {
    padding-right: 0;
    padding-left: 20%;
}

.viettel__detail .section__content:first-child .detail__content__title {
    padding-top: 42px;
}

.viettel__detail .section__content .content__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.viettel__detail .section__content .detail__image {
    width: 45%;
    text-align: right;
    padding-left: 30px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.viettel__detail .section__content .detail__image img {
    display: block;
    float: right;
}

.viettel__detail .section__content .detail__content {
    color: #576c8a;
    width: 55%;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    padding: 0 0 30px;
}

.viettel__detail .section__content .detail__content__title {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 46px;
    line-height: 56px;
    letter-spacing: 0.1px;
    padding-right: 20%;
    padding-top: 62px;
    padding-bottom: 2px;
}

.viettel__detail .section__content .detail__content__des {
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
}

.viettel__detail .section__tabs {
    padding: 40px 0 60px;
}

.viettel__detail .section__tabs .tab__item.active .tab__title {
    border-bottom: 1px solid #ee0033;
}

.viettel__detail .section__tabs .tab__item.active .tab__title__text {
    color: #ee0033;
}

.viettel__detail .section__tabs .tab__item.active .tab__title i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    color: #ee0033;
}

.viettel__detail .section__tabs .tab__item.active .tab__content {
    display: block;
}

.viettel__detail .section__tabs .tab__title {
    border-bottom: 1px solid #576c8a;
    cursor: pointer;
    padding: 21px 25px 23px 0;
    position: relative;
}

.viettel__detail .section__tabs .tab__title__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0c0033;
}

.viettel__detail .section__tabs .tab__title i {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    display: inline-block;
    color: #576c8a;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -11px;
}

.viettel__detail .section__tabs .tab__content {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    display: none;
    padding: 30px 0 40px;
}

.viettel__detail .section__tabs table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    border: 1px solid #576c8a;
}

.viettel__detail .section__tabs table tbody tr:first-child td {
    border-top: none;
}

.viettel__detail .section__tabs table th {
    background: #ee0033;
    vertical-align: top;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding: 15px 35px;
}

.viettel__detail .section__tabs table td {
    color: #0c0033;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    vertical-align: top;
    text-align: center;
    border-top: 1px solid #576c8a;
    padding: 32px 40px 30px;
}

.viettel__detail .section__tabs .table__pack tbody .number {
    width: 160px;
}

.viettel__detail .section__tabs .table__pack tbody .pack {
    width: 170px;
}

.viettel__detail .section__tabs .table__pack tbody .maintaining {
    width: 185px;
}

.viettel__detail .section__tabs .table__pack tbody .limit--amount {
    width: 330px;
}

.viettel__detail .section__tabs .table__pack tbody .limit--trade {
    width: calc(100% - 845px);
}

.search__policy__banner img {
    width: 100%;
}

.search__maintain .tab-title,
.transfers__wrapper .tab-title {
    overflow: hidden;
    text-align: center;
}

.search__maintain .tab-title li,
.transfers__wrapper .tab-title li {
    padding: 0 55px;
    display: inline-block;
    float: none;
}

.search__maintain .tab-title li a,
.transfers__wrapper .tab-title li a {
    padding: 0;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #576c8a;
}

.search__maintain .tab-title li.active a,
.transfers__wrapper .tab-title li.active a {
    font-weight: bold;
    color: #ee0033;
}

.search__maintain .tit,
.transfers__wrapper .tit {
    margin: 0 0 40px;
}

.search__maintain .tit h3,
.transfers__wrapper .tit h3 {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0c0033;
    padding: 2px 0 35px;
}

.search__maintain .policy__image img {
    width: 100%;
}

.search__maintain .policy__content__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #0c0033;
    padding: 52px 0 0;
}

.search__maintain .section__tabs {
    padding: 30px 0 3px;
}

.search__maintain .section__tabs .tab__item.active .tab__title i {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.search__maintain .section__tabs .tab__item.active .tab__content {
    display: block;
}

.search__maintain .section__tabs .tab__title {
    cursor: pointer;
    padding: 12px 30px 12px 0;
    position: relative;
}

.search__maintain .section__tabs .tab__title__text {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.search__maintain .section__tabs .tab__title i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    display: inline-block;
    color: #b70d02;
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -8px;
}

.search__maintain .section__tabs .tab__content {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    display: none;
    padding: 8px 0 22px;
}

.search__maintain .tab__content__item {
    padding: 0 0 10px;
}

.search__maintain .tab__content__item p {
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    padding-left: 60px;
    border-bottom: 1px solid rgba(197, 200, 219, 0.3);
    padding-top: 20px;
    padding-bottom: 8px;
}

.search__maintain .tab__content__item p:last-child {
    border-bottom: none;
}

.search__maintain .tab__content__item .item__title {
    border-bottom: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0c0033;
    padding-left: 30px;
}

.search__maintain .search-store_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.search__maintain .search-store_content iframe {
    width: 100%;
}

.search__maintain .search-store_content .map {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    width: 750px;
    padding-bottom: 10px;
    margin-right: 0;
}

.search__maintain .search-store_content .box-search {
    width: 100%;
    padding: 0 17px;
    padding-bottom: 25px;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
}

.search__maintain .search-store_content .box-search .control {
    width: 100%;
    margin-right: 10px;
}

.search__maintain .search-store_content .box-search .action {
    width: 100%;
}

.search__maintain .search-store_content .box-search select,
.search__maintain .search-store_content .box-search input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #576c8a;
    padding: 15px 50px 13px 16px;
}

.search__maintain .search-store_content .box-search select::-webkit-input-placeholder,
.search__maintain .search-store_content .box-search input::-webkit-input-placeholder {
    color: #576c8a;
}

.search__maintain .search-store_content .box-search select::-moz-placeholder,
.search__maintain .search-store_content .box-search input::-moz-placeholder {
    color: #576c8a;
}

.search__maintain .search-store_content .box-search select:-ms-input-placeholder,
.search__maintain .search-store_content .box-search input:-ms-input-placeholder {
    color: #576c8a;
}

.search__maintain .search-store_content .box-search select:-moz-placeholder,
.search__maintain .search-store_content .box-search input:-moz-placeholder {
    color: #576c8a;
}

.search__maintain .search-store_content .box-search select {
    background-position-x: 95%;
}

.search__maintain .search-store_content .box-search .btn {
    width: 100%;
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 20px;
    padding: 13px 30px;
}

.search__maintain .notice__location {
    padding: 25px 0 0;
}

.search__maintain .notice__location p {
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    color: #576c8a;
    padding-left: 15px;
    position: relative;
}

.search__maintain .notice__location p:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 9px;
    height: 13px;
}

.search__maintain .notice__location p.notice__location__one:before {
    /* background: transparent url("../images/i-location1.svg") no-repeat; */
}

.search__maintain .notice__location p.notice__location__two:before {
    /* background: transparent url("../images/i-location2.svg") no-repeat; */
}

.search__maintain .notice__location p.notice__location__three:before {
    /* background: transparent url("../images/i-location3.svg") no-repeat; */
}

.search__maintain .maintain__title {
    border-right: 1px solid rgba(197, 200, 219, 0.3);
    width: 390px;
}

.search__maintain .maintain__title__text li {
    border-top: 1px solid rgba(197, 200, 219, 0.3);
    padding: 18px 40px;
}

.search__maintain .maintain__title__text li:first-child {
    border-top-width: 0;
}

.search__maintain .maintain__title__text li a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
    padding-left: 43px;
    position: relative;
}

.search__maintain .maintain__title__text li a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
}

.search__maintain .maintain__title--search:before {
    /* background: transparent url("../images/i-maintain-search.svg") no-repeat; */
    background-size: contain;
    width: 20px;
    height: 23px;
    margin-top: -12px;
}

.search__maintain .maintain__title--speed:before {
    /* background: transparent url("../images/i-maintain-speed.svg") no-repeat; */
    background-size: contain;
    width: 23px;
    height: 23px;
    margin-top: -12px;
}

.search__maintain .maintain__title--time:before {
    /* background: transparent url("../images/i-maintain-time.svg") no-repeat; */
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-top: -11px;
}

.search__maintain .maintain__content {
    width: calc(100% - 390px);
    padding: 30px;
}

.search__maintain .maintain__content--wrapper {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    display: none;
}

.search__maintain .maintain__content--wrapper.open {
    display: block;
}

.search__maintain .maintain__content__title {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0c0033;
    padding: 14px 0 45px;
}

.search__maintain .block__request {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 30px;
}

.search__maintain .block__request .field label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0c0033;
    display: block;
    float: none;
    padding-bottom: 5px;
}

.search__maintain .block__request .field .control {
    padding: 0;
}

.search__maintain .block__request .field .control input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #576c8a;
    padding: 14px 16px;
}

.search__maintain .block__request .field .control input::-webkit-input-placeholder {
    color: #e7e7e7;
}

.search__maintain .block__request .field .control input::-moz-placeholder {
    color: #e7e7e7;
}

.search__maintain .block__request .field .control input:-ms-input-placeholder {
    color: #e7e7e7;
}

.search__maintain .block__request .field .control input:-moz-placeholder {
    color: #e7e7e7;
}

.search__maintain .block__request .field .captcha input {
    width: calc(100% - 170px);
}

.search__maintain .block__request .field .captcha .box-captcha {
    width: 170px;
    float: right;
    position: relative;
    height: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-left: 30px;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.search__maintain .block__request .field .captcha img {
    max-height: 30px;
    vertical-align: middle;
}

.search__maintain .block__request .field .captcha .img {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    width: 108px;
    overflow: hidden;
}

.search__maintain .block__request .field .captcha .img img {
    max-height: none;
    margin: 11px 0 9px 12px;
}

.search__maintain .block__request .field .captcha .refresh {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 8px;
}

.search__maintain .block__request .note__require {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #576c8a;
    padding: 15px 0 0;
}

.search__maintain .block__result {
    padding: 35px 0 0;
}

.search__maintain .block__result .result__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.search__maintain .block__result .result__item {
    width: 50%;
    padding-bottom: 30px;
}

.search__maintain .block__result .result__item:nth-child(odd) {
    padding-right: 15px;
}

.search__maintain .block__result .result__item:nth-child(even) {
    padding-left: 15px;
}

.search__maintain .block__result .result__item__wrapper {
    border-bottom: 1px solid rgba(231, 231, 231, 0.7);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.search__maintain .block__result .result__item__img {
    text-align: right;
    width: 120px;
    overflow: hidden;
}

.search__maintain .block__result .result__item__img img {
    border-radius: 6px;
}

.search__maintain .block__result .result__item__content {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    width: calc(100% - 120px);
}

.search__maintain .block__result .result__item__content__price,
.search__maintain .block__result .result__item__content__serial,
.search__maintain .block__result .result__item__content__status {
    position: relative;
    padding-left: 20px;
    margin-left: 8px;
}

.search__maintain .block__result .result__item__content__price:before,
.search__maintain .block__result .result__item__content__serial:before,
.search__maintain .block__result .result__item__content__status:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
}

.search__maintain .block__result .result__item__content__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4d4d4d;
    margin-bottom: 12px;
}

.search__maintain .block__result .result__item__content__price,
.search__maintain .block__result .result__item__content__serial {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
}

.search__maintain .block__result .result__item__content__price {
    margin-bottom: 8px;
}

.search__maintain .block__result .result__item__content__price:before {
    /* background: transparent url("../images/i-price-result1.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__result .result__item__content__serial {
    margin-bottom: 8px;
}

.search__maintain .block__result .result__item__content__serial:before {
    /* background: transparent url("../images/i-price-result2.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__result .result__item__content__status {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #ee0033;
    margin-bottom: 20px;
}

.search__maintain .block__result .result__item__content__status:before {
    /* background: transparent url("../images/i-price-result3.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__result.block__time__result {
    padding-bottom: 30px;
}

.search__maintain .block__result.block__time__result .block__time__result__item__right {
    width: 35%;
    text-align: right;
}

.search__maintain .block__result.block__time__result .block__time__result__item__status {
    position: relative;
    padding-left: 20px;
    display: inline-block;
    font-family: "SF Pro Text";
    font-size: 16px;
    line-height: 18px;
    color: #ee0033;
}

.search__maintain .block__result.block__time__result .block__time__result__item__status.expires {
    color: #fe9a00;
}

.search__maintain .block__result.block__time__result .block__time__result__item__status:before {
    /* background: transparent url("../images/i-price-result3.svg") no-repeat; */
    background-size: contain;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
}

.search__maintain .block__result.block__time__result .result__item__content {
    -webkit-box-ordinal-group: unset;
    -webkit-order: unset;
    -ms-flex-order: unset;
    order: unset;
    width: 65%;
}

.search__maintain .block__result.block__time__result .result__item__content__price,
.search__maintain .block__result.block__time__result .result__item__content__serial {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
}

.search__maintain .block__result.block__time__result .result__item__content__status {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    margin-bottom: 20px;
}

.search__maintain .block__result.block__time__result .result__item__content__status:before {
    /* background: transparent url("../images/i-maintain-time.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__action {
    text-align: center;
    padding: 0 0 10px;
}

.search__maintain .block__action .btn {
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 39px;
}

.search__maintain .block-location__result {
    box-shadow: none;
    border-radius: unset;
    padding: 43px 0 0;
    margin: 0 0 13px;
}

.search__maintain .block-location__result__total {
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: -0.5px;
    color: #000;
    text-align: center;
    background: rgba(20, 175, 180, 0.15);
    border-radius: 10px;
    padding: 17px;
}

.search__maintain .location__detail__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 30px 0 12px;
}

.search__maintain .location__detail__info__wrapper {
    width: 60%;
}

.search__maintain .location__detail__info__rating {
    width: 40%;
    text-align: right;
}

.search__maintain .location__detail__info__rating__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #576c8a;
    position: relative;
    top: 2px;
    margin-left: 10px;
}

.search__maintain .location__detail__info__title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #ee0033;
    padding-bottom: 20px;
}

.search__maintain .location__detail__info__location,
.search__maintain .location__detail__info__phone,
.search__maintain .location__detail__info__time {
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    display: block;
    position: relative;
    padding-left: 35px;
    padding-bottom: 20px;
}

.search__maintain .location__detail__info__location i,
.search__maintain .location__detail__info__phone i,
.search__maintain .location__detail__info__time i {
    font-size: 24px;
    font-weight: 300;
    position: absolute;
    left: 0;
    top: 2px;
}

.search__maintain .location__detail__list .item-vote h3 a {
    font-weight: 400;
}

.search__maintain .location__detail__more {
    text-align: center;
}

.search__maintain .location__detail__more a {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125px;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.search__maintain .block__speed__result {
    padding: 30px 0;
}

.search__maintain .block__speed__result .speed__result__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.search__maintain .block__speed__result .speed__result__item {
    width: 50%;
    padding-bottom: 30px;
}

.search__maintain .block__speed__result .speed__result__item:nth-child(odd) {
    padding-right: 15px;
}

.search__maintain .block__speed__result .speed__result__item:nth-child(even) {
    padding-left: 15px;
}

.search__maintain .block__speed__result .speed__result__item__wrapper {
    background: #ffffff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 30px 30px 20px 15px;
}

.search__maintain .block__speed__result .speed__result__item__content {
    width: 65%;
}

.search__maintain .block__speed__result .speed__result__item__content__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.search__maintain .block__speed__result .speed__result__item__content__employee,
.search__maintain .block__speed__result .speed__result__item__content__employee--phone {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    position: relative;
    margin-left: 5px;
    padding-left: 25px;
    margin-bottom: 10px;
}

.search__maintain .block__speed__result .speed__result__item__content__employee:before,
.search__maintain .block__speed__result .speed__result__item__content__employee--phone:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 13px;
    height: 12px;
    margin-top: -6px;
}

.search__maintain .block__speed__result .speed__result__item__content__employee:before {
    /* background: transparent url("../images/i-speed-result1.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__result .speed__result__item__content__employee--phone:before {
    /* background: transparent url("../images/i-speed-result2.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__result .speed__result__item__right {
    text-align: right;
    width: 35%;
}

.search__maintain .block__speed__result .speed__result__item__date--time {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #576c8a;
    padding: 0 0 50px;
}

.search__maintain .block__speed__result .speed__result__item__status {
    font-family: "SF Pro Text";
    font-size: 16px;
    line-height: 18px;
    color: #ee0033;
    position: relative;
    display: inline-block;
    padding-left: 25px;
}

.search__maintain .block__speed__result .speed__result__item__status:before {
    /* background: transparent url("../images/i-price-result3.svg") no-repeat; */
    background-size: contain;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 13px;
    height: 12px;
    margin-top: -6px;
}

.search__maintain .block__speed__detail__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.search__maintain .block__speed__detail__item {
    width: 50%;
    padding-bottom: 30px;
}

.search__maintain .block__speed__detail__item:nth-child(odd) {
    padding-right: 15px;
}

.search__maintain .block__speed__detail__item:nth-child(even) {
    padding-left: 15px;
}

.search__maintain .block__speed__detail__item__content {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 45px 95px 30px;
}

.search__maintain .block__speed__detail__item__content .item__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    padding-bottom: 28px;
}

.search__maintain .block__speed__detail__item__content .item__text--bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    display: block;
}

.search__maintain .block__speed__detail__item__content .item__text--normal {
    font-family: "SF Pro Text";
    font-size: 14px;
    line-height: 21px;
    color: #000;
    display: block;
}

.search__maintain .block__speed__detail__item__content .item__customer,
.search__maintain .block__speed__detail__item__content .item__address,
.search__maintain .block__speed__detail__item__content .item__bug,
.search__maintain .block__speed__detail__item__content .item__phone {
    position: relative;
    padding-left: 25px;
    padding-bottom: 12px;
}

.search__maintain .block__speed__detail__item__content .item__customer:before,
.search__maintain .block__speed__detail__item__content .item__address:before,
.search__maintain .block__speed__detail__item__content .item__bug:before,
.search__maintain .block__speed__detail__item__content .item__phone:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
}

.search__maintain .block__speed__detail__item__content .item__customer:before {
    /* background: transparent url("../images/i-speed-result1.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__detail__item__content .item__phone:before {
    /* background: transparent url("../images/i-speed-result2.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__detail__item__content .item__bug:before {
    /* background: transparent url("../images/i-maintain-search.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__detail__item__content .item__address:before {
    /* background: transparent url("../images/i-speed-detail.svg") no-repeat; */
    background-size: contain;
}

.search__maintain .block__speed__bar {
    margin: 0 0 40px;
}

.search__maintain .block__speed__bar__wrapper {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.search__maintain .block__speed__bar__top {
    padding: 45px 100px 10px;
}

.search__maintain .block__speed__bar__order {
    padding: 0 0 3px;
}

.search__maintain .block__speed__bar__text--bold {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
}

.search__maintain .block__speed__bar__text--normal {
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    color: #000;
}

.search__maintain .block__speed__bar__date__time {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #576c8a;
}

.search__maintain .block__speed__bar .bar__progress {
    padding: 0 0 45px;
}

.search__maintain .block__speed__bar .bar__progress__content {
    margin: 10px auto;
    max-width: 1100px;
}

.search__maintain .block__speed__bar .bar__progress__content li {
    width: 25%;
    float: left;
    position: relative;
}

.search__maintain .block__speed__bar .bar__progress__content li:first-child:after {
    width: 50%;
    left: 50%;
}

.search__maintain .block__speed__bar .bar__progress__content li:last-child:after {
    width: 50%;
    left: 0;
}

.search__maintain .block__speed__bar .bar__progress__content li.active .bar__progress__check,
.search__maintain .block__speed__bar .bar__progress__content li.activated .bar__progress__check {
    background-color: #ee0033;
    color: #fff;
}

.search__maintain .block__speed__bar .bar__progress__content li.active .bar__progress__check:before,
.search__maintain .block__speed__bar .bar__progress__content li.activated .bar__progress__check:before {
    content: "\e920";
    font-family: "icon-theme" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13.5px;
}

.search__maintain .block__speed__bar .bar__progress__content li.active .bar__progress__text__bold,
.search__maintain .block__speed__bar .bar__progress__content li.activated .bar__progress__text__bold {
    color: #ee0033;
}

.search__maintain .block__speed__bar .bar__progress__content li.activated:after {
    width: 100%;
    border-color: #d65470;
}

.search__maintain .block__speed__bar .bar__progress__content li:after {
    background-color: #b70d02;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    top: 13px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.search__maintain .block__speed__bar .bar__progress__content li .bar__progress__check {
    background-color: #fff;
    border-radius: 50%;
    display: block;
    height: 26px;
    line-height: 26px;
    position: relative;
    text-align: center;
    width: 26px;
    z-index: 1;
    margin: 0 auto;
    border: 2px solid #ee0033;
}

.search__maintain .block__speed__bar .bar__progress__text {
    text-align: center;
    display: block;
    padding: 0 20px;
}

.search__maintain .block__speed__bar .bar__progress__text__bold {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    display: block;
    padding: 15px 0 2px;
}

.search__maintain .block__speed__bar .bar__progress__text__normal {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #576c8a;
}

.transfers__wrapper .tit {
    margin: 0 0 65px;
}

.transfers__wrapper .tit h3 {
    text-transform: uppercase;
    padding: 25px 0 55px;
}

.transfers__wrapper .block__transfers__list {
    padding: 0 0 64px;
}

.transfers__wrapper .block__transfers__list .item__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    padding: 30px;
}

.transfers__wrapper .block__transfers__list .item__content__left {
    width: 50%;
}

.transfers__wrapper .block__transfers__list .item__content__price--phone {
    padding: 0 0 5px;
}

.transfers__wrapper .block__transfers__list .item__content__price {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #0c0033;
}

.transfers__wrapper .block__transfers__list .item__content__phone {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
    border-left: 1px solid #e7e7e7;
    position: relative;
    top: -1px;
    padding-left: 10px;
    margin-left: 7px;
}

.transfers__wrapper .block__transfers__list .item__content__phone:before {
    /* background: transparent url("../images/i-transfers-mobile.svg") no-repeat; */
    background-size: contain;
    content: "";
    display: inline-block;
    width: 15px;
    height: 18px;
    position: relative;
    top: 3px;
    margin-right: 7px;
}

.transfers__wrapper .block__transfers__list .item__content__time--date {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #576c8a;
}

.transfers__wrapper .block__transfers__list .item__content__right {
    width: 50%;
    text-align: right;
    padding: 5px 0 6px;
}

.transfers__wrapper .block__transfers__list .item__link__bug {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.125px;
    color: #ee0033;
    margin-right: 28px;
}

.transfers__wrapper .block__transfers__list .item__link__bug span {
    text-decoration: underline;
}

.transfers__wrapper .block__transfers__list .item__link__template {
    line-height: 20px;
    padding: 14px 43px;
}

.transfers__wrapper .block__transfers__list .item__link__template:hover,
.transfers__wrapper .block__transfers__list .item__link__template:focus {
    box-shadow: 30px 30px 50px rgba(50, 50, 50, 0.4);
}

.transfers__wrapper .block__transfers__item {
    padding: 0 0 30px;
}

.transfers__wrapper .block__transfers__more {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.125px;
    color: #ee0033;
}

.transfers__wrapper .block__transfers__more span {
    text-decoration: underline;
}

.transfers__wrapper .block__transfers__title {
    padding: 0 0 17px;
}

.transfers__wrapper .block__transfers__title h3 {
    color: #0c0033;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 0 0 12px;
}

.transfers__wrapper .block__transfers__title p {
    color: #576c8a;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}

.transfers__wrapper .block__transfers__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.transfers__wrapper .block__transfers__form {
    width: calc(100% - 265px);
    padding-right: 125px;
}

.transfers__wrapper .block__transfers__form .field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 30px;
}

.transfers__wrapper .block__transfers__form .field label {
    color: #0c0033;
    float: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    width: 195px;
}

.transfers__wrapper .block__transfers__form .field input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #576c8a;
    padding: 0 16px;
    height: 50px;
    margin: 0;
}

.transfers__wrapper .block__transfers__form .field input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.transfers__wrapper .block__transfers__form .field input::-moz-placeholder {
    color: #bfc4ce;
}

.transfers__wrapper .block__transfers__form .field input:-ms-input-placeholder {
    color: #bfc4ce;
}

.transfers__wrapper .block__transfers__form .field input:-moz-placeholder {
    color: #bfc4ce;
}

.transfers__wrapper .block__transfers__form .field__values {
    margin: -20px 0 15px;
}

.transfers__wrapper .block__transfers__form .field__values__link a {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ee0033;
}

.transfers__wrapper .block__transfers__form .field__values__item {
    background: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    margin: 18px 25px 12px 0;
    line-height: 20px;
    padding: 11px;
    text-align: center;
    width: 109px;
}

.transfers__wrapper .block__transfers__form .field__values__item.active,
.transfers__wrapper .block__transfers__form .field__values__item:hover {
    background: #b70d02;
    border-color: #b70d02;
    color: #fff;
}

.transfers__wrapper .block__transfers__form .field__values__vat {
    color: #0c0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    padding: 5px 0 0;
}

.transfers__wrapper .block__transfers__form .field__values__vat__price {
    color: #ee0033;
}

.transfers__wrapper .block__transfers__form .control {
    width: calc(100% - 195px);
    padding: 0;
}

.transfers__wrapper .block__transfers__form__action {
    text-align: right;
    padding: 48px 0 42px;
}

.transfers__wrapper .block__transfers__form__action .btn {
    max-width: 360px;
    line-height: 20px;
    padding: 14px 30px;
    width: 100%;
}

.transfers__wrapper .block__transfers__support {
    width: 265px;
}

.transfers__wrapper .block__transfers__support__item {
    color: #0c0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    position: relative;
    padding-left: 40px;
    padding-bottom: 33px;
}

.transfers__wrapper .block__transfers__support__item:before {
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 24px;
}

.transfers__wrapper .block__transfers__support__item.item__hotline:before {
    /* background: transparent url("../images/i-transfers-hotline.svg") no-repeat; */
    background-size: contain;
}

.transfers__wrapper .block__transfers__support__item.item__phone:before {
    /* background: transparent url("../images/i-transfers-phone.svg") no-repeat; */
    background-size: contain;
}

.transfers__wrapper .block__transfers__support__item.item__email:before {
    /* background: transparent url("../images/i-transfers-email.svg") no-repeat; */
    background-size: contain;
}

.transfers__wrapper .block__transfers__support__item.item__group:before {
    /* background: transparent url("../images/i-transfers-group.svg") no-repeat; */
    background-size: contain;
}

.data__sharing__wrapper {
    margin: 0 auto;
    max-width: 683px;
    min-height: 655px;
    padding: 0 15px;
}

.data__sharing__title h3 {
    color: #0c0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding: 38px 0 61px;
    text-align: center;
    text-transform: uppercase;
}

.data__sharing__content .field {
    border-bottom: 1px solid #e7e7e7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 0 20px;
}

.data__sharing__content .field:last-child {
    border-bottom-width: 0;
}

.data__sharing__content .control {
    padding: 0 30px 0 0;
    width: calc(100% - 166px);
}

.data__sharing__content .control input {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    color: #bfc4ce;
    font-weight: 500;
    font-size: 14px;
    height: 44px;
    margin: 0;
    line-height: 20px;
    padding: 0 16px;
}

.data__sharing__content .control input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.data__sharing__content .control input::-moz-placeholder {
    color: #bfc4ce;
}

.data__sharing__content .control input:-ms-input-placeholder {
    color: #bfc4ce;
}

.data__sharing__content .control input:-moz-placeholder {
    color: #bfc4ce;
}

.data__sharing__content .action {
    width: 166px;
}

.data__sharing__content .action .btn {
    background: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    line-height: 20px;
    padding: 11px 61px;
}

.data__sharing__content .action .btn:hover,
.data__sharing__content .action .btn:focus {
    background: #ee0033;
    border-color: #ee0033;
    color: #fff;
}

.data__sharing__content .note {
    color: #576c8a;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0 31px;
    width: 100%;
}

.data__sharing--detail__wrapper {
    max-width: 975px;
    margin: 0 auto;
    padding: 0 15px;
}

.data__sharing--detail__title h3 {
    color: #0c0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding: 38px 0 61px;
    text-align: center;
    text-transform: uppercase;
}

.data__sharing--detail__content .detail__top {
    padding: 0 0 34px;
}

.data__sharing--detail__content .detail__title {
    color: #0c0033;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 28px;
}

.data__sharing--detail__content .detail__des__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.data__sharing--detail__content .detail__des__left {
    width: 40%;
}

.data__sharing--detail__content .detail__des__right {
    text-align: right;
    width: 60%;
}

.data__sharing--detail__content .detail__des__right__text {
    color: #576c8a;
    font-weight: normal;
    font-size: 20px;
    margin-right: 7px;
    line-height: 180%;
    letter-spacing: -0.5px;
}

.data__sharing--detail__content .detail__des__bar {
    padding: 55px 0 85px;
    width: 100%;
}

.data__sharing--detail__content .detail__des__bar .bar__progress {
    position: relative;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__full {
    background: #e7e7e7;
    border-radius: 6px;
    height: 8px;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__full span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__current {
    position: absolute;
    left: 0;
    top: 0;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__current:before {
    background: #ee0033;
    border-radius: 6px;
    content: "";
    height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__current:after {
    bottom: 100%;
    border: solid transparent;
    border-bottom-color: #fe9a00;
    border-width: 0 6px 12px 6px;
    content: " ";
    height: 0;
    right: -6px;
    position: absolute;
    pointer-events: none;
    top: 14px;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__text {
    position: absolute;
    right: -63px;
    text-align: center;
    top: 25px;
}

.data__sharing--detail__content .detail__des__bar .bar__progress__text span {
    color: #fff;
    background: #fe9a00;
    border-radius: 10px;
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    padding: 5px 10px;
    width: 130px;
}

.data__sharing--detail__content .detail__customer__wrapper {
    padding: 30px 0 0;
}

.data__sharing--detail__content .detail__customer__title {
    padding: 0 0 15px;
}

.data__sharing--detail__content .detail__customer__items {
    padding: 0 0 50px;
}

.data__sharing--detail__content .detail__customer__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 15px 0;
}

.data__sharing--detail__content .detail__customer__item__left {
    color: #0c0033;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-left: 40px;
    position: relative;
    width: 40%;
}

.data__sharing--detail__content .detail__customer__item__left:before {
    /* background: transparent url("../images/i-customer.svg") no-repeat; */
    background-size: contain;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: -3px;
    width: 24px;
}

.data__sharing--detail__content .detail__customer__item--phone {
    font-weight: 400;
}

.data__sharing--detail__content .detail__customer__item__right {
    text-align: right;
    width: 60%;
}

.data__sharing--detail__content .detail__customer__item__data {
    color: #0c0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-right: 25px;
}

.data__sharing--detail__content .detail__customer__item .btn {
    background: #ee0033;
    border-color: #ee0033;
    border-radius: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: 195px;
}

.data__sharing--detail__content .detail__customer__item .btn:focus,
.data__sharing--detail__content .detail__customer__item .btn:hover {
    background: #b70d02;
    border-color: #b70d02;
}

.data__sharing--detail__content .detail__customer__action {
    padding: 0 0 100px;
    text-align: center;
}

.data__sharing--detail__content .detail__customer__action .btn {
    background: #ee0033;
    border-color: #ee0033;
    border-radius: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    margin: 0 14px;
    line-height: 20px;
    width: 262px;
}

.data__sharing--detail__content .detail__customer__action .btn:focus,
.data__sharing--detail__content .detail__customer__action .btn:hover {
    background: #ff0048;
    border-color: #ff0048;
}

.trade__sim .tit {
    margin: 0 0 32px;
}

.trade__sim__free__wrapper {
    margin: 0 auto;
    max-width: 750px;
    padding-right: 100px;
}

.trade__sim__free__note {
    color: #576c8a;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 0 0 15px;
}

.trade__sim__free__content .field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 24px 0 0;
}

.trade__sim__free__content .field label {
    color: #0c0033;
    float: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    width: 195px;
}

.trade__sim__free__content .field input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #576c8a;
    padding: 0 16px;
    height: 50px;
    margin: 0;
}

.trade__sim__free__content .field input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.trade__sim__free__content .field input::-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim__free__content .field input:-ms-input-placeholder {
    color: #bfc4ce;
}

.trade__sim__free__content .field input:-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim__free__content .field__custom {
    margin: 18px 0 0;
}

.trade__sim__free__content .field__link {
    padding: 0 0 23px;
}

.trade__sim__free__content .field__link a {
    color: #ee0033;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-decoration: underline;
}

.trade__sim__free__content .field__note {
    color: #576c8a;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 62px;
}

.trade__sim__free__content .field__action {
    padding: 0 0 50px;
}

.trade__sim__free__content .field__action .btn {
    background: #ee0033;
    border-color: #ee0033;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    max-width: 360px;
    line-height: 20px;
    padding: 14px;
    text-align: center;
    width: 100%;
}

.trade__sim__free__content .field__action .btn.active,
.trade__sim__free__content .field__action .btn:hover {
    background: #b70d02;
    border-color: #b70d02;
}

.trade__sim__free__content .control {
    width: calc(100% - 195px);
    padding: 0;
}

.trade__sim__request__note {
    color: #576c8a;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 63px;
    text-align: center;
}

.trade__sim__request__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.trade__sim__request__content__left,
.trade__sim__request__content__right {
    width: 50%;
}

.trade__sim__request__content__left {
    padding-right: 15px;
}

.trade__sim__request__content__right {
    padding-left: 15px;
}

.trade__sim__request .field {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 0 24px;
}

.trade__sim__request .field label {
    color: #0c0033;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    width: 195px;
}

.trade__sim__request .field input {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    padding: 14px 16px;
}

.trade__sim__request .field input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.trade__sim__request .field input::-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim__request .field input:-ms-input-placeholder {
    color: #bfc4ce;
}

.trade__sim__request .field input:-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim__request .field .control {
    padding: 0;
    width: calc(100% - 195px);
}

.trade__sim__request .field.field__action {
    -webkit-align-content: last-baseline;
    -ms-flex-line-pack: last-baseline;
    align-content: last-baseline;
}

.trade__sim__request .field.field__action .btn {
    border: 1px solid #ee0033;
    margin-right: 15px;
    line-height: 20px;
    padding: 15px;
    width: calc(50% - 15px);
}

.trade__sim__request .field.field__action .btn:nth-child(2n + 2) {
    margin-right: 0;
    margin-left: 15px;
}

.trade__sim__request__action {
    padding: 45px 0 13px;
    text-align: center;
}

.trade__sim__request__action__note {
    color: #ff0000;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 38px;
}

.trade__sim__request__action .btn {
    background: #ee0033;
    border-color: #ee0033;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    max-width: 360px;
    line-height: 20px;
    padding: 14px;
    text-align: center;
    width: 100%;
}

.trade__sim__request__action .btn.active,
.trade__sim__request__action .btn:hover {
    background: #b70d02;
    border-color: #b70d02;
}

.trade__sim .get__sim--home {
    padding: 36px 0 310px;
}

.trade__sim .get__sim--home__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.trade__sim .get__sim--home__content .field {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-left: 15px;
    margin-bottom: 0;
    width: calc(50% - 15px);
}

.trade__sim .get__sim--home__content .field:nth-child(2n + 1) {
    margin-right: 15px;
    margin-left: 0;
}

.trade__sim .get__sim--home__content .field .control {
    margin: 0 0 24px;
    padding: 0;
    width: 65%;
}

.trade__sim .get__sim--home__content .field label {
    padding: 0;
    width: 35%;
}

.trade__sim .get__sim--home__content .field input {
    font-weight: 500;
    font-size: 14px;
    height: 50px;
    margin: 0;
    line-height: 20px;
    padding: 0 16px;
}

.trade__sim .get__sim--home__content .field input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.trade__sim .get__sim--home__content .field input::-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim .get__sim--home__content .field input:-ms-input-placeholder {
    color: #bfc4ce;
}

.trade__sim .get__sim--home__content .field input:-moz-placeholder {
    color: #bfc4ce;
}

.trade__sim .get__sim--home__action {
    padding: 40px 0 0;
    text-align: center;
}

.trade__sim .get__sim--home__action .btn {
    background: #ee0033;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    max-width: 360px;
    line-height: 20px;
    padding: 15px;
    width: 100%;
}

.trade__sim .get__sim--home__action .btn:hover,
.trade__sim .get__sim--home__action .btn:focus {
    background: #fff;
    border-color: #ee0033;
    color: #ee0033;
}

.check__fixed__title {
    color: #44494d;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.check__fixed__top {
    padding: 0 0 40px;
}

.check__fixed__top h3 {
    color: #0c0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
}

.check__fixed__mid {
    padding: 0 0 92px;
    text-align: center;
}

.check__fixed__info__img {
    padding: 0 0 17px;
}

.check__fixed__info__img img {
    border-radius: 50%;
}

.check__fixed__info__phone {
    color: #576c8a;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    padding: 17px 0 28px;
}

.check__fixed__info__date {
    background-position-x: 95%;
    color: #576c8a;
    font-weight: 500;
    font-size: 14px;
    max-width: 460px;
    line-height: 20px;
    padding: 14px 45px 14px 16px;
}

.check__fixed__info__text {
    color: #0c0033;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 30px 0 0;
    text-align: center;
}

.check__fixed__info__price {
    color: #ee0033;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    padding: 15px 0 0;
    text-align: center;
}

.check__fixed__info__action {
    padding: 39px 0 0;
}

.check__fixed__info__action .btn {
    background: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    font-size: 14px;
    max-width: 262px;
    line-height: 20px;
    padding: 16px;
    width: 100%;
}

.check__fixed__info__action .btn:hover,
.check__fixed__info__action .btn:focus {
    background: #ee0033;
    border-color: #ee0033;
    color: #fff;
}

.check__fixed__info__action .btn.btn-payment {
    background: #ee0033;
    border-color: #ee0033;
    color: #fff;
    margin-right: 27px;
}

.check__fixed__info__action .btn.btn-payment:hover,
.check__fixed__info__action .btn.btn-payment:focus {
    background: #fff;
    color: #ee0033;
}

.check__fixed__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.check__fixed__content__left,
.check__fixed__content__right {
    width: 50%;
}

.check__fixed__content__left {
    padding-right: 15px;
}

.check__fixed__content__left .check__fixed__title {
    margin-bottom: 15px;
}

.check__fixed__content__right {
    padding-left: 15px;
}

.check__fixed__content__title {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 25px;
    position: relative;
}

.check__fixed__content__title__link {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 21px;
    margin: -5px 0 0 20px;
}

.check__fixed__content__title__link:hover {
    text-decoration: underline;
}

.check__fixed__content__item {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 25px 30px 18px;
}

.check__fixed__content__date {
    border-bottom: 1px solid #e7e7e7;
    color: #576c8a;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
    padding: 0 0 11px;
}

.check__fixed__content__mid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 13px 0 0;
}

.check__fixed__content__mid__left,
.check__fixed__content__mid__right {
    width: 50%;
}

.check__fixed__content__mid__text {
    color: #0c0033;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
}

.check__fixed__content__mid__date--time {
    color: #576c8a;
    font-weight: normal;
    font-size: 12px;
    margin-top: -3px;
    line-height: 26px;
}

.check__fixed__content__mid__price {
    color: #b70d02;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: right;
}

.check__fixed__content__action {
    padding: 32px 0;
    text-align: center;
}

.check__fixed__content__action .btn {
    background: #ee0033;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #fff;
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    max-width: 360px;
    line-height: 20px;
    padding: 14px;
    width: 100%;
}

.check__fixed__content__action .btn:hover,
.check__fixed__content__action .btn:focus {
    background: #fff;
    color: #ee0033;
}

.check__month__box {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.check__month .check__fixed__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.check__month__box {
    padding: 45px 15px 20px;
    width: 360px;
}

.check__month__box.margin__right {
    margin-left: 30px;
    padding: 30px 35px 20px;
    width: calc(100% - 390px);
}

.check__month__box__info {
    text-align: center;
}

.check__month__box__total .check__fixed__info__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.check__month__box__total__left,
.check__month__box__total__right {
    width: 50%;
}

.check__month__box__total__left .check__fixed__info__text,
.check__month__box__total__left .check__fixed__info__price {
    text-align: left;
}

.check__month__box__total__left .check__fixed__info__text {
    padding: 10px 0 0;
}

.check__month__box__total__left .check__fixed__info__price {
    padding: 4px 0 35px;
}

.check__month__box__total__right {
    text-align: right;
}

.check__month__box__total__right select {
    max-width: 320px;
}

.check__month__box__total__bottom {
    border-top: 1px solid #e7e7e7;
    width: 100%;
}

.check__month__box__total .detail__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 51px 0 9px;
}

.check__month__box__total .detail__item {
    padding-left: 30px;
    position: relative;
    text-align: left;
}

.check__month__box__total .detail__item:before {
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 20px;
}

.check__month__box__total .detail__item.one,
.check__month__box__total .detail__item.two,
.check__month__box__total .detail__item.three {
    padding-right: 15px;
}

.check__month__box__total .detail__item.one {
    width: 33%;
}

.check__month__box__total .detail__item.one:before {
    /* background: transparent url("../images/i-check__month1.svg") no-repeat; */
    background-size: contain;
}

.check__month__box__total .detail__item.two {
    width: 27.7%;
}

.check__month__box__total .detail__item.two:before {
    /* background: transparent url("../images/i-check__month2.svg") no-repeat; */
    background-size: contain;
}

.check__month__box__total .detail__item.three {
    width: 24%;
}

.check__month__box__total .detail__item.three:before {
    /* background: transparent url("../images/i-check__month3.svg") no-repeat; */
    background-size: contain;
}

.check__month__box__total .detail__item.four {
    width: 15.3%;
}

.check__month__box__total .detail__item.four:before {
    /* background: transparent url("../images/i-check__month4.svg") no-repeat; */
    background-size: contain;
}

.check__month__box__total .detail__item__label {
    color: #576c8a;
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.check__month__box__total .detail__item__content {
    color: #0c0033;
    display: block;
    font-weight: 500;
    font-size: 16px;
    margin-top: -5px;
    line-height: 180%;
}

.check__month .check__fixed__mid {
    padding: 0 0 65px;
}

.check__month .check__fixed__title {
    margin-bottom: 18px;
}

.check__month .check__fixed__content {
    padding: 0 0 25px;
}

.check__month .check__fixed__content .check__month__box {
    padding: 30px 34px 13px 30px;
    width: 100%;
}

.check__month .check__fixed__content .check__month__box__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0 0 15px;
}

.check__month .check__fixed__content .check__month__box__item.total .check__month__box__item__left,
.check__month .check__fixed__content .check__month__box__item.total .check__month__box__item__right {
    padding-top: 17px;
}

.check__month .check__fixed__content .check__month__box__item.total .check__month__box__item__right {
    border-top: 1px solid #9e9e9e;
}

.check__month .check__fixed__content .check__month__box__item__left,
.check__month .check__fixed__content .check__month__box__item__right {
    font-family: "SF Pro Text";
    font-size: 16px;
    line-height: 32px;
}

.check__month .check__fixed__content .check__month__box__item__left {
    color: #0c0033;
    width: calc(100% - 100px);
}

.check__month .check__fixed__content .check__month__box__item__right {
    color: #ee0033;
    text-align: right;
    width: 100px;
}

.check__month .check__fixed__content__link {
    padding: 65px 0 0;
    text-align: center;
    width: 100%;
}

.check__month .check__fixed__content__link a {
    color: #ee0033;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-decoration: underline;
}

.tracking__network__title {
    padding: 11px 15px 25px;
}

.tracking__network__title h3 {
    color: #0c0033;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
}

.tracking__network__top {
    text-align: center;
}

.tracking__network__info__img {
    padding: 0 0 17px;
}

.tracking__network__info__img img {
    border-radius: 50%;
}

.tracking__network__info__content {
    padding: 0 0 38px;
}

.tracking__network__info__title {
    color: #0c0033;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 0 0 17px;
}

.tracking__network__info__phone {
    color: #ee0033;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.5px;
}

.tracking__network__info__phone.color__black {
    color: #000044;
    padding-bottom: 28px;
}

.tracking__network__otp {
    max-width: 555px;
    margin: 0 auto;
}

.tracking__network__otp__wrapper {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 25px 75px 20px;
}

.tracking__network__otp__text {
    color: #0c0033;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding: 0 0 25px;
}

.tracking__network__otp__content {
    padding: 0 0 20px;
}

.tracking__network__otp__content input {
    border: 1px solid transparent;
    background: #e7e7e7;
    border-radius: 6px;
    height: 76px;
    margin: 0 15px;
    padding: 0 16px;
    width: 67px;
}

.tracking__network__otp__content input.active,
.tracking__network__otp__content input:focus {
    border-color: #ee0033;
}

.tracking__network__otp__link a {
    color: #ee0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.125px;
    text-decoration: underline;
}

.tracking__network__otp__action {
    padding: 28px 0 64px;
}

.tracking__network__otp__action .btn {
    background: #ee0033;
    border: 1px solid #ee0033;
    color: #fff;
    font-size: 14px;
    max-width: 360px;
    line-height: 20px;
    padding: 14px;
    width: 100%;
}

.tracking__network__otp__action .btn:hover,
.tracking__network__otp__action .btn:focus {
    background: #fff;
    color: #ee0033;
}

.tracking__network .history__using {
    padding: 24px 24px 0;
}

.tracking__network .history__using__title {
    color: #0c0033;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 0 0 25px;
}

.tracking__network .history__using__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0 0 20px;
}

.tracking__network .history__using__item__left {
    padding-left: 42px;
    position: relative;
    width: 60%;
}

.tracking__network .history__using__item__left:before {
    content: "";
    left: 0;
    position: absolute;
}

.tracking__network .history__using__item__right {
    text-align: right;
    width: 40%;
}

.tracking__network .history__using__item__label,
.tracking__network .history__using__item__time {
    display: block;
}

.tracking__network .history__using__item__label {
    color: #0c0033;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
}

.tracking__network .history__using__item__time {
    color: #576c8a;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    margin-top: -9px;
}

.tracking__network .history__using__item.call__post .history__using__item__left:before {
    /* background: transparent url("../images/i-tracking-call-post.svg") no-repeat; */
    background-size: contain;
    height: 37px;
    top: -3px;
    width: 35px;
}

.tracking__network .history__using__item.message__post .history__using__item__left:before {
    /* background: transparent url("../images/i-tracking-message-post.svg") no-repeat; */
    background-size: contain;
    height: 23px;
    top: 7px;
    width: 30px;
}

.tracking__network .history__using__item.message__get .history__using__item__left:before {
    /* background: transparent url("../images/i-tracking-message-get.svg") no-repeat; */
    background-size: contain;
    height: 23px;
    top: 7px;
    width: 30px;
}

.tracking__network .history__using__item.call__get .history__using__item__left:before {
    /* background: transparent url("../images/i-tracking-call-get.svg") no-repeat; */
    background-size: contain;
    height: 38px;
    top: -3px;
    width: 36px;
}

.tracking__network .history__using__item.item__pack .history__using__item__left:before {
    /* background: transparent url("../images/i-tracking-pack.svg") no-repeat; */
    background-size: contain;
    height: 30px;
    top: 5px;
    width: 30px;
}

.tracking__network .owl-carousel .owl-stage-outer {
    margin-bottom: 0;
}

.tracking__network .owl-carousel .owl-dots {
    display: none !important;
}

.tracking__network .navpage {
    margin-top: 0;
}

.tracking__network .navpage ul li:hover a {
    background: transparent;
    color: #b70d02;
}

.get__sim .tit {
    margin: 0 0 30px;
}

.get__sim__note {
    color: #576c8a;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 0 0 39px;
    text-align: center;
}

.get__sim__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0 0 10px;
}

.get__sim__content__left,
.get__sim__content__right {
    width: 50%;
}

.get__sim__content__left {
    padding-right: 15px;
}

.get__sim__content__left .map iframe {
    width: 100%;
}

.get__sim__content__right {
    padding-left: 15px;
}

.get__sim__box__select {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0 0 25px;
}

.get__sim__box__select .control {
    width: calc(50% - 15px);
    margin-left: 15px;
}

.get__sim__box__select .control:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: 15px;
}

.get__sim__box__select select {
    background-position-x: 94%;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    color: #bfc4ce;
    font-weight: 500;
    font-size: 14px;
    height: 50px;
    line-height: 20px;
    padding: 0 40px 0 16px;
    width: 100%;
}

.get__sim__box__item {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 25px;
}

.get__sim__box__item:last-child {
    border-bottom: none;
}

.get__sim__box__item.active .item__content.address .item__right span {
    opacity: 1;
}

.get__sim__box__item.active .item__content.time .item__right .item__content__check {
    background: #ee0033;
    border-color: #ee0033;
    box-shadow: none;
}

.get__sim__box__item .item__title {
    color: #ee0033;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 0 0 20px;
}

.get__sim__box__item .item__content {
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
}

.get__sim__box__item .item__content.address {
    padding: 0 0 10px;
}

.get__sim__box__item .item__content.address .item__right span {
    /* background: transparent url("../images/i-get-sim-calender.svg") no-repeat; */
    background-size: contain;
    display: inline-block;
    height: 24px;
    opacity: 0.5;
    width: 24px;
}

.get__sim__box__item .item__content.phone {
    padding: 0 0 18px;
}

.get__sim__box__item .item__content.phone .item__left:before {
    /* background: transparent url("../images/i-transfers-phone.svg") no-repeat; */
    background-size: contain;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 24px;
}

.get__sim__box__item .item__content.time {
    padding: 0 0 7px;
}

.get__sim__box__item .item__content.time .item__right .item__content__check {
    background: #fff;
    border: 2px solid #e7e7e7;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    position: relative;
    width: 24px;
}

.get__sim__box__item .item__content.time .item__right .item__content__check i {
    color: #fff;
    font-size: 10px;
    font-weight: 300;
    position: absolute;
    left: calc(50% - 6px);
    top: calc(50% - 5px);
}

.get__sim__box__item .item__left {
    padding-left: 35px;
    position: relative;
    width: calc(100% - 50px);
}

.get__sim__box__item .item__left i {
    color: #ee0033;
    font-size: 24px;
    font-weight: 300;
    left: 0;
    position: absolute;
    top: 2px;
}

.get__sim__box__item .item__right {
    padding-top: 3px;
    text-align: right;
    width: 50px;
}

.get__sim .store__detail {
    padding: 0 0 120px;
}

.get__sim .store__detail .get__sim__wrapper {
    margin: 0 auto;
    max-width: 555px;
}

.get__sim .store__detail .get__sim__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.get__sim .store__detail .get__sim__box__item {
    border-bottom: none;
}

.get__sim .store__detail .get__sim__box__item .item__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.get__sim .store__detail .get__sim__box__item .item__content.address {
    padding: 0 0 15px;
}

.get__sim .store__detail .get__sim__box__item .item__content.time {
    padding: 0 0 10px;
}

.get__sim .store__detail .get__sim__box__item .item__content.date {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 0 0 18px;
}

.get__sim .store__detail .get__sim__box__item .item__content.date .item__left {
    color: #ee0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    width: calc(100% - 164px);
}

.get__sim .store__detail .get__sim__box__item .item__content.date .item__left:before {
    /* background: transparent url("../images/i-get-sim-calender.svg") no-repeat; */
    background-size: contain;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 24px;
}

.get__sim .store__detail .get__sim__box__item .item__content.date .item__right {
    padding: 0;
    width: 164px;
}

.get__sim .store__detail .get__sim__box__item .item__content.date .btn {
    background: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    font-size: 14px;
    max-width: 164px;
    line-height: 20px;
    padding: 15px;
    width: 100%;
}

.get__sim .store__detail .get__sim__box__item .item__content.date .btn:hover,
.get__sim .store__detail .get__sim__box__item .item__content.date .btn:focus {
    background: #ee0033;
    color: #fff;
}

.get__sim .store__detail .get__sim__box__item .item__left {
    width: 100%;
}

.get__sim .store__detail .get__sim__box__item .item__right {
    width: 100%;
}

.get__sim .store__detail .get__sim__box__item__action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.get__sim .store__detail .get__sim__box__item__action .btn {
    background: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    font-size: 14px;
    margin-right: 15px;
    max-width: 262px;
    line-height: 20px;
    padding: 15px;
    width: calc(100% - 15px);
}

.get__sim .store__detail .get__sim__box__item__action .btn:hover,
.get__sim .store__detail .get__sim__box__item__action .btn:focus {
    background: #ee0033;
    color: #fff;
}

.get__sim .store__detail .get__sim__box__item__action .btn.btn-continue {
    background: #ee0033;
    color: #fff;
    margin-right: 0;
    margin-left: 15px;
}

.get__sim .store__detail .get__sim__box__item__action .btn.btn-continue:hover,
.get__sim .store__detail .get__sim__box__item__action .btn.btn-continue:focus {
    background: #fff;
    border-color: #ee0033;
    color: #ee0033;
}

.charges-price__date {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: 840px;
    margin: 0 auto;
}

.charges-price__date .field {
    margin: 0 60px 20px 0;
    width: calc(50% - 60px);
}

.charges-price__date .field:nth-child(2n + 2) {
    margin-right: 0;
    margin-left: 60px;
}

.charges-price__date .field label {
    display: block;
    float: none;
    padding: 0 0 7px;
    text-align: left;
}

.charges-price__date .field .control {
    padding: 0;
}

.charges-price__date .field input {
    /* background: #fff url("../images/i-input-date.svg") no-repeat; */
    background-position-x: calc(100% - 20px);
    background-position-y: 46%;
    font-weight: 500;
    font-size: 14px;
    height: 50px;
    margin: 0;
    line-height: 20px;
    padding: 0 35px 0 16px;
    width: 100%;
}

.charges-price__date .field input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.charges-price__date .field input::-moz-placeholder {
    color: #bfc4ce;
}

.charges-price__date .field input:-ms-input-placeholder {
    color: #bfc4ce;
}

.charges-price__date .field input:-moz-placeholder {
    color: #bfc4ce;
}

.payment__page__title {
    color: #0c0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding: 35px 0 24px;
    text-align: center;
    text-transform: uppercase;
}

.payment__wrapper {
    margin: 0 auto;
    max-width: 750px;
}

.payment__title {
    color: #0c0033;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    padding: 0 0 15px;
}

.payment__top {
    padding: 0 0 31px;
    text-align: center;
}

.payment__top .payment__title {
    font-weight: 500;
    padding-bottom: 5px;
}

.payment__top__price {
    color: #ee0033;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
}

.payment__method {
    padding: 0 0 15px;
}

.payment__method .payment__title {
    padding-bottom: 24px;
}

.payment__method .control {
    padding: 0 0 24px;
}

.payment__method .control input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.payment__method .control input[type="radio"]:checked + label {
    color: #ee0033;
}

.payment__method .control input[type="radio"]:checked + label i {
    border-color: transparent;
    border-radius: inherit;
    color: #ee0033;
    height: 15px;
    left: -1px;
    top: 0;
    width: 15px;
}

.payment__method__label {
    color: #576c8a;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-left: 20px;
    position: relative;
}

.payment__method__label i {
    border-radius: 50%;
    border: 1px solid #576c8a;
    color: transparent;
    height: 13px;
    font-size: 15px;
    font-weight: 300;
    left: 0;
    position: absolute;
    top: 2px;
    width: 13px;
}

.payment__orders {
    padding: 0 0 40px;
}

.payment__orders__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.payment__orders__item .payment__title {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    padding-right: 30px;
    width: 50%;
}

.payment__orders__item .payment__title.box__right {
    padding-left: 15px;
}

.payment__orders__box {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    padding: 30px 30px 10px;
}

.payment__orders__box.box__left,
.payment__orders__box.box__right {
    width: calc(50% - 15px);
}

.payment__orders__box.box__left {
    margin-right: 15px;
}

.payment__orders__box.box__right {
    margin-left: 15px;
}

.payment__orders__box p {
    color: #576c8a;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 15px;
}

.payment__orders__box .payment__orders__price {
    padding-bottom: 20px;
}

.payment__orders__price {
    padding-top: 10px;
}

.payment__customer .payment__title {
    padding-bottom: 22px;
}

.payment__customer p {
    color: #576c8a;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    padding-left: 40px;
    padding-bottom: 17px;
    position: relative;
}

.payment__customer p:before {
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 24px;
}

.payment__customer__name:before {
    /* background: transparent url("../images/i-customer.svg") no-repeat; */
    background-size: contain;
}

.payment__customer__birth:before {
    /* background: transparent url("../images/i-get-sim-calender.svg") no-repeat; */
    background-size: contain;
}

.payment__customer__phone:before {
    /* background: transparent url("../images/i-transfers-phone.svg") no-repeat; */
    background-size: contain;
}

.payment__action {
    padding: 23px 0 40px;
    text-align: center;
}

.payment__action .btn {
    background: #ee0033;
    border: 1px solid #ee0033;
    color: #fff;
    font-size: 14px;
    max-width: 360px;
    line-height: 20px;
    padding: 15px;
    width: 100%;
}

.payment__action .btn:hover,
.payment__action .btn:focus {
    background: #fff;
    color: #ee0033;
}

.orders__pages .btn__orders {
    background: #ee0033;
    border: 1px solid #ee0033;
    color: #fff;
    cursor: pointer;
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 14px;
    max-width: 165px;
    line-height: 20px;
    padding: 15px;
    width: 165px;
}

.orders__pages .btn__orders:hover,
.orders__pages .btn__orders:focus {
    background: #b70d02;
    border-color: #b70d02;
}

.orders__manager {
    padding: 0 0 34px;
}

.orders__manager__wrapper {
    margin: 0 auto;
    max-width: 945px;
}

.orders__manager__search .control {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.orders__manager__search .control__left {
    position: relative;
    width: calc(100% - 195px);
}

.orders__manager__search .control__left i {
    font-size: 20px;
    color: #576c8a;
    font-weight: 300;
    left: 14px;
    position: absolute;
    top: calc(50% - 10px);
}

.orders__manager__search .control__right {
    padding-left: 30px;
    width: 195px;
}

.orders__manager__search .control input {
    background: rgba(244, 248, 248, 0.788188);
    border-color: transparent;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #576c8a;
    height: 52px;
    padding: 0 16px 0 46px;
}

.orders__manager__search .control input::-webkit-input-placeholder {
    color: #576c8a;
}

.orders__manager__search .control input::-moz-placeholder {
    color: #576c8a;
}

.orders__manager__search .control input:-ms-input-placeholder {
    color: #576c8a;
}

.orders__manager__search .control input:-moz-placeholder {
    color: #576c8a;
}

.orders__manager__search .sort-by {
    padding: 30px 0 40px;
    width: 100%;
}

.orders__manager__search .sort-by .filter {
    color: #bfc4ce;
    float: right;
    max-width: 262px;
    width: 100%;
}

.orders__manager__search .sort-by .filter .box-filter {
    padding: 14px 35px 14px 16px;
}

.orders__manager__search .sort-by .filter .filter-content {
    left: 0;
    right: inherit;
    width: 254px;
}

.orders__manager__items.no__images .orders__manager__item {
    padding-left: 47px;
}

.orders__manager__items.no__images .orders__manager__item__detail__content__info {
    width: 100%;
}

.orders__manager__items.no__images .orders__manager__item__detail__content__info h3:before {
    /* background: transparent url("../images/i-transfers-phone.svg") no-repeat; */
    background-size: contain;
    content: "";
    display: inline-block;
    height: 23px;
    margin-right: 7px;
    position: relative;
    top: 7px;
    width: 23px;
}

.orders__manager__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 0 30px;
    padding: 30px;
}

.orders__manager__item:hover {
    box-shadow: none;
}

.orders__manager__item__detail,
.orders__manager__item__status {
    width: 50%;
}

.orders__manager__item__detail__content {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.orders__manager__item__detail__content__img {
    padding-right: 30px;
    width: 165px;
}

.orders__manager__item__detail__content__info {
    width: calc(100% - 165px);
}

.orders__manager__item__detail__content__info h3 {
    color: #0c0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding: 0 0 13px;
}

.orders__manager__item__detail__content__info p {
    color: #576c8a;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
}

.orders__manager__item__status {
    text-align: right;
}

.orders__manager__item__status .order__status {
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding-left: 40px;
    position: relative;
}

.orders__manager__item__status .order__status:before {
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.orders__manager__item__status .order__done {
    color: #ee0033;
}

.orders__manager__item__status .order__done:before {
    /* background: transparent url("../images/i-order-detail-check.svg") no-repeat; */
    background-size: contain;
}

.orders__manager__item__status .order__close {
    color: #ff0048;
}

.orders__manager__item__status .order__close:before {
    /* background: transparent url("../images/i-order-detail-close.svg") no-repeat; */
    background-size: contain;
}

.orders__manager__item__status .order__waiting {
    color: #fe9a00;
    padding-bottom: 15px;
}

.orders__manager__item__status .order__waiting:before {
    /* background: transparent url("../images/i-order-detail-loading.svg") no-repeat; */
    background-size: contain;
}

.orders__manager__item__status .btn__orders {
    margin-left: 20px;
}

.orders__detail .tit {
    margin: 0 0 45px;
}

.orders__detail__box {
    background: #fff;
    box-shadow: 0 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
}

.orders__detail__title {
    color: #576c8a;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.orders__detail__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.orders__detail__sidebar {
    padding-bottom: 34px;
    width: 360px;
}

.orders__detail__address,
.orders__detail__status {
    padding: 0 0 30px;
}

.orders__detail__address .orders__detail__box,
.orders__detail__status .orders__detail__box {
    padding: 20px 20px 10px 30px;
}

.orders__detail__address .orders__detail__title,
.orders__detail__status .orders__detail__title {
    display: block;
}

.orders__detail__address__title,
.orders__detail__address__text,
.orders__detail__address__phone,
.orders__detail__address__time {
    display: block;
}

.orders__detail__address__text,
.orders__detail__address__phone,
.orders__detail__address__time {
    color: #0c0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    padding-left: 22px;
    padding-bottom: 8px;
    position: relative;
}

.orders__detail__address__text i,
.orders__detail__address__phone i,
.orders__detail__address__time i {
    content: "";
    height: 16px;
    font-weight: 300;
    left: 0;
    position: absolute;
    top: 6px;
    width: 16px;
}

.orders__detail__address__title {
    color: #0c0033;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 0 8px;
}

.orders__detail__address__phone i {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.orders__detail__status .orders__detail__title {
    padding: 0 0 20px;
}

.orders__detail__status__content {
    padding: 0 0 18px;
}

.orders__detail__status__items {
    position: relative;
}

.orders__detail__status__item {
    padding: 0 0 51px 27px;
    position: relative;
}

.orders__detail__status__item.active:before {
    background: #ee0033;
    bottom: -13px;
    content: "";
    left: 0;
    position: absolute;
    top: 13px;
    width: 7px;
}

.orders__detail__status__item.active:after {
    background: #ee0033;
    border-radius: 50%;
    content: "";
    height: 17px;
    left: -5px;
    position: absolute;
    top: 5px;
    width: 17px;
}

.orders__detail__status__item.active.order__delivered:before {
    top: 13px;
}

.orders__detail__status__item.active.order__add:before {
    bottom: 75%;
}

.orders__detail__status__item.order__add {
    padding-bottom: 0;
}

.orders__detail__status__text,
.orders__detail__status__time {
    display: block;
}

.orders__detail__status__text {
    color: #0c0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
}

.orders__detail__status__time {
    color: #576c8a;
    font-weight: normal;
    font-size: 14px;
    margin-top: -3px;
    line-height: 24px;
}

.orders__detail__content {
    padding-bottom: 64px;
    padding-left: 30px;
    width: calc(100% - 360px);
}

.orders__detail__content__top {
    padding: 20px 38px 25px 30px;
}

.orders__detail__content__top .orders__detail__title:before {
    /* background: transparent url("../images/i-order-detail-cart.svg") no-repeat; */
    background-size: contain;
    content: "";
    display: inline-block;
    height: 18px;
    margin-right: 7px;
    position: relative;
    top: 2px;
    width: 19px;
}

.orders__detail__content__top__code {
    color: #ee0033;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    padding-left: 5px;
}

.orders__detail__content__item {
    border-top: 1px solid #e7e7e7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 18px 38px 18px 30px;
}

.orders__detail__content__item:first-child {
    border-top: none;
    padding-top: 0;
}

.orders__detail__content__item__img {
    background: #fff;
    border: 2px solid #eef1f7;
    box-shadow: 0 10px 20px rgba(177, 186, 201, 0.15);
    border-radius: 10px;
    max-height: 162px;
    max-width: 162px;
    padding: 21px 28px;
    text-align: center;
}

.orders__detail__content__item__img.prepay,
.orders__detail__content__item__img.postpaid {
    padding: 52px 35px;
}

.orders__detail__content__item__img.prepay {
    background: #864fdf;
}

.orders__detail__content__item__img.postpaid {
    background: #fe9a00;
}

.orders__detail__content__item__img span {
    color: #fff;
    display: inline-block;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 46px;
    line-height: 54px;
}

.orders__detail__content__item__img img {
    max-width: 104px;
}

.orders__detail__content__item__info {
    padding-left: 20px;
    padding-right: 20px;
    width: 55%;
}

.orders__detail__content__item__info h3 {
    color: #0c0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 5px;
}

.orders__detail__content__item__info p {
    color: #0c0033;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
}

.orders__detail__content__item__price {
    text-align: right;
    width: calc(100% - 55% - 162px);
}

.orders__detail__content__item__price .price,
.orders__detail__content__item__price .price__old {
    display: block;
}

.orders__detail__content__item__price .price {
    color: #ee0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

.orders__detail__content__item__price .price__old {
    color: #576c8a;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-decoration: line-through;
}

.orders__detail__content__total {
    border-top: 1px solid #e7e7e7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 20px 38px 27px 30px;
}

.orders__detail__content__total__text {
    color: #576c8a;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    width: 40%;
}

.orders__detail__content__total__price {
    text-align: right;
    width: 60%;
}

.orders__detail__content__total__price .price,
.orders__detail__content__total__price .note {
    display: block;
}

.orders__detail__content__total__price .price {
    color: #ee0033;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 10px;
}

.orders__detail__content__total__price .note {
    color: #576c8a;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
}

@media only screen and (max-width: 1200px) {
    .page-qltkdd .tabs__detail__content__mid__bot .btn {
        min-width: 170px;
    }
    .page-qltkdd .tabs-utilities .utilities__image {
        width: 40%;
    }
    .page-qltkdd .tabs-utilities .list-utilities {
        width: 60%;
    }
    .viettel__detail .section__content--right.section .detail__content__title {
        padding-left: 10%;
    }
    .viettel__detail .section__content .detail__content__title {
        padding-right: 10%;
    }
    .viettel__detail .section__tabs table th {
        font-size: 20px;
        line-height: 24px;
        padding: 15px 10px;
    }
    .viettel__detail .section__tabs table td {
        font-size: 20px;
        line-height: 24px;
        border-top: 1px solid #576c8a;
        padding: 32px 10px 30px;
    }
    .viettel__detail .section__tabs .table__pack tbody .number {
        width: 12%;
    }
    .viettel__detail .section__tabs .table__pack tbody .pack,
    .viettel__detail .section__tabs .table__pack tbody .maintaining {
        width: 19%;
    }
    .viettel__detail .section__tabs .table__pack tbody .limit--amount,
    .viettel__detail .section__tabs .table__pack tbody .limit--trade {
        width: 25%;
    }
    .search__maintain .search-store_content .map {
        width: 550px;
    }
    .search__maintain .search-store_content .box-search {
        width: 100%;
    }
    .orders__detail__sidebar {
        width: 300px;
    }
    .orders__detail__content {
        width: calc(100% - 300px);
    }
    .orders__detail__content__top {
        padding: 20px 30px 25px 30px;
    }
    .orders__detail__content__item {
        padding: 18px 30px 18px 30px;
    }
    .orders__detail__content__item:first-child {
        padding-top: 0;
    }
    .orders__detail__content__item__img {
        max-height: 150px;
        max-width: 150px;
        padding: 15px 25px;
    }
    .orders__detail__content__item__img.prepay,
    .orders__detail__content__item__img.postpaid {
        padding: 46px 29px;
    }
    .orders__detail__content__item__info {
        width: 50%;
    }
    .orders__detail__content__item__price {
        width: calc(100% - 50% - 150px);
    }
    .orders__detail__content__total {
        padding: 20px 30px 27px 30px;
    }
}

@media only screen and (max-width: 1023px) {
    .page-qltkdd.page-ftth .tabs__detail__content__left__price__sale,
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__left__price__sale,
    .page-qltkdd.page-ftth .tabs__detail__content__left__date i,
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__left__date i {
        margin-left: 0;
    }
    .page-qltkdd.page-ftth .tabs__detail__content__channel__text,
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__channel__text {
        display: block;
    }
    .page-qltkdd.page-ftth .tabs__detail__content__right__top-channel,
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__right__top-channel {
        padding-right: 0;
    }
    .page-qltkdd.page-ftth .tabs__detail__content__right__top-channel .tabs__detail__content__channel__point,
    .page-qltkdd.page-ftth.page-ftth3
        .tabs__detail__content__right__top-channel
        .tabs__detail__content__channel__point {
        font-size: 20px;
    }
    .page-qltkdd.page-ftth .tabs__detail__content__left__price,
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__left__price {
        font-size: 20px;
        white-space: nowrap;
    }
    .page-qltkdd.page-ftth .tabs-utilities .list-utilities,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .list-utilities,
    .page-qltkdd.page-ftth .tabs-utilities .utilities__image,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .utilities__image {
        width: 100%;
    }
    .page-qltkdd.page-ftth .tabs-utilities .utilities__image,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .utilities__image {
        padding: 20px 0;
        text-align: center;
    }
    .page-qltkdd.page-ftth .tabs-utilities .content .lists.slider .list-utilities,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .list-utilities {
        padding: 20px 50px 10px;
    }
    .page-qltkdd.page-ftth .tabs-utilities .content .lists.slider .list-utilities .item,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .list-utilities .item {
        margin: 0;
    }
    .page-qltkdd.page-ftth .tabs-utilities .content .lists.slider .item__utilities,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .item__utilities {
        width: calc(50% - 15px);
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .item__utilities:nth-child(2n + 1),
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .item__utilities:nth-child(2n + 2) {
        margin: 0 auto;
    }
    .page-qltkdd .tabs__detail__content__left,
    .page-qltkdd .tabs__detail__content__right {
        width: 23%;
    }
    .page-qltkdd .tabs__detail__content__mid {
        width: 54%;
    }
    .page-qltkdd .tabs__detail__content__left__text,
    .page-qltkdd .tabs__detail__content__left__price {
        display: block;
        margin: 0;
        text-align: left;
    }
    .page-qltkdd .tabs__detail__content__right__point,
    .page-qltkdd .tabs__detail__content__left__price {
        font-size: 20px;
    }
    .page-qltkdd .tabs__detail__content__mid__price {
        font-size: 35px;
    }
    .page-qltkdd .tabs__detail__content__right__text,
    .page-qltkdd .tabs__detail__content__right__point {
        display: block;
        margin: 0;
        text-align: right;
    }
    .page-qltkdd .tabs__detail__content__right__member {
        padding: 0;
    }
    .page-qltkdd .tabs__detail__content__left__time {
        padding-left: 0;
    }
    .page-qltkdd .tabs-utilities {
        padding: 0 0 40px;
    }
    .page-qltkdd .tabs-utilities .utilities__image,
    .page-qltkdd .tabs-utilities .list-utilities {
        width: 100%;
    }
    .page-qltkdd .tabs-utilities .utilities__image {
        padding: 20px 0;
        text-align: center;
    }
    .page-qltkdd .tabs-utilities .list-utilities {
        padding: 0 50px;
    }
    .page-qltkdd .mobile-service.tabs-lookups {
        overflow: unset;
    }
    .page-qltkdd .content .tit .btabs {
        margin-left: 0;
    }
    .viettel__detail .section__content--right.section .detail__content__title {
        padding-left: 0;
    }
    .viettel__detail .section__content .detail__content__title {
        padding-right: 0;
        font-size: 36px;
        line-height: 46px;
        padding-bottom: 15px;
    }
    .viettel__detail .section__content .detail__content__des {
        padding-right: 0;
        font-size: 18px;
    }
    .search__maintain .tab-title li,
    .transfers__wrapper .tab-title li {
        padding: 0 25px;
    }
    .search__maintain .search-store_content .map,
    .search__maintain .search-store_content .box-search {
        width: 100%;
    }
    .search__maintain .search-store_content .map {
        -webkit-box-ordinal-group: unset;
        -webkit-order: unset;
        -ms-flex-order: unset;
        order: unset;
    }
    .search__maintain .search-store_content .box-search {
        padding-left: 0;
    }
    .search__maintain .maintain__title {
        width: 320px;
    }
    .search__maintain .maintain__content {
        width: calc(100% - 320px);
    }
    .search__maintain .block__speed__result .speed__result__item:nth-child(odd) {
        padding-right: 15px;
    }
    .search__maintain .block__speed__result .speed__result__item:nth-child(even) {
        padding-left: 15px;
    }
    .search__maintain .block__speed__result .speed__result__item__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        padding: 20px;
    }
    .search__maintain .block__speed__result .speed__result__item__content {
        width: 100%;
    }
    .search__maintain .block__speed__result .speed__result__item__right {
        text-align: left;
        width: 100%;
    }
    .search__maintain .block__speed__result .speed__result__item__date--time {
        padding-left: 25px;
        padding-bottom: 10px;
    }
    .search__maintain .block__speed__detail__item__content {
        padding: 45px 30px 30px;
    }
    .search__maintain .block__speed__bar__top {
        padding: 45px 30px 10px;
    }
    .transfers__wrapper .block__transfers__form {
        padding-right: 30px;
    }
    .transfers__wrapper .block__transfers__form .field label {
        width: 145px;
    }
    .transfers__wrapper .block__transfers__form .field .control {
        width: calc(100% - 145px);
    }
    .check__month .check__fixed__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .check__month__box {
        margin: 0 0 30px;
        width: 100%;
    }
    .check__month__box.margin__right {
        margin-left: 0;
        width: 100%;
    }
    .check__month .check__fixed__content__link {
        padding-bottom: 50px;
    }
    .trade__sim__request .field label {
        width: 125px;
    }
    .trade__sim__request .field .control {
        width: calc(100% - 125px);
    }
    .trade__sim__request .field.field__action .btn {
        margin: 0 0 24px;
        width: 100%;
    }
    .trade__sim__request .field.field__action .btn:nth-child(2n + 2) {
        margin: 0;
    }
    .get__sim__content__left,
    .get__sim__content__right {
        width: 100%;
    }
    .get__sim__content__left {
        padding-right: 0;
        padding-bottom: 50px;
    }
    .get__sim__content__right {
        padding-left: 0;
        padding-bottom: 30px;
    }
    .orders__detail__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .orders__detail__sidebar {
        padding-bottom: 0;
        width: 100%;
    }
    .orders__detail__content {
        padding-left: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__channel__text,
    .page-qltkdd.page-ftth .tabs__detail__content__channel__text {
        display: inline-block;
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs__detail__content__right__top-channel,
    .page-qltkdd.page-ftth .tabs__detail__content__right__top-channel {
        padding: 10px 0 0;
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities,
    .page-qltkdd.page-ftth .tabs-utilities {
        margin-bottom: 0;
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider,
    .page-qltkdd.page-ftth .tabs-utilities .content .lists.slider {
        border-radius: 0;
        box-shadow: none;
        margin: 0 -15px;
        padding: 0;
    }
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .content .lists.slider .list-utilities,
    .page-qltkdd.page-ftth .tabs-utilities .content .lists.slider .list-utilities {
        padding: 20px 15px 0;
    }
    .page-qltkdd.page-ftth.page-ftth3 .error-service,
    .page-qltkdd.page-ftth .error-service {
        margin-bottom: 15px;
        margin-top: 20px;
    }
    .page-qltkdd .tabs__detail__content__left,
    .page-qltkdd .tabs__detail__content__right,
    .page-qltkdd .tabs__detail__content__mid {
        width: 100%;
        text-align: center;
    }
    .page-qltkdd .tabs__detail__content__right__point,
    .page-qltkdd .tabs__detail__content__right__text,
    .page-qltkdd .tabs__detail__content__left__text,
    .page-qltkdd .tabs__detail__content__left__price {
        display: inline-block;
    }
    .page-qltkdd .tabs__detail__content__right__text,
    .page-qltkdd .tabs__detail__content__left__text {
        margin-right: 10px;
    }
    .page-qltkdd .tabs__detail__info__name {
        display: block;
        padding-bottom: 5px;
    }
    .page-qltkdd .tabs__detail__content__left__time {
        text-align: center;
        padding: 0 0 10px;
    }
    .page-qltkdd .tabs__detail__info__phone:before {
        display: none;
    }
    .page-qltkdd .tabs__detail__content__right__top {
        padding: 15px 0 0;
    }
    .page-qltkdd .tabs__detail__content__right__bot {
        padding: 0;
    }
    .page-qltkdd .tabs__detail__content__mid__price {
        font-size: 30px;
    }
    .page-qltkdd .tabs__detail__content__mid__price:after {
        height: 20px;
    }
    .page-qltkdd .tabs__detail__content__mid__text {
        top: -5px;
    }
    .page-qltkdd .tabs__detail__content__mid__bot .btn-checkout {
        margin: 20px 0 0;
    }
    .page-qltkdd .tabs-pack-data:before {
        display: none;
    }
    .page-qltkdd .tabs-pack-data .item {
        box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
        margin: 0 15px 10px;
        padding: 15px 0;
    }
    .page-qltkdd .tabs-lookups .item {
        text-align: center;
    }
    .page-qltkdd .tabs-lookups .item img {
        height: 60px;
        margin: 0 auto;
        -o-object-fit: cover;
        object-fit: cover;
        width: 60px;
    }
    .page-qltkdd .tabs-utilities .list-utilities {
        background: -webkit-linear-gradient(bottom, rgba(20, 175, 180, 0.15), rgba(20, 175, 180, 0.15)), #ffffff;
        background: linear-gradient(0deg, rgba(20, 175, 180, 0.15), rgba(20, 175, 180, 0.15)), #ffffff;
        padding: 0;
    }
    .page-qltkdd .intro__text {
        font-size: 12px;
        line-height: 16px;
        padding-right: 0;
    }
    .view-all-uds .uds__box {
        margin: 0;
        padding: 15px 0 0;
    }
    .view-all-uds .uds__box__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .view-all-uds .uds__box__content .item {
        width: 100%;
        padding: 0 0 30px;
        margin: 0;
    }
    .viettel__detail .section__content:first-child .detail__content__title {
        padding-top: 15px;
    }
    .viettel__detail .section__content .content__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .viettel__detail .section__content .detail__image {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        width: 100%;
        padding: 0 10px;
    }
    .viettel__detail .section__content .detail__content {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        text-align: left;
    }
    .viettel__detail .section__content .detail__content__title {
        padding-top: 15px;
    }
    .viettel__detail .section .tab__content {
        overflow-x: scroll;
    }
    .viettel__detail .section__tabs table {
        width: 720px;
    }
    .viettel__detail .section__tabs table th {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 5px;
    }
    .viettel__detail .section__tabs table td {
        font-size: 16px;
        line-height: 20px;
        padding: 32px 5px 30px;
    }
    .search__maintain .tab-title li,
    .transfers__wrapper .tab-title li {
        border: 1px solid #f9f9f9;
        border-top-width: 0;
        padding: 0;
        text-align: left;
        width: 50%;
    }
    .search__maintain .tab-title li:first-child,
    .transfers__wrapper .tab-title li:first-child {
        border-top-width: 1px;
    }
    .search__maintain .tab-title li a,
    .transfers__wrapper .tab-title li a {
        padding: 5px 10px;
        white-space: initial;
    }
    .search__maintain .tab__content__item p {
        padding-left: 20px;
    }
    .search__maintain .tab__content__item .item__title {
        padding-left: 10px;
    }
    .search__maintain .maintain__title {
        width: 100%;
        border-right: none;
    }
    .search__maintain .maintain__title__text li {
        padding: 18px 10px;
    }
    .search__maintain .maintain__title__text li.open {
        background: #f9f9f9;
    }
    .search__maintain .maintain__title__text li:first-child {
        border-top-width: 1px;
    }
    .search__maintain .maintain__title__text li:last-child {
        border-bottom: 1px solid rgba(197, 200, 219, 0.3);
    }
    .search__maintain .maintain__content {
        width: 100%;
        padding: 30px 0;
    }
    .search__maintain .block__request {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .search__maintain .block__result .result__items {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .search__maintain .block__result .result__item {
        width: 100%;
    }
    .search__maintain .block__result .result__item:nth-child(odd) {
        padding-right: 0;
    }
    .search__maintain .block__result .result__item:nth-child(even) {
        padding-left: 0;
    }
    .search__maintain .location__detail__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .search__maintain .location__detail__info__wrapper,
    .search__maintain .location__detail__info__rating {
        width: 100%;
    }
    .search__maintain .block__speed__result .speed__result__items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .search__maintain .block__speed__result .speed__result__item {
        width: 100%;
    }
    .search__maintain .block__speed__result .speed__result__item:nth-child(odd) {
        padding-right: 0;
    }
    .search__maintain .block__speed__result .speed__result__item:nth-child(even) {
        padding-left: 0;
    }
    .search__maintain .block__speed__detail__items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .search__maintain .block__speed__detail__item {
        width: 100%;
        padding-bottom: 30px;
    }
    .search__maintain .block__speed__detail__item:nth-child(odd) {
        padding-right: 0;
    }
    .search__maintain .block__speed__detail__item:nth-child(even) {
        padding-left: 0;
    }
    .search__maintain .block__speed__detail__item__content {
        padding: 30px 15px;
    }
    .search__maintain .block__speed__bar .bar__progress__text {
        padding: 0 5px;
    }
    .transfers__wrapper .tab-title li {
        border: 1px solid #f9f9f9;
        border-top-width: 0;
        padding: 0;
        text-align: left;
        width: 50%;
    }
    .transfers__wrapper .tab-title li:first-child {
        border-top-width: 1px;
    }
    .transfers__wrapper .tab-title li a {
        padding: 5px 10px;
        white-space: initial;
    }
    .transfers__wrapper .block__transfers__list .item__content {
        padding: 20px 10px;
    }
    .transfers__wrapper .block__transfers__list .item__content__price--phone span,
    .transfers__wrapper .block__transfers__list .item__content__time--date span {
        display: block;
    }
    .transfers__wrapper .block__transfers__list .item__content__price--phone .item__content__phone,
    .transfers__wrapper .block__transfers__list .item__content__time--date .item__content__phone {
        border: none;
        margin-left: 0;
        padding-left: 0;
    }
    .transfers__wrapper .block__transfers__list .item__content__price--phone .item__content__phone:before,
    .transfers__wrapper .block__transfers__list .item__content__time--date .item__content__phone:before {
        display: none;
    }
    .transfers__wrapper .block__transfers__list .item__content__price--phone .item__content__space,
    .transfers__wrapper .block__transfers__list .item__content__time--date .item__content__space {
        display: none;
    }
    .transfers__wrapper .block__transfers__list .item__link__bug {
        margin: 0 0 15px;
        display: inline-block;
    }
    .transfers__wrapper .block__transfers__list .item__link__template {
        margin-left: 28px;
        padding: 14px 20px;
    }
    .transfers__wrapper .block__transfers__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .transfers__wrapper .block__transfers__support,
    .transfers__wrapper .block__transfers__form {
        width: 100%;
    }
    .transfers__wrapper .block__transfers__form {
        padding-right: 0;
    }
    .transfers__wrapper .block__transfers__form .field {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .transfers__wrapper .block__transfers__form .field label,
    .transfers__wrapper .block__transfers__form .field .control {
        width: 100%;
    }
    .transfers__wrapper .block__transfers__form .field label {
        margin: 0 0 10px;
    }
    .transfers__wrapper .block__transfers__form .field__values__item {
        width: 90px;
        margin-right: 20px;
    }
    .data__sharing--detail__content .detail__customer__item__left:before {
        top: 5px;
    }
    .data__sharing--detail__content .detail__customer__item--text,
    .data__sharing--detail__content .detail__customer__item--phone {
        display: block;
        padding: 0 0 5px;
    }
    .data__sharing--detail__content .detail__customer__item--space {
        display: none;
    }
    .data__sharing--detail__content .detail__customer__item__data {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .trade__sim .tab-title li {
        border: 1px solid #f9f9f9;
        border-top-width: 0;
        padding: 0;
        text-align: left;
        width: 50%;
    }
    .trade__sim .tab-title li:first-child {
        border-top-width: 1px;
    }
    .trade__sim .tab-title li a {
        padding: 5px 10px;
        white-space: initial;
    }
    .trade__sim__free__wrapper {
        padding-right: 0;
    }
    .trade__sim__free__content .field {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .trade__sim__free__content .field label,
    .trade__sim__free__content .field .control {
        width: 100%;
    }
    .trade__sim__free__content .field label {
        margin: 0 0 10px;
    }
    .trade__sim__request__content__left,
    .trade__sim__request__content__right {
        padding: 0;
        width: 100%;
    }
    .trade__sim__request .field .control,
    .trade__sim__request .field label {
        width: 100%;
    }
    .trade__sim__request .field label {
        padding: 0 0 10px;
    }
    .trade__sim .get__sim--home {
        padding: 20px 0 50px;
    }
    .trade__sim .get__sim--home__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .trade__sim .get__sim--home__content .field {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        margin-left: 0;
        margin-bottom: 0;
        width: 100%;
    }
    .trade__sim .get__sim--home__content .field:nth-child(2n + 1) {
        margin-right: 0;
        margin-left: 0;
    }
    .trade__sim .get__sim--home__content .field .control,
    .trade__sim .get__sim--home__content .field label {
        width: 100%;
    }
    .trade__sim .get__sim--home__content .field label {
        margin: 0 0 10px;
    }
    .check__fixed__info__action .btn {
        margin-bottom: 30px;
    }
    .check__fixed__info__action .btn.btn-payment {
        margin-right: 0;
    }
    .check__fixed__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        padding: 0 0 50px;
    }
    .check__fixed__content__left,
    .check__fixed__content__right {
        width: 100%;
        padding: 0 0 50px;
    }
    .check__fixed__content__left {
        padding-right: 0;
    }
    .check__fixed__content__right {
        padding-left: 0;
    }
    .check__fixed__content__title .check__fixed__title {
        margin-right: 90px;
    }
    .check__month .check__fixed__content__left,
    .check__month .check__fixed__content__right {
        padding: 0 0 30px;
    }
    .check__month .check__month__box,
    .check__month .check__month__box.margin__right {
        padding: 30px 15px;
    }
    .check__month .check__month__box__total__right {
        padding: 0 0 30px;
    }
    .check__month .check__month__box__total .check__fixed__info__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .check__month .check__month__box__total .check__month__box__total__right,
    .check__month .check__month__box__total .check__month__box__total__left {
        text-align: left;
        width: 100%;
    }
    .check__month .check__month__box__total .check__fixed__info__text,
    .check__month .check__month__box__total .check__fixed__info__price {
        text-align: left;
    }
    .check__month .check__month__box__total .check__month__box__total__bottom .detail__items {
        padding: 30px 0 0;
    }
    .check__month .check__month__box__total .check__month__box__total__bottom .detail__item {
        padding-bottom: 15px;
        width: 100%;
    }
    .check__month .check__fixed__content .check__month__box {
        padding: 30px 15px;
    }
    .check__month .check__fixed__content__link {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 639px) {
    .modal.cancel-service__popup .action {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .modal.cancel-service__popup .action a.btn {
        width: 100%;
    }
    .modal.cancel-service__popup .action a.btn.action-back {
        margin: 0 0 20px;
    }
    .data__sharing__content .control {
        padding: 0 15px 0 0;
        width: calc(100% - 115px);
    }
    .data__sharing__content .action {
        width: 115px;
    }
    .data__sharing__content .action .btn {
        padding: 11px 35px;
    }
    .data__sharing--detail__content .detail__des__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .data__sharing--detail__content .detail__des__right,
    .data__sharing--detail__content .detail__des__left {
        width: 100%;
        padding: 0 0 10px;
    }
    .data__sharing--detail__content .detail__des__right {
        text-align: left;
    }
    .data__sharing--detail__content .detail__des__right__text {
        display: block;
    }
    .data__sharing--detail__content .detail__des__bar .bar__progress__current:after {
        top: 8px;
    }
    .data__sharing--detail__content .detail__des__bar .bar__progress__text {
        position: relative;
        right: inherit;
        text-align: center;
        top: 35px;
    }
    .data__sharing--detail__content .detail__customer__item {
        -webkit-box-align: baseline;
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .data__sharing--detail__content .detail__customer__item__left,
    .data__sharing--detail__content .detail__customer__item__right {
        padding-bottom: 15px;
        padding-left: 40px;
        text-align: left;
        width: 100%;
    }
    .data__sharing--detail__content .detail__customer__item__data {
        margin-right: 0;
    }
    .data__sharing--detail__content .detail__customer__action {
        padding: 0 0 100px;
        text-align: center;
    }
    .data__sharing--detail__content .detail__customer__action .btn {
        margin: 0 0 20px;
    }
    .tracking__network__otp__wrapper {
        padding: 25px 15px 20px;
    }
    .tracking__network__otp__text {
        color: #0c0033;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        padding: 0 0 25px;
    }
    .tracking__network__otp__content input {
        height: 55px;
        margin: 0 5px;
        padding: 0 10px;
        width: 50px;
    }
    .tracking__network__otp__link a {
        color: #ee0033;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.125px;
        text-decoration: underline;
    }
    .tracking__network__otp__action {
        padding: 28px 0 64px;
    }
    .tracking__network__otp__action .btn {
        background: #ee0033;
        border: 1px solid #ee0033;
        color: #fff;
        font-size: 14px;
        max-width: 360px;
        line-height: 20px;
        padding: 14px;
        width: 100%;
    }
    .tracking__network__otp__action .btn:hover,
    .tracking__network__otp__action .btn:focus {
        background: #fff;
        color: #ee0033;
    }
    .get__sim__box__select {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        padding: 0 0 25px;
    }
    .get__sim__box__select .control {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
    }
    .get__sim__box__select .control:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: 0;
    }
    .get__sim__box__select select {
        background-position-x: 94%;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        color: #bfc4ce;
        font-weight: 500;
        font-size: 14px;
        height: 50px;
        line-height: 20px;
        padding: 0 40px 0 16px;
        width: 100%;
    }
    .get__sim__box__item {
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 25px;
    }
    .get__sim__box__item:last-child {
        border-bottom: none;
    }
    .get__sim__box__item.active .item__content.address .item__right span {
        opacity: 1;
    }
    .get__sim__box__item.active .item__content.time .item__right .item__content__check {
        background: #ee0033;
        border-color: #ee0033;
        box-shadow: none;
    }
    .get__sim__box__item .item__title {
        color: #ee0033;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.5px;
        padding: 0 0 20px;
    }
    .get__sim__box__item .item__content {
        color: #576c8a;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        font-weight: normal;
        font-size: 16px;
        line-height: 180%;
    }
    .get__sim__box__item .item__content.address {
        padding: 0 0 10px;
    }
    .get__sim__box__item .item__content.address .item__right span {
        /* background: transparent url("../images/i-get-sim-calender.svg") no-repeat; */
        background-size: contain;
        display: inline-block;
        height: 24px;
        opacity: 0.5;
        width: 24px;
    }
    .get__sim__box__item .item__content.phone {
        padding: 0 0 18px;
    }
    .get__sim__box__item .item__content.phone .item__left:before {
        /* background: transparent url("../images/i-transfers-phone.svg") no-repeat; */
        background-size: contain;
        content: "";
        height: 24px;
        left: 0;
        position: absolute;
        top: 1px;
        width: 24px;
    }
    .get__sim__box__item .item__content.time {
        padding: 0 0 7px;
    }
    .get__sim__box__item .item__content.time .item__right .item__content__check {
        background: #fff;
        border: 2px solid #e7e7e7;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.11);
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        position: relative;
        width: 24px;
    }
    .get__sim__box__item .item__content.time .item__right .item__content__check i {
        color: #fff;
        font-size: 10px;
        font-weight: 300;
        position: absolute;
        left: calc(50% - 6px);
        top: calc(50% - 5px);
    }
    .get__sim__box__item .item__left {
        padding-left: 35px;
        position: relative;
        width: calc(100% - 50px);
    }
    .get__sim__box__item .item__left i {
        color: #ee0033;
        font-size: 24px;
        font-weight: 300;
        left: 0;
        position: absolute;
        top: 2px;
    }
    .get__sim__box__item .item__right {
        padding-top: 3px;
        text-align: right;
        width: 50px;
    }
    .get__sim .store__detail .get__sim__box__item {
        border-bottom: none;
    }
    .get__sim .store__detail .get__sim__box__item .item__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .get__sim .store__detail .get__sim__box__item .item__content.time {
        padding: 0 0 25px;
    }
    .get__sim .store__detail .get__sim__box__item .item__content.date {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        padding: 0;
    }
    .get__sim .store__detail .get__sim__box__item .item__content.date .item__left {
        width: 100%;
    }
    .get__sim .store__detail .get__sim__box__item .item__content.date .item__right {
        width: 100%;
        text-align: center;
        padding: 20px 0 0;
    }
    .get__sim .store__detail .get__sim__box__item .item__content.date .btn {
        max-width: inherit;
    }
    .get__sim .store__detail .get__sim__box__item__action {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .get__sim .store__detail .get__sim__box__item__action .btn {
        margin-right: 0;
        margin-bottom: 24px;
        max-width: inherit;
        width: 100%;
    }
    .get__sim .store__detail .get__sim__box__item__action .btn.btn-continue {
        margin-right: 0;
        margin-left: 0;
    }
    .charges-price__date {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        max-width: 840px;
        margin: 0 auto;
    }
    .charges-price__date .field {
        margin: 0 0 20px;
        width: 100%;
    }
    .charges-price__date .field:nth-child(2n + 2) {
        margin-right: 0;
        margin-left: 0;
    }
    .payment__orders {
        padding: 0 0 20px;
    }
    .payment__orders__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .payment__orders__item .payment__title {
        -webkit-box-ordinal-group: inherit;
        -webkit-order: inherit;
        -ms-flex-order: inherit;
        order: inherit;
        padding-right: 0;
        width: 100%;
    }
    .payment__orders__item .payment__title.box__right {
        padding-left: 0;
    }
    .payment__orders__box {
        margin-bottom: 40px;
    }
    .payment__orders__box.box__left,
    .payment__orders__box.box__right {
        width: 100%;
    }
    .payment__orders__box.box__left {
        margin-right: 0;
    }
    .payment__orders__box.box__right {
        margin-left: 0;
    }
    .orders__pages .btn__orders {
        max-width: inherit;
        width: 100%;
    }
    .orders__manager__search .control {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .orders__manager__search .control__left {
        position: relative;
        width: calc(100% - 120px);
    }
    .orders__manager__search .control__right {
        padding-left: 15px;
        width: 120px;
    }
    .orders__manager__items.no__images .orders__manager__item {
        padding-left: 30px;
    }
    .orders__manager__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .orders__manager__item__detail,
    .orders__manager__item__status {
        width: 100%;
    }
    .orders__manager__item__detail {
        padding-bottom: 30px;
    }
    .orders__manager__item__detail__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        text-align: center;
    }
    .orders__manager__item__detail__content__info,
    .orders__manager__item__detail__content__img {
        width: 100%;
    }
    .orders__manager__item__detail__content__img {
        padding-right: 0;
        padding-bottom: 30px;
    }
    .orders__manager__item__status .order__waiting {
        padding-bottom: 20px;
    }
    .orders__manager__item__status .btn__orders {
        margin-left: 0;
    }
    .orders__detail__content__top {
        padding: 20px 20px 25px;
    }
    .orders__detail__content__top .orders__detail__title {
        display: block;
    }
    .orders__detail__content__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        padding: 18px 20px 18px;
    }
    .orders__detail__content__item:first-child {
        padding-top: 0;
    }
    .orders__detail__content__item__img {
        margin: 0 auto;
    }
    .orders__detail__content__item__info {
        padding: 30px 0 0;
        width: 100%;
    }
    .orders__detail__content__item__info h3 {
        padding-bottom: 15px;
    }
    .orders__detail__content__item__info p {
        padding-bottom: 15px;
    }
    .orders__detail__content__item__price {
        text-align: left;
        width: 100%;
    }
    .orders__detail__content__total {
        padding: 20px 20px 27px;
    }
}

.menu_select_sp {
    color: #44494d;
    cursor: pointer;
    display: none;
    font-weight: 500;
    vertical-align: middle;
}

.menu_select_sp span {
    font-size: 12px;
    vertical-align: middle;
}

.menu_select_sp i {
    font-size: 8px;
    margin-left: 5px;
    position: relative;
    top: -2px;
    vertical-align: middle;
}

@media (max-width: 991px) {
    .menu_select_sp {
        display: inline-block;
    }
}

@media (max-width: 991px) {
    .banner-slideshow .item {
        height: 170px;
    }
    .banner-slideshow .item img {
        height: 100%;
    }
}

.title-home {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 36px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .title-home {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
    }
}

.img-hover {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.img-hover img {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 100%;
}

.img-hover img:hover {
    -webkit-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.lists-slider {
    margin: 0 -15px;
    position: relative;
    z-index: 1;
}

@media (max-width: 991px) {
    .lists-slider {
        margin: 0 -8px;
    }
}

.lists-slider__item {
    margin: 0 15px;
}

@media (max-width: 991px) {
    .lists-slider__item {
        margin: 0 8px;
    }
}

@media (max-width: 991px) {
    .content .tit {
        margin: 0 0 10px;
    }
}

.news-event {
    margin: 0 0 30px;
    padding: 60px 0 0;
    word-break: break-word;
}

@media (max-width: 991px) {
    .news-event {
        padding: 30px 0 0;
    }
}

.news-event .date-time {
    color: #576c8a;
    font-size: 14px;
}

.news-event__detail {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .news-event__detail {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }
}

.news-event__left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(66% - 30px);
    -ms-flex: 0 0 calc(66% - 30px);
    flex: 0 0 calc(66% - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .news-event__left {
        margin: 0 0 20px;
        width: 100%;
    }
}

.news-event__left .news-event__images {
    border-radius: 12px 12px 12px 0;
    height: 425px;
}

@media (max-width: 991px) {
    .news-event__left .news-event__images {
        height: 100%;
    }
}

.news-event__left .news-event__images img {
    border-radius: 12px 12px 12px 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.news-event__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(34% - 30px);
    -ms-flex: 0 0 calc(34% - 30px);
    flex: 0 0 calc(34% - 30px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 15px;
}

@media (max-width: 991px) {
    .news-event__right {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 0;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .news-event__right .news-event__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 calc(100% / 2 - 10px);
        -ms-flex: 0 0 calc(100% / 2 - 10px);
        flex: 0 0 calc(100% / 2 - 10px);
        margin: 0 10px 5px;
    }
    .news-event__right .news-event__item:first-child {
        margin: 0 10px 5px 0;
    }
}

@media (max-width: 767px) {
    .news-event__right .news-event__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 250px;
        -ms-flex: 0 0 250px;
        flex: 0 0 250px;
        margin: 0 10px 5px;
    }
    .news-event__right .news-event__item:first-child {
        margin: 0 10px 5px 0;
    }
}

.news-event__right .news-event__images {
    border-radius: 12px 12px 12px 0;
    height: 200px;
    margin: 0 0 10px;
}

@media (max-width: 991px) {
    .news-event__right .news-event__images {
        height: auto;
    }
}

.news-event__right .news-event__images:hover {
    border-radius: 12px 12px 12px 0;
}

.news-event__right .news-event__title {
    font-size: 18px;
    line-height: 28px;
    -webkit-line-clamp: 1;
}

@media (max-width: 991px) {
    .news-event__right .news-event__title {
        font-size: 14px;
        line-height: 20px;
    }
}

.news-event__item:not(:last-child) {
    margin: 0 0 15px;
}

.news-event__images {
    margin: 0 0 12px;
}

.news-event__images img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.news-event__info {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 3px;
}

.news-event__sub {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .news-event__sub {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: inherit;
    }
}

.news-event__time {
    color: #44494d;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 0 10px;
}

@media (max-width: 991px) {
    .news-event__time {
        color: #727272;
        font-size: 12px;
        line-height: 18px;
    }
}

.news-event__title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .news-event__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.news-event__title:hover {
    color: #ee0033;
}

.mobile-service {
    background-color: #eee;
    margin: 40px 0 30px;
    padding: 30px 0 40px;
    word-break: break-word;
}

@media (max-width: 991px) {
    .mobile-service {
        margin: 30px 0 20px;
        padding: 20px 0 30px;
    }
}

.data-pack__images {
    border-radius: 12px 12px 12px 0;
    height: 370px;
    position: relative;
}

@media (max-width: 991px) {
    .data-pack__images {
        height: 220px;
    }
}

.data-pack__images--small {
    height: 130px;
}

.data-pack__images img {
    border-radius: 12px 12px 12px 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.data-pack__images-name {
    color: #fff;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
    font-size: 40px;
    left: 50%;
    letter-spacing: -2.5px;
    line-height: 40px;
    position: absolute;
    top: calc(50% - 40px);
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
}

@media (max-width: 991px) {
    .data-pack__images-name {
        font-size: 36px;
        line-height: 36px;
    }
}

.data-pack__intro {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 40px 0 12px 0;
    /* margin-top: -40px; */
    padding: 15px 20px 30px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .data-pack__intro {
        border-radius: 20px 0 12px 0;
        padding: 10px 10px 20px 15px;
    }
}

.data-pack__intro:hover {
    border-color: #ee0033;
}

.data-pack__top {
    display: flex;
    flex-direction: column;
}

.data-pack__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    width: 68%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

@media (max-width: 991px) {
    .data-pack__name {
        font-size: 20px;
        line-height: 30px;
    }
}

.data-pack__name:hover {
    color: #ee0033;
}

.data-pack__price {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.data-pack__price-icon {
    color: #44494d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px 0 0;
}

.data-pack__price-icon i {
    font-size: 20px;
}

@media (max-width: 991px) {
    .data-pack__price-icon i {
        font-size: 14px;
    }
}

.data-pack__price-value {
    color: #44494d;
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 991px) {
    .data-pack__price-value {
        font-size: 14px;
    }
}

.data-pack__detail {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.data-pack__info {
    margin: 2px 0 5px;
}

@media (max-width: 991px) {
    .data-pack__info {
        margin: 5px 0 10px;
    }
}

.data-pack__des {
    color: #44494d;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .data-pack__des {
        font-size: 14px;
        line-height: 20px;
    }
}

.data-pack__last {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.data-pack__see-more {
    color: #ee0033;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

@media (max-width: 991px) {
    .data-pack__see-more {
        font-size: 14px;
        line-height: 20px;
    }
}

.data-pack__see-more:hover {
    text-decoration: underline;
}

.data-pack__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0 10px;
    position: relative;
}

.data-pack__btn .button {
    width: 108px;
}

@media (max-width: 991px) {
    .data-pack__btn .button {
        width: 110px;
    }
}

.data-pack--gt .data-pack__images {
    height: 150px;
}

.data-pack--gt .data-pack__name {
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 2px;
    width: 100%;
}

.data-pack--gt .data-pack__des {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 5px;
}

.data-pack--gt .data-pack__info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
}

.data-pack--gt .data-pack__price {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.data-pack--gt .data-pack__price-value {
    color: #ee0033;
}

.data-pack--gt .data-pack__btn .button {
    width: 130px;
}

.mobile-data {
    display: none;
    overflow-x: auto;
}

@media (max-width: 991px) {
    .mobile-data {
        display: block;
    }
}

.mobile-data__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -8px;
    padding: 0 0 3px;
}

.mobile-data__item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 1.5 - 16px);
    -ms-flex: 0 0 calc(100% / 1.5 - 16px);
    flex: 0 0 calc(100% / 1.5 - 16px);
    margin: 0 8px;
    width: calc(100% / 1.5 - 16px);
}

.data-badge {
    background-color: #def1dc;
    border-radius: 20px;
    left: 8px;
    padding: 2px 10px;
    position: absolute;
    top: 8px;
    z-index: 1;
}

.data-badge__name {
    color: #58b84f;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
}

.sim-so {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .sim-so {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0 0 10px;
    }
}

.sim-so--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sim-so__top {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 25px;
}

@media (max-width: 991px) {
    .sim-so__top {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.sim-so__form {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 655px;
    -ms-flex: 0 0 655px;
    flex: 0 0 655px;
}

@media (max-width: 1200px) {
    .sim-so__form {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
}

@media (max-width: 991px) {
    .sim-so__form {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
    }
}

.sim-so__input {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 460px;
    -ms-flex: 0 0 460px;
    flex: 0 0 460px;
    position: relative;
}

@media (max-width: 1200px) {
    .sim-so__input {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 350px;
        -ms-flex: 0 0 350px;
        flex: 0 0 350px;
    }
}

@media (max-width: 991px) {
    .sim-so__input {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
    }
}

.sim-so__input:hover .sim-so__hover {
    display: block;
}

.sim-so__input .form-control {
    padding: 9px 15px 9px 40px;
}

.sim-so__input i {
    color: #727272;
    font-size: 20px;
    left: 15px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sim-so__hover {
    background: -webkit-linear-gradient(bottom, rgba(238, 0, 51, 0.05), rgba(238, 0, 51, 0.05)), #fff;
    background: linear-gradient(0deg, rgba(238, 0, 51, 0.05), rgba(238, 0, 51, 0.05)), #fff;
    border-radius: 8px 8px 8px 0px;
    display: none;
    left: 0;
    padding: 15px 20px;
    position: absolute;
    top: 55px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1;
}

.sim-so__hover:before {
    background: -webkit-linear-gradient(bottom, rgba(238, 0, 51, 0.05), rgba(238, 0, 51, 0.05)), #fff;
    background: linear-gradient(0deg, rgba(238, 0, 51, 0.05), rgba(238, 0, 51, 0.05)), #fff;
    content: "";
    height: 35px;
    left: 20px;
    position: absolute;
    top: -5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 35px;
    z-index: -1;
}

.sim-so__hover-title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 10px;
}

.sim-so__hover-item {
    color: #44494d;
    font-size: 14px;
    line-height: 24px;
    word-break: break-word;
}

.sim-so__hover-item:not(:last-child) {
    margin: 0 0 5px;
}

.sim-so__btn {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 165px;
    -ms-flex: 0 0 165px;
    flex: 0 0 165px;
    margin: 0 0 0 30px;
}

@media (max-width: 991px) {
    .sim-so__btn {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 20px 0 0;
        width: 100%;
    }
}

.sim-so__info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.sim-so__images {
    border-radius: 12px 12px 12px 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 460px;
    -ms-flex: 0 0 460px;
    flex: 0 0 460px;
    height: 470px;
    margin: 0 0 0 30px;
}

@media (max-width: 991px) {
    .sim-so__images {
        display: none;
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        height: 200px;
        margin: 25px 0 20px;
    }
}

.sim-so__images img {
    border-radius: 12px 12px 12px 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.sim-so .hot-key {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 30px;
}

@media (max-width: 1200px) {
    .sim-so .hot-key {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
}

@media (max-width: 991px) {
    .sim-so .hot-key {
        margin: 20px 0 0;
    }
}

@media (max-width: 1200px) {
    .sim-so .hot-key__list {
        margin: 0 -10px;
    }
}

@media (max-width: 1200px) {
    .sim-so .hot-key__item {
        margin: 0 10px;
    }
}

.sim-so__radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 15px;
}

.sim-so__radio .radio-custom1 {
    margin: 0 30px 0 0;
    padding: 0 0 0 26px;
}

.sim-so__radio .radio-custom1:last-child {
    margin: 0;
}

.sim-so__radio .radio-custom1 input:checked ~ .checkmark ~ span {
    color: #ee0033;
    font-weight: 400;
}

@media (max-width: 991px) {
    .sim-so--pc {
        display: none;
    }
}

.sim-so--sp {
    display: none;
}

@media (max-width: 991px) {
    .sim-so--sp {
        display: block;
    }
    .sim-so--sp .sim-number__table {
        min-width: 100%;
    }
}

.hot-key__title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

.hot-key__list {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -18px;
}

@media (max-width: 991px) {
    .hot-key__list {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
}

.hot-key__item {
    margin: 0 18px;
}

@media (max-width: 991px) {
    .hot-key__item {
        margin: 0 10px;
    }
}

.hot-key__value {
    color: #ee0033;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

@media (max-width: 991px) {
    .sim-number {
        overflow-x: auto;
        width: 100%;
    }
}

.sim-number__table {
    background-color: #fff;
    border-collapse: separate;
    border-radius: 12px 12px 12px 0;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
}

@media (max-width: 991px) {
    .sim-number__table {
        min-width: 500px;
    }
}

.sim-number thead tr {
    background-color: #e4e4e4;
}

.sim-number tr:last-child td {
    border-bottom: 0;
}

.sim-number th,
.sim-number td {
    border-bottom: 1px solid #e4e4e4;
    color: #44494d;
    font-size: 18px;
    padding: 10px 20px;
    text-align: left;
}

@media (max-width: 991px) {
    .sim-number th,
    .sim-number td {
        font-size: 14px;
        padding: 8px 15px;
    }
}

.sim-number th {
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
    padding: 13px 20px;
}

@media (max-width: 991px) {
    .sim-number th {
        padding: 12px 15px;
    }
}

.sim-number__value {
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
}

@media (max-width: 991px) {
    .sim-number__value {
        font-size: 14px;
    }
}

.sim-number__value.price {
    color: #ee0033;
}

.sim-number__action {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.sim-number__action .button {
    width: 146px;
}

.sim-number__icon {
    color: #44494d;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    margin: 0 35px 0 0;
}

.sim-number__icon i {
    color: #44494d;
}

.sim-number__icon .icon-i-wishlist-full {
    color: #ee0033;
}

.sim-number__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .sim-number__btn .button {
        padding: 6px 20px;
    }
}

.sim-number .radio-custom1 .txt-radio {
    color: #44494d;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
}

.sim-number .radio-custom1 .checkmark {
    border: 1px solid #ee0033;
    height: 18px;
    top: 0;
    width: 18px;
}

.sim-number .radio-custom1:hover input ~ .checkmark {
    border-color: #ee0033;
}

.sim-number .radio-custom1 input:checked ~ .checkmark {
    border-color: #ee0033;
}

.sim-number .radio-custom1 input:checked ~ .checkmark:after {
    height: 8px;
    left: 4px;
    top: 4px;
    width: 8px;
}

.sim-number .radio-custom1 input:checked ~ .checkmark ~ span {
    font-weight: normal;
}

.fee-pack {
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-bottom: 6px solid #ee0033;
    border-radius: 12px 12px 12px 0;
    padding: 20px 15px 30px;
    position: relative;
}

@media (max-width: 991px) {
    .fee-pack {
        padding: 20px;
    }
}

.fee-pack:hover {
    border-color: #ee0033;
}

.fee-pack .fee-pack__images img {
    width: auto;
}

.fee-pack__images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 10px;
}

.fee-pack__top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.fee-pack__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 15px;
    overflow: hidden;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .fee-pack__name {
        font-size: 16px;
        line-height: 24px;
    }
}

.fee-pack__name:hover {
    color: #ee0033;
}

.fee-pack__info {
    margin: 15px 0 30px;
}

@media (max-width: 991px) {
    .fee-pack__info {
        margin: 15px 0 20px;
    }
}

.fee-pack__price {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 32px;
    font-weight: 600;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 20px;
}

@media (max-width: 991px) {
    .fee-pack__price {
        font-size: 20px;
        margin: 0 0 10px;
    }
}

.fee-pack__price span {
    font-size: 20px;
    font-weight: 500;
    position: relative;
}

@media (max-width: 991px) {
    .fee-pack__price span {
        font-size: 18px;
    }
}

.fee-pack__des {
    color: #44494d;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    margin: 20px 0 0;
    overflow: hidden;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .fee-pack__des {
        font-size: 14px;
        height: 40px;
        line-height: 20px;
        margin: 10px 0 0;
    }
}

.fee-pack__btn {
    margin: 30px 0 0;
}

@media (max-width: 991px) {
    .fee-pack__btn {
        margin: 20px 0 0;
    }
}

.fee-pack--choose .fee-pack__images {
    left: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.fee-pack--choose .fee-pack__name {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    text-align: left;
}

.vat-service__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .vat-service__list {
        margin: 0 -8px;
    }
}

.vat-service__item {
    background-color: #fff;
    border-radius: 12px 12px 12px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px 30px;
    width: calc(100% / 2 - 30px);
}

@media (max-width: 991px) {
    .vat-service__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 calc(100% / 2 - 16px);
        -ms-flex: 0 0 calc(100% / 2 - 16px);
        flex: 0 0 calc(100% / 2 - 16px);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 8px 20px;
        width: calc(100% / 2 - 16px);
    }
}

.vat-service__col {
    background-color: #fff;
    border-radius: 12px 12px 12px 0;
    margin: 0 0 15px;
}

.vat-service__images {
    border-radius: 12px 0 0 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 122px;
    -ms-flex: 0 0 122px;
    flex: 0 0 122px;
    height: 122px;
    position: relative;
}

@media (max-width: 991px) {
    .vat-service__images {
        border-radius: 12px 12px 0 0;
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        height: 120px;
    }
}

.vat-service__images img {
    border-radius: 12px 0 0 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

@media (max-width: 991px) {
    .vat-service__images img {
        border-radius: 12px 12px 0 0;
    }
}

.vat-service__images-name {
    color: #fff;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
    font-size: 28px;
    left: 50%;
    line-height: 38px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
    .vat-service__images-name {
        font-size: 24px;
        line-height: 36px;
    }
}

.vat-service__intro {
    border-radius: 0 12px 12px 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 5px 20px;
}

@media (max-width: 991px) {
    .vat-service__intro {
        background-color: #fff;
        border-radius: 0 0 12px 0;
        padding: 10px 15px 20px;
    }
}

.vat-service__detail {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 991px) {
    .vat-service__detail {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.vat-service__info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.vat-service__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .vat-service__name {
        font-size: 16px;
        line-height: 24px;
    }
}

.vat-service__name:hover {
    color: #ee0033;
}

.vat-service__des {
    color: #44494d;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .vat-service__des {
        font-size: 12px;
        line-height: 18px;
    }
}

.vat-service__price {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0 0;
}

@media (max-width: 991px) {
    .vat-service__price {
        font-size: 16px;
    }
}

.vat-service__price span {
    color: #44494d;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    top: -2px;
}

.vat-service__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 8px 0 0 20px;
}

@media (max-width: 991px) {
    .vat-service__btn {
        margin: 15px 0 0;
        width: 100%;
    }
}

.vat-service__btn .button {
    width: 130px;
}

@media (max-width: 991px) {
    .vat-service__btn .button {
        width: 100%;
    }
}

.vat-service--tt .vat-service__item {
    border: 1px solid #e1e1e1;
}

.vat-service--tt .vat-service__images {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 193px;
    -ms-flex: 0 0 193px;
    flex: 0 0 193px;
    height: 100%;
}

@media (max-width: 991px) {
    .vat-service--tt .vat-service__images {
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        height: 120px;
    }
}

.vat-service--tt .vat-service__intro {
    padding: 20px;
}

@media (max-width: 991px) {
    .vat-service--tt .vat-service__intro {
        padding: 15px 15px 25px;
    }
}

.vat-service--tt .vat-service__detail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.vat-service--tt .vat-service__name {
    margin: 0 0 5px;
}

.vat-service--tt .vat-service__des {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 10px;
}

.vat-service--tt .vat-service__price {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .vat-service--tt .vat-service__price {
        font-size: 16px;
    }
}

.vat-service--tt .check-box {
    margin: 0 0 30px;
}

.vat-service--tt .vat-service__btn {
    margin: 15px 0 0;
}

.vat-service--tt .vat-service__btn .button {
    width: 155px;
}

@media (max-width: 991px) {
    .vat-service--tt .vat-service__btn .button {
        width: 100%;
    }
}

.inter-service {
    border: 1px solid #e4e4e4;
    border-radius: 12px 12px 12px 0;
    height: 100%;
    overflow: hidden;
}

@media (max-width: 991px) {
    .inter-service {
        border-radius: 6px 6px 0 0;
    }
}

.inter-service__images {
    border-radius: 12px 12px 0 0;
    height: 240px;
}

@media (max-width: 991px) {
    .inter-service__images {
        border-radius: 6px 6px 0 0;
        height: 200px;
    }
}

@media (max-width: 575px) {
    .inter-service__images {
        height: 120px;
    }
}

.inter-service__images img {
    border-radius: 12px 12px 0 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

@media (max-width: 991px) {
    .inter-service__images img {
        border-radius: 6px 6px 0 0;
    }
}

.inter-service__intro {
    background-color: #fff;
    border-radius: 0 0 12px 0;
    padding: 20px 20px 30px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .inter-service__intro {
        border-radius: 0 0 6px 0;
        padding: 10px 10px 15px;
    }
}

.inter-service__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .inter-service__name {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 5px;
    }
}

.inter-service__name:hover {
    color: #ee0033;
}

.inter-service__info {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 8px 0 0;
}

@media (max-width: 991px) {
    .inter-service__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 5px 0 0;
    }
}

.inter-service__des {
    color: #44494d;
    font-size: 16px;
    height: 72px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .inter-service__des {
        font-size: 12px;
        height: 54px;
        line-height: 18px;
    }
}

.inter-service__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0 20px;
}

@media (max-width: 991px) {
    .inter-service__btn {
        margin: 10px 0 0;
    }
}

.inter-service__btn .button {
    width: 145px;
}

@media (max-width: 991px) {
    .inter-service__btn .button {
        width: 130px;
    }
}

.inter-service--row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 991px) {
    .inter-service--row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.inter-service--row .inter-service__images {
    border-radius: 12px 0 0 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 240px;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    height: 100%;
    width: 240px;
}

@media (max-width: 991px) {
    .inter-service--row .inter-service__images {
        border-radius: 12px 12px 0 0;
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        height: 200px;
        width: 100%;
    }
}

.inter-service--row .inter-service__images img {
    border-radius: 12px 0 0 0;
}

@media (max-width: 991px) {
    .inter-service--row .inter-service__images img {
        border-radius: 12px 12px 0 0;
    }
}

.inter-service--row .inter-service__intro {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.inter-service--row .inter-service__info {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.inter-service--row .inter-service__btn {
    margin: 20px 0 0;
}

@media (max-width: 991px) {
    .inter-service--row .inter-service__btn {
        margin: 15px 0 0;
    }
}

.phone-device {
    margin: 30px 0;
    word-break: break-word;
}

@media (max-width: 991px) {
    .phone-device {
        margin: 20px 0 10px;
    }
}

.phone-device .owl-carousel img {
    width: auto;
}

.tel-device {
    background-color: #fff;
    border: 1px solid #b5b4b4;
    border-radius: 12px 12px 12px 0;
    padding: 25px 30px;
    position: relative;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media (max-width: 991px) {
    .tel-device {
        padding: 12px 10px 10px;
    }
}

.tel-device:hover {
    border-color: #ee0033;
}

.tel-device__images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 198px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 20px;
}

@media (max-width: 991px) {
    .tel-device__images {
        margin: 0 0 10px;
    }
}

.tel-device__images img {
    height: 100%;
    width: auto;
}

.tel-device__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.15px;
    line-height: 30px;
    margin: 0 0 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .tel-device__name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0 0 5px;
        text-align: center;
    }
}

.tel-device__name:hover {
    color: #ee0033;
}

.tel-device__price {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px 0 0;
}

@media (max-width: 991px) {
    .tel-device__price {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 5px 0 0;
    }
}

.tel-device__price-new {
    color: #44494d;
    font-size: 30px;
    font-weight: 600;
    line-height: 34px;
}

@media (max-width: 991px) {
    .tel-device__price-new {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.tel-device__price-old {
    color: #727272;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 0 12px;
    text-decoration: line-through;
}

@media (max-width: 991px) {
    .tel-device__price-old {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 0 8px;
    }
}

.tel-device__btn {
    margin: 10px 0 0;
}

.tel-device--shop .tel-device__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
}

.tel-device--shop .tel-device__price {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 56px;
}

.tel-device--shop .tel-device__price-new {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.tel-device--shop .tel-device__price-old {
    margin: 2px 0 0;
}

.internet-tv {
    background-color: #eee;
    margin: 30px 0;
    padding: 30px 0;
    word-break: break-word;
}

@media (max-width: 991px) {
    .internet-tv {
        margin: 10px 0 20px;
        padding: 20px 0 10px;
    }
}

.internet-tele__images {
    border-radius: 12px 12px 0 0;
    height: 196px;
    position: relative;
}

@media (max-width: 991px) {
    .internet-tele__images {
        height: 150px;
    }
}

.internet-tele__images img {
    border-radius: 12px 12px 0 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.internet-tele__images-info {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 700;
    letter-spacing: 0.15px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    top: calc(50% - 10px);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.internet-tele__images-name {
    font-size: 36px;
    margin: 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .internet-tele__images-name {
        font-size: 30px;
    }
}

.internet-tele__images-sub {
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .internet-tele__images-sub {
        font-size: 20px;
    }
}

.internet-tele__intro {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 30px 0 12px 0;
    margin-top: -30px;
    padding: 15px 20px 20px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .internet-tele__intro {
        padding: 10px 20px 20px;
    }
}

.internet-tele__intro:hover {
    border-color: #ee0033;
}

.internet-tele__top {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.internet-tele__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    overflow: hidden;
    width: 50%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .internet-tele__name {
        font-size: 16px;
        line-height: 24px;
    }
}

.internet-tele__name:hover {
    color: #ee0033;
}

.internet-tele__name--full {
    width: 100%;
}

.internet-tele__price {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 54px;
    margin: 0 0 6px;
}

@media (max-width: 991px) {
    .internet-tele__price {
        height: auto;
    }
}

.internet-tele__price-new {
    color: #ee0033;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.internet-tele__price-old {
    color: #b5b4b4;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-decoration: line-through;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .internet-tele__price-old {
        font-size: 12px;
        line-height: 18px;
    }
}

.internet-tele__capacity {
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    margin: 6px 40px 12px;
    padding: 8px 0;
}

.internet-tele__capacity-value {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 700;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .internet-tele__capacity-value {
        font-size: 20px;
    }
}

.internet-tele__capacity-value span {
    color: #252525;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 5px;
}

@media (max-width: 991px) {
    .internet-tele__capacity-value span {
        color: #44494d;
        font-size: 12px;
    }
}

.internet-tele__data {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 36px;
    margin: 0 0 0 10px;
}

@media (max-width: 991px) {
    .internet-tele__data {
        font-size: 16px;
        line-height: 24px;
    }
}

.internet-tele__data span {
    color: #252525;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 0 5px;
    position: relative;
}

.internet-tele__detail {
    margin: 5px 0 10px;
}

.internet-tele__list {
    height: 142px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 991px) {
    .internet-tele__list {
        height: 124px;
    }
}

.internet-tele__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.internet-tele__item:not(:last-child) {
    margin: 0 0 8px;
}

.internet-tele__icon {
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px 0 0;
}

.internet-tele__icon i {
    font-size: 14px;
    line-height: 20px;
}

@media (max-width: 991px) {
    .internet-tele__icon i {
        font-size: 12px;
        line-height: 18px;
    }
}

.internet-tele__txt {
    color: #44494d;
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .internet-tele__txt {
        font-size: 12px;
        height: 36px;
        line-height: 18px;
    }
}

.internet-tele__txt.price {
    color: #ee0033;
    font-size: 20px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .internet-tele__txt.price {
        font-size: 14px;
    }
}

.internet-tele__see-more {
    color: #ee0033;
    display: block;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

@media (max-width: 991px) {
    .internet-tele__see-more {
        font-size: 14px;
        line-height: 20px;
    }
}

.internet-tele__see-more:hover {
    text-decoration: underline;
}

.internet-tele__btn {
    margin: 15px 0 0;
}

.internet-tele--it .internet-tele__images-sub {
    font-size: 30px;
}

@media (max-width: 991px) {
    .internet-tele--it .internet-tele__images-sub {
        font-size: 22px;
    }
}

.internet-tele--th .internet-tele__images-sub {
    font-size: 22px;
}

@media (max-width: 991px) {
    .internet-tele--th .internet-tele__images-sub {
        font-size: 18px;
    }
}

.viettel-plus {
    margin: 30px 0;
    word-break: break-word;
}

@media (max-width: 991px) {
    .viettel-plus {
        margin: 20px 0 30px;
    }
}

.viettel-plus__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 991px) {
    .viettel-plus__content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.viettel-plus__left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 363px;
    -ms-flex: 0 0 363px;
    flex: 0 0 363px;
    margin: 0 30px 0 0;
}

@media (max-width: 991px) {
    .viettel-plus__left {
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        margin: 0 0 30px;
        width: 100%;
    }
}

.viettel-plus__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .viettel-plus__right {
        margin: 0 -8px;
    }
}

.viettel-plus__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 15px;
    position: relative;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
}

@media (max-width: 991px) {
    .viettel-plus__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 calc(100% / 2 - 16px);
        -ms-flex: 0 0 calc(100% / 2 - 16px);
        flex: 0 0 calc(100% / 2 - 16px);
        margin: 0 8px;
    }
}

.viettel-plus__item:hover .viettel-plus__info {
    opacity: 1;
}

.viettel-plus__intro {
    margin: 15px 0 20px;
}

@media (max-width: 991px) {
    .viettel-plus__intro {
        margin: 10px 0 20px;
    }
}

.viettel-plus__des {
    color: #252525;
    font-size: 14px;
    letter-spacing: 0.0875px;
    line-height: 24px;
}

@media (max-width: 991px) {
    .viettel-plus__des {
        color: #44494d;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
}

.viettel-plus__images {
    border-radius: 8px 8px 8px 0;
    height: 440px;
}

@media (max-width: 991px) {
    .viettel-plus__images {
        height: 300px;
    }
}

.viettel-plus__images img {
    border-radius: 8px 8px 8px 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.viettel-plus__info {
    background: -webkit-linear-gradient(296.46deg, #ef0134 7.09%, #d7002e 62.85%);
    background: linear-gradient(153.54deg, #ef0134 7.09%, #d7002e 62.85%);
    border-radius: 0 0 8px 0;
    bottom: 0;
    opacity: 0;
    padding: 25px 20px;
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
}

@media (max-width: 991px) {
    .viettel-plus__info {
        height: 56px;
        opacity: 1;
        padding: 10px;
    }
}

.viettel-plus__name {
    color: #fff;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.15px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .viettel-plus__name {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
    }
}

.viettel-plus__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 0;
}

@media (max-width: 991px) {
    .viettel-plus__btn .button {
        min-width: 165px;
    }
}

.apply-number {
    background-color: #eee;
    margin: 30px 0;
    padding: 30px 0 40px;
    word-break: break-word;
}

@media (max-width: 991px) {
    .apply-number {
        padding: 20px 0 30px;
    }
    .apply-number .tab-title {
        display: none;
    }
}

.apply-number__detail {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .apply-number__detail {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }
}

.apply-number__left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .apply-number__left {
        margin: 0 0 20px;
        width: 100%;
    }
}

.apply-number__left .apply-number__images {
    height: 305px;
}

@media (max-width: 991px) {
    .apply-number__left .apply-number__images {
        height: 220px;
    }
}

.apply-number__left .apply-number__intro {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 30px 0 12px 0;
    margin-top: -30px;
    padding: 20px 20px 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .apply-number__left .apply-number__intro {
        padding: 15px 15px 20px;
    }
}

.apply-number__left .apply-number__intro:hover {
    border-color: #ee0033;
}

.apply-number__left .apply-number__des {
    height: 80px;
}

.apply-number__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 15px;
}

@media (max-width: 991px) {
    .apply-number__right {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 0;
        overflow: auto;
        width: 100%;
    }
}

.apply-number__right .apply-number__item {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 12px 12px 12px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 250px;
        -ms-flex: 0 0 250px;
        flex: 0 0 250px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 8px;
    }
    .apply-number__right .apply-number__item:not(:last-child) {
        margin: 0 8px;
    }
    .apply-number__right .apply-number__item:first-child {
        margin: 0 8px 0 0;
    }
    .apply-number__right .apply-number__item:last-child {
        margin: 0 0 0 8px;
    }
}

.apply-number__right .apply-number__item:hover {
    border-color: #ee0033;
}

.apply-number__right .apply-number__images {
    border-radius: 12px 0 0 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 263px;
    -ms-flex: 0 0 263px;
    flex: 0 0 263px;
    height: 144px;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__images {
        border-radius: 12px 12px 0 0;
        -webkit-box-flex: 1;
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
    }
}

.apply-number__right .apply-number__images img {
    border-radius: 12px 0 0 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__images img {
        border-radius: 12px 12px 0 0;
    }
}

.apply-number__right .apply-number__intro {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 15px 20px;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__intro {
        background-color: #fff;
        border-radius: 16px 0 0 0;
        margin: -16px 0 0;
        padding: 10px 12px 15px;
    }
}

.apply-number__right .apply-number__name {
    margin: 0 0 5px;
    -webkit-line-clamp: 1;
}

.apply-number__right .apply-number__info {
    margin: 5px 0 10px;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__info {
        margin: 5px 0;
    }
}

.apply-number__right .apply-number__des {
    height: 40px;
    -webkit-line-clamp: 2;
}

@media (max-width: 991px) {
    .apply-number__right .apply-number__des {
        font-size: 12px;
        height: 36px;
        line-height: 18px;
    }
}

.apply-number__item:not(:last-child) {
    margin: 0 0 26px;
}

.apply-number__images {
    border-radius: 12px 12px 12px 0;
}

.apply-number__images img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.apply-number__intro {
    position: relative;
}

.apply-number__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 6px;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .apply-number__name {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 5px;
    }
}

.apply-number__name:hover {
    color: #ee0033;
}

.apply-number__info {
    margin: 2px 0 20px;
}

@media (max-width: 991px) {
    .apply-number__info {
        margin: 5px 0 10px;
    }
}

.apply-number__des {
    color: #252525;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.apply-number__see-more {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

@media (max-width: 991px) {
    .apply-number__see-more {
        font-size: 14px;
        line-height: 20px;
    }
}

.apply-number__see-more:hover {
    text-decoration: underline;
}

.apply-number__time {
    color: #727272;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 12px;
    line-height: 18px;
}

.apply-number .tab-title li a {
    padding: 0 10px;
}

@media (max-width: 991px) {
    .apply-number .viewall {
        bottom: -35px;
    }
}

.support-customer {
    margin: 30px 0 0;
    padding: 0 0 60px;
    word-break: break-word;
}

@media (max-width: 991px) {
    .support-customer {
        margin: 30px 0 0;
        padding: 0 0 30px;
    }
}

.support-customer__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px -15px 0;
}

@media (max-width: 991px) {
    .support-customer__list {
        margin: 10px -8px 0;
    }
}

.support-customer__item {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 12px 12px 12px 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 4 - 30px);
    -ms-flex: 0 0 calc(100% / 4 - 30px);
    flex: 0 0 calc(100% / 4 - 30px);
    margin: 0 15px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

@media (max-width: 991px) {
    .support-customer__item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 calc(100% / 2 - 16px);
        -ms-flex: 0 0 calc(100% / 2 - 16px);
        flex: 0 0 calc(100% / 2 - 16px);
        margin: 0 8px 16px;
    }
}

.support-customer__item:hover {
    border-color: #ee0033;
}

.support-customer__images {
    border-radius: 12px 12px 0 0;
    height: 308px;
}

@media (max-width: 991px) {
    .support-customer__images {
        height: 150px;
    }
}

.support-customer__images img {
    border-radius: 12px 12px 0 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.support-customer__info {
    padding: 25px 10px;
}

@media (max-width: 991px) {
    .support-customer__info {
        padding: 15px 10px;
    }
}

.support-customer__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
    .support-customer__name {
        font-size: 14px;
        line-height: 20px;
    }
}

.support-customer__name:hover {
    color: #ee0033;
}

.radio-title__name {
    color: #44494d;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 2px;
}

@media (max-width: 991px) {
    .radio-title__name {
        font-size: 14px;
        line-height: 21px;
    }
}

.radio-title__sub {
    color: #ee0033;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

@media (max-width: 991px) {
    .radio-title__sub {
        font-size: 18px;
        line-height: 28px;
    }
}

@media (max-width: 991px) {
    .slider--mobile {
        display: none;
    }
}

.charge-fixed {
    padding: 0 0 60px;
    word-break: break-word;
}

@media (max-width: 991px) {
    .charge-fixed {
        padding: 30px 0 40px;
    }
}

.charge-fixed__title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 36px;
    margin: 0 0 25px;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .charge-fixed__title {
        font-size: 24px;
        letter-spacing: 0;
        margin: 0 0 20px;
    }
}

.charge-fixed__info {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 15px;
}

.charge-fixed__sub {
    color: #44494d;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .charge-fixed__sub {
        font-size: 20px;
        line-height: 28px;
    }
}

.charge-fixed__see-more {
    color: #ee0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 21px;
    margin: -5px 0 0 20px;
}

.charge-fixed__see-more:hover {
    text-decoration: underline;
}

.charge-fixed__top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px 25px;
}

@media (max-width: 991px) {
    .charge-fixed__top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 0 20px;
    }
}

.charge-fixed__user {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
    text-align: center;
}

@media (max-width: 991px) {
    .charge-fixed__user {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 20px;
        width: 100%;
    }
}

.charge-fixed__user-images {
    border-radius: 50%;
    height: 100px;
    margin: 0 auto 10px;
    width: 100px;
}

.charge-fixed__user-images img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.charge-fixed__user-name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 10px 0 3px;
}

@media (max-width: 991px) {
    .charge-fixed__user-name {
        font-size: 16px;
        line-height: 21px;
    }
}

.charge-fixed__user-des {
    color: #ee0033;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin: 3px 0 0;
}

@media (max-width: 991px) {
    .charge-fixed__user-des {
        font-size: 16px;
        letter-spacing: 0;
    }
}

.charge-fixed__total {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed__total {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        width: 100%;
    }
}

.charge-fixed__total .form-control {
    width: 360px;
}

@media (max-width: 991px) {
    .charge-fixed__total .form-control {
        width: 100%;
    }
}

.charge-fixed__total-info {
    margin: 25px 0 0;
}

@media (max-width: 991px) {
    .charge-fixed__total-info {
        margin: 20px 0 0;
    }
}

.charge-fixed__total-name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 10px;
}

@media (max-width: 991px) {
    .charge-fixed__total-name {
        font-size: 16px;
        line-height: 21px;
    }
}

.charge-fixed__total-price {
    color: #ee0033;
    font-size: 34px;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 0 0;
}

@media (max-width: 991px) {
    .charge-fixed__total-price {
        font-size: 24px;
        line-height: 28px;
    }
}

.charge-fixed__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 25px -15px 50px;
}

@media (max-width: 991px) {
    .charge-fixed__btn {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 20px 0 30px;
    }
}

.charge-fixed__btn-left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed__btn-left {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 15px;
        width: 100%;
    }
}

.charge-fixed__btn-left .button {
    margin: 0 auto;
}

.charge-fixed__btn-right {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed__btn-right {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        width: 100%;
    }
}

.charge-fixed__btn .button {
    width: 360px;
}

@media (max-width: 991px) {
    .charge-fixed__btn .button {
        width: 100%;
    }
}

.charge-fixed__detail {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 50px -15px 0;
}

@media (max-width: 991px) {
    .charge-fixed__detail {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 30px 0 0;
    }
}

.charge-fixed__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .charge-fixed__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }
}

.charge-fixed__row .packs-pstn {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 20px 15px 0;
    max-width: 100%;
    width: calc(100% / 2 - 30px);
}

@media (max-width: 991px) {
    .charge-fixed__row .packs-pstn {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        width: 100%;
    }
    .charge-fixed__row .packs-pstn:not(:last-child) {
        margin: 0 0 20px;
    }
}

.charge-fixed__link {
    color: #ee0033;
    display: block;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 21px;
    margin: 25px 0 0;
    text-align: center;
}

.charge-fixed__link:hover {
    text-decoration: underline;
}

.charge-fixed__search {
    margin: 0 auto;
    max-width: 850px;
    text-align: center;
}

@media (max-width: 991px) {
    .charge-fixed__search {
        max-width: 100%;
        width: 100%;
    }
}

.charge-fixed__search .charge-fixed__btn {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed__search .charge-fixed__btn {
        margin: 20px 0 15px;
    }
}

.charge-fixed__select {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px 30px;
}

@media (max-width: 991px) {
    .charge-fixed__select {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0 0 20px;
    }
}

.charge-fixed__select .form-control {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed__select .form-control {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 15px;
        width: 100%;
    }
    .charge-fixed__select .form-control:last-child {
        margin: 0;
    }
}

.charge-fixed__select .form-control--full {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 20px 15px 0;
}

.charge-fixed__note {
    color: #ee0033;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin: 10px 0 0;
}

.charge-fixed__table {
    margin: 40px 0 0;
}

@media (max-width: 991px) {
    .charge-fixed__table {
        margin: 30px 0 0;
        overflow-x: auto;
        width: 100%;
    }
}

.charge-fixed--detail .charge-fixed__user {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(40% - 30px);
    -ms-flex: 0 0 calc(40% - 30px);
    flex: 0 0 calc(40% - 30px);
}

.charge-fixed--detail .charge-fixed__total {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(60% - 30px);
    -ms-flex: 0 0 calc(60% - 30px);
    flex: 0 0 calc(60% - 30px);
}

.charge-fixed--history .charge-fixed__user {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% - 30px);
    -ms-flex: 0 0 calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
}

@media (max-width: 991px) {
    .charge-fixed--history .charge-fixed__user {
        margin: 0;
    }
}

.charge-fixed--history .payment-history__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

@media (max-width: 991px) {
    .charge-fixed--history .payment-history__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }
}

.charge-fixed--history .payment-history__item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px 20px;
}

.charge-fixed--history .payment-history__item:last-child {
    margin: 0 15px 20px;
}

@media (max-width: 991px) {
    .charge-fixed--history .payment-history__item:last-child {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .charge-fixed--history .payment-history__item {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 20px;
        width: 100%;
    }
}

.charge-fixed .payment-history {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .charge-fixed .payment-history {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        width: 100%;
    }
}

.traffic-lookup {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 2 - 30px);
    -ms-flex: 0 0 calc(100% / 2 - 30px);
    flex: 0 0 calc(100% / 2 - 30px);
    margin: 0 15px;
}

@media (max-width: 991px) {
    .traffic-lookup {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 20px;
        width: 100%;
    }
}

.traffic-lookup__images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 0 20px;
}

.traffic-lookup__images img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.traffic-lookup__link {
    color: #ee0033;
    display: block;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 21px;
    margin: 20px 0 0;
    text-align: center;
}

.traffic-lookup__link:hover {
    text-decoration: underline;
}

.payment-history {
    word-break: break-word;
}

.payment-history .charge-fixed__info {
    margin: 0 0 25px;
}

@media (max-width: 991px) {
    .payment-history .charge-fixed__info {
        margin: 0 0 15px;
    }
}

.payment-history__label {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 8px;
}

.payment-history__item {
    margin: 0 0 16px;
}

.payment-history__item:last-child {
    margin: 0;
}

.payment-history__box {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 8px 8px 8px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px 25px 10px;
}

@media (max-width: 991px) {
    .payment-history__box {
        padding: 15px 15px 10px;
    }
}

.payment-history__box.active,
.payment-history__box:hover {
    border-color: #ee0033;
}

.payment-history__box:not(:last-child) {
    margin: 0 0 16px;
}

.payment-history__left {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media (max-width: 991px) {
    .payment-history__left {
        width: 100%;
    }
}

.payment-history__icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 10px 0 0;
}

.payment-history__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.payment-history__name {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .payment-history__name {
        font-size: 16px;
        line-height: 21px;
    }
}

.payment-history__name:hover {
    color: #ee0033;
}

.payment-history__date {
    color: #44494d;
    font-size: 14px;
    line-height: 21px;
}

.payment-history__date span:not(:last-child) {
    margin: 0 2px 0 0;
}

.payment-history__price {
    color: #ee0033;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 0 0 20px;
}

@media (max-width: 991px) {
    .payment-history__price {
        font-size: 18px;
        line-height: 28px;
    }
}

.payment-history__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px 0 0;
}

.payment-history__btn .button {
    width: 360px;
}

@media (max-width: 991px) {
    .payment-history__btn .button {
        width: 100%;
    }
}

.packs-pstn {
    margin: 50px auto 0;
    max-width: 558px;
    width: 100%;
}

@media (max-width: 991px) {
    .packs-pstn {
        margin: 30px 0 0;
        max-width: 100%;
    }
}

.packs-pstn__title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 15px;
    text-align: center;
}

@media (max-width: 991px) {
    .packs-pstn__title {
        font-size: 20px;
        line-height: 28px;
    }
}

.packs-pstn__list {
    background-color: #fff;
    border-radius: 8px 8px 8px 0;
    margin: 15px 0 0;
    padding: 30px;
}

@media (max-width: 991px) {
    .packs-pstn__list {
        padding: 15px;
    }
}

.packs-pstn__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.packs-pstn__item.total {
    border-top: 1px solid #e7e7e7;
    padding: 15px 0 0;
}

.packs-pstn__item:not(:last-child) {
    margin: 0 0 15px;
}

.packs-pstn__name {
    color: #44494d;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 991px) {
    .packs-pstn__name {
        line-height: 21px;
    }
}

.packs-pstn__price {
    color: #ee0033;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 0 0 20px;
}

@media (max-width: 991px) {
    .packs-pstn__price {
        font-size: 16px;
        line-height: 21px;
    }
}

.re-charge {
    border-top: 1px solid #eee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 25px -5px 0;
    padding: 25px 0 0;
}

@media (max-width: 991px) {
    .re-charge {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 20px 0 0;
        padding: 20px 0 0;
    }
}

.re-charge__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 5px;
}

@media (max-width: 991px) {
    .re-charge__item {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        width: 100%;
    }
    .re-charge__item:not(:last-child) {
        margin: 0 0 10px;
    }
}

.re-charge__icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 32px;
    -ms-flex: 0 0 32px;
    flex: 0 0 32px;
    margin: 0 10px 0 0;
}

.re-charge__info {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.re-charge__name {
    color: #44494d;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    margin: 0 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.re-charge__value {
    color: #0c0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

@media (max-width: 991px) {
    .re-charge__value {
        font-size: 16px;
        line-height: 24px;
    }
}

.table-charge {
    background-color: #fff;
    border: 1px solid #eee;
    border-collapse: separate;
    border-radius: 8px 8px 8px 0;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
}

@media (max-width: 991px) {
    .table-charge {
        min-width: 1024px;
    }
}

.table-charge thead tr {
    background-color: #f6d0d0;
}

.table-charge tr:last-child td {
    border-bottom: 0;
}

.table-charge th,
.table-charge td {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    color: #44494d;
    font-size: 16px;
    padding: 20px 10px;
    text-align: center;
}

.table-charge th:last-child,
.table-charge td:last-child {
    border-right: 0;
}

@media (max-width: 991px) {
    .table-charge th,
    .table-charge td {
        font-size: 14px;
        padding: 15px 10px;
    }
}

.table-charge th {
    color: #0c0033;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding: 22px 10px 16px;
}

@media (max-width: 991px) {
    .table-charge th {
        font-size: 14px;
        padding: 18px 10px 12px;
    }
}

.table-charge .txt-left {
    text-align: left;
}

@media (max-width: 991px) {
    .table-charge--custom {
        min-width: 480px;
    }
}

.table-charge--custom tbody tr:last-child {
    background-color: #f6d0d0;
}

.table-charge--custom tr:last-child td {
    font-family: "FS PFBeauSansPro", sans-serif;
    font-weight: 600;
    padding: 24px 10px 18px;
}

@media (max-width: 991px) {
    .table-charge--custom tr:last-child td {
        padding: 18px 10px 12px;
    }
}

.support-contact {
    margin: 20px 0;
}

.support-contact__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -8px;
}

@media (max-width: 991px) {
    .support-contact__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
    }
}

.support-contact__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% / 3 - 16px);
    -ms-flex: 0 0 calc(100% / 3 - 16px);
    flex: 0 0 calc(100% / 3 - 16px);
    margin: 0 8px 15px;
}

@media (max-width: 991px) {
    .support-contact__item {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0 0 15px;
        width: 100%;
    }
    .support-contact__item:last-child {
        margin: 0;
    }
}

.support-contact__icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 28px;
    -ms-flex: 0 0 28px;
    flex: 0 0 28px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 10px 0 0;
}

.support-contact__name {
    color: #0c0033;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 991px) {
    .support-contact__name {
        font-size: 14px;
        line-height: 21px;
    }
}

@media (min-width: 576px) {
    .modal--charge .modal__content {
        max-width: 602px;
    }
}

.modal--charge .modal__body {
    padding: 0 40px 40px;
}

@media (max-width: 991px) {
    .modal--charge .modal__body {
        padding: 0 0 30px;
    }
}

.modal-charge__title {
    color: #44494d;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 15px;
    text-align: center;
}

@media (max-width: 991px) {
    .modal-charge__title {
        font-size: 20px;
        line-height: 28px;
    }
}

.modal-charge__info {
    margin: 15px 0 20px;
    text-align: center;
}

.modal-charge__sub {
    color: #ee0033;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin: 0 0 3px;
}

@media (max-width: 991px) {
    .modal-charge__sub {
        font-size: 16px;
        letter-spacing: 0;
    }
}

.modal-charge__date {
    color: #ee0033;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin: 3px 0 0;
}

@media (max-width: 991px) {
    .modal-charge__date {
        font-size: 16px;
        letter-spacing: 0;
    }
}

.modal-charge .payment-history {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 20px 0;
    width: 100%;
}

.modal-charge .payment-history__list {
    max-height: 270px;
    overflow: auto;
}

.modal-charge .payment-history__box {
    border: 0;
    border-radius: 0;
    padding: 0;
}

.modal-charge .payment-history__date {
    font-size: 16px;
}

@media (max-width: 991px) {
    .modal-charge .payment-history__date {
        font-size: 14px;
    }
}

.modal-charge__btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 20px 0 0;
}

.modal-charge__btn .button {
    width: 263px;
}

@media (max-width: 991px) {
    .modal-charge__btn .button {
        width: 100%;
    }
}

.footer .footer-pc {
    display: block;
}

@media (max-width: 991px) {
    .footer .footer-pc {
        display: none;
    }
}

.footer .footer-sp {
    display: none;
    text-align: left;
}

@media (max-width: 991px) {
    .footer .footer-sp {
        display: block;
    }
}

.footer-sp {
    color: #fff;
}

.footer-sp .des:not(:last-child) {
    margin-bottom: 15px;
}

.footer-sp .footer-info {
    margin-bottom: 10px;
}

.footer-sp .footer-info i,
.footer-sp .footer-info img {
    margin-right: 10px;
}

.footer-sp .footer-social {
    padding: 5px 0;
}

.footer-sp .link-social {
    margin: 0;
}

.footer-sp .link-social li {
    display: inline-block;
    padding: 0 5px;
    vertical-align: middle;
}

.footer-sp .link-social i {
    font-size: 24px;
}

.footer-sp .link-social img {
    width: 24px;
}

.footer-sp .copy-right {
    font-size: 12px;
    padding-bottom: 5px;
}

.footer-sp .site-map {
    font-size: 12px;
    text-decoration: underline;
}

.footer-sp .footer-top {
    border-bottom: 1px solid #fff;
    display: block;
    margin-bottom: 30px;
}

.footer-sp .footer-col {
    display: block;
    line-height: 18px;
    margin-bottom: 30px;
    width: 100%;
}

.footer-sp .footer-col .title {
    font-size: 14px;
    margin-bottom: 10px;
}

.footer-sp .footer-list a {
    font-size: 12px;
    letter-spacing: 0.1px;
}

.certification {
    margin: 0 0 18px;
}

.certification-title {
    color: #000;
    font-family: "FS PFBeauSansPro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 10px;
}

.certification-images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.invoices {
    padding-top: 25px;
}

.invoices h2.title-h2 {
    font-family: "Montserrat";
    margin-bottom: 60px;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .invoices h2.title-h2 {
        margin-bottom: 20px;
    }
}

.invoices .field {
    margin: 0 0 25px;
}

@media (max-width: 991px) {
    .invoices .field {
        margin: 0;
    }
}

.invoices .field label {
    padding: 10px 0 0;
}

@media (max-width: 991px) {
    .invoices .field label {
        padding: 0 0 10px;
    }
}

.invoices .field .control input,
.invoices .field .control select,
.invoices .field .control textarea {
    margin: 0;
}

.invoices .txt-center a {
    margin: 0;
    width: 360px;
}

@media (max-width: 991px) {
    .invoices .txt-center a {
        width: 100%;
    }
}

.field .addon {
    position: absolute;
    right: 15px;
    text-decoration: underline;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.field .addon a {
    color: #ee0033;
    font-size: 14px;
}

.tabs__detail {
    display: block;
}

@media (max-width: 991px) {
    .tabs__detail {
        display: none;
    }
}

.user-info {
    display: none;
}

@media (max-width: 991px) {
    .user-info {
        display: block;
        margin-bottom: 30px;
    }
}

.user-info__inner {
    text-align: center;
}

.user-info__top {
    background-color: #ee0033;
    color: #fff;
    padding: 10px 0 30px;
}

.user-info__detail {
    background-color: #ee0033;
    color: #fff;
    padding: 0 0 25px;
}

.user-info__box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    margin: 0 15px;
    padding: 20px;
    position: relative;
}

.user-info__box:before {
    background-color: #ee0033;
    border-radius: 0 0 100% 100%;
    content: "";
    height: 90px;
    left: -10%;
    position: absolute;
    right: -10%;
    top: 0;
    width: 120%;
    z-index: -1;
}

.user-info--like .user-info__top {
    padding: 10px 0 18px;
}

.box-info__top {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.box-info__label {
    background-color: #fe9a00;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
}

.box-info__name {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: bold;
    margin-left: 15px;
}

.box-info__sub {
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    margin-left: 10px;
}

.box-info__hsd {
    color: #ee0033;
    font-size: 12px;
    margin: 8px 0 12px;
}

.box-info__state {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 18px;
    margin-top: 12px;
}

.box-info__status {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 8px;
}

.box-info__status i {
    font-size: 16px;
    margin-right: 8px;
}

.box-info__reason {
    color: #ee0033;
    font-style: italic;
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
}

.box-info__detail {
    margin: 15px 0;
}

.box-info__button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.box-info__button .btn {
    border-radius: 6px;
    border-width: 1px;
    padding: 11px 16px;
    width: 50%;
}

.box-info__button .btn--full {
    width: 100%;
}

.box-info__button .btn-tracuoc {
    margin-right: 16px;
}

.user__name {
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.user__name:after {
    /* background: transparent url(../images/i-check.svg) no-repeat left top; */
    background-size: contain;
    content: "";
    display: inline-block;
    height: 11px;
    margin-left: 8px;
    width: 17px;
}

.user__tel {
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0 12px;
}

.user__detail {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 18px;
    margin: 0 auto;
    min-height: 27px;
    width: 215px;
}

.user__detail i {
    font-size: 10px;
    margin-right: 5px;
}

.user__level {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 55%;
}

.user__line {
    margin: 0 5px;
}

.user__point {
    font-weight: bold;
}

.status-success {
    color: #14d6cf;
}

.status-error {
    color: #ff0048;
}

.data__text {
    color: #576c8a;
    font-size: 12px;
    line-height: 18px;
}

.data__number {
    color: #576c8a;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-left: 5px;
    text-transform: uppercase;
}

.data__number.has-select i {
    font-size: 10px;
    font-weight: bold;
    margin-left: 5px;
    position: relative;
    top: -3px;
}

.data__date {
    color: #576c8a;
    font-size: 12px;
    line-height: 18px;
}

.tabs-pack-data .tit {
    display: none;
}

@media (max-width: 991px) {
    .tabs-pack-data .tit {
        display: block;
        margin: 0 0 5px;
    }
}

.page-ftth.page-qltkdd .tabs-utilities .tabs h3 {
    display: none;
}

@media (max-width: 991px) {
    .page-ftth.page-qltkdd .tabs-utilities .tabs h3 {
        display: block;
    }
}

.hidden-dots .owl-dots {
    display: none;
}

@media (max-width: 991px) {
    .error-service .content .tit {
        margin: 0;
    }
}

.inner-input .title-modal {
    display: none;
}

.inner-input .d-none {
    display: none;
}

@media (max-width: 991px) {
    .inner-input .d-none {
        display: block;
    }
}

.wifi h2.title-h2 {
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
}

.menu-pc {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 15px;
}

@media (max-width: 991px) {
    .menu-pc {
        display: none;
    }
}

.menu-sp {
    display: none;
    padding: 70px 15px 40px;
}

@media (max-width: 991px) {
    .menu-sp {
        display: block;
    }
}

.menu-sp__inner.opened .user-account {
    border-bottom: 0;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.menu-sp__inner.opened .user-account__list {
    display: none;
}

.menu-sp__inner.opened .detail-account__content {
    display: block;
}

.menu-sp__inner.opened .detail-account__action i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.menu-sp__inner.opened .menu-sp__content {
    display: none;
}

.menu-sp__top {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.menu-sp__info {
    width: 80%;
}

.menu-sp__logo {
    font-size: 0;
    line-height: 1;
}

.menu-sp__noti {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 20%;
}

.menu-sp__button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 30px;
}

.menu-sp__button .btn {
    border-radius: 6px;
    border-width: 1px;
    padding: 12px 15px;
    width: 50%;
}

.menu-sp__button .btn + .btn {
    margin-left: 16px;
}

.menu-sp__button .btn--full {
    width: 100%;
}

.menu-sp__account {
    border-bottom: 1px solid #e7e7e7;
    margin-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.menu-sp__content {
    margin-top: 40px;
}

.menu-sp__content.show-submenu .menu-sp__detail {
    display: none;
}

.menu-sp__sub {
    display: none;
}

.menu-sp__sub.active {
    display: block;
}

.menu-sp__list:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 25px;
}

.menu-sp__link {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}

.menu-sp__link.active {
    color: #ee0033;
}

.menu-sp__link.opened {
    padding-right: 5px;
}

.menu-sp__link.opened .menu-sp__icon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.menu-sp__link img {
    margin-right: 10px;
}

.menu-sp__link__icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
}

.menu-sp__back {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding-bottom: 20px;
}

.menu-sp__back i {
    font-size: 16px;
    margin-right: 20px;
}

.menu-sp--add-account .menu-sp__button {
    margin-top: 20px;
}

.menu-sp--add-account .menu-sp__content {
    margin-top: 25px;
}

.menu-sub__list {
    margin-top: 20px;
}

.menu-sub__title {
    color: #576c8a;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}

.menu-sub__subtitle {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}

.menu-sub__child {
    padding-left: 30px;
}

.menu-sub__link {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #576c8a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
}

.menu-slide__sub {
    display: none;
}

.menu-slide__link {
    color: #576c8a;
    font-size: 14px;
    line-height: 20px;
}

.user-sp {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.user-sp__img {
    border-radius: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 64px;
    -ms-flex: 0 0 64px;
    flex: 0 0 64px;
    height: 64px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 16px;
}

.user-sp__name {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.user-sp__phone {
    color: #ee0033;
    font-size: 14px;
    line-height: 21px;
}

.noti__link {
    position: relative;
}

.noti__icon {
    font-size: 0;
}

.noti__icon i {
    color: #ee0033;
    font-size: 27px;
}

.noti__dot {
    background-color: #ff0048;
    border-radius: 100%;
    height: 12px;
    position: absolute;
    right: 2px;
    top: -5px;
    width: 12px;
}

.search-sp {
    position: relative;
}

.search-sp__result {
    background-color: #fff;
    border-radius: 6px 0 6px 6px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    display: none;
    min-width: 356px;
    position: absolute;
    right: 3px;
    text-align: left;
    top: 37px;
    z-index: 99;
}

.search-sp__result:before {
    content: "";
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    height: 0;
    position: absolute;
    right: 0;
    top: -15px;
    width: 0;
}

.search-sp__box {
    padding: 16px;
}

.search-sp__box:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
}

.search-sp__input input {
    border: 0;
    border-radius: 0;
    color: #576c8a;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
}

.search-sp__input input::-webkit-input-placeholder {
    color: #bfc4ce;
}

.search-sp__input input::-moz-placeholder {
    color: #bfc4ce;
}

.search-sp__input input:-ms-input-placeholder {
    color: #bfc4ce;
}

.search-sp__input input::placeholder {
    color: #bfc4ce;
}

.search-sp__subtitle {
    color: #bfc4ce;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 5px;
    word-break: break-word;
}

.search-sp__button .btn {
    background-color: #fff;
    border: 1px solid #ff0000;
    color: #ff0000;
    width: 100%;
}

.search-sp__button .btn:hover {
    background-color: #ff0000;
    color: #fff;
}

.search-sp.opened .search-sp__result {
    display: block;
}

.tag-search {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tag-search__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.tag-search__link {
    background-color: #fff;
    border: 1px solid #ee0033;
    border-radius: 10px;
    color: #ee0033;
    font-family: "Montserrat";
    font-size: 14px;
    margin: 5px;
    padding: 4px 18px;
}

.tag-search__link:hover {
    background-color: #ee0033;
    color: #fff;
}

.history-search__link {
    color: #576c8a;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0;
}

.users-account {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.users-account__list {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 70%;
}

.users-account__info {
    width: 30%;
}

.users-account__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 64px;
    -ms-flex: 0 0 64px;
    flex: 0 0 64px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 64px;
}

.users-account__item img {
    border-radius: 50%;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 40px;
}

.users-account__name {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.detail-account__action {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ee0033;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.detail-account__action i {
    font-size: 8px;
}

.detail-account__all {
    font-size: 14px;
    margin-right: 5px;
}

.detail-account__content {
    display: none;
    padding-bottom: 50px;
    position: absolute;
    right: 0;
    top: 100%;
}

.box-account__item {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
    padding: 15px;
    position: relative;
    width: 100%;
}

.box-account__item:not(:last-child) {
    margin-bottom: 16px;
}

.box-account__item .radio-custom {
    padding-left: 0;
}

.box-account__item .radio-custom .checkmark {
    border: 1px solid #e7e7e7;
    height: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 18px;
}

.box-account__item .radio-custom input:checked ~ .checkmark:after {
    font-size: 18px;
    margin: 0;
}

.box-account__item.active {
    background-color: #ee0033;
}

.box-account__item.active .box-account__info {
    color: #fff;
}

.box-account__item.active .radio-custom input:checked ~ .checkmark:after {
    color: #fff;
}

.box-account__info {
    color: #979797;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-account__title {
    font-size: 16px;
    font-weight: bold;
}

.box-account__name {
    font-size: 12px;
    padding: 10px 0;
}

.box-account__des {
    font-size: 12px;
}

.box-account__button {
    margin-top: 30px;
}

.box-account__button .btn {
    border-radius: 6px;
    box-shadow: none;
    border-width: 1px;
    width: 100%;
}

.box-account__button .btn:not(:last-child) {
    margin-bottom: 20px;
}

.hhv-content {
    display: block;
}

@media (max-width: 991px) {
    .hhv-content {
        display: none;
    }
}

.product-like {
    display: none;
    padding: 0 16px 22px;
}

@media (max-width: 991px) {
    .product-like {
        display: block;
    }
}

.product-like__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    position: relative;
}

.product-like__close {
    color: #576c8a;
    font-size: 0;
    position: absolute;
    right: 16px;
    top: 16px;
}

.product-like__close i {
    font-size: 18px;
}

.product-like__img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 64px;
    -ms-flex: 0 0 64px;
    flex: 0 0 64px;
    margin-right: 16px;
    width: 64px;
}

.product-like__img img {
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.product-like__number {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 64px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 64px;
}

.product-like__number.bg-orange {
    background-color: #ffba23;
}

.product-like__number.bg-red {
    background-color: #fb4600;
}

.product-like__info {
    width: calc(100% - 100px);
}

.product-like__title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-like__sale {
    color: #ee0033;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 2px;
}

.product-like__price {
    color: #c4c4c4;
    font-size: 12px;
    line-height: 18px;
    text-decoration: line-through;
}

.product-like__des {
    color: #576c8a;
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    word-break: break-word;
}

.categories__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.categories__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 80px;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 10px;
    position: relative;
    width: 80px;
}

.categories__item:not(:last-child):after {
    background-color: #fff;
    content: "";
    height: 44px;
    position: absolute;
    right: 0;
    width: 1px;
}

.categories__name {
    color: #fff;
    font-size: 12px;
    max-height: 28px;
    letter-spacing: 0.125px;
    line-height: 14px;
    margin-top: 5px;
    text-transform: capitalize;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.goi-cuoc .data-pack-register-not-login {
    padding: 20px 0;
}

@media (max-width: 767px) {
    .goi-cuoc .inner-input .row label {
        margin: 8px 5px 0 0;
        width: 40%;
    }
}

@media (max-width: 767px) {
    .goi-cuoc .row .control {
        width: calc(100% - 40% - 5px);
    }
}

@media (max-width: 767px) {
    .goi-cuoc .inner-input .row.text-center {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0 -5px;
    }
    .goi-cuoc .inner-input .row.text-center .style-btn {
        margin: 0 5px;
        min-width: auto;
        width: calc(100% / 2 - 10px);
    }
}

.cred {
    color: #ff0048;
}

.cmain {
    color: #ee0033;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content h3.title a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content .des {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(39, 52, 125, 0.12);
    border-radius: 10px;
    overflow: hidden;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-content {
    box-shadow: none;
    border-radius: 0;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-image {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item .item-image img {
    position: absolute;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item.first-item {
    width: 750px;
    max-width: 100% !important;
    overflow: hidden;
}

.page-qltkdd .pack-data__date .pack-data__circle,
.page-qltkdd .pack-data__data .pack-data__circle {
    background: none;
    padding: 0;
    height: auto;
    position: relative;
}

.page-qltkdd .pack-data__date .pack-data__circle .pack-info,
.page-qltkdd .pack-data__data .pack-data__circle .pack-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item.first-item .item-content {
    z-index: 2;
}

.page-ttsk .content.tabs .tabcontent .content-tab .item:hover .item-image:before {
    opacity: 1;
    z-index: 1;
}

.main-video-ct .video-tt .videott-content .videott-content_item .item-image img,
.main-htkh
    .support-online
    .support-online_content
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .item
    .item-image
    img {
    width: 100%;
}

div.main.main-chatonline {
    background-color: #fff;
}

.page-qltkdd .ud-viettel .item .images_content-plus a img {
    border-radius: 10px;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .btn-delete {
    font-size: 0;
    padding-right: 0;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .btn-delete::after {
    content: "\e913";
    display: block;
    font-family: "icon-theme";
    font-size: 16px;
}

.page-qltkdd .tabs-pack-data.hhv-like-product .list-productlike .child-item .btn-delete:hover {
    color: #ee0033;
}

.main-pay .content.tabs .tabcontent .col-payl .action.mt-55 {
    margin-top: 55px;
}

.main-pay .content.tabs .tabcontent .col-payl .action .action-pay {
    min-width: 165px;
    height: 52px;
}

.hhv-info-private a.link-info:hover {
    text-decoration: underline;
}

.hhv-step-by-step.search__maintain .block__speed__bar .bar__progress__content li.check .bar__progress__text__normal {
    color: #0c0033;
}

.hhv-ctdh .btn-blue {
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 253px;
}

.hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input input {
    font-size: 14px;
}

.btn-upload__overlay {
    background: -webkit-linear-gradient(top, #000000 5.49%, rgba(196, 196, 196, 0) 100%);
    background: linear-gradient(180deg, #000000 5.49%, rgba(196, 196, 196, 0) 100%);
    position: absolute;
    background-blend-mode: multiply;
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
    -ms-transform: matrix(1, 0, 0, -1, 0, 0);
    transform: matrix(1, 0, 0, -1, 0, 0);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 95px;
    z-index: 8;
}

.btn-upload__btn {
    position: absolute;
    color: #fff !important;
    bottom: 10px;
    z-index: 9;
}

.btn-upload__btn.icon-edit {
    left: 10px;
}

.btn-upload__btn.fs-remove {
    right: 10px;
}

.search__maintain .maintain__title__text li a:hover {
    color: #ee0033;
}

.charges-price select {
    color: #bfc4ce;
}

.location-change .txt-account__address {
    font-size: 12px;
}

.form-group-item .btn-upload .preview {
    width: 100%;
}

.charges--ver2 .form-group--action {
    text-align: center;
}

.charges--ver2 .form-group--action .btn {
    width: calc((100% - 60px) / 3);
}

.charges--ver2 .form-group--action .txt-underline {
    display: block;
}

.charges--ver2 .form-group--action .txt-underline:hover {
    color: #b70d02;
}

.cart-sim .cart-inner .cart-infor .cart-data {
    margin-bottom: 15px;
}

.cart-sim .cart-inner .cart-infor .cart-change-fc .delete {
    margin-left: 10px;
}

select,
input {
    font-size: 14px;
}

.link-shop {
    position: absolute;
    left: 0;
}

.modal .inner-input p.t-cen a {
    width: 180px;
    padding: 15px 0;
}

.modal .inner-input p.t-cen a + a {
    margin-left: 45px;
}

.modal .inner-input p.t-cen a.btn-small {
    width: 169px;
}

@media (max-width: 767px) {
    .modal .inner-input p.t-cen a {
        padding: 12px 0;
        width: 50%;
    }
    .modal .inner-input p.t-cen .btn--full {
        width: 100%;
    }
}

.modal .hhv-group-btn .t-cen--btn {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.popup-register-pending {
    padding: 45px;
}

.popup-register-pending .choose-time .datepicker div.item-date {
    width: calc(50% - 15px);
}

.popup-register-pending .inner-input p.btn-two-full-width {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.popup-register-pending .inner-input p.btn-two-full-width a {
    width: calc(50% - 15px);
    margin: 0;
}

.popup-register-pending .inner-input p.btn-two-full-width a + a {
    margin-left: 30px;
}

@media (max-width: 767px) {
    .popup-register-pending {
        padding: 15px;
    }
    .popup-register-pending .choose-time .datepicker {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .popup-register-pending .choose-time .datepicker div.item-date {
        width: 100%;
    }
    .popup-register-pending .choose-time .datepicker div.item-date + div {
        margin-top: 10px;
    }
    .popup-register-pending .btn-two-full-width {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .popup-register-pending .inner-input p.btn-two-full-width a {
        width: 100%;
    }
    .popup-register-pending .inner-input p.btn-two-full-width a + a {
        margin-left: 0;
        margin-top: 10px;
    }
}

.link-top li a:hover {
    color: #ee0033;
}

.charges-account__edit-img.open,
.edit-img.open {
    overflow: unset;
}

.hhv-group-btn .t-cen--btn .btn + .btn {
    margin-left: 40px;
}

@media (max-width: 767px) {
    .hhv-group-btn .t-cen--btn .btn + .btn {
        margin-left: 16px;
    }
}

#otp-verification .reg-before .control {
    display: block;
}

@media (max-width: 767px) {
    #otp-verification .reg-before .control {
        padding-left: 0;
    }
}

.modal.pin-puk__popup .field .control .box-capcha {
    margin-top: 0;
}

.charges--ver2 .charges-form .field input,
.charges--ver2 .charges-form .field select {
    font-size: 16px;
}

.modal .inner-input .hhv-btn-icon p.t-cen a.btn.btn-large {
    width: 253px;
    padding: 0 20px 0 0;
}

.modal .inner-input .hhv-btn-icon p.t-cen a.btn.btn-large span.img {
    height: 42px;
    line-height: 40px;
    padding: 6px;
}

div.page-result-search .inner-search .child-item .inner-itm .item-content .box-btn .btn.btn-large {
    width: 150px;
}

.search__maintain .maintain__title__text li a {
    display: block;
}

.page-qltkdd .item__utilities__intro {
    padding: 10px 5px 10px 15px;
}

.tabs-utilities .lists.slider .item {
    margin: 0 15px;
}

.tabs-utilities .lists.slider .item .item__utilities {
    box-shadow: 0px 4px 16px rgba(39, 52, 125, 0.12);
}

.tabs-utilities .lists.slider .item .item__utilities:hover {
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.4);
}

@media (max-width: 1199px) {
    .page-ttsk .content.tabs .tabcontent .content-tab .item.first-item {
        width: 653px;
        max-width: 100% !important;
    }
}

@media (max-width: 991px) {
    .owl-carousel .owl-dots {
        width: 100%;
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        transform: translate(0);
        left: 0;
    }
}

@media (max-width: 767px) {
    .link-shop {
        position: relative;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-first {
        font-size: 15px;
    }
    .support-all .support-inner #box-chat .footer-chat {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat #menuChat,
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom #menuInfo {
        padding: 10px 10px;
    }
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-boxchat #menuChat img,
    .support-all .support-inner #box-chat .footer-chat .menuchat .menu-infocustom #menuInfo img {
        display: inline-block;
    }
    .main-pay .content.tabs .tabcontent .col-payl .row-group label {
        width: 100%;
    }
    .hhv-find-nd49 .content .hhv-find-phone .form-find-phone .control .ipt-input {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10px;
    }
    .charges-form .field label {
        width: 100%;
        margin-bottom: 10px;
    }
    .charges-form .field .control {
        padding-left: 0;
    }
    .charges--ver2 .form-group--action .btn {
        width: 100%;
    }
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.85;
    position: relative;
    padding-right: 8px;
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin img {
    width: 14px;
    margin-right: 4px;
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin:before {
    position: absolute;
    top: -8px;
    left: 10px;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    opacity: 0.85;
    content: "1";
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin.coin1:before {
    content: "1";
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin.coin2:before {
    content: "2";
}

.tabs__detail__content__left__bot_showtk .tabs__detail__content__left__coin.coin3:before {
    content: "3";
}

.tabs__detail__content__left__top_listtkkm .inner_tabs__detail__content__left__top_listtkkm {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    z-index: 12;
}

.tabs__detail__content__left__top_listtkkm
    .inner_tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__title {
    cursor: pointer;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm {
    display: none;
    vertical-align: top;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(39, 52, 125, 0.12);
    position: absolute;
    top: 90%;
    right: 0;
    z-index: 3;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .inner_tabs__detail__content__left__listkm {
    position: relative;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .inner_tabs__detail__content__left__listkm:before {
    content: "";
    position: absolute;
    top: -20px;
    right: 10px;
    border-style: solid;
    border-width: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #fff;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .firs-ttkkm {
    padding: 30px 30px 10px;
    border-bottom: 1px solid #ee0033;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .firs-ttkkm .txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ee0033;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .firs-ttkkm .price {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #ee0033;
    vertical-align: middle;
    padding: 0 10px;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .firs-ttkkm .vnd {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ee0033;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .firs-ttkkm
    .tabs__detail__content__mid__timer {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ee0033;
    padding-left: 44px;
    padding-top: 5px;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .firs-ttkkm
    .tabs__detail__content__mid__timer
    i {
    vertical-align: text-top;
    margin-right: 10px;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .list-tkkm {
    padding: 10px 30px 20px;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .list-tkkm li {
    margin-bottom: 14px;
    padding-left: 44px;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .list-tkkm li .txt {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #576c8a;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm .list-tkkm li .price {
    font-weight: 500;
    color: #576c8a;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .list-tkkm
    li
    .tabs__detail__content__mid__timer {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #576c8a;
}

.tabs__detail__content__left__top_listtkkm
    .tabs__detail__content__left__listkm
    .list-tkkm
    li
    .tabs__detail__content__mid__timer
    i {
    vertical-align: top;
    margin-right: 8px;
}

.tabs__detail__content__left__top_listtkkm .tabs__detail__content__left__listkm.open {
    display: inline-block;
}

.hhv_custom_banner .item {
    position: relative;
}

.hhv_custom_banner .item .wp-btn-app {
    position: absolute;
    bottom: 26%;
    left: 25%;
}

.hhv_custom_banner a.btn-play,
.hhv_custom_banner a.btn-store {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
}

.hhv_custom_banner a.btn-play img,
.hhv_custom_banner a.btn-store img {
    width: auto;
}

.hhv-phuong-an-slider .owl-carousel .owl-stage-outer {
    padding: 40px 0;
    margin: -40px 0;
}

.mobile-service .view-detail {
    border: 0;
}

@media (max-width: 1366px) {
    .hhv_custom_banner .item .wp-btn-app {
        left: 20%;
    }
}

@media (max-width: 991px) {
    .hhv_custom_banner .item .wp-btn-app {
        left: 10%;
    }
}

@media (max-width: 767px) {
    .search__maintain .search-store_content .box-search {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }
    .search-store .search-store_content .box-search .control {
        margin-bottom: 15px;
    }
    .search__maintain .location__detail__info__titlev2 {
        padding-left: 10px;
    }
    .hhv_custom_banner a.btn-play,
    .hhv_custom_banner a.btn-store {
        width: 100px;
        margin: 0 5px;
    }
}

@media (max-width: 480px) {
    #hhv-edit-avata .popup-upload {
        left: -50px;
    }
}

@media (max-width: 360px) {
    .support-all .support-inner #box-chat {
        width: 290px;
    }
    #hhv-edit-avata .popup-upload {
        left: -80px;
    }
}

@media (min-width: 768px) {
    .hidden-xs {
        display: block;
    }
    .visible-xs {
        display: none;
    }
}

@media (max-width: 991px) {
    .hidden-xs {
        display: none;
    }
    .visible-xs {
        display: block;
    }
    .row-top .link-top {
        display: none;
    }
    .row-top {
        background-color: #ee0033;
    }
    .row-top .content {
        padding: 25px 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .row-top .language {
        display: none;
    }
    .search-top .icon-search {
        color: #fff;
        font-size: 26px;
        font-weight: 300;
    }
    .banner-slideshow .owl-stage-outer {
        overflow: hidden;
    }
    .lists.slider {
        margin: 0 -15px;
    }
    .lists.slider .item .text-intro .left .price {
        font-size: 14px;
    }
    .lists.slider .item .text-intro .right {
        display: none;
    }
    .lists.slider .item .text-intro .more-right .rg {
        width: 100%;
        text-align: center;
        line-height: 32px;
    }
    .lists.slider .item .text-intro .more-right .view-detail {
        display: none;
    }
    .content.tabs {
        position: relative;
    }
    .content .tit h1.left {
        float: left;
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 70%;
    }
    .content .btabs {
        position: relative;
        z-index: 2;
    }
    .content .btabs.left {
        float: right;
        margin: 3px 0 0;
    }
    .content .btabs ul.tab-title {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        padding: 0 10px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
        margin-top: 10px;
        display: none;
    }
    .content .btabs ul.tab-title li {
        display: block;
        border-bottom: 1px solid #ddd;
        padding: 12px 0 8px;
    }
    .content .btabs ul.tab-title li:last-child {
        border-bottom: 0;
    }
    .content .btabs ul.tab-title li.active a {
        border-bottom: 0;
    }
    .content .btabs.opened ul.tab-title {
        display: block;
    }
    .tabs {
        position: relative;
    }
    .mobile-service .item .text-intro {
        bottom: 15px;
    }
    .mobile-service .item .text-intro span {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
    }
    .mobile-service .item .text-intro .more-right .rg {
        border: 1px solid #ee0033;
        border-radius: 6px;
        font-family: "Montserrat";
        font-size: 14px;
    }
    .mobile-service .more-right {
        margin: 15px 0 0;
    }
    .dv-internet .lists {
        margin: 0 -15px;
    }
    .dv-internet .intro-more {
        padding: 15px;
    }
    .dv-internet .intro-more h2 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
    }
    .dv-internet .intro-more .top-title .txt-dv {
        color: #ee0033;
    }
    .dv-internet .intro-more .top-title .txt-dv span {
        font-size: 14px;
    }
    .dv-internet .more-c {
        border: 0;
        padding: 0;
    }
    .dv-internet .more-c a {
        border: 1px solid #ee0033;
        border-radius: 6px;
        box-shadow: none;
        display: inline-block;
        padding: 8px 32px;
    }
    .dv-internet .list-check li {
        color: #252525;
        font-size: 12px;
        line-height: 14px;
        padding: 0 0 12px 18px;
    }
    .dv-internet .list-check li i {
        font-size: 10px;
        top: 7px;
    }
    .dv-internet .sub-tabs .item {
        margin: 0 15px;
    }
    .dv-internet .images-plus img {
        border-radius: 6px 6px 0 0;
        height: 110px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .viettel-plush {
        background-color: #e7e7e7;
        padding-top: 20px;
    }
    .viettel-plush .viettel-content img {
        border-radius: 6px;
        max-height: 136px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
    }
    .viettel-plush .more.list-check {
        display: none;
    }
    .viettel-plush a.applynow {
        margin: 15px 0;
        text-align: center;
        width: 100%;
    }
    .page-bot {
        margin-top: 20px;
    }
    .page-bot .support h1 {
        margin: 0 0 15px;
    }
    .news-event .intro h3 a {
        font-size: 16px;
    }
    .news-event .text p {
        font-size: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
    .apply {
        margin-bottom: 30px;
    }
    .apply .sub-tabs .subright {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        overflow: auto;
    }
    .apply .sub-tabs .subright .items {
        box-shadow: 0 1px 6px #ccd8db;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 185px;
        -ms-flex: 0 0 185px;
        flex: 0 0 185px;
        margin: 0 15px 10px;
        width: 185px;
    }
    .apply .sub-tabs .subright .items:first-child {
        margin-left: 2px;
    }
    .support-all {
        bottom: 0;
    }
    .page-qltkdd .tabs h3 {
        color: #000;
        font-family: "Montserrat";
        font-size: 16px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 70%;
    }
    .page-qltkdd .tabs h3.pack-data__tit {
        color: #576c8a;
        font-family: "Roboto";
        font-size: 16px;
        line-height: 24px;
        padding: 0 15px;
        text-align: left;
        text-transform: inherit;
        width: 100%;
    }
    .page-qltkdd .owl-carousel .owl-stage-outer {
        margin-bottom: 0;
        padding: 0 0 20px;
    }
    .page-qltkdd .item__box {
        padding: 20px;
    }
    .page-qltkdd .item__title {
        color: #000;
        font-size: 16px;
        line-height: 24px;
    }
    .page-qltkdd .item__text {
        color: #252525;
        font-size: 14px;
        line-height: 21px;
    }
    .page-qltkdd .item__price {
        color: #ee0033;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
    .page-qltkdd .lists img {
        border-radius: 10px 10px 0 0;
    }
    .page-qltkdd .tabs.tabs-dvdsd .btn {
        background: #ee0033;
        border-radius: 6px;
        display: block;
        color: #fff;
        margin-top: 15px;
        padding: 8px 22px;
        width: 100%;
    }
    .page-qltkdd .box-data-cacgoi .inner-item h2 {
        font-size: 16px;
        line-height: 24px;
    }
    .page-qltkdd .box-data-cacgoi .inner-item span {
        font-size: 16px;
        line-height: 19px;
    }
    .page-qltkdd .int {
        font-size: 14px;
        line-height: 21px;
    }
    .page-qltkdd .box-data .view {
        margin: 18px 0 0;
    }
    .page-qltkdd .ud-viettel .item__title {
        font-size: 14px;
        line-height: 21px;
        padding-top: 10px;
    }
    .page-qltkdd .tabs-lookups {
        margin-bottom: 25px;
        padding: 15px 0 0;
    }
    .page-qltkdd .tabs-lookups .lists.slider .owl-stage-outer {
        padding-bottom: 20px;
    }
    .page-qltkdd .tabs-lookups .lists.slider .owl-carousel {
        padding: 0;
    }
    .page-qltkdd .tabs-lookups .item__title {
        color: #4d4d4d;
        font-size: 12px;
        line-height: 16px;
        padding: 10px 0 0;
    }
    .page-qltkdd .tabs-utilities {
        padding: 0;
    }
    .page-qltkdd .tabs-utilities .tit {
        margin: 0;
    }
    .page-qltkdd .tabs-utilities .utilities__image {
        padding: 0 55px;
    }
    .page-qltkdd .tabs-utilities .lists.slider .item .item__utilities {
        background: #fff;
        border-radius: 6px;
        -webkit-flex-flow: nowrap;
        -ms-flex-flow: nowrap;
        flex-flow: nowrap;
    }
    .page-qltkdd .tabs-utilities .lists.slider .owl-stage-outer {
        padding-top: 20px;
    }
    .page-qltkdd .tabs-pack-data {
        margin-bottom: 0;
    }
    .page-qltkdd .tabs-promotion,
    .page-qltkdd .tabs-service {
        margin-bottom: 10px;
    }
    .page-qltkdd .item__utilities {
        height: 53px;
        margin-bottom: 16px;
    }
    .page-qltkdd .item__utilities__img {
        padding: 15px;
        width: 64px;
    }
    .page-qltkdd .item__utilities__intro {
        padding: 10px 10px 10px 15px;
        width: calc(100% - 64px);
    }
    .page-qltkdd .intro__tit {
        font-size: 12px;
        line-height: 16px;
    }
    .page-qltkdd .pack-data__text__big {
        font-size: 14px;
        line-height: 21px;
    }
    .page-qltkdd .pack-data__text__small {
        font-size: 12px;
        line-height: 18px;
    }
    .page-qltkdd .pack-data__action__link {
        font-size: 12px;
    }
    .page-qltkdd .pack-data__data,
    .page-qltkdd .pack-data__date {
        margin-top: -190px;
        padding: 0;
    }
    .page-qltkdd .pack-data__data .pack-data__circle .pack-info,
    .page-qltkdd .pack-data__date .pack-data__circle .pack-info {
        position: inherit;
    }
    .page-qltkdd .pack-data__action {
        border-top: 0;
        padding: 0;
    }
    .page-qltkdd .pack-data__detail {
        padding: 0 0 10px;
    }
    .page-qltkdd .pack-data__action__link:before {
        margin-right: 2px;
    }
    .page-qltkdd .pack-data__action__link.pack__share:before {
        background-position: center center;
        background-size: cover;
        height: 10px;
        top: 1px;
        width: 12px;
    }
    .page-qltkdd .pack-data__action__link.pack__circle:before {
        background-position: center center;
        background-size: cover;
        height: 11px;
        top: 1px;
        width: 11px;
    }
    .page-qltkdd .ud-viettel {
        margin-bottom: 5px;
    }
    .page-ftth.page-qltkdd .mobile-service.hot-sales .tabs-dvdsd .lists.slider .item {
        box-shadow: 0px 10px 10px rgba(39, 52, 125, 0.12);
    }
    .page-ftth.page-qltkdd .mobile-service.hot-sales .item__box {
        padding: 20px;
    }
    .page-ftth.page-qltkdd .tabs-utilities:before {
        display: none;
    }
    .page-ftth.page-qltkdd .mobile-service.hot-sales {
        margin: 0 0 10px;
    }
    .page-ftth.page-qltkdd .ud-viettel {
        margin: 0 0 5px;
    }
    .page-ftth.page-qltkdd .item__utilities {
        margin-bottom: 30px;
    }
    .error-service .error-service__content .error-service__content-inner {
        overflow: auto;
        width: 100%;
    }
    .error-service .error-service__content .error-service__content-inner .error-service__content-item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 200px;
        -ms-flex: 0 0 200px;
        flex: 0 0 200px;
        margin-bottom: 10px;
        width: 200px;
    }
    .error-service .error-service__content .error-service__content-inner .error-service__content-item a span {
        bottom: 15px;
        font-family: "Montserrat";
        font-size: 14px;
        line-height: 21px;
    }
    .error-service .error-service__content .error-service__content-inner .error-service__content-item a img {
        height: 230px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .page-qltkdd.page-ftth .tabs-utilities .utilities__image,
    .page-qltkdd.page-ftth.page-ftth3 .tabs-utilities .utilities__image {
        padding: 0 55px;
    }
    .popup-data-sharing {
        padding-bottom: 25px;
        width: 100%;
    }
    .inner-input h1 {
        display: none;
    }
    .inner-input .title-modal {
        color: #000;
        display: block;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-top: 20px;
        word-break: break-word;
    }
    .inner-input p.modal-des {
        color: #576c8a;
        font-size: 14px;
        line-height: 21px;
        margin: 15px 0 20px;
    }
    .wifi {
        margin: 30px 0 20px;
    }
    .wifi h2.title-h2 {
        color: #000;
        font-size: 16px;
        line-height: 24px;
    }
    .field .control input,
    .field .control textarea,
    .field .control select {
        border-radius: 6px;
        padding: 10px 15px;
    }
    .wifi-form .box-capcha {
        border-color: #e7e7e7;
        border-radius: 6px;
        width: 100%;
    }
    .open .row-menu {
        background: #fff;
        width: 85%;
    }
}

.row-menu .boxmenu ul li .submenu .submenu-lv-2 {
    position: absolute;
    display: none;
    top: 0;
    width: 284px;
    z-index: 99;
    background: #fff;
    left: 200px;
}

.row-menu .boxmenu ul li .submenu li:hover .submenu-lv-2 {
    display: block;
}
