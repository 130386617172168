.vat-service__des-2lines {
    height: 36px;
}

.slider-search__description-2lines {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.transform-y--6 {
    transform: translateY(-6px);
}

.vertical-bar-right:before {
    content: '';
    position: absolute;
    top: 4px;
    right: -36.5px;
    width: 1px;
    height: 33px;
    background-color: #000;
}