.footer .footer-content.footer-contentfooter {
  background-color: transparent;
  padding: 0;
}

.footer .footer-content .footer-top {
  background: #ee0033;
}

.footer .footer-content .footer-top .footer-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  margin: 0 -15px;
}

.footer .footer-content .footer-top .column {
  padding: 0 15px;
}

.footer .footer-content .footer-top .column.first .infotop {
  width: 300px;
  max-width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 50px;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

/* .footer .footer-content .footer-top .column.first .infotop .logo-fotter {
    margin-bottom: 20px;
} */

.footer .footer-content .footer-top .column.first .infobellow {
  font-size: 12px;
}

.footer .footer-content .footer-top .column .menu {
  margin-bottom: 30px;
}

.footer .footer-content .footer-top .column .menu h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 15px;
}

.footer .footer-content .footer-top .column .menu .sub-menu li {
  line-height: 18px;
}

.footer .footer-content .footer-top .column .menu .sub-menu li a {
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.footer .footer-content .footer-bottom {
  background: #0aa5aa;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  padding: 15px 0;
}

.footer .footer-content .footer-bottom .content .address-support {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer .footer-content .footer-bottom .content .address-support li {
  line-height: 14px;
}

.footer .footer-content .footer-bottom .content .address-support li i {
  margin-top: -2px;
  margin-right: 5px;
}

.footer .footer-content .footer-bottom .content .address-support li img {
  margin-right: 5px;
}

.footer
  .footer-content
  .footer-bottom
  .content
  .address-support
  li:first-child {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #fff;
}
