.form-wrapper {
  margin: auto;
  padding: 0 10px;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 20px;
}

.create-form-title {
  padding: 20px 10px 10px;
}

.create-form {
  padding: 0 10px;
}

.create-form-item label {
  font-weight: 500;
  min-width: 150px;
}

.create-form-btn {
  margin-bottom: 20px;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.rowClass {
  display: flex;
  margin-bottom: 30px;
}

.label {
  min-width: 150px;
}

.required {
  color: red;
}
